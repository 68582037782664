import React from "react";

import { Link, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const DatosCliente = ({ cliente, nombre, tel, styles }) => (
  <View style={[styles.rowcontentCliente]}>
    <View style={styles.rowCliente}></View>

    <View style={styles.rowCliente}>
      <Text>CLIENTE:</Text>
      <Text>{" " + cliente}</Text>
    </View>
    <View
      style={[
        styles.rowCliente,
        styles.sizeTextCliente,
        styles.blacktextCliente,
      ]}
    >
      <Text>{nombre}</Text>
    </View>
    <View style={[styles.rowCliente, styles.sizeTextCliente]}>
      <Text>TEL:</Text>
      <Text>{" " + tel}</Text>
    </View>
    <View style={styles.rowCliente}></View>
  </View>
);

export default DatosCliente;
