import React, { useEffect, useState,useContext } from "react";
import PaqueteService from "../../../services/Paquete.service";
import Titulo from "./Titulo";
import NosotrosService from "../../../services/Nosotros.service";

const Nosotros = () => {
  const [loading, setLoading] = useState(true);
  const [nosotros, setNosotros] = useState([]);
  
  useEffect(() => {
    getNosotros();
    window.scrollTo(0, 0);
  }, []);


  const getNosotros = async () => {
    
    try {
      const data =await NosotrosService.getAll();
        setNosotros(data[0]);
        console.log(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div className="main-wrapper packages-grid">
      <Titulo />
      <h1>Nosotros</h1>
      <section className="bg-smoke py-10">
        <div className="containerPublic">
          <div className="row">            
            <div className="col-md-12 col-lg-12 mb-5">
            <p style={{textAlign:"justify"}}
              dangerouslySetInnerHTML={{
                __html: nosotros.Cuerpo,
              }}
            ></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Nosotros;
