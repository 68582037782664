import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import TicketsService from "../../../../services/Tickets.service";
import Header from "./Header";
import MyTable from "./Table";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontDamily: "Lato",
    padding: 30,
    textTransform: "uppercase",
  },
  header: { height: "10%", margin: 10, flexDirection: "row", fontSize: 14 },
  logoheader: { height: "40px", width: "136px", margin: 10 },
  textheader: { position: "absolute", top: 10, right: 10, width: "100px" },
  textsubtitleright: { position: "absolute", right: 10, width: "200px" },
  totalessection: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 10,
    flexDirection: "row",
  },
  totalsection: { marginRight: 20 },
  textheadertable: { fontSize: 10 },
  footer: {
    fontSize: "8px",
    color: "#7b818d",
    marginTop: 20,
  },
  section: { color: "white", textAlign: "center", margin: 10 },
  item: {
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    margin: 10,
    borderBottom: "1px solid #dde4ea",
    borderTop: "1px solid #dde4ea",
  },
  itemcontent: {
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    margin: 10,
    flexDirection: "row",
  },
  itemcliente: { width: "30%", textAlign: "left", fontSize: 14 },
  itemdetalles: { textAlign: "left", width: "70%", fontSize: 14 },
  itemfooter: {
    textAlign: "left",
    width: "100%",
    flexDirection: "row",
    fontSize: "14px",
    color: "#506690",
  },
  itemfooteritem: { margin: 10 },
  fontnegrita: { fontFamily: "Lato Bold" },
  texttitle: { marginBottom: 20 },
  textdireccion: { marginTop: 20 },
  totaldivisor: { borderBottom: "1px solid #000", height: "1px" },
  metodo: { flexDirection: "row", justifyContent: "space-between" },
  tableseparator: { marginBottom: 20, height: "1px" },
  monto: { marginLeft: 3 },
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

const orderAsc = (a, b) => {
  if (Number(a.total) < Number(b.total)) {
    return -1;
  }
  if (Number(a.total) > Number(b.total)) {
    return 1;
  }
  return 0;
};

const orderDesc = (a, b) => {
  if (Number(a.total) > Number(b.total)) {
    return -1;
  }
  if (Number(a.total) < Number(b.total)) {
    return 1;
  }
  return 0;
};

const orderFAscR = (a, b) => {
  let fechaa = new Date(a.fechaRecepcion);
  let fechab = new Date(b.fechaRecepcion);
  if (fechaa < fechab) {
    return -1;
  }
  if (fechaa > fechab) {
    return 1;
  }
  return 0;
};

const orderFDescR = (a, b) => {
  let fechaa = new Date(a.fechaRecepcion);
  let fechab = new Date(b.fechaRecepcion);
  if (fechaa > fechab) {
    return -1;
  }
  if (fechaa < fechab) {
    return 1;
  }
  return 0;
};
const orderFAsc = (a, b) => {
  let fechaa = new Date(a.fecha);
  let fechab = new Date(b.fecha);
  if (fechaa < fechab) {
    return -1;
  }
  if (fechaa > fechab) {
    return 1;
  }
  return 0;
};

const orderFDesc = (a, b) => {
  let fechaa = new Date(a.fecha);
  let fechab = new Date(b.fecha);
  if (fechaa > fechab) {
    return -1;
  }
  if (fechaa < fechab) {
    return 1;
  }
  return 0;
};

// Create Document Component
const MyDocument = ({
  search,
  filtero,
  ordenTotal,
  ordenFechaRecepcion,
  ordenFechaEntrega,
}) => {
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);

  const today = new Date();
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );

  useEffect(() => {
    searchFunctionPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filtero]);

  useEffect(() => {
    orderTotal();
  }, [ordenTotal]);

  useEffect(() => {
    orderFechaRecepcion();
  }, [ordenFechaRecepcion]);

  useEffect(() => {
    orderFecha();
  }, [ordenFechaEntrega]);

  const orderTotal = () => {
    let orderT = tickets.sort(!orderTotal ? orderAsc : orderDesc);
    setTickets(orderT);
  };
  const orderFechaRecepcion = () => {
    let orderF = tickets.sort(!ordenFechaRecepcion ? orderFAscR : orderFDescR);
    setTickets(orderF);
  };
  const orderFecha = () => {
    let orderF = tickets.sort(!ordenFechaEntrega ? orderFAsc : orderFDesc);
    setTickets(orderF);
  };

  const searchFunctionPage = async () => {
    try {
      let searchData = {
        search: search,
        filters: filtero,
      };
      const data = await TicketsService.allByClienteSearch(searchData);

      if (data) {
        setTickets(data.data);
        setTotalTickets(data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Document pdfVersion="1.7">
      <Page size="A4" style={styles.page} wrap={true}>
        <Header
          src="/static/media/logo2.77456c09d5f4412e909f.png"
          date={today.toLocaleDateString()}
          text="La Esperanza | CUIDAMOS LA EXCELENCIA DE TU IMAGEN"
        />

        <View style={styles.textheadertable}>
          <Text style={styles.fontnegrita}>Tickets</Text>
        </View>
        <MyTable rows={tickets} />
        <View style={styles.tableseparator}></View>

        <View style={styles.totalessection} wrap={false}>
          <View style={[styles.metodo, styles.fontnegrita]}>
            <Text>TOTAL DE TICKETS</Text>
            <Text style={styles.monto}>{totalTickets}</Text>
          </View>
        </View>

        <View style={styles.footer}>
          <Text>La Esperanza | CUIDAMOS LA EXCELENCIA DE TU IMAGEN</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
