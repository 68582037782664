/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";

const CrearCarrusel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [loading, setLoading] = useState(true);
  const [imagen, setImagen] = useState("");
  useEffect(() => {
    getNoticia();
  }, []);

  const getNoticia = async () => {
    setLoading(true);
    try {
      const data = await NoticiaService.getById(parseInt(id));
      setNoticia(data[0]);
      if (data[0].imagen) {
        const imagenS3 = await S3Service.get(data[0].imagen[0]);
        setImagen(imagenS3.result);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteNoticia = async () => {
    setLoading(true);
    try {
      console.log("ELIMINAR");
      if (noticia.imagen) {
        const imagen = await S3Service.delete(noticia.imagen);
        console.log("---------se borro" + imagen.result);
      }
      const noticiaBorrada = await NoticiaService.remove(id);
      toast.info("Noticia eliminada");
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/blog");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Eliminar entrada</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/blog")}
                    >
                      Blog
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Eliminar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="card-body">
                      <div className="row g-3">
                        {noticia.imagen ? (
                          <div>
                            <div className="col-lg-12">
                              <div style={{ textAlign: "center" }}>
                                <img
                                  src={String(imagen)}
                                  style={{
                                    height: 300,
                                    objectFit: "contain",
                                    width: "-webkit-fill-available",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              disabled={true}
                              value={noticia.titulo}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: noticia.cuerpoNoticia,
                                }}
                                style={{
                                  textAlign: "justify",
                                  marginRight: "10%",
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              value={noticia.fraseCelebre}
                            />
                            <label>Frace celebre</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              value={noticia.autorFraseCelebre}
                            />
                            <label>Autor</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              onChange={(e) => {
                                setNoticia({
                                  ...noticia,
                                  categoria: e.target.value,
                                });
                              }}
                              value={noticia.categoria}
                              defaultValue={noticia.categoria}
                            />
                            <label>Categoria</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => deleteNoticia()}
                >
                  <span className="small">Eliminar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
