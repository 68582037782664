import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";
import MensajeService from "../../../services/Mensaje.service";

const BlogMensaje = ({
  id,
  nombre,
  createdAt,
  imagenes,
  MensajeCuerpo,
  borrar,
  borrarComentario,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
  }, []);

  const getImagen = async () => {
    try {
      if (imagenes != undefined && imagenes != null) {
        console.log();
        const result = await S3Service.get(imagenes);
        setImag(result.result);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {/* Comment 1 + Reply */}
      <div className="d-flex mb-4">
        {/* avatar : as background */}
        <div style={{ width: 180 }} className="text-center">
          <div
            className="mb-1 rounded-circle d-inline-block bg-light bg-cover "
            style={{
              width: 70,
              height: 70,
            }}
          >
            <img
              className="w-100 lazyestload mb-1 rounded-circle d-inline-block bg-light bg-cover "
              src={imagenes == undefined ? require("./assets/user.png") : imag}
              alt="Generic placeholder image"
              style={{
                width: 70,
                height: 70,
              }}
            />
          </div>
          <span className="d-block text-dark">{nombre}</span>
          <small className="d-block text-dark smaller">
            {String(createdAt).slice(0, 10)}
          </small>
        </div>
        <div className="w-100">
          <p>{MensajeCuerpo}</p>

          {/* options */}
          <ul className="list-inline my-3">
            {/* borrar */}
            <li className="list-inline-item">
              {borrar ? (
                <button
                  type="submit"
                  onClick={() => {
                    borrarComentario(id);
                  }}
                  className="btn text-primary p-0"
                >
                  Borrar
                </button>
              ) : (
                ""
              )}
            </li>
            {/* /borrar */}
          </ul>
          {/* /options */}
        </div>
      </div>
    </>
  );
};

export default BlogMensaje;
