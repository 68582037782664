import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 9,
  },
  headerCol: {
    backgroundColor: "#d3d3d3",
  },
  fontnegrita: { fontFamily: "Lato Bold" },
});

const formatDateCrate = (fecha) => {
  const newdatec = new Date(fecha);
  return newdatec.toLocaleString();
};

// Componente que renderiza la tabla
const MyTable = ({ rows }) => {
  return (
    <View style={styles.table} wrap={true}>
      {/* Fila del header */}
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>UUID</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>CLIENTE</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>FECHA NACIMIENTO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>CORREO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>NUMERO</Text>
        </View>
      </View>
      {rows?.map((row, index) => (
        <>
          <View key={index} style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.uuid}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.nombre}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                <i className="fa fa-calendar fa-1" aria-hidden="true" />{" "}
                {String(row.fechaNacimiento).slice(0, 10)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.correo}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.telefono}</Text>
            </View>
          </View>
        </>
      ))}
    </View>
  );
};

export default MyTable;
