/* React */
import React, { useState, useEffect, useRef } from "react";

/* Componentes y dependencias */
import Preloader from "../../utils/PreloaderElement";
import { Box, TablePagination } from "@mui/material";
import Slide from "@mui/material/Slide";
import { toast } from "react-toastify";
import MensajeDetalles from "./MensajeDetalles";

/* Servicios */
import MensajeService from "../../../services/Contacto.service";
import Mensaje from "./Mensaje";

const TablaMensaje = () => {
  const [mensajes, setMensajes] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [update, setUpdate] = useState(false);
  const [message, setMessage] = useState(null);
  const [visible, setVisible] = useState(false);

  const containerRef = useRef(null);

  const onUpdate = () => {
    setUpdate(!update);
  };
  useEffect(() => {
    getMensajes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, update]);

  const getMensajes = async () => {
    setLoading(true);
    try {
      const data = await MensajeService.list(limit, page * limit);
      if (data) {
        setMensajes(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMessage = (selectMessage) => {
    setMessage(selectMessage);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setMessage(null);
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">MENSAJES</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Hay {mensajes.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <Box className="section p-0" ref={containerRef}>
                <div className={visible ? "min-vh-50 hide" : "min-vh-50 show"}>
                  {/* item list */}
                  <div className="card-body table-responsive-md min-vh-50">
                    <table className="table" style={{ marginLeft: "10px" }}>
                      <thead>
                        <tr>
                          <th
                            className="small text-muted"
                            style={{ width: 100 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className="d-flex link-muted"
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className="ms-2">#</span>
                            </a>
                          </th>
                          <th
                            className="small text-muted"
                            style={{ width: 350 }}
                          >
                            NOMBRE
                          </th>
                          <th
                            className="small text-muted"
                            style={{ width: 300 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className="d-flex link-muted"
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className="ms-2">EMAIL</span>
                            </a>
                          </th>
                          <th
                            className="small text-muted"
                            style={{ width: 200 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className="d-flex link-muted"
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className="ms-2">STATUS</span>
                            </a>
                          </th>
                          <th
                            className="small text-muted"
                            style={{ width: 200 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className="d-flex link-muted"
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className="ms-2">
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                CREACIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className="small text-muted"
                            style={{ width: 20 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className="d-flex link-muted"
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className="ms-2"></span>
                            </a>
                          </th>
                          <th
                            className="small text-muted"
                            style={{ width: 20 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className="d-flex link-muted"
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className="ms-2"></span>
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {mensajes.map((record, index) => {
                          return (
                            <MensajeDetalles
                              onSumit={onUpdate}
                              key={index}
                              id={record.id}
                              data={record}
                              selected={handleMessage}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* pagination, selected items */}
                  <div className="row">
                    <div className="col py-3 text-center text-md-start"></div>
                    <div className="col-md-auto py-3">
                      {/* pagination */}
                      <nav
                        className="w-100 text-center"
                        aria-label="Pagination"
                      >
                        {/* pagination : desktop */}
                        <nav
                          className="text-center float-md-end mt-3 mt-md-0 d-none d-md-flex"
                          aria-label="Pagination"
                        >
                          <TablePagination
                            style={{ color: "var(--color-dark-variant)" }}
                            component="div"
                            classes="recent-orders"
                            labelRowsPerPage="Items por pagina"
                            count={total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </nav>
                      </nav>
                    </div>
                  </div>
                </div>
                <Slide
                  direction="right"
                  in={visible}
                  container={containerRef.current}
                >
                  <div
                    className={
                      !visible
                        ? "card-body min-vh-50 hide"
                        : "card-body min-vh-50 show"
                    }
                  >
                    <Mensaje
                      id={message?.id}
                      onSumit={onUpdate}
                      onClose={handleClose}
                    />
                  </div>
                </Slide>
              </Box>
            </>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaMensaje;
