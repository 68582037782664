import httpClient from "./HttpClient";
const prefix = "/permiso";

export default class PermisoService {
  static async getAll() {
    return (await httpClient.get(`${prefix}s/`)).data;
  }

  static async update(noticia) {
    return (await httpClient.put(`${prefix}/${noticia.usuario}`, noticia)).data;
  }
}
