import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import TicketsService from "../../../../services/Tickets.service";
import Header from "./Header";
import MyTable from "./Table";
import DatosOrden from "./DatosOrden";

import MartialMonoMedium from "../../../utils/fonts/MartianMono/MartianMono_Condensed-Medium.ttf";
import MartialMonoExtraBold from "../../../utils/fonts/MartianMono/MartianMono_Condensed-ExtraBold.ttf";

import CourierPrimeRegular from "../../../utils/fonts/Courier_Prime/CourierPrime-Regular.ttf";
import CourierPrimeBold from "../../../utils/fonts/Courier_Prime/CourierPrime-Bold.ttf";

import RobotoRegular from "../../../utils/fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../../../utils/fonts/Roboto/Roboto-Black.ttf";
import ConfiguracionService from "../../../../services/Configuracion.service";

import FiraRegular from "../../../utils/fonts/fira-mono/FiraMono-Regular.otf";
import FiraBold from "../../../utils/fonts/fira-mono/FiraMono-Bold.otf";

import SpaceRegular from "../../../utils/fonts/Space_Mono/SpaceMono-Regular.ttf";
import SpaceBold from "../../../utils/fonts/Space_Mono/SpaceMono-Bold.ttf";

import AntonioRegular from "../../../utils/fonts/Antonio/Antonio-Regular.ttf";
import AntonioBold from "../../../utils/fonts/Antonio/Antonio-Bold.ttf";

import PoppinsRegular from "../../../utils/fonts/Poppins/Poppins-Regular.ttf";
import PoppinsBold from "../../../utils/fonts/Poppins/Poppins-Black.ttf";

import Bodoni9ptRegular from "../../../utils/fonts/Bodonic/BodoniModa_9pt-Regular.ttf";
import Bodoni9ptBold from "../../../utils/fonts/Bodonic/BodoniModa_9pt-Black.ttf";

import Bodoni18ptRegular from "../../../utils/fonts/Bodonic/BodoniModa_18pt-Regular.ttf";
import Bodoni18ptBold from "../../../utils/fonts/Bodonic/BodoniModa_18pt-Black.ttf";

import JostRegular from "../../../utils/fonts/Jost/Jost-Regular.ttf";
import JostBold from "../../../utils/fonts/Jost/Jost-Black.ttf";

import MonserratRegular from "../../../utils/fonts/Monserrat/MontserratAlternates-Regular.ttf";
import MonserratBold from "../../../utils/fonts/Monserrat/MontserratAlternates-Black.ttf";

import MuseoModernoRegular from "../../../utils/fonts/MuseoModerno/MuseoModerno-Regular.ttf";
import MuseoModernoBold from "../../../utils/fonts/MuseoModerno/MuseoModerno-Black.ttf";

Font.register({
  family: "MuseoModerno-Regular",
  src: MuseoModernoRegular,
});
Font.register({
  family: "MuseoModerno-Bold",
  src: MuseoModernoBold,
});

Font.register({
  family: "Monserrat-Regular",
  src: MonserratRegular,
});
Font.register({
  family: "Monserrat-Bold",
  src: MonserratBold,
});

Font.register({
  family: "Jost-Regular",
  src: JostRegular,
});
Font.register({
  family: "Jost-Bold",
  src: JostBold,
});

Font.register({
  family: "Bodoni9pt-Regular",
  src: Bodoni9ptRegular,
});
Font.register({
  family: "Bodoni9pt-Bold",
  src: Bodoni9ptBold,
});

Font.register({
  family: "Bodoni18pt-Regular",
  src: Bodoni18ptRegular,
});
Font.register({
  family: "Bodoni18pt-Bold",
  src: Bodoni18ptBold,
});

Font.register({
  family: "Poppins-Regular",
  src: PoppinsRegular,
});
Font.register({
  family: "Poppins-Bold",
  src: PoppinsBold,
});

Font.register({
  family: "Antonio-Regular",
  src: AntonioRegular,
});
Font.register({
  family: "Antonio-Bold",
  src: AntonioBold,
});

Font.register({
  family: "SpaceMono-Regular",
  src: SpaceRegular,
});
Font.register({
  family: "SpaceMono-Bold",
  src: SpaceBold,
});

Font.register({
  family: "FiraMono-Regular",
  src: FiraRegular,
});
Font.register({
  family: "FiraMono-Bold",
  src: FiraBold,
});

Font.register({
  family: "MartialMono-Regular",
  src: MartialMonoMedium,
});
Font.register({
  family: "MartialMono-Bold",
  src: MartialMonoExtraBold,
});

Font.register({
  family: "CourierPrime-Regular",
  src: CourierPrimeRegular,
});
Font.register({
  family: "CourierPrime-Bold",
  src: CourierPrimeBold,
});

Font.register({
  family: "Roboto-Regular",
  src: RobotoRegular,
});
Font.register({
  family: "Roboto-Bold",
  src: RobotoBold,
});

// Create Document Component
const MyDocument = ({ id }) => {
  const today = new Date();
  const [ticket, setTicket] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState(0);
  const [pagos, setPagos] = useState([]);
  const [ingesos, setIngresos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [pagoclientes, setPagoclientes] = useState(0);
  const newdate = new Date();
  const [productos, setProductos] = useState([]);
  const [nota, setNota] = useState("");
  const [usuario, setUsuario] = useState({
    nombre: "Nombre",
    correo: "Correo",
    telefono: "Telefono",
    direccion: "Direccion",
  });
  const [pagado, setPagado] = useState(0);
  const [fontFamilyValue, setFontFamilyValue] = useState(
    "CourierPrime-Regular"
  );
  const [fontFamilyBoldValue, setFontFamilyBoldValue] =
    useState("CourierPrime-Bold");

  const [fontSizeValue, setFontSizeValue] = useState(9);
  const [fontSizeBoldValue, setFontSizeBoldValue] = useState(12);

  const [pageWidthValue, setPageWidthValue] = useState(75);

  const staticdata = {
    propietario: "ANA KAREN CONTRERAS MARIN",
    direccion: "NORTE UNO 423 UNIDAD MODELO 2A SECCION",
    estado: "OAXACA DE JUAREZ,OAXACA ",
    cp: "68100",
    tel: "(951)1543743/1326569",
    rfc: "COMA910720Q46",
  };

  // Create styles
  const styles = StyleSheet.create({
    page: {
      fontSize: 7,
      fontFamily: fontFamilyValue,
      textTransform: "uppercase",
    },
    header: { height: "10%", margin: 10, flexDirection: "row" },
    logoheader: { height: "40px", width: "136px", margin: 10 },
    textheader: { position: "absolute", top: 10, right: 10, width: "100px" },
    textsubtitleright: {
      flexDirection: "column",
      flexGrow: 0,
      alignSelf: "flex-end",
      justifySelf: "flex-end",
    },
    texttotales: {
      // alignSelf: "stretch",
      // justifyContent: "space-between",
      justifyContent: "flex-end",
    },
    footer: {
      color: "#7b818d",
      marginTop: 8,
      textAlign: "center",
    },
    section: { color: "white", textAlign: "center", margin: 10 },
    totalessection: {
      width: "98.1%",
      marginTop: 4,
      fontSize: fontSizeValue,
      rowGap: 5,
      lineHeight: 1.5,
    },
    item: {
      backgroundColor: "white",
      color: "black",
      textAlign: "center",
      margin: 10,
      borderBottom: "1px solid #dde4ea",
      borderTop: "1px solid #dde4ea",
    },
    itemcontent: {
      backgroundColor: "white",
      color: "black",
      textAlign: "center",
      margin: 10,
      flexDirection: "row",
    },
    itemcliente: { width: "30%", textAlign: "left" },
    itemdetalles: { textAlign: "left", width: "70%" },
    itemfooter: {
      textAlign: "left",
      width: "100%",
      flexDirection: "row",
      color: "#506690",
    },
    itemfooteritem: { margin: 10 },
    fontnegrita: {
      fontFamily: fontFamilyBoldValue,
    },
    texttitle: { marginBottom: 20 },
    textdireccion: { marginTop: 20 },
    cliente: { marginTop: 20, marginBottom: 20 },
    clienterow: { flexDirection: "row", gap: 2 },
    textanticipo: {
      borderBottom: "0.5px dashed #7b818d",
      marginBottom: 2,
    },
    textjustify: {
      textAlign: "justify",
    },
    textwrap: {
      flexWrap: "wrap",
    },
    divider: {
      borderBottom: "0.5px dashed #0000",
    },
    dividerwhite: {
      marginTop: 1,
      marginBottom: 1,
      borderBottom: "0.5px dashed #ffffff",
    },

    containerCliente: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    subcontainerCliente: {
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "center",
      textTransform: "uppercase",
    },
    detailColumnCliente: {
      flexDirection: "column",
      flexGrow: 9,
      textTransform: "uppercase",
    },
    linkColumnCliente: {
      flexDirection: "column",
      flexGrow: 2,
      alignSelf: "flex-end",
      justifySelf: "flex-end",
    },
    nameCliente: {
      fontFamily: fontFamilyBoldValue,
    },
    subtitleCliente: {},
    linkCliente: {
      color: "black",
      textDecoration: "none",
      alignSelf: "flex-end",
      justifySelf: "flex-end",
    },
    rowcontentCliente: {
      marginBottom: 2,
      rowGap: 5,
    },
    rowCliente: {
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      gap: 5,
      flexWrap: "wrap",
      fontSize: fontSizeValue,
    },
    logoCliente: { height: "65px", width: "100%" },
    sizeTextCliente: {
      fontSize: fontSizeBoldValue,
    },
    blacktextCliente: {
      fontFamily: fontFamilyBoldValue,
    },

    containerOrden: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    subcontainerOrden: {
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "center",
      textTransform: "uppercase",
    },
    detailColumnOrden: {
      flexDirection: "column",
      flexGrow: 9,
      textTransform: "uppercase",
    },
    linkColumnOrden: {
      flexDirection: "column",
      flexGrow: 2,
      alignSelf: "flex-end",
      justifySelf: "flex-end",
    },
    nameOrden: {
      fontFamily: fontFamilyBoldValue,
    },
    subtitleOrden: {},
    linkOrden: {
      color: "black",
      textDecoration: "none",
      alignSelf: "flex-end",
      justifySelf: "flex-end",
    },
    rowcontentOrden: {
      rowGap: 5,
    },
    rowOrden: {
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: 5,
      fontSize: fontSizeValue,
      rowGap: 5,
    },
    logoOrden: { height: "65px", width: "100%" },
    blacktextOrden: {
      fontFamily: fontFamilyBoldValue,
    },
    sizeTextOrden: {
      fontSize: fontFamilyBoldValue,
      display: "flex",
      flexDirection: "row",
    },

    table: {
      // display: "table",
      width: "100%",
      marginTop: 2,
      borderStyle: "dashed",
      borderWidth: 0.5,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
      borderStyle: "dashed",
      borderColor: "#7b818d",
      borderWidth: 0.5,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontSize: fontSizeValue,
      paddingVertical: 2,
    },
    tableCol: {
      width: "69%",
      marginRight: 2,
    },
    tableColCant: {
      width: "30%",
    },
    tableColImporte: {
      width: "30%",
    },
    tableCell: {
      margin: "auto",
      marginTop: 3,
      fontSize: fontSizeValue,
    },
    headerCol: {
      fontFamily: fontFamilyBoldValue,
    },
  });

  useEffect(() => {
    if (id) {
      Promise.all([getTicket(), getConfiguracionImpresion()]).finally(() => {
        setLoading(false);
      });
    }
    // getPagos();
  }, [id]);

  const toMoney = (value) => {
    const money = Number(value);

    if (isNaN(money)) {
      return value;
    }

    // return money.toLocaleString("en", {
    //   style: "currency",
    //   currency: "MXN",
    // });
    return `$ ${money.toFixed(2)}`;
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleString("es", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "numeric",
    });
  };

  const formatDateh = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleDateString();
  };

  const addProduct = (newProduct) => {
    console.log(newProduct);
    setProductos((prev) => [...prev, newProduct]);

    setTotal(
      (prev) =>
        prev + Number(newProduct.value.precio) * Number(newProduct.cantidad)
    );
  };

  const getTicket = async () => {
    setLoading(true);
    try {
      const data = await TicketsService.getById(id);
      console.log(data);
      setProductos([]);
      setTotal(0);
      setUsuario(data.cliente);
      setTicket(data);
      setNota(data.nota);
      setPagado(Number(data.pagado));
      setPagos(data.pagos);
      for (const detalle of data.detallesTiket) {
        let newProduct = {
          cantidad: detalle.cantidad,
          value: {
            label: detalle.producto.nombre,
            id: detalle.producto.id,
            categoria: detalle.producto.categoria?.tipo,
            precio: detalle.producto.precios[0]?.precio,
            descuento: detalle.producto.descuento,
          },
        };
        addProduct(newProduct);
      }
    } catch (error) {}
  };

  const getConfiguracionImpresion = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionImpresion();
      setFontFamilyBoldValue(data.fontFamilyBold);
      setFontFamilyValue(data.fontFamily);
      setFontSizeValue(data.fontSize);
      setFontSizeBoldValue(data.fontSizeBold);
      setPageWidthValue(data.pageWidth);
    } catch (error) {}
  };

  const getPagos = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await TicketsService.listPagoFecha({ fecha: newdate });
      console.log(data);
      setPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresos(newIngresos);
      setPagoclientes(efectivo + tarjeta + transferencia);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Document pdfVersion="1.7">
      <Page
        size={[pageWidthValue, 210]}
        dpi={182}
        style={styles.page}
        wrap={false}
      >
        <Header
          src="/static/media/logoTicket.b1bafc9016e5486bc76f.png"
          date={formatDateh(ticket?.createdAt)}
          text={staticdata}
        />
        <DatosOrden
          styles={styles}
          serie={ticket?.uuid}
          fechaentrega={formatDate(ticket?.fecha)}
          fecharecibido={formatDate(ticket?.fechaRecepcion)}
        />
        <MyTable concepto={nota} pagos={pagos} styles={styles} />
        <View style={styles.totalessection} wrap={false}>
          <View style={[styles.textsubtitleright, styles.clienterow]}>
            <Text style={styles.fontnegrita}>IMPORTE</Text>
            <Text>{toMoney(ticket.pagado)}</Text>
          </View>
        </View>
        <View style={styles.totalessection} wrap={false}>
          <View style={[styles.clienterow]}>
            <Text style={styles.fontnegrita}>ATENDIÓ:</Text>
            <Text style={styles.textwrap}>
              {ticket?.responsableservicio?.nombre}
            </Text>
          </View>
        </View>

        <View style={styles.footer}>
          <Text>La Esperanza | CUIDAMOS LA EXCELENCIA DE TU IMAGEN</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
