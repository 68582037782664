import { Route, Routes } from "react-router-dom";
import FullPageLoading from "../utils/FullPageLoading";
/* Public components*/
import PublicLayout from "../components/public/layout/PublicLayout";
import Login from "../components/public/login/Login";
import PaqueteIndividual from "../components/public/paquetes/PaqueteIndividual";
import BookingStep1 from "../components/public/booking/BookinStep1";
import BookingStep3 from "../components/public/booking/BookinStep3";
import PaquetesGrid from "../components/public/paquetes/PaquetesGrid";
import BlogGrid from "../components/public/blog/BlogGrid";
import ServicioIndividual from "../components/public/servicios/ServicioIndividual";
import Nosotros from "../components/public/layout/Nosotros";
//Inicio
import Inicio from "../components/public/portada/Inicio";
import Contactanos from "../components/public/layout/Contactanos";
import AvisoPrivacidad from "../components/public/layout/AvisoPrivacidad";
import Terminos from "../components/public/layout/Terminos";
import ServiciosGrid from "../components/public/servicios/ServiciosGrid";
//login
import IniciarSesion from "../components/public/login/IniciarSesion";
import SignUp from "../components/public/login/signup";

import BlogEntrada from "../components/public/blog/BlogEntrada";
import ListaTickets from "../components/public/cliente/ListaTickets";
import TicketDetails from "../components/public/cliente/TicketDetails";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Inicio />} />
        <Route path="paquetes" element={<PaquetesGrid />} />
        <Route path="paquete/:id" element={<PaqueteIndividual />} />
        <Route path="paquetes/:filtroI" element={<PaquetesGrid />} />
        <Route path="bookingStepUno/:id" element={<BookingStep1 />} />
        <Route path="bookingStepTres/:id" element={<BookingStep3 />} />
        <Route path="blog" element={<BlogGrid />} />
        <Route path="blog/:id" element={<BlogEntrada />} />
        <Route path="aviso" element={<AvisoPrivacidad />} />
        <Route path="terminosycondiciones" element={<Terminos />} />
        <Route path="contactanos" element={<Contactanos />} />
        <Route path="nosotros" element={<Nosotros />} />
        <Route path="Servicios" element={<ServiciosGrid />} />
        <Route path="Servicios/:id" element={<ServicioIndividual />} />
        <Route path="iniciarsesion" element={<IniciarSesion />} />
        {/* <Route path="registrarse" element={<SignUp />} /> */}
        <Route path="tickets" element={<ListaTickets />} />
        <Route path="ticket/:id" element={<TicketDetails />} />
        <Route path="*" element={<FullPageLoading />} />
      </Route>
    </Routes>
  );
};

export default PublicRoutes;
