import React, { useEffect, useState } from "react";
/*Servicios*/
import S3Service from "../../../services/S3.service";
import CarruselService from "../../../services/Carrusel.service";
/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import AOS from "aos";
import "aos/dist/aos.css";

const Slide = () => {
  const navigate = useNavigate();
  const [carrusels, setCarrusels] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
    AOS.init();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await CarruselService.getActive();
      setCarrusels(data.data);
      for (const record of data.data) {
        const imagen = await S3Service.get(record.imagen);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const buton = (url, boton) => {
    return (
      <div className="text-center-xs">
        <a
          href={url}
          className="btn btn-primary bg-gradient scroll-to shadow-primary-xlg row-pill fw-medium d-inline-flex align-items-center px-4 mb-3"
        >
          <span className="me-1">{boton}</span>
          <svg
            width={18}
            height={18}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="bi bi-arrow-right-short"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
            />
          </svg>
        </a>
        {/*<a
          href={url}
          target="_blank"
          rel="noopener nofollow"
          className="btn bg-light-hover btn-ghost row-pill fw-medium d-inline-flex align-items-center px-4 mb-3"
        >
          <span className="me-1">{boton}</span>
          <svg
            width={18}
            height={18}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="bi bi-arrow-right-short"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
            />
          </svg>
        </a>*/}
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <div id="inicio">
            <Carousel
              slide={false}
              nextLabel={""}
              prevLabel={""}
              style={{ height: "601px" }}
              indicators={false}
            >
              {carrusels.map((record, index) => {
                return (
                  <Carousel.Item indicators="false">
                    <img
                      className="d-block w-100"
                      src={imagenes[index]}
                      alt="imagen"
                      style={{ filter: "brightness(70%)", height: "600px" }}
                    />
                    <Carousel.Caption>
                      <div className="row g-4 justify-content-md-between align-items-md-center mb-5">
                        {/* texto */}
                        <div
                          className="col-lg-6 col-xl-6"
                          data-aos="fade-up"
                          data-aos-delay={100}
                        >
                          <div className="mb-5">
                            <h2 className="mb-5 display-2 fw-bold">
                              <span className="d-block">{record.titulo}</span>
                              {/*<span className="d-block fs-1 text-primary">
                                {record.subtitulo}
                              </span>*/}
                            </h2>
                            <p className="fs-3 text-primary">
                              {record.subtitulo}
                            </p>
                          </div>
                          {record.url ? buton(record.url, record.boton) : null}
                        </div>
                        {/* images */}
                        {/*<div
                          className="col-lg-6 col-xl-6"
                          data-aos="fade-up"
                          data-aos-delay={100}
                        >
                           images 
                          <div
                            className="position-relative text-center"
                            data-aos="fade-in"
                            data-aos-delay={450}
                          >
                            <div className="position-absolute bottom-0 w-100 z-index-1">
                              <span
                                className="d-inline-block ratio ratio-1x1 rounded-circle bg-cover mx-1 mb-n5"
                                data-jarallax-element={-30}
                                style={{
                                  maxWidth: 200,
                                  backgroundImage:
                                    "url(demo.files/images/unsplash/team/thumb_330/michael-dam-mEZ3PoFGs_k-unsplash.jpg)",
                                }}
                              />
                              <span
                                className="d-inline-block ratio ratio-1x1 rounded-circle bg-cover mx-1 mt-n5"
                                data-jarallax-element={-60}
                                style={{
                                  maxWidth: 130,
                                  backgroundImage:
                                    "url(demo.files/images/unsplash/team/thumb_330/foto-sushi-6anudmpILw4-unsplash.jpg)",
                                }}
                              />
                            </div>
                          </div>
                        </div>*/}
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};

export default Slide;
