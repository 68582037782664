import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

//servicios
import ServicioService from "../../../services/Servicio.service";

import { AuthContext } from "../../../context/AuthContext";

//componentes
import ServicioItem from "../../utils/ServicioItem";

const Servicios = ({ nuestrosPaquetes }) => {
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(12);
  const [tipo, setTipo] = useState("Usuario");
  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, []);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      const data = await ServicioService.mostrarInicio(4);
      setPaquetes(data.data);
      if (data.data.total < 6) {
        let limite = 6 - data.total;
        console.log("----" + limite);
        const dataDESC = await ServicioService.list(limite, 0);
        setPaquetes({ ...paquetes, dataDESC });
      }
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container">
      <div className="" style={{ marginTop: "-5%" }}> 
      <h2 className="display-5 text-center fw-bold">Servicios</h2>
      <h2 className="fw-bold mb-0">Descubre nuestros servicios</h2>
        <p className="fw-medium">Solo lo mejor para ti!</p>
        <div className="row g-4 g-lg-5">
          {paquetes.map((record, index) => {
            return <ServicioItem data={record} tipo={tipo}/>;
          })}
          
        </div>
      </div>
        

        
        <div style={{ textAlign: "center", marginTop:"40px"}}>
              <button
                type="button"
                class="btn btn-outline-secondary"
                style={{ width: "30%" }}
                onClick={() => {
                  navigate("/Servicios");
                }}
              >
                Ver Todos
              </button>
            </div>
      </div>
      
    </>
  );
};

export default Servicios;
