/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./Document/DocumentById";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";

/* Servicios */
import ConfiguracionService from "../../../services/Configuracion.service";
import ReportesService from "../../../services/Reportes.service";

import { FormatDateTime } from "../../utils/Format";
import { Grid } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import CortesService from "../../../services/Corte.service";
import { usePrint } from "../../../hooks/usePrint";

const TablaCorte = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [tickets, setTickets] = useState([]);
  const [ticketsSalida, setTicketsSalida] = useState([]);
  const [ticketsPagos, setTicketsPagos] = useState([]);

  const [ingresos, setIngresos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [ingresosPagos, setIngresosPagos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [egresos, setEgresoss] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });

  const [totales, setTotales] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesPago, setTotalesPago] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesSalida, setTotalesSalida] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalIngresos, setTotalIngresos] = useState(0);
  const [totalIngresosPagos, setTotalIngresosPagos] = useState(0);
  const [totalEgresos, setTotalEgresos] = useState(0);

  const [pagos, setPagos] = useState([]);
  const [pagosPagos, setPagosPagos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [pagoclientes, setPagoclientes] = useState(0);
  const [pagoclientesPagos, setPagoclientesPagos] = useState(0);

  const [cortes, setCortes] = useState([]);
  const [corte, setCorte] = useState(null);
  const [update, setUpdate] = useState(false);
  const newdate = new Date();
  const [responsableServicio, setResponsableServicio] = useState(null);
  const [responsableTurno, setResponsableTurno] = useState(null);
  const [responsableEntrega, setResponsableEntrega] = useState(null);

  const today = new Date();
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [dejoCambio, setDejoCambio] = useState("");
  const [dejoSobre, setDejoSobre] = useState("");
  const [dejoSdia, setDejoSdia] = useState("");

  const [reciboCambio, setReciboCambio] = useState("");
  const [reciboSobre, setReciboSobre] = useState("");
  const [reciboSdia, setReciboSdia] = useState("");

  const [waitingPrint, setWaitingPrint] = useState(false);
  const { printUrl, printIsAuto } = usePrint();

  const formatDateCrate = (date) => {
    if (!date) {
      return "00/00/0000";
    }

    const newdate = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return newdate
      .toLocaleString("es-ES", options)
      .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
  };

  const enviarImpresion = async (uuid) => {
    if (waitingPrint) {
      return;
    }

    setWaitingPrint(true);
    try {
      let newTicket = {
        id: parseInt(id),
      };
      const res = await ReportesService.printCorteById(newTicket);
      printUrl(res.url);
    } catch (error) {
      toast.error("Error. No enviado!");
    } finally {
      setWaitingPrint(false);
    }
  };

  const impresionManual = async (url) => {
    printUrl(url);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getTicket(),
      getTicketPago(),
      getTicketSalida(),
      getPagos(),
      getPagosPagos(),
    ]).finally(() => {
      setLoading(false);
    });
  }, [fechaEntrega, update]);

  const getTicket = async () => {
    try {
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await CortesService.listCortesById({
        id: parseInt(id),
      });
      setTickets(data.data);
      setCorte(data.cortes);
      setDejoCambio(data?.cortes?.dejoCambio ? data.cortes.dejoCambio : "");
      setDejoSobre(data?.cortes?.dejoSobre ? data.cortes.dejoSobre : "");
      setDejoSdia(data?.cortes?.dejoSdia ? data.cortes.dejoSdia : "");

      setReciboCambio(
        data?.cortes?.reciboCambio ? data.cortes.reciboCambio : ""
      );
      setReciboSobre(data?.cortes?.reciboSobre ? data.cortes.reciboSobre : "");
      setReciboSdia(data?.cortes?.reciboSdia ? data.cortes.reciboSdia : "");

      setResponsableTurno(
        data?.responsableTurno ? data.responsableTurno : null
      );

      setResponsableEntrega(
        data?.responsableEntrega ? data.responsableEntrega : null
      );

      for (const p of data.data) {
        ordenessaldo += Number(p.total) - Number(p.pagado);
        ordenestotal += Number(p.total);
      }
      sumarTotales(ordenestotal, ordenessaldo);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTicketPago = async () => {
    try {
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await CortesService.listCortePagos({
        id: parseInt(id),
      });
      setTicketsPagos(data.data);

      for (const p of data.data) {
        ordenessaldo += parseInt(p.total) - parseInt(p.pagado);
        ordenestotal += parseInt(p.total);
      }
      sumarTotalesPago(ordenestotal, ordenessaldo);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTicketSalida = async () => {
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await CortesService.listCorte({
        id: parseInt(id),
      });
      console.log("data salida -----------------");
      console.log(data);
      setTicketsSalida(data.data);

      for (const p of data.data) {
        ordenessaldo += parseInt(p.total) - parseInt(p.pagado);
        ordenestotal += parseInt(p.total);

        if (p.pagos[0].metodopago === "Efectivo") {
          efectivo += Number(p.pagos[0].precio);
        } else if (p.pagos[0].metodopago === "Tarjeta") {
          tarjeta += Number(p.pagos[0].precio);
        } else if (p.pagos[0].metodopago === "Transferencia") {
          transferencia += Number(p.pagos[0].precio);
        }
      }
      let newEgresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newEgresos);
      setEgresoss(newEgresos);
      setTotalEgresos(efectivo + tarjeta + transferencia);
      setTotalesSalida({ ordenes: ordenestotal, saldo: ordenessaldo });
    } catch (error) {
      toast.error(error);
    }
  };

  const getPagos = async () => {
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await CortesService.listPagoFecha({
        id: parseInt(id),
      });
      console.log(data);
      setPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresos(newIngresos);
      setPagoclientes(efectivo + tarjeta + transferencia);
      setTotalIngresos(efectivo + tarjeta + transferencia);
    } catch (error) {
      toast.error(error);
    }
  };

  const getPagosPagos = async () => {
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await CortesService.listPagoFechaPagos({
        id: parseInt(id),
      });
      console.log(data);
      setPagosPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresosPagos(newIngresos);
      setPagoclientesPagos(efectivo + tarjeta + transferencia);
      setTotalIngresosPagos(efectivo + tarjeta + transferencia);
    } catch (error) {
      toast.error(error);
    }
  };

  const totalTicket = (totalt) => {
    if (totalt > 0) {
      return <span class="d-block text-danger">${totalt}</span>;
    }
    return <span class="d-block text-success">${totalt}</span>;
  };

  const metodosPago = (listaMetodos) => {
    let sumaPagos = {
      Tarjeta: 0,
      Transferencia: 0,
      Efectivo: 0,
    };

    listaMetodos.map((metodoPago) => {
      sumaPagos[metodoPago.metodopago] += parseInt(metodoPago.precio);
    });

    return (
      <>
        <td
          class="text-end"
          style={sumaPagos.Efectivo > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Efectivo}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Transferencia > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Transferencia}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Tarjeta > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Tarjeta}
        </td>
      </>
    );
  };

  const sumarTotales = (ordentotal, ordensaldo) => {
    setTotales({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };
  const sumarTotalesPago = (ordentotal, ordensaldo) => {
    setTotalesPago({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };

  const metodosPagoSalida = (ordenmetodopago, monto) => {
    let sumaPagos = {
      Tarjeta: 0,
      Transferencia: 0,
      Efectivo: 0,
    };

    sumaPagos[ordenmetodopago] += parseInt(monto);

    return (
      <>
        <td
          class="text-end"
          style={sumaPagos.Efectivo > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Efectivo}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Transferencia > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Transferencia}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Tarjeta > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Tarjeta}
        </td>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <main id="middle" className="flex-fill" style={{ maxWidth: "100%" }}>
            <Grid container className="section p-0 card border-0">
              <Grid container>
                <Grid sm={12} md={12} padding={2} direction="row">
                  <Grid container className="card-header d-flex gap-3">
                    <span className="fw-bold text-muted">Fecha de corte:</span>
                    <span className="text-muted">
                      {FormatDateTime(corte?.fecha ? corte.fecha : "")}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div class="g-2 mb-3" style={{ width: "100%" }}>
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div
                    class="card border-0 h-100"
                    style={{ overflowX: "auto" }}
                  >
                    <div class="card-header px-4 border-bottom-0 fw-bold text-muted">
                      ENTREGADOS
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted">CLIENTE</th>
                              <th class="small text-muted">EMISOR</th>
                              <th class="small text-muted text-center">
                                EFECTIVO
                              </th>
                              <th class="small text-muted text-center">
                                TRANSFERENCIA
                              </th>
                              <th class="small text-muted text-center">
                                TARJETA
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center">
                                ESTADO
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {tickets?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.fechaRecepcion)}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {product.cliente?.nombre}
                                  </td>
                                  <td class="text-center">
                                    {product.responsableservicio?.nombre}
                                  </td>
                                  {metodosPago(product.pagos)}
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span class="d-block">
                                      ${product.total}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span
                                      class="badge rounded-pill"
                                      style={
                                        product.estadoEntrega === "Entregado"
                                          ? { color: "#9b242c" }
                                          : { color: "#506690" }
                                      }
                                    >
                                      {product.estadoEntrega}
                                    </span>
                                  </td>

                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/detalles/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL ORDENES
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotal">
                              <td></td>
                              <td class="text-center"></td>
                              <td class="text-center"></td>
                              <td class="text-end">${ingresos.efectivo}</td>
                              <td class="text-end">
                                ${ingresos.transferencia}
                              </td>
                              <td class="text-end">${ingresos.tarjeta}</td>
                              {/* <td class="text-center">
                                  {formatDateCrate(product.fecha)}
                                </td> */}
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block">${totales.ordenes}</span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">${pagoclientes}</span>
                              </td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid container>
              <div class="g-2 mb-3" style={{ width: "100%" }}>
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div
                    class="card border-0 h-100"
                    style={{ overflowX: "auto" }}
                  >
                    <div class="card-header px-4 border-bottom-0 fw-bold text-muted">
                      PAGOS POR ADELANTADO
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted">CLIENTE</th>
                              <th class="small text-muted">EMISOR</th>
                              <th class="small text-muted text-center">
                                EFECTIVO
                              </th>
                              <th class="small text-muted text-center">
                                TRANSFERENCIA
                              </th>
                              <th class="small text-muted text-center">
                                TARJETA
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SALDO
                              </th>
                              <th class="small text-muted text-center">
                                ESTADO
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {ticketsPagos?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.fechaRecepcion)}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {product.cliente?.nombre}
                                  </td>
                                  <td class="text-center">
                                    {product.responsableservicio?.nombre}
                                  </td>
                                  {metodosPago(product.pagos)}
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span class="d-block">
                                      ${product.total}
                                    </span>
                                  </td>
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    {totalTicket(
                                      product.total - product.pagado
                                    )}
                                    <span class="badge bg-secondary rounded-pill">
                                      {/* {product.metodopago} */}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span
                                      class="badge rounded-pill"
                                      style={
                                        product.estadoEntrega === "Entregado"
                                          ? { color: "#9b242c" }
                                          : { color: "#506690" }
                                      }
                                    >
                                      {product.estadoEntrega}
                                    </span>
                                  </td>

                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/detalles/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL ORDENES
                              </th>
                              <th class="small text-muted text-center">
                                SALDO
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotal">
                              <td></td>
                              <td class="text-center"></td>
                              <td class="text-center"></td>
                              <td class="text-end">
                                ${ingresosPagos.efectivo}
                              </td>
                              <td class="text-end">
                                ${ingresosPagos.transferencia}
                              </td>
                              <td class="text-end">${ingresosPagos.tarjeta}</td>
                              {/* <td class="text-center">
                                  {formatDateCrate(product.fecha)}
                                </td> */}
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block">
                                  ${totalesPago.ordenes}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                ${totalesPago.saldo}
                                <span class="badge bg-secondary rounded-pill">
                                  {/* {product.metodopago} */}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">
                                  ${totalIngresosPagos}
                                </span>
                              </td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid container>
              {/* Tickets salida */}
              {/* <!-- Ticket items --> */}
              <div class="g-2 mb-3" style={{ width: "100%" }}>
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div
                    class="card border-0 h-100"
                    style={{ overflowX: "auto" }}
                  >
                    <div class="card-header px-4 border-bottom-0 fw-bold">
                      GASTOS
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted">EMISOR</th>
                              <th class="small text-muted text-center">
                                EFECTIVO
                              </th>
                              <th class="small text-muted text-center">
                                TRANSFERENCIA
                              </th>
                              <th class="small text-muted text-center">
                                TARJETA
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {ticketsSalida?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.fecha)}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {product.responsableservicio?.nombre}
                                  </td>
                                  {metodosPagoSalida(
                                    product.pagos[0].metodopago,
                                    product.pagos[0].precio
                                  )}
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span class="d-block">
                                      ${product.total}
                                    </span>
                                  </td>
                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/salida/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL ORDENES
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotalegresos">
                              <td class="text-center"></td>
                              <td class="text-center"></td>
                              <td class="text-end">${egresos.efectivo}</td>
                              <td class="text-end">${egresos.transferencia}</td>
                              <td class="text-end">${egresos.tarjeta}</td>
                              {/* <td class="text-center">
                                  {formatDateCrate(product.fecha)}
                                </td> */}
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block">
                                  ${totalesSalida.ordenes}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">
                                  ${totalesSalida.ordenes - totalesSalida.saldo}
                                </span>
                              </td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <div class="col-12 card border-0 mb-0">
              <div></div>
            </div>
            <div></div>
            <div className="row g-2">
              {/* <!-- side --> */}
              <div class="col-xl-4">
                <div class="card border-0 h-100 p-4">
                  {/*User*/}
                  <div class=" mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">REGISTRO DEL TURNO</span>
                    </div>
                    <div class="d-flex align-items-center mb-3 small">
                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <p class="list-item m-0">
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted fw-bold"
                                style={{ width: "140px" }}
                              >
                                Recibo en cambio
                              </span>
                              <span>${reciboCambio}</span>
                            </div>
                          </p>
                          <p class="list-item m-0">
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted fw-bold"
                                style={{ width: "140px" }}
                              >
                                Recibo en sobre
                              </span>
                              <span>${reciboSobre}</span>
                            </div>
                          </p>
                          <p class="list-item">
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted fw-bold"
                                style={{ width: "170px" }}
                              >
                                Recibo en sobre del dia
                              </span>
                              <span>${reciboSdia}</span>
                            </div>
                          </p>
                          <li class="list-item">
                            <a
                              class="text-decoration-none d-block"
                              href="javascript:void(0)"
                            >
                              {corte?.responsableEntrega?.nombre
                                ? corte?.responsableEntrega?.nombre
                                : "N/A"}
                            </a>
                            <p class="link-muted small">
                              RESPONSABLE DEL TURNO
                            </p>
                          </li>
                          <li class="list-item">
                            <a
                              class="text-decoration-none d-block"
                              href="javascript:void(0)"
                            >
                              {formatDateCrate(corte?.fechaInicio)}
                            </a>
                            <p class="link-muted small">INICIO JORNADA</p>
                          </li>
                          <li class="list-item">
                            <a
                              class="text-decoration-none d-block"
                              href="javascript:void(0)"
                            >
                              {formatDateCrate(corte?.fechaFinal)}
                            </a>
                            <p class="link-muted small">FINALIZACIÓN JORNADA</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div class="d-flex align-items-center">
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            <a
                              class="text-decoration-none d-block"
                              href="javascript:void(0)"
                            >
                              {corte?.responsableservicio?.nombre}
                            </a>
                            <a
                              class="link-muted small"
                              href="javascript:void(0)"
                            >
                              {formatDateCrate(corte?.fecha)}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="col-xl-5">
                <div class="card border-0 h-100 p-4">
                  {/*User*/}
                  <div class=" mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Totales</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <div class="d-flex align-items-center">
                          <div class="small" style={{ width: "100%" }}>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "140px" }}
                              >
                                Total entregas
                              </span>
                              <span>${pagoclientes}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "140px" }}
                              >
                                Total PXA
                              </span>
                              <span>+ ${totalIngresosPagos}</span>
                            </div>
                            <div className="hr my-2"></div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted fw-bold"
                                style={{ width: "140px" }}
                              >
                                Total ingreso
                              </span>
                              <span className="fw-bold">
                                ${pagoclientes + totalIngresosPagos}
                              </span>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span class="flex-none text-muted">
                                Total transferencia
                              </span>
                              <span className="fw-bold">
                                $
                                {ingresosPagos.transferencia +
                                  ingresos.transferencia}
                              </span>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "140px" }}
                              >
                                Total tarjeta
                              </span>
                              <span className="fw-bold">
                                ${ingresosPagos.tarjeta + ingresos.tarjeta}
                              </span>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "140px" }}
                              >
                                Total GASTOS
                              </span>
                              <span>- ${totalEgresos}</span>
                            </div>
                            <div className="hr my-2"></div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted fw-bold"
                                style={{ width: "140px" }}
                              >
                                Total del dia en efectivo
                              </span>
                              <span className="fw-bold">
                                $
                                {(
                                  pagoclientes +
                                  totalIngresosPagos -
                                  ingresosPagos.transferencia -
                                  ingresosPagos.tarjeta -
                                  ingresos.tarjeta -
                                  ingresos.transferencia -
                                  totalEgresos
                                ).toFixed(2)}
                              </span>
                            </div>
                            <p className="list-item"></p>
                            <p className="list-item"></p>
                            <p class="list-item m-0">
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted fw-bold"
                                  style={{ width: "140px" }}
                                >
                                  Dejo en cambio
                                </span>
                                <span>${dejoCambio}</span>
                              </div>
                            </p>
                            <p class="list-item m-0">
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted fw-bold"
                                  style={{ width: "140px" }}
                                >
                                  Dejo en sobre
                                </span>
                                <span>${dejoSobre}</span>
                              </div>
                            </p>
                            <p class="list-item">
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted fw-bold"
                                  style={{ width: "170px" }}
                                >
                                  Dejo en sobre del dia
                                </span>
                                <span>${dejoSdia}</span>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-3">
                <div class="card border-0 h-100 p-4">
                  {/*User*/}
                  <div class=" mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Opciones</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- customer details --> */}
                      <div class="w-100 py-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            {printIsAuto ? (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                                role="button"
                                onClick={() => enviarImpresion()}
                              >
                                <PrintIcon fontSize="small" />
                                <span class="d-sm-inline-block ps-2">
                                  Imprime
                                </span>
                              </a>
                            ) : (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <PrintIcon fontSize="small" />
                                <PDFDownloadLink
                                  document={<Document id={id} />}
                                  fileName={`LET_ORDEN_${newdate.toLocaleDateString()}.pdf`}
                                  style={{ padding: "0.5rem" }}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? (
                                      "Cargando..."
                                    ) : (
                                      <a
                                        className="text-decoration-none"
                                        href="javascript:void(0);"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          impresionManual(url);
                                        }}
                                      >
                                        <span
                                          style={{ marginInline: "0.5rem" }}
                                        >
                                          Imprime
                                        </span>
                                      </a>
                                    )
                                  }
                                </PDFDownloadLink>
                              </a>
                            )}
                          </li>
                          <li class="list-item">
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              role="button"
                            >
                              <PictureAsPdfIcon fontSize="small" />
                              <span class="d-sm-inline-block ps-2">
                                <PDFDownloadLink
                                  document={<Document id={id} />}
                                  fileName={`LET_CORTE_${newdate.toLocaleDateString()}.pdf`}
                                  style={{ padding: "0.5rem" }}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? "Cargando..." : "Descargar PDF"
                                  }
                                </PDFDownloadLink>
                              </span>
                            </a>
                          </li>
                          <li class="list-item">
                            <span className="p-4"></span>
                          </li>
                          <li class="list-item">
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              role="button"
                              onClick={() => navigate("/cortes/historial")}
                            >
                              <HistoryIcon fontSize="small" />
                              <span class="d-sm-inline-block ps-2">
                                HISTORIAL DE CORTES
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaCorte;
