/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import Button from "@mui/material/Button";
import DropZone from "../../DropZone";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import Preloader from "../../utils/Preloader";
import bcrypt from "bcryptjs";

/* Servicios */
import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.service";

import {
  validarContrasenaSimple,
  validarEmail,
  validarNombreCompleto,
} from "../../utils/Validator";

const NuevoUsuario = () => {
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const [permisosList, setPermisosList] = useState([]);
  const navigate = useNavigate();
  const saltRounds = 10;

  const saveUsuario = async () => {
    if (!usuario.nombre || !validarNombreCompleto(usuario.nombre)) {
      toast.info(
        "Error, tienes que colocar un nombre con solo caracteres alfabéticos y espacios"
      );
    } else if (!usuario.correo || !validarEmail(usuario.correo)) {
      toast.info("Error, tienes que colocar un correo valido");
    } else if (
      !usuario.contraseña ||
      !validarContrasenaSimple(usuario.contraseña)
    ) {
      toast.info(
        "Error, tienes que colocar una contraseña que tenga mas de 6 caracteres sin espacios en blanco y al menos un numero"
      );
    } else if (!file) {
      toast.info("Error, tienes que colocar una imagen");
    } else {
      setLoading(true);
      const saltRounds = 10;
      const salt = await bcrypt.genSalt(saltRounds);
      const hashedPassword = await bcrypt.hash(usuario.contraseña, salt);
      try {
        const datosUsuario = usuario;
        datosUsuario.permiso = permisosList;
        datosUsuario.contraseña = hashedPassword;

        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          datosUsuario.imagen = uploadPhoto.result.data;
          await UserService.create(datosUsuario);
        } else {
          await UserService.create(datosUsuario);
        }

        toast.success("Usuario creado con exito!");
        navigate("/usuario");
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Nuevo usuario</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/usuario")}
                    >
                      Usuarios
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold">
                      Usuario
                    </div>
                    <div className="card-body">
                      {file ? (
                        <div>
                          <div className="col-lg-12">
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={URL.createObjectURL(file)}
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                  width: "-webkit-fill-available",
                                }}
                              />
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  width: "-webkit-fill-available",
                                }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div style={{ width: "-webkit-fill-available" }}>
                        <DropZone
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        />
                      </div>
                      <div className="row g-3">
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Nombre"
                              value={usuario?.nombre}
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  nombre: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            <label>Nombre</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <select
                              class="form-select shadow-none"
                              id="shipping__country"
                              name="shipping[country]"
                              aria-label="Country"
                              onChange={(e) => {
                                setUsuario({
                                  ...usuario,
                                  tipo: e.target.value,
                                });
                              }}
                              value={usuario.tipo}
                            >
                              <option disabled="disabled" selected="selected">
                                SELECCIONA UNO
                              </option>
                              <option value="General">GENERAL</option>
                              <option value="Administrativo">
                                ADMINISTRATIVO
                              </option>
                              <option value="Ventas">VENTAS</option>
                            </select>

                            <label htmlFor="shipping__last_name">Tipo</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  correo: e.target.value,
                                })
                              }
                              value={usuario.correo}
                              placeholder="Correo"
                            />
                            <label htmlFor="shipping__last_name">Correo</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  contraseña: e.target.value,
                                })
                              }
                              placeholder="Contraseña"
                              value={usuario.contraseña}
                              type="password"
                            />
                            <label htmlFor="shipping__last_name">
                              Contraseña
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => saveUsuario()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className="small">Añadir</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default NuevoUsuario;
