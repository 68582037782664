import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import TicketsService from "../../../../services/Tickets.service";
import Header from "./Header";
import MyTable from "./Table";
import MyTableEntregas from "./TableEntregas";
import TicketsSalidaService from "../../../../services/TicketsSalida.service";
import TableEgresos from "./TableEgresos";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontDamily: "Lato",
    padding: 30,
    textTransform: "uppercase",
  },
  header: { height: "10%", margin: 10, flexDirection: "row", fontSize: 14 },
  logoheader: { height: "40px", width: "136px", margin: 10 },
  textheader: { position: "absolute", top: 10, right: 10, width: "100px" },
  textsubtitleright: { position: "absolute", right: 10, width: "200px" },
  totalessection: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 10,
    flexDirection: "row",
  },
  totalsection: { marginRight: 20 },
  textheadertable: { fontSize: 10 },
  footer: {
    fontSize: "8px",
    color: "#7b818d",
    marginTop: 20,
  },
  section: { color: "white", textAlign: "center", margin: 10 },
  item: {
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    margin: 10,
    borderBottom: "1px solid #dde4ea",
    borderTop: "1px solid #dde4ea",
  },
  itemcontent: {
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    margin: 10,
    flexDirection: "row",
  },
  itemcliente: { width: "30%", textAlign: "left", fontSize: 14 },
  itemdetalles: { textAlign: "left", width: "70%", fontSize: 14 },
  itemfooter: {
    textAlign: "left",
    width: "100%",
    flexDirection: "row",
    fontSize: "14px",
    color: "#506690",
  },
  itemfooteritem: { margin: 10 },
  fontnegrita: { fontFamily: "Lato Bold" },
  texttitle: { marginBottom: 20 },
  textdireccion: { marginTop: 20 },
  totaldivisor: { borderBottom: "1px solid #000", height: "1px" },
  metodo: { flexDirection: "row", justifyContent: "space-between" },
  tableseparator: { marginBottom: 20, height: "1px" },
  monto: { marginLeft: 3 },
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// Create Document Component
const MyDocument = ({ fecha }) => {
  const [loading, setLoading] = useState(true);

  const [tickets, setTickets] = useState([]);
  const [ticketsSalida, setTicketsSalida] = useState([]);
  const [ticketsPagos, setTicketsPagos] = useState([]);

  const [ingresos, setIngresos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [ingresosPagos, setIngresosPagos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [egresos, setEgresoss] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });

  const [totales, setTotales] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesPago, setTotalesPago] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesSalida, setTotalesSalida] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalIngresos, setTotalIngresos] = useState(0);
  const [totalIngresosPagos, setTotalIngresosPagos] = useState(0);
  const [totalEgresos, setTotalEgresos] = useState(0);
  const [configuracionImpresion, setConfiguracionImpresion] = useState(null);

  const [pagos, setPagos] = useState([]);
  const [pagosPagos, setPagosPagos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [pagoclientes, setPagoclientes] = useState(0);
  const [pagoclientesPagos, setPagoclientesPagos] = useState(0);

  const [cortes, setCortes] = useState([]);
  const [corte, setCorte] = useState(null);
  const [update, setUpdate] = useState(false);
  const newdate = new Date();
  const [responsableServicio, setResponsableServicio] = useState(null);
  const [responsableTurno, setResponsableTurno] = useState(null);
  const [responsableEntrega, setResponsableEntrega] = useState(null);

  const today = new Date();
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [dejoCambio, setDejoCambio] = useState("");
  const [dejoSobre, setDejoSobre] = useState("");
  const [dejoSdia, setDejoSdia] = useState("");

  const [error, setError] = useState(false);

  const onUpdate = () => {
    setUpdate(!update);
  };

  const handleResponsableServicio = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableServicio(changecliente);
  };

  const handleResponsalbeTurno = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableTurno(changecliente);
  };

  const handleResponsalbeEntrega = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableEntrega(changecliente);
  };

  const formatDate = () => {
    return newdate.toLocaleString();
  };

  const formatDateCrate = (fecha) => {
    const newdatec = new Date(fecha);
    return newdatec.toLocaleString();
  };

  useEffect(() => {
    getTicket();
    getTicketPago();
    getTicketSalida();
    getPagos();
    getPagosPagos();
  }, [fechaEntrega, update]);

  const getTicket = async () => {
    setLoading(true);
    try {
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsService.listCorte({
        fecha: today.toISOString(),
      });
      setTickets(data.data);
      setCortes(data.cortes);
      setDejoCambio(data?.corte?.dejoCambio ? data.corte.dejoCambio : "");
      setDejoSobre(data?.corte?.dejoSobre ? data.corte.dejoSobre : "");
      setDejoSdia(data?.corte?.dejoSdia ? data.corte.dejoSdia : "");

      setResponsableTurno(
        data?.responsableTurno ? data.responsableTurno : null
      );

      setResponsableEntrega(
        data?.responsableEntrega ? data.responsableEntrega : null
      );

      for (const p of data.data) {
        ordenessaldo += Number(p.total) - Number(p.pagado);
        ordenestotal += Number(p.total);
      }
      sumarTotales(ordenestotal, ordenessaldo);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getTicketPago = async () => {
    setLoading(true);
    try {
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsService.listCortePagos({
        fecha: today.toISOString(),
      });
      setTicketsPagos(data.data);

      for (const p of data.data) {
        ordenessaldo += Number(p.total) - Number(p.pagado);
        ordenestotal += Number(p.total);
      }
      sumarTotalesPago(ordenestotal, ordenessaldo);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getTicketSalida = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsSalidaService.listCorte({
        fecha: today.toISOString(),
      });
      console.log("data salida -----------------");
      console.log(data);
      setTicketsSalida(data.data);

      for (const p of data.data) {
        ordenessaldo += Number(p.total) - Number(p.pagado);
        ordenestotal += Number(p.total);

        if (p.pagos[0].metodopago === "Efectivo") {
          efectivo += Number(p.pagos[0].precio);
        } else if (p.pagos[0].metodopago === "Tarjeta") {
          tarjeta += Number(p.pagos[0].precio);
        } else if (p.pagos[0].metodopago === "Transferencia") {
          transferencia += Number(p.pagos[0].precio);
        }
      }
      let newEgresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newEgresos);
      setEgresoss(newEgresos);
      setTotalEgresos(efectivo + tarjeta + transferencia);
      setTotalesSalida({ ordenes: ordenestotal, saldo: ordenessaldo });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getPagos = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await TicketsService.listPagoFecha({
        fecha: today.toISOString(),
      });
      console.log(data);
      setPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresos(newIngresos);
      setPagoclientes(efectivo + tarjeta + transferencia);
      setTotalIngresos(efectivo + tarjeta + transferencia);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getPagosPagos = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await TicketsService.listPagoFechaPagos({
        fecha: today.toISOString(),
      });
      console.log(data);
      setPagosPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresosPagos(newIngresos);
      setPagoclientesPagos(efectivo + tarjeta + transferencia);
      setTotalIngresosPagos(efectivo + tarjeta + transferencia);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const totalTicket = (totalt) => {
    if (totalt > 0) {
      return <span class="d-block text-danger">${totalt}</span>;
    }
    return <span class="d-block text-success">${totalt}</span>;
  };

  const sumarTotales = (ordentotal, ordensaldo) => {
    setTotales({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };
  const sumarTotalesPago = (ordentotal, ordensaldo) => {
    setTotalesPago({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };

  return (
    <Document pdfVersion="1.7">
      <Page size="A4" style={styles.page} wrap={true}>
        <Header
          src="/static/media/logo2.77456c09d5f4412e909f.png"
          date={newdate.toLocaleDateString()}
          text="La Esperanza | CUIDAMOS LA EXCELENCIA DE TU IMAGEN"
        />

        <View style={styles.textheadertable}>
          <Text style={styles.fontnegrita}>INGRESOS</Text>
        </View>
        <MyTableEntregas
          key="tebleentregados"
          rows={tickets}
          ingresos={ingresos}
          totales={totales}
          total={totalIngresos}
        />
        <View style={styles.tableseparator}></View>

        <View style={styles.textheadertable}>
          <Text style={styles.fontnegrita}>PAGOS POR ADELANTADO</Text>
        </View>
        <MyTable
          key="tablepagos"
          rows={ticketsPagos}
          ingresos={ingresosPagos}
          totales={totalesPago}
          total={totalIngresosPagos}
        />
        <View style={styles.tableseparator}></View>

        <View style={styles.textheadertable}>
          <Text style={styles.fontnegrita}>GASTOS</Text>
        </View>
        <TableEgresos
          key="tablesalidas"
          rows={ticketsSalida}
          egresos={egresos}
          totales={totalesSalida}
        />
        <View style={styles.tableseparator}></View>

        <View style={styles.totalessection} wrap={false}>
          <View style={styles.totalsection}>
            <View style={styles.textheadertable}>
              <Text style={styles.fontnegrita}>TOTALES</Text>\
            </View>
            <View style={styles.tableseparator}></View>

            {/* <Text style={styles.fontnegrita}>TOTAL ENTREGAS</Text> */}
            <View style={styles.metodo}>
              <Text>TOTAL ENTREGAS</Text>
              <Text style={styles.monto}>${pagoclientes}</Text>
            </View>
            <View style={styles.metodo}>
              <Text>TOTAL PXA</Text>
              <Text style={styles.monto}>+ ${totalIngresosPagos}</Text>
            </View>
            <Text style={styles.totaldivisor}></Text>
            <View style={[styles.metodo, styles.fontnegrita]}>
              <Text>TOTAL INGRESO</Text>
              <Text style={styles.monto}>
                ${pagoclientes + totalIngresosPagos}
              </Text>
            </View>
            <View style={styles.metodo}>
              <Text>TOTAL TRANSFERENCIA</Text>
              <Text style={styles.monto}>- ${ingresosPagos.transferencia}</Text>
            </View>
            <View style={styles.metodo}>
              <Text>TOTAL TARJETA</Text>
              <Text style={styles.monto}>- ${ingresosPagos.tarjeta}</Text>
            </View>
            <View style={styles.metodo}>
              <Text>TOTAL GASTOS</Text>
              <Text style={styles.monto}>- ${totalEgresos}</Text>
            </View>
            <Text style={styles.totaldivisor}></Text>
            <View style={[styles.metodo, styles.fontnegrita]}>
              <Text>TOTAL DEL DIA EN EFECTIVO</Text>
              <Text style={styles.monto}>
                $
                {(
                  pagoclientes +
                  totalIngresosPagos -
                  ingresosPagos.transferencia -
                  ingresosPagos.tarjeta -
                  totalEgresos
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.totalessection} wrap={false}></View>
        <View style={styles.footer}>
          <Text>La Esperanza | CUIDAMOS LA EXCELENCIA DE TU IMAGEN</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
