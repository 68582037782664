import React from "react";

const Modal = ({ status, handleStatus, headerText, children }) => {
  return (
    <div
      className={status ? "modal fade show" : "modal fade"}
      id="item-add"
      tabindex="-1"
      aria-labelledby="item-add-label"
      aria-hidden={!status}
      aria-modal={status}
      role="dialog"
      style={
        status
          ? {
              display: "block",
              backgroundColor: "#a2313738",
            }
          : {
              zIndex: "-1",
              display: "block",
            }
      }
    >
      <div
        method="post"
        action="#"
        className={
          status
            ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
            : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
        }
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="item-add-label">
              {headerText}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleStatus}
            ></button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
