/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import DropComponent from "../imagesDrop";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";

/*Services */
import S3Service from "../../../services/S3.service";
import FullPageLoading from "../../FullPageLoading";
import CardDay from "./CardDay";
import CardServicioPrecio from "./CardServicioPrecio";
import ServicioService from "../../../services/Servicio.service";
import Preloader from "../../utils/Preloader";

const CrearCarrusel = () => {
  //#region variables
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [servicio, setServicio] = useState(false);
  const [ubicacion, setUbicacion] = useState([]);
  const [file, setFile] = useState(undefined);
  const [ubicacion_NEW, setUbicacion_NEW] = useState({});
  const [mostrarI_NEW, setMostrarI_NEW] = useState(false);
  const [ciudad, setCiudad] = useState("");

  const [dia, setDia] = useState({});
  const [diasArray, setDiasArray] = useState([]);
  const [diaContador, setDiaContador] = useState(0);

  const [servicioPrecioU, setservicioPrecioU] = useState({});
  const [servicioPrecioArrayU, setservicioPrecioArrayU] = useState([]);
  const [precioUContador, setPrecioUContador] = useState(0);

  const [servicioPrecioE, setservicioPrecioE] = useState({});
  const [servicioPrecioArrayE, setservicioPrecioArrayE] = useState([]);
  const [precioEContador, setPrecioEContador] = useState(0);
  const [mostrarI, setMostrarI] = useState(true);
  const [destacados, setDestacados] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);
  const myTheme = createTheme({});
  //#endregion

  useEffect(() => {
    setServicio({
      ...servicio,
      mostrarInicio: false,
    });
    setServicio({
      ...servicio,
      descuento: 0,
    });
  }, []);

  const guardarServicio = async () => {
    try {
      if (!servicio.titulo) {
        toast.info("Error, tienes que agregar un titulo");
      } else if (!servicio.subtitulo) {
        toast.info("Error, tienes que colocar un subtitulo");
      } else if (!servicio.descripcion) {
        toast.info("Error, tienes que colocar una descripción");
      } else {
        setLoading(true);
        let imagenesArray = []; //Array para los id del s3
        let diasjson = []; //Array para almecenar los dias en formatos JSON y guardarlos en la BD

        let servicioPrecioU = [];
        let servicioPrecioE = [];
        /* Subida de imagenes del carrusel */
        for (const file of imagenesCarrusel) {
          const resultFile = await S3Service.upload(file);
          console.log("imagen carrusel guardad:" + resultFile.result.data);
          imagenesArray.push(resultFile.result.data);
        }

        /* Subida de imagenes de los dias */
        for (const dia of diasArray) {
          const resultImagenDia = await S3Service.upload(dia.props.imagen);
          const diaJSON = {
            titulo: dia.props.titulo,
            descripcion: dia.props.descripcion,
            imagen: resultImagenDia.result.data,
          };
          diasjson.push(diaJSON);
        }

        const { titulo, subtitulo, tiempo, descripcion, incluye, descuento } =
          servicio;

        const dataServicio = {
          titulo,
          subtitulo,
          tiempo,
          descripcion,
          incluye,
          imagenes: imagenesArray,
          descuento: parseInt(descuento),
          precios: servicioPrecioArrayU,
          mostrarInicio: !mostrarI,
          destacados: !destacados,
        };
        const servicioSave = await ServicioService.create(dataServicio);
        console.log("--------------" + servicioSave.response);
        navigate("/servicio");
        toast.success("Servicio creado con exito" + dataServicio.ciudad);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(true);
    }
  };

  const limpiarItinerario = () => {
    try {
      dia.titulo = "";
      dia.descripcion = "";
    } catch (error) {
    } finally {
    }
  };

  //#region Dias
  const saveDay = async () => {
    setDiaContador(diaContador + 1);
    console.log("id  del dia: " + diaContador);
    try {
      const newDay = (
        <CardDay
          id={diaContador}
          titulo={dia.titulo}
          descripcion={dia.descripcion}
          imagen={file}
          url={URL.createObjectURL(file)}
          eliminarActual={(data) => {
            eliminarDay(data);
          }}
        />
      );
      setDiasArray((diasArray) => [...diasArray, newDay]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarDay = (id) => {
    console.log("Eliminar id:" + id);

    try {
      let arrayOriginal = diasArray;
      console.log("dias array eliminar, length: " + diasArray);
      console.log("****ELIMINAR: " + arrayOriginal.length);
      let nuevoArray = [];
      arrayOriginal.map((record) => {
        if (record.props.id != id) {
          console.log("****push ID: " + record.props.id);
          nuevoArray.push(record);
        }
      });
      setDiasArray(nuevoArray);
      //arrayOriginalDiasJson=nuevoArray;
      //arrayDiasJSon = nuevoArray;
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region servicioUsuario
  const saveServicioPrecioU = (userType) => {
    try {
      const newServicioPrecio = (
        <CardServicioPrecio
          cantidad={servicioPrecioU.cant}
          tiempo={servicioPrecioU.tiempo}
          precio={servicioPrecioU.precio}
          user={userType}
          id={precioUContador}
          eliminarActual={(data) => {
            eliminarServicioUsuario(data);
          }}
        />
      );

      setservicioPrecioArrayU([newServicioPrecio]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarServicioUsuario = (id) => {
    try {
      let arrayOriginal = servicioPrecioArrayU;
      let nuevoArray = [];
      setservicioPrecioArrayU(nuevoArray);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region servicioEmpresa
  const saveServicioPrecioE = (userType) => {
    setPrecioEContador(precioEContador + 1);
    try {
      const newServicioPrecio = (
        <CardServicioPrecio
          cantidad={servicioPrecioE.cant}
          tipo={servicioPrecioE.tipo}
          precio={servicioPrecioE.precio}
          user={userType}
          id={precioEContador}
          eliminarActual={(data) => {
            eliminarServicioEmpresa(data);
          }}
        />
      );
      setservicioPrecioArrayE((serviciosArray) => [
        ...serviciosArray,
        newServicioPrecio,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarServicioEmpresa = (id) => {
    try {
      let arrayOriginal = servicioPrecioArrayE;
      let nuevoArray = [];
      arrayOriginal.map((record) => {
        if (record.props.id != id) {
          nuevoArray.push(record);
        }
      });
      setservicioPrecioArrayE(nuevoArray);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Nuevo servicio</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/servicio")}
                    >
                      Servicios
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="h5 card-header border-bottom-0 fw-bold text-bg-dark text-center">
                    Servicio
                  </div>
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold">
                      Servicio
                    </div>

                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-2">
                          <div className="form-floating">
                            Mostrar al inicio
                            <Switch
                              checked={!mostrarI}
                              color="error"
                              onClick={() => {
                                mostrarI
                                  ? setMostrarI(false)
                                  : setMostrarI(true);
                                console.log("mostrar inicio:" + mostrarI);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-floating">
                            Servicio destacado
                            <Switch
                              checked={!destacados}
                              color="error"
                              onClick={() => {
                                destacados
                                  ? setDestacados(false)
                                  : setDestacados(true);
                                console.log("mostrar inicio:" + destacados);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating"></div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.titulo}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  titulo: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.descuento}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  descuento: e.target.value,
                                })
                              }
                            />
                            <label>Descuento %</label>
                          </div>
                        </div>
                        {/* <div className="col-sm-6"> */}
                        {/* <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.tiempo}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  tiempo: e.target.value,
                                })
                              }
                            />
                            <label>Tiempo</label>
                          </div> */}
                        {/* </div> */}
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.subtitulo}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  subtitulo: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            <label>Subtítulo</label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <div>
                              <ThemeProvider theme={myTheme}>
                                <MUIRichTextEditor
                                  label="Descripción del servicio... "
                                  controls={[
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "highlight",
                                    "undo",
                                    "redo",
                                    "link",
                                    "numberList",
                                    "bulletList",
                                    "quote",
                                    "code",
                                    "clear",
                                    // "save"
                                  ]}
                                  // onSave={save}
                                  inlineToolbar={true}
                                  onChange={(value) =>
                                    setServicio({
                                      ...servicio,
                                      descripcion: stateToHTML(
                                        value.getCurrentContent()
                                      ),
                                    })
                                  }
                                />
                              </ThemeProvider>
                              <br></br>
                              <br></br>
                            </div>
                          </div>

                          <div>
                            <ThemeProvider theme={myTheme}>
                              <MUIRichTextEditor
                                id="incluye"
                                label="El servicio incluye..."
                                controls={[
                                  "italic",
                                  "underline",
                                  "strikethrough",
                                  "highlight",
                                  "undo",
                                  "redo",
                                  "link",
                                  "numberList",
                                  "bulletList",
                                  "quote",
                                  "code",
                                  "clear",
                                ]}
                                inlineToolbar={true}
                                onChange={(value) =>
                                  setServicio({
                                    ...servicio,
                                    incluye: stateToHTML(
                                      value.getCurrentContent()
                                    ),
                                  })
                                }
                              />
                            </ThemeProvider>
                            <br></br>
                          </div>
                          {file ? (
                            <div>
                              <div className="col-lg-12">
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    style={{
                                      height: 300,
                                      objectFit: "contain",
                                      width: "-webkit-fill-available",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    style={{
                                      width: "-webkit-fill-available",
                                    }}
                                  >
                                    {file.name}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropComponent
                              subirImagen={(data) => setImagenesCarrusel(data)}
                            />
                          </div>
                        </div>
                        <h4>Precios</h4>
                        {/* PRECIOS  */}
                        <div className="col-sm-3">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              value={servicioPrecioU.cant}
                              onChange={(e) =>
                                setservicioPrecioU({
                                  ...servicioPrecioU,
                                  cant: e.target.value,
                                })
                              }
                            />
                            <label>Cantidad</label>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              value={servicioPrecioU.tiempo}
                              onChange={(e) =>
                                setservicioPrecioU({
                                  ...servicioPrecioU,
                                  tiempo: e.target.value,
                                })
                              }
                            />
                            <label>Tiempo (Días habiles)</label>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              value={servicioPrecioU.precio}
                              onChange={(e) =>
                                setservicioPrecioU({
                                  ...servicioPrecioU,
                                  precio: e.target.value,
                                })
                              }
                            />
                            <label>Precio (MXM)</label>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-floating">
                            <Button
                              id="guardarUsuario"
                              variant="outlined"
                              color="success"
                              onClick={(userType) =>
                                saveServicioPrecioU("Usuario")
                              }
                            >
                              Guardar
                            </Button>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            {servicioPrecioArrayU.map((record, index) => {
                              return (
                                <>
                                  <div
                                    id="row__posterLarge"
                                    style={{
                                      padding: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {record}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => guardarServicio()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>{" "}
                  {"  "}
                  <span className="small">Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
