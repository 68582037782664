/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../../utils/Preloader";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./Document/Document";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import Swal from "sweetalert2";

/* Servicios */
import TicketsService from "../../../../services/Tickets.service";
import TicketsSalidaService from "../../../../services/TicketsSalida.service";
import ConfiguracionService from "../../../../services/Configuracion.service";
import ReportesService from "../../../../services/Reportes.service";
import { usePrint } from "../../../../hooks/usePrint";

const TablaOrdenesPendientes = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [tickets, setTickets] = useState([]);
  const [ticketsSalida, setTicketsSalida] = useState([]);

  const [ingresos, setIngresos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [egresos, setEgresoss] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });

  const [totales, setTotales] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesSalida, setTotalesSalida] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalIngresos, setTotalIngresos] = useState(0);
  const [totalEgresos, setTotalEgresos] = useState(0);

  const [pagos, setPagos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [pagoclientes, setPagoclientes] = useState(0);
  const [corte, setCorte] = useState(null);
  const [update, setUpdate] = useState(false);
  const newdate = new Date();
  const [responsableServicio, setResponsableServicio] = useState(null);
  let today = new Date();
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [fechaFinal, setFechaFinal] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [fechaLimit, setFechaLimit] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );

  const [waitingPrint, setWaitingPrint] = useState(false);
  const { printUrl, printIsAuto } = usePrint();

  const onUpdate = () => {
    setUpdate(!update);
  };

  const handleFechaEntrega = (newFecha) => {
    setFechaEntrega(newFecha.target.value);
    console.log(newFecha.target.value);
  };

  const handleFechaFinal = (newFecha) => {
    setFechaFinal(newFecha.target.value);
    console.log(newFecha.target.value);
  };

  const formatDateCrate = (fecha) => {
    const newdatec = new Date(fecha);
    return newdatec.toLocaleString();
  };

  const formatDateStatus = (fecha) => {
    const newdatec = new Date(fecha);

    if (today > newdatec) {
      return (
        <span class="d-block small text-danger">
          {newdatec.toLocaleString()}
        </span>
      );
    }
    return (
      <span class="d-block text-muted small">{newdatec.toLocaleString()}</span>
    );
  };

  useEffect(() => {
    getTicket();
  }, [fechaEntrega, fechaFinal, update]);

  const enviarImpresion = async (uuid) => {
    if (waitingPrint) {
      return;
    }

    setWaitingPrint(true);
    try {
      let newTicket = {
        fechaInicial: `${fechaEntrega} 00:00:00`,
        fechaFinal: `${fechaFinal} 00:00:00`,
      };
      const res = await ReportesService.printPendientes(newTicket);
      printUrl(res.url);
    } catch (error) {
      toast.error("Error. No enviado!");
    } finally {
      setWaitingPrint(false);
    }
  };

  const impresionManual = async (url) => {
    printUrl(url);
  };

  const getTicket = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsService.listFechaPendientes({
        fechaInicial: `${fechaEntrega} 00:00:00`,
        fechaFinal: `${fechaFinal} 00:00:00`,
      });
      setTickets(data.data);
      setCorte(data.corte);
      console.log("data fecha ordenes");
      console.log(data);
      for (const p of data.data) {
        ordenessaldo += parseInt(p.total) - parseInt(p.pagado);
        ordenestotal += parseInt(p.total);

        for (const pa of p.pagos) {
          if (pa.metodopago === "Efectivo") {
            efectivo += Number(pa.precio);
          } else if (pa.metodopago === "Tarjeta") {
            tarjeta += Number(pa.precio);
          } else if (pa.metodopago === "Transferencia") {
            transferencia += Number(pa.precio);
          }
        }
      }
      sumarTotales(ordenestotal, ordenessaldo);
      setIngresos({
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      });
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const totalTicket = (totalt) => {
    if (totalt > 0) {
      return <span class="d-block text-danger">${totalt}</span>;
    }
    return <span class="d-block text-success">${totalt}</span>;
  };

  const metodosPago = (listaMetodos) => {
    let sumaPagos = {
      Tarjeta: 0,
      Transferencia: 0,
      Efectivo: 0,
    };

    listaMetodos.map((metodoPago) => {
      sumaPagos[metodoPago.metodopago] += parseInt(metodoPago.precio);
    });

    return (
      <>
        <td
          class="text-end"
          style={
            sumaPagos.Efectivo + sumaPagos.Transferencia + sumaPagos.Tarjeta > 0
              ? { color: "#9b242c" }
              : null
          }
        >
          ${sumaPagos.Efectivo + sumaPagos.Transferencia + sumaPagos.Tarjeta}
        </td>
      </>
    );
  };

  const sumarTotales = (ordentotal, ordensaldo) => {
    setTotales({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <main id="middle" className="flex-fill mx-auto">
            <div class="col-12 d-flex mb-3 bg-white">
              <div className="col-6 p-4">
                <span className="fw-bold text-muted">Reporte:</span>
                <span className="text-muted ps-1">Ordenes pendientes</span>
              </div>
              <div class="col-3 p-4 d-flex gap-3">
                <span className="fw-bold text-muted">Periodo:</span>
                <span className="text-muted">
                  <input
                    type="date"
                    max={fechaLimit}
                    defaultValue={fechaEntrega}
                    onChange={handleFechaEntrega}
                    style={{
                      border: "none",
                      paddingY: "0.5rem",
                    }}
                  />
                </span>
              </div>
              <div class="col-3 p-4 d-flex gap-3">
                <span className="fw-bold text-muted"></span>
                <span className="text-muted">
                  <input
                    type="date"
                    defaultValue={fechaFinal}
                    onChange={handleFechaFinal}
                    style={{
                      border: "none",
                      paddingY: "0.5rem",
                    }}
                  />
                </span>
              </div>
            </div>
            {/* <!-- Ticket items --> */}
            <div class="row g-2 mb-3">
              <div class="col">
                {/* <!-- Order items --> */}
                <div class="card border-0 h-100">
                  <div class="card-header px-4 border-bottom-0 fw-bold">
                    Hay ({tickets.length}) tickets
                  </div>
                  <div class="card-body px-4 h-100">
                    <div class="table-responsive-md mb-3">
                      <table
                        key="tableingresos"
                        class="table table-align-middle mb-0"
                        role="grid"
                      >
                        <thead key="headertableingresos">
                          <tr>
                            <th class="small text-muted">ORDEN</th>
                            <th class="small text-muted">CLIENTE</th>
                            <th class="small text-muted">FECHA</th>
                            <th class="small text-muted">FECHA ENTREGA</th>
                            <th class="small text-muted text-center">
                              ANTICIPO
                            </th>
                            <th class="small text-muted text-center">TOTAL</th>
                            <th class="small text-muted text-center">SALDO</th>
                            <th class="small text-muted text-center">ESTADO</th>
                            <th class="small text-muted text-center"></th>
                          </tr>
                        </thead>
                        <tbody id="checkall-list">
                          {tickets?.map((product, index) => (
                            <>
                              <tr key={index}>
                                <td>
                                  <a
                                    href="javascript:void(0);"
                                    class="link-normal fw-medium"
                                  >
                                    {product.uuid}
                                  </a>
                                </td>
                                <td class="text-center">
                                  {product.cliente.nombre}
                                </td>
                                <td class="text-center">
                                  <span class="d-block text-muted small">
                                    {formatDateCrate(product.createdAt)}
                                  </span>
                                </td>
                                <td class="text-center">
                                  {formatDateStatus(product.fecha)}
                                </td>
                                {metodosPago(product.pagos)}
                                <td class="text-end">
                                  {/* <!-- unit price --> */}
                                  {/* <del class="d-block text-muted">$50.00</del> */}
                                  <span class="d-block">${product.total}</span>
                                </td>
                                <td class="text-end">
                                  {/* <!-- unit price --> */}
                                  {/* <del class="d-block text-muted">$50.00</del> */}
                                  {totalTicket(product.total - product.pagado)}
                                  <span class="badge bg-secondary rounded-pill">
                                    {/* {product.metodopago} */}
                                  </span>
                                </td>
                                <td class="text-center">
                                  {/* <!-- unit price --> */}
                                  {/* <del class="d-block text-muted">$50.00</del> */}
                                  <span
                                    class="badge rounded-pill"
                                    style={
                                      product.estadoEntrega === "Entregada"
                                        ? { color: "#9b242c" }
                                        : { color: "#506690" }
                                    }
                                  >
                                    {product.estadoEntrega}
                                  </span>
                                </td>

                                <td class="dropstart text-end">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      navigate(
                                        `/ticket/detalles/${product.uuid}`
                                      )
                                    }
                                  >
                                    Ver
                                  </a>
                                </td>
                              </tr>
                            </>
                          ))}
                          <tr>
                            <th class="small text-muted"></th>
                            <th class="small text-muted"></th>
                            <th class="small text-muted"></th>
                            <th class="small text-muted"></th>
                            <th class="small text-muted text-center">TOTAL</th>
                            <th class="small text-muted text-center">
                              TOTAL ORDENES
                            </th>
                            <th class="small text-muted text-center">SALDO</th>
                            <th class="small text-muted text-center"></th>
                          </tr>
                          <tr key="indextotal">
                            <td></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-end">
                              $
                              {ingresos.efectivo +
                                ingresos.transferencia +
                                ingresos.tarjeta}
                            </td>
                            {/* <td class="text-center">
                                  {formatDateCrate(product.fecha)}
                                </td> */}
                            <td class="text-end">
                              {/* <!-- unit price --> */}
                              {/* <del class="d-block text-muted">$50.00</del> */}
                              <span class="d-block">${totales.ordenes}</span>
                            </td>
                            <td class="text-end">
                              <span class="d-block">${totales.saldo}</span>
                            </td>
                            <td class="dropstart text-end"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-2">
              {/* <!-- side --> */}
              <div class="col-xl-4">
                <div class="card border-0 h-100 p-4">
                  {/*User*/}
                  {/* <div class=" mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Encargado</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          {corte ? (
                            <li class="list-item">
                              <a
                                class="text-decoration-none d-block"
                                href="javascript:void(0)"
                              >
                                {corte.responsableservicio.nombre}
                              </a>
                              <a
                                class="link-muted small"
                                href="javascript:void(0)"
                              >
                                {formatDateCrate(corte.fecha)}
                              </a>
                            </li>
                          ) : (
                            <>
                              <li class="list-item">
                                <a
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                >
                                  <BuscarUsuario
                                    onSumit={handleResponsableServicio}
                                    responsableServicio={responsableServicio}
                                  />
                                </a>
                              </li>
                              <li class="list-item pt-4">
                                <button
                                  className="btn btn-primary w-100"
                                  onClick={() => save()}
                                >
                                  <svg
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <polyline points="20 6 9 17 4 12" />
                                  </svg>
                                  {"  "}
                                  <span className="small">Registrar</span>
                                </button>
                              </li>
                              <li>
                                <small>Hora: {formatDate()}</small>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div class="col-xl-4">
                <div class="card border-0 h-100 p-4">
                  {/*User*/}
                  <div class=" mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Opciones</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            {printIsAuto ? (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                                role="button"
                                onClick={() => enviarImpresion()}
                              >
                                <PrintIcon fontSize="small" />
                                <span class="d-sm-inline-block ps-2">
                                  Imprime
                                </span>
                              </a>
                            ) : (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <PrintIcon fontSize="small" />
                                <PDFDownloadLink
                                  document={
                                    <Document
                                      fecha={fechaEntrega}
                                      fechaFinal={fechaFinal}
                                    />
                                  }
                                  fileName={`LET_ORDEN_${newdate.toLocaleDateString()}.pdf`}
                                  style={{ padding: "0.5rem" }}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? (
                                      "Cargando..."
                                    ) : (
                                      <a
                                        className="text-decoration-none"
                                        href="javascript:void(0);"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          impresionManual(url);
                                        }}
                                      >
                                        <span
                                          style={{ marginInline: "0.5rem" }}
                                        >
                                          Imprime
                                        </span>
                                      </a>
                                    )
                                  }
                                </PDFDownloadLink>
                              </a>
                            )}
                          </li>
                          <li class="list-item">
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              role="button"
                            >
                              <PictureAsPdfIcon fontSize="small" />
                              <span class="d-sm-inline-block ps-2">
                                <PDFDownloadLink
                                  document={
                                    <Document
                                      fecha={fechaEntrega}
                                      fechaFinal={fechaFinal}
                                    />
                                  }
                                  fileName={`LET_ORDENES_PENDIENTES_${newdate.toLocaleDateString()}.pdf`}
                                  style={{ padding: "0.5rem" }}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? "Cargando..." : "Descargar PDF"
                                  }
                                </PDFDownloadLink>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaOrdenesPendientes;
