import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";

const FontPreview = ({ fontFamily }) => {
  const fonts = {
    "CourierPrime-Regular": require("../../utils/fonts/Courier_Prime/CourierPrime-Regular.ttf"),
    "MartialMono-Regular": require("../../utils/fonts/MartianMono/MartianMono_Condensed-Medium.ttf"),
    "Roboto-Regular": require("../../utils/fonts/Roboto/Roboto-Regular.ttf"),
    "FiraMono-Regular": require("../../utils/fonts/fira-mono/FiraMono-Regular.otf"),
    "SpaceMono-Regular": require("../../utils/fonts/Space_Mono/SpaceMono-Regular.ttf"),
    "Antonio-Regular": require("../../utils/fonts/Antonio/Antonio-Regular.ttf"),
    "Poppins-Regular": require("../../utils/fonts/Poppins/Poppins-Regular.ttf"),
    "Bodoni9pt-Regular": require("../../utils/fonts/Bodonic/BodoniModa_9pt-Regular.ttf"),
    "Bodoni18pt-Regular": require("../../utils/fonts/Bodonic/BodoniModa_18pt-Regular.ttf"),
    "Jost-Regular": require("../../utils/fonts/Jost/Jost-Regular.ttf"),
    "Monserrat-Regular": require("../../utils/fonts/Monserrat/MontserratAlternates-Regular.ttf"),
    "MuseoModerno-Regular": require("../../utils/fonts/MuseoModerno/MuseoModerno-Regular.ttf"),

    "CourierPrime-Bold": require("../../utils/fonts/Courier_Prime/CourierPrime-Bold.ttf"),
    "MartialMono-Bold": require("../../utils/fonts/MartianMono/MartianMono_Condensed-ExtraBold.ttf"),
    "Roboto-Bold": require("../../utils/fonts/Roboto/Roboto-Black.ttf"),
    "FiraMono-Bold": require("../../utils/fonts/fira-mono/FiraMono-Bold.otf"),
    "SpaceMono-Bold": require("../../utils/fonts/Space_Mono/SpaceMono-Bold.ttf"),
    "Antonio-Bold": require("../../utils/fonts/Antonio/Antonio-Bold.ttf"),
    "Poppins-Bold": require("../../utils/fonts/Poppins/Poppins-Bold.ttf"),
    "Bodoni9pt-Bold": require("../../utils/fonts/Bodonic/BodoniModa_9pt-Black.ttf"),
    "Bodoni18pt-Bold": require("../../utils/fonts/Bodonic/BodoniModa_18pt-Black.ttf"),
    "Jost-Bold": require("../../utils/fonts/Jost/Jost-Black.ttf"),
    "Monserrat-Bold": require("../../utils/fonts/Monserrat/MontserratAlternates-Black.ttf"),
    "MuseoModerno-Bold": require("../../utils/fonts/MuseoModerno/MuseoModerno-Black.ttf"),
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!fontFamily && (
          <Card variant="outlined" style={{ padding: 8 }}>
            <Typography variant="h6">Sin fuente reconocida</Typography>
          </Card>
        )}
        {fontFamily && (
          <Card variant="outlined" style={{ padding: 8 }}>
            <Typography variant="caption" className="text-muted">
              {fontFamily}
            </Typography>
            <style>
              {`
                  @font-face {
                    font-family: '${fontFamily}';
                    src: url(${fonts[fontFamily]}) format('truetype');
                  }
                `}
            </style>
            <Typography variant="body1" style={{ fontFamily: fontFamily }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit
              amet nulla auctor, vestibulum magna sed, convallis ex.
            </Typography>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default FontPreview;
