/* React */
import React, { useState, useEffect } from "react";
import { Badge } from "@mui/material";
import { Delete, Visibility } from "@material-ui/icons";
import { grey, lightBlue } from "@mui/material/colors";

// import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import Preloader from "../../utils/PreloaderElement";
import { toast } from "react-toastify";

/* Servicios */
import MensajeService from "../../../services/Contacto.service";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormatDate } from "../../utils/Format";

const MensajeDetalles = ({ id, data, onSumit, selected }) => {
  const [loading, setLoading] = useState(true);
  const [mensaje, setMensaje] = useState(data);

  const theme = createTheme({
    palette: {
      primary: {
        main: grey[500],
      },
      secondary: {
        main: lightBlue[400],
      },
      success: {
        main: "#536dfe",
      },
    },
  });

  useEffect(() => {
    loadMensaje();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMensaje = async () => {
    setLoading(true);
    try {
      const result = await MensajeService.getById(id);
      setMensaje(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const estado = (state) => {
    if (state == "SinAbrir") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"SinAbrir"}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%", backgroundColor: "#686868" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state == "Leido") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"Leído"}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state == "EnProceso") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"EnProceso"}
              color="warning"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state == "Cerrado") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"Cerrado"}
              color="success"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state == "Revisar") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"Revisar"}
              color="error"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
  };

  const leidoMensaje = async () => {
    try {
      if (mensaje.status == "SinAbrir") {
        mensaje.status = "Leido";
        MensajeService.update(mensaje)
          .then((response) => {})
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const eliminarMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿ELIMINAR MENSAJE?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "ELIMINAR",
        denyButtonText: `CANCELAR`,
        cancelButtonText: `CANCELAR`,
      }).then((result) => {
        if (result.isConfirmed) {
          MensajeService.remove(id)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          onSumit();
          // window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {loading && mensaje != undefined ? (
        <Preloader />
      ) : (
        <tr key={id} className="text-uppercase">
          <th scope="row">{id}</th>
          <td>{mensaje.nombre.toUpperCase()}</td>
          <td>{mensaje.correo.toUpperCase()}</td>
          <td>{estado(mensaje.status)}</td>
          <td>{FormatDate(mensaje.createdAt)}</td>
          <td>
            {" "}
            <IconButton
              aria-label="ver"
              onClick={() => {
                selected(mensaje);
                leidoMensaje();
              }}
              style={{ marginTop: "-4%" }}
            >
              <Visibility />
            </IconButton>
          </td>
          <td>
            {" "}
            <IconButton
              aria-label="delete"
              onClick={() => eliminarMensaje(id)}
              style={{ marginTop: "-4%" }}
            >
              <Delete />
            </IconButton>
          </td>
        </tr>
      )}
    </>
  );
};

export default MensajeDetalles;
