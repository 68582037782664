import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const EntradaSimilar = ({
  id,
  titulo,
  idAutor,
  fecha,
  categoria,
  imagenes,
  cuerpo,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
    getAutor();
    console.log("use effect" + titulo);
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imag);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const getAutor = async () => {
    try {
      const result = await UserService.getById(id);
      setAutor(result.nombre);
      autorNombre = result.nombre;
      console.log("Nombre autor nn: " + autorNombre);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {/* post */}
      <a
        href="javascript:void(0)"
        onClick={() => navigate(`/blog/${id}`)}
        className="col-6 col-md-4 mb-5 position-relative text-dark clearfix text-decoration-none"
      >
        <figure className="d-block text-center overlay-dark-hover overlay-opacity-2 rounded overflow-hidden">
          <img className="img-fluid rounded" src={imag} alt="..." />
        </figure>
        <p className="mt-3 fs-2 mb-2">
          <span className="h6 text-dark d-block">{titulo}</span>
          <span className="d-block text-muted font-regular fs-6">
            Post Date:{" "}
            <time dateTime="2019-12-03">{String(fecha).slice(0, 10)} </time>
          </span>
        </p>
      </a>
      {/* /post */}
    </>
  );
};

export default EntradaSimilar;
