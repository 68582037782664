import React, { Component } from "react";

class Uploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageValidationError: null,
      imagenesDefault: props.imagenesDefault,
    };
  }

  // async componentDidMount() {
  //   if (this.state.imagenesDefault) {
  //     const { imagesPreviewUrls } = this.props;

  //     try {
  //       this.state.imagenesDefault.map((record, index) => {
  //         const result = {
  //           urlImaen: record,
  //           id: index,
  //         };
  //         imagesPreviewUrls(result);
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // }

  filesSelectedHandler = (e) => {
    if (this.checkMimeType(e)) {
      const { imagesPreviewUrls } = this.props;
      const files = Array.from(e.target.files);
      // console.log(files)
      /**
       * Aqui tenemos que pasar las imagenes
       */

      files.forEach((file, index) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = file;
          result.id = index;

          this.setState({ imageValidationError: null });
          imagesPreviewUrls(result, true, 1);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  checkMimeType(event) {
    const { files } = event.target;
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 10 MB
    let err = "";
    const types = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/jfif",
      "image/tiff",
    ];
    for (let x = 0; x < files.length; x += 1) {
      if (types.every((type) => files[x].type !== type)) {
        err += `${files[x].type} No es un formato de archivo soportado\n`;
      }
      if (files[x].size > MAX_FILE_SIZE) {
        console.log(files[x]);
        err += `${files[x].name} supera los 2mb\n`;
      }
    }

    if (err !== "") {
      event.target.value = null;
      this.setState({ imageValidationError: err });
      return false;
    }
    return true;
  }

  render() {
    const { imageValidationError } = this.state;

    return (
      <>
        <div
          id="main"
          style={{
            padding: "20px",
            marginTop: "20px",
            marginBottom: "30px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "10px",
            borderColor: "#000000",
            backgroundColor: "#9b242c",
            backgroundImage: "url(/upload.png)",
            backgroundSize: "contain",
            position: "relative",
          }}
        >
          <label style={{ position: "absolute", top: "-1.5rem" }}>
            {this.props.label && this.props.label}
          </label>
          {/* <p
            style={{
              position: "absolute",
              maxWidth: "100%",
              maxHeight: "14%",
              marginLeft: "-12%",
              bottom: "236px",
            }}
          >
            Suelta tus imagenes en esta area
          </p> */}
          <input
            type="file"
            name="file"
            id="file"
            className="custom-file-input"
            onChange={this.filesSelectedHandler}
            accept="image/png, image/jpeg, image/jpg"
            multiple
            style={{
              height: "100px",
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "-webkit-fill-available",
              border: "dashed",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          />
          {imageValidationError ? (
            <span className="error-msg">{imageValidationError}</span>
          ) : null}

          <label style={{ position: "absolute", bottom: "-1.5rem" }}>
            {this.props.footer && this.props.footer}
          </label>
        </div>
      </>
    );
  }
}

export default Uploader;
