import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//servicio
import S3Service from "../../../services/S3.service";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Carousel from "react-bootstrap/Carousel";

const ServicioItem = ({ data, tipo }) => {
  const navigate = useNavigate();

  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState("0");
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  <>
    {/*if (data.descuento > 0) {
    const descuentoProps = data.descuento;
    const precioOriginal = precio;
    precioFinal = precioOriginal - 100 / (precioOriginal / descuentoProps);
  }
//tipo: Empresa o usuario
 /* if (tipo == "Empresa") {
    if (record.precioE == null) {
      precios = 0;
    } else {
      for (const pre of record.precioE) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  } else {
    if (record.precioU == null) {
      precios = 0;
    } else {
      for (const pre of record.precioU) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  }*/}
  </>;

  const [open, setOpen] = useState(false);
  const [imagenesItem, setImagenes] = useState([]);

  useEffect(() => {
    if (data.imagenes.length > 0) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      for (const record of data.imagenes) {
        const imagen = await S3Service.get(record);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
        console.log("------------" + imagen.result);
      }
      const result = await S3Service.get(data.imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imagen);
      const image = document.querySelector(`#imagenProducto${data.id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const Precio = () => {
    if (data.descuento > 0) {
      return (
        <>
          <div className="text-white">
            <p className="mb-0 lead fw-bold text-warning">
              Descuento de {data.descuento}%{" "}
            </p>
            <h2 className="mb-4 text-uppercase">{data.titulo}</h2>
            <p className="mb-0 fw-medium">
              Precio final:{" "}
              <span className="text-warning">
                $
                {data.precios[0].props.precio -
                  data.precios[0].props.precio * (data.descuento / 100)}
              </span>
            </p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="text-white">
            <h2 className="mb-4 text-uppercase">{data.titulo}</h2>
            <p className="mb-0 fw-medium">
              Precio:{" "}
              <span className="text-warning">
                ${data.precios[0].props.precio}
              </span>
            </p>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="col-lg-6">
        <a
          href="javascript:void(0)"
          className="position-relative overlay-dark overlay-dark-hover overlay-opacity-5 overlay-opacity-6-hover cursor-pointer d-flex align-items-center bg-dark bg-cover p-4"
          style={{
            height: 300,
            backgroundImage: `url(${imag})`,
          }}
          onClick={() => navigate(`/Servicios/${data.id}`)}
        >
          {Precio()}
          {/* lines, looks like through a glass */}
          <div className="absolute-full w-100 overflow-hidden opacity-5">
            <img
              className="img-fluid"
              width={2000}
              height={2000}
              src="assets/images/masks/shape-line-lense.svg"
              alt="..."
            />
          </div>
        </a>
      </div>
    </>
  );
};

export default ServicioItem;
