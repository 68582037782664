import React from "react";

import { Link, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    textTransform: "uppercase",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  name: {
    fontSize: 24,
    fontFamily: "Lato Bold",
  },
  subtitle: {
    fontSize: 10,
    justifySelf: "flex-end",
    fontFamily: "Lato",
  },
  link: {
    fontFamily: "Lato",
    fontSize: 10,
    color: "black",
    textDecoration: "none",
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  logo: { height: "40px", width: "136px" },
});

const Header = ({ src, date, text }) => (
  <View style={styles.container}>
    <View style={styles.detailColumn}>
      <Image style={styles.logo} src={src} />
      <Text style={styles.subtitle}>{text}</Text>
    </View>
    <View style={styles.linkColumn}>
      <Text style={styles.link}>{date}</Text>
    </View>
  </View>
);

export default Header;
