import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

/* Components & dependencies */
import { toast } from "react-toastify";
import Preloader from "../../utils/Preloader";
import { nanoid } from "nanoid";
import { TextareaAutosize } from "@mui/material";

/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import {
  validarContrasenaSimple,
  validarDireccion,
  validarEmail,
  validarNombreCompleto,
  validarNumeroTelefono,
} from "../../utils/Validator";

const EditarCliente = () => {
  const { id } = useParams();
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const navigate = useNavigate();
  const [requerimientos, setRequerimientos] = useState("");

  const saveUsuario = async () => {
    if (!usuario.nombre || !validarNombreCompleto(usuario.nombre)) {
      toast.info(
        "Error, tienes que colocar un nombre con mas de 5 caracteres y solo caracteres alfabéticos y espacios"
      );
    } else if (!usuario.correo || !validarEmail(usuario.correo)) {
      toast.info("Error, tienes que colocar un correo valido");
    } else if (
      !usuario.contraseña ||
      !validarContrasenaSimple(usuario.contraseña)
    ) {
      toast.info(
        "Error, tienes que colocar una contraseña que tenga mas de 6 caracteres sin espacios en blanco"
      );
    } else if (!usuario.telefono || !validarNumeroTelefono(usuario.telefono)) {
      toast.info("Error, tienes que colocar una numero de 10 dígitos");
    } else if (!usuario.direccion || !validarDireccion(usuario.direccion)) {
      toast.info("Error, tienes que colocar una dirección con calle y numero");
    } else if (!usuario.fechaNacimiento) {
      toast.info("Error, tienes que colocar una fecha de nacimiento");
    } else {
      try {
        const datosUsuario = usuario;
        datosUsuario.uuid = nanoid();
        datosUsuario.requerimientos = requerimientos;

        await ClienteService.create(datosUsuario);

        toast.success("guardado!");
      } catch (error) {
        toast.error(error);
      } finally {
        navigate("/clientes");
      }
    }
  };

  function validarString(str) {
    // Eliminar espacios en blanco
    str = str.trim();

    // Verificar si el string es vacío
    if (str.length === 0) {
      return false;
    }

    // Verificar si el string contiene solo caracteres especiales
    const regex = /^[\s\n\t]+$/;
    if (regex.test(str)) {
      return false;
    }

    return true;
  }

  const actualizarUsuario = async () => {
    if (!usuario.nombre || !validarNombreCompleto(usuario.nombre)) {
      toast.info(
        "Error, tienes que colocar un nombre con solo caracteres alfabéticos y espacios"
      );
    } else if (!usuario.correo || !validarEmail(usuario.correo)) {
      toast.info("Error, tienes que colocar un correo valido");
    } else if (!usuario.telefono || !validarNumeroTelefono(usuario.telefono)) {
      toast.info("Error, tienes que colocar una numero de 10 dígitos");
    } else if (!usuario.direccion || !validarDireccion(usuario.direccion)) {
      toast.info("Error, tienes que colocar una dirección");
    } else if (!usuario.fechaNacimiento) {
      toast.info("Error, tienes que colocar una fecha de nacimiento");
    } else {
      setLoading(true);
      try {
        let data = {};
        if (password && !validarContrasenaSimple(password)) {
          toast.info(
            "Error, tienes que colocar una contraseña que tenga mas de 6 caracteres sin espacios en blanco y al menos un numero"
          );
          return;
        } else if (password.length >= 1) {
          data = {
            id: usuario.uuid,
            nombre: usuario.nombre,
            correo: usuario.correo,
            contraseña: password,
            telefono: usuario.telefono,
            direccion: usuario.direccion,
            fechaNacimiento: usuario.fechaNacimiento,
            requerimientos: validarString(requerimientos) ? requerimientos : "",
          };
          console.log("cambio de contraseña" + data.contraseña);
        } else {
          data = {
            id: usuario.uuid,
            nombre: usuario.nombre,
            correo: usuario.correo,
            telefono: usuario.telefono,
            direccion: usuario.direccion,
            fechaNacimiento: usuario.fechaNacimiento,
            requerimientos: validarString(requerimientos) ? requerimientos : "",
          };
          console.log("NO cambio de contraseña" + password);
        }

        // if (file) {
        //   await S3Service.delete(usuario.imagen);
        //   const uploadPhoto = await S3Service.upload(file);
        //   data.imagen = uploadPhoto.result.data;
        // }

        const userActualizado = await ClienteService.update(data);
        console.log(userActualizado);
        toast.success("Usuario actualizado con exito");
        navigate("/clientes");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const data = await ClienteService.getById(id);
      console.log("data");
      // if (data.imagen) {
      //   const imagen = await S3Service.get(data.imagen);
      //   console.log(imagen);
      //   setImagen(imagen.result);
      // }
      console.log("data");
      console.log(data);
      setUsuario(data);
      setRequerimientos(data?.requerimientos ? data.requerimientos : "");

      let fechan = new Date(data.fechaNacimiento);
      setFechaNacimiento(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(fechan)
      );
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Clientes</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/clientes")}
                    >
                      Clientes
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Editar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold"></div>
                    <div className="card-body">
                      <div style={{ width: "-webkit-fill-available" }}></div>
                      <div className="row g-3">
                        <div>
                          <h3>Información personal</h3>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Nombre"
                              value={usuario?.nombre}
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  nombre: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            <label>Nombre</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="date"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  fechaNacimiento: e.target.value,
                                })
                              }
                              defaultValue={fechaNacimiento}
                              placeholder="Fecha nacimiento"
                            />
                            <label htmlFor="shipping__last_name">
                              Fecha de nacimiento
                            </label>
                          </div>
                        </div>
                        <div>
                          <h3>Detalles de contacto</h3>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  telefono: `${e.target.value}`,
                                })
                              }
                              value={usuario.telefono}
                              placeholder="Telefono"
                            />
                            <label>Telefono</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  correo: e.target.value,
                                })
                              }
                              value={usuario.correo}
                              placeholder="Correo"
                            />
                            <label htmlFor="shipping__last_name">Correo</label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  direccion: e.target.value.toUpperCase(),
                                })
                              }
                              value={usuario.direccion}
                              placeholder="Direccion"
                            />
                            <label htmlFor="shipping__last_name">
                              Direccion
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <TextareaAutosize
                            type="text"
                            className="form-control shadow-none"
                            placeholder="Requerimientos"
                            value={requerimientos}
                            minRows={5}
                            onChange={(e) =>
                              setRequerimientos(e.target.value.toUpperCase())
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => actualizarUsuario()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className="small">Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EditarCliente;
