/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./Document";
import PrintIcon from "@mui/icons-material/Print";

/* Servicios */
import TicketsService from "../../../services/Tickets.service";
import ConfiguracionService from "../../../services/Configuracion.service";
import ReportesService from "../../../services/Reportes.service";
import { usePrint } from "../../../hooks/usePrint";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const [ticketsNormal, setTicketsNormal] = useState([]);
  const [ticketsExpress, setTicketsExpress] = useState([]);
  const [ticketsHora, setTicketsHora] = useState([]);

  const [total, setTotal] = useState(0);
  const [clientes, setClientes] = useState(0);

  const [waitingPrint, setWaitingPrint] = useState(false);
  const { printUrl, printIsAuto } = usePrint();

  useEffect(() => {
    getTickets();
  }, []);

  useEffect(() => {
    clientesCount();
  }, [ticketsExpress, ticketsNormal, ticketsHora]);

  const getTickets = async () => {
    setLoading(true);
    try {
      const list = await TicketsService.listFechaGroup({ fecha: today });
      if (list) {
        setTicketsNormal((old) => list.dataNormal);
        setTicketsExpress((old) => list.dataExpress);
        setTicketsHora((old) => list.dataHora);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enviarImpresion = async () => {
    if (waitingPrint) {
      return;
    }

    setWaitingPrint(true);
    try {
      let newTicket = { fecha: today };
      const res = await ReportesService.printDashboard(newTicket);
      printUrl(res.url);
    } catch (error) {
      toast.error("Error. No enviado!");
    } finally {
      setWaitingPrint(false);
    }
  };

  const impresionManual = async (url) => {
    printUrl(url);
  };

  const clientesCount = () => {
    let totalClientes = 0;
    let totalEntregas = 0;
    let cliente = [];
    ticketsExpress?.map((tick) => {
      if (!cliente.includes(tick.cliente?.correo)) {
        totalClientes++;
        cliente.push(tick.cliente?.correo);
      }
      if (tick.estadoEntrega === "Entregada") {
        totalEntregas++;
      }
    });
    ticketsNormal?.map((tick) => {
      if (!cliente.includes(tick.cliente?.correo)) {
        totalClientes++;
        cliente.push(tick.cliente?.correo);
      }
      if (tick.estadoEntrega === "Entregada") {
        totalEntregas++;
      }
    });
    ticketsHora?.map((tick) => {
      if (!cliente.includes(tick.cliente?.correo)) {
        totalClientes++;
        cliente.push(tick.cliente?.correo);
      }
      if (tick.estadoEntrega === "Entregada") {
        totalEntregas++;
      }
    });
    setClientes(totalClientes);

    setTotal(totalEntregas);
  };

  const formatDateString = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleString();
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <main id="middle" class="flex-fill mx-auto">
          <div class="row g-4 mb-4">
            <div class="col-lg-3">
              <div class="section p-4">
                <h6 class="mb-0 text-muted">Fecha:</h6>
                <div
                  class="d-flex align-items-center"
                  style={{ maxHeight: "3rem" }}
                >
                  <span class="w-100 fs-5 fw-bold mb-0">
                    {today.toLocaleDateString()}
                  </span>
                  {/* <small class="text-muted"></small> */}
                  {/* <span class="badge bg-soft-success">
                    <svg
                      width="14px"
                      height="14px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                    </svg>
                  </span> */}
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="section p-4">
                <h6 class="mb-0 text-muted">Entregas:</h6>
                <div class="d-flex align-items-center">
                  <span class="w-100">
                    <span class="fs-5 fw-bold">{total}</span>
                    {/* <small class="text-muted">/ 3899</small> */}
                  </span>
                  <span class="badge bg-soft-success">
                    <svg
                      width="14px"
                      height="14px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                      <polyline points="17 6 23 6 23 12"></polyline>
                    </svg>
                    <span class="ms-2">68%</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="section p-4">
                <h6 class="mb-0 text-muted">Clientes</h6>
                <div class="d-flex align-items-center">
                  <span class="w-100">
                    <span class="fs-5 fw-bold">{clientes}</span>
                    {/* <small class="text-muted">/ 2912</small> */}
                  </span>
                  <span class="badge bg-soft-danger">
                    <svg
                      width="14px"
                      height="14px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                      <polyline points="17 18 23 18 23 12"></polyline>
                    </svg>
                    <span class="ms-2">24%</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-3" style={{ height: "6rem" }}>
              <div class="section p-4">
                {printIsAuto ? (
                  <a
                    class="text-decoration-none"
                    href="javascript:void(0)"
                    onClick={() => enviarImpresion()}
                  >
                    <PrintIcon fontSize="small" />
                    <span style={{ marginInline: "0.5rem" }}>Imprime</span>
                  </a>
                ) : (
                  <a class="text-decoration-none" href="javascript:void(0)">
                    <PrintIcon fontSize="small" />
                    <PDFDownloadLink
                      document={<Document />}
                      fileName={`LET_ORDEN_${today.toLocaleDateString()}.pdf`}
                      style={{ padding: "0.5rem" }}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Cargando..."
                        ) : (
                          <a
                            className="text-decoration-none"
                            href="javascript:void(0);"
                            onClick={(event) => {
                              event.preventDefault();
                              impresionManual(url);
                            }}
                          >
                            <span style={{ marginInline: "0.5rem" }}>
                              Imprime
                            </span>
                          </a>
                        )
                      }
                    </PDFDownloadLink>
                  </a>
                )}

                <div style={{ color: "#9b242c" }}>
                  <PictureAsPdfIcon fontSize="small" />
                  <PDFDownloadLink
                    document={<Document />}
                    fileName={`LET_${today.toLocaleDateString()}.pdf`}
                    style={{ marginInline: "0.5rem" }}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Cargando..." : "Descargar PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>

          <h2 class="h5 mb-3 fw-bold">ORDENES DE SERVICIO</h2>

          <div class="col-12 card border-0 mb-3">
            <div>
              <div class="col-12 card-header d-flex gap-3 mb-3">
                <span className="fw-bold text-muted">Express:</span>
                <span className="text-muted"></span>
              </div>
            </div>
            <div class="card-body p-4 p-xl-5">
              {ticketsExpress?.map((ticket) => (
                <div class="clearfix border-bottom py-2">
                  <div class="d-flex">
                    <div style={{ width: "40px" }}>
                      <StickyNote2OutlinedIcon fontSize="medium" />
                    </div>

                    <div class="col">
                      <a
                        href="javascript:void(0);"
                        class="fw-bold link-normal"
                        style={
                          ticket.estadoEntrega === "Entregada"
                            ? {
                                cursor: "pointer",
                                textDecoration: "line-through",
                              }
                            : { cursor: "pointer" }
                        }
                        onClick={() =>
                          navigate(`/ticket/detalles/${ticket.uuid}`)
                        }
                      >
                        {ticket.uuid}
                      </a>

                      {!ticket.nota ? (
                        <p className="small">Sin notas</p>
                      ) : (
                        <p>{ticket.nota}</p>
                      )}
                      <span className="small">{ticket.cliente?.direccion}</span>
                    </div>
                    {/* <!-- Customer --> */}
                    <div class="card-header">
                      <div class="d-flex align-items-center">
                        {/* <!-- customer details --> */}
                        <div class="w-100 ps-4">
                          <ul class="list-unstyled m-0 p-0">
                            <li class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0);"
                              >
                                {ticket.cliente?.nombre}
                              </a>
                              <a
                                class="link-muted small ms-2"
                                href="javascript:void(0);"
                              >
                                {/* 4 orders */}
                              </a>
                            </li>
                            <li class="list-item">
                              {/* <!-- customer email --> */}
                              <a
                                class="link-normal small d-inline-grid gap-auto-2"
                                href="mailto:john.doe@gmail.com"
                              >
                                <svg
                                  class="text-muted"
                                  width="18px"
                                  height="18px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                  ></path>
                                </svg>
                                <span>{ticket.cliente?.correo}</span>
                              </a>
                            </li>
                            <li class="list-item">
                              {/* <!-- customer phone --> */}
                              <a
                                class="link-normal small d-inline-grid gap-auto-2"
                                href="tel:2483440447"
                              >
                                <svg
                                  class="text-muted"
                                  width="18px"
                                  height="18px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                  ></path>
                                </svg>
                                <span>{ticket.cliente?.telefono}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul class="list-inline mt-2 text-muted small">
                    <li class="list-inline-item">
                      <AttachMoneyOutlinedIcon
                        color="disabled"
                        style={{ height: "18px", marginTop: "-2px" }}
                      />{" "}
                      {ticket.estadopago}
                    </li>
                    <li class="list-inline-item">
                      <i class="fi fi-time float-start"></i>{" "}
                      {formatDateString(ticket.fecha)}
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <div class="col-12 card border-0 mb-3">
            <div>
              <div class="col-12 card-header d-flex gap-3 mb-3">
                <span className="fw-bold text-muted">Hora especifica:</span>
                <span className="text-muted"></span>
              </div>
            </div>
            <div class="card-body p-4 p-xl-5">
              {ticketsHora?.map((ticket) => (
                <div class="clearfix border-bottom py-2">
                  <div class="d-flex">
                    <div style={{ width: "40px" }}>
                      <StickyNote2OutlinedIcon fontSize="medium" />
                    </div>

                    <div class="col">
                      <a
                        href="javascript:void(0);"
                        class="fw-bold link-normal"
                        style={
                          ticket.estadoEntrega === "Entregada"
                            ? {
                                cursor: "pointer",
                                textDecoration: "line-through",
                              }
                            : { cursor: "pointer" }
                        }
                        onClick={() =>
                          navigate(`/ticket/detalles/${ticket.uuid}`)
                        }
                      >
                        {ticket.uuid}
                      </a>

                      {!ticket.nota ? (
                        <p className="small">Sin notas</p>
                      ) : (
                        <p>{ticket.nota}</p>
                      )}
                      <span className="small">{ticket.cliente?.direccion}</span>
                    </div>
                    {/* <!-- Customer --> */}
                    <div class="card-header">
                      <div class="d-flex align-items-center">
                        {/* <!-- customer details --> */}
                        <div class="w-100 ps-4">
                          <ul class="list-unstyled m-0 p-0">
                            <li class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0);"
                              >
                                {ticket.cliente?.nombre}
                              </a>
                              <a
                                class="link-muted small ms-2"
                                href="javascript:void(0);"
                              >
                                {/* 4 orders */}
                              </a>
                            </li>
                            <li class="list-item">
                              {/* <!-- customer email --> */}
                              <a
                                class="link-normal small d-inline-grid gap-auto-2"
                                href="mailto:john.doe@gmail.com"
                              >
                                <svg
                                  class="text-muted"
                                  width="18px"
                                  height="18px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                  ></path>
                                </svg>
                                <span>{ticket.cliente?.correo}</span>
                              </a>
                            </li>
                            <li class="list-item">
                              {/* <!-- customer phone --> */}
                              <a
                                class="link-normal small d-inline-grid gap-auto-2"
                                href="tel:2483440447"
                              >
                                <svg
                                  class="text-muted"
                                  width="18px"
                                  height="18px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                  ></path>
                                </svg>
                                <span>{ticket.cliente?.telefono}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul class="list-inline mt-2 text-muted small">
                    <li class="list-inline-item">
                      <AttachMoneyOutlinedIcon
                        color="disabled"
                        style={{ height: "18px", marginTop: "-2px" }}
                      />{" "}
                      {ticket.estadopago}
                    </li>
                    <li class="list-inline-item">
                      <i class="fi fi-time float-start"></i>{" "}
                      {formatDateString(ticket.fecha)}
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <div class="d-flex align-items-center"></div>
        </main>
      )}
    </>
  );
};

export default Dashboard;
