/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./Document/Document";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import BuscarUsuario from "./BuscarUsuario";
import Swal from "sweetalert2";

/* Servicios */
import TicketsService from "../../../services/Tickets.service";
import TicketsSalidaService from "../../../services/TicketsSalida.service";
import ImpresionService from "../../../services/Impresion.service";
import ConfiguracionService from "../../../services/Configuracion.service";
import ReportesService from "../../../services/Reportes.service";

import { FormatDate } from "../../utils/Format";
import { Divider, Grid, TextField, Typography } from "@mui/material";

const TablaCorte = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [tickets, setTickets] = useState([]);
  const [ticketsSalida, setTicketsSalida] = useState([]);
  const [ticketsPagos, setTicketsPagos] = useState([]);

  const [ingresos, setIngresos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [ingresosPagos, setIngresosPagos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [egresos, setEgresoss] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });

  const [totales, setTotales] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesPago, setTotalesPago] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesSalida, setTotalesSalida] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalIngresos, setTotalIngresos] = useState(0);
  const [totalIngresosPagos, setTotalIngresosPagos] = useState(0);
  const [totalEgresos, setTotalEgresos] = useState(0);
  const [configuracionImpresion, setConfiguracionImpresion] = useState(null);

  const [pagos, setPagos] = useState([]);
  const [pagosPagos, setPagosPagos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [pagoclientes, setPagoclientes] = useState(0);
  const [pagoclientesPagos, setPagoclientesPagos] = useState(0);

  const [corte, setCorte] = useState(null);
  const [update, setUpdate] = useState(false);
  const newdate = new Date();
  const [responsableServicio, setResponsableServicio] = useState(null);
  let today = new Date();
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [fechaLimit, setFechaLimit] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );

  const [dejoCambio, setDejoCambio] = useState("");
  const [dejoSobre, setDejoSobre] = useState("");
  const [dejoSdia, setDejoSdia] = useState("");

  const onUpdate = () => {
    setUpdate(!update);
  };

  const handleFechaEntrega = (newFecha) => {
    setFechaEntrega(newFecha.target.value);
    console.log(newFecha.target.value);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const handleResponsableServicio = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableServicio(changecliente);
  };

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  const formatDate = () => {
    return newdate.toLocaleString();
  };

  const formatDateCrate = (fecha) => {
    const newdatec = new Date(fecha);
    return newdatec.toLocaleString();
  };

  const enviarImpresion = async (uuid) => {
    try {
      let newTicket = {
        fecha: `${fechaEntrega} 00:00:00`,
      };
      const res = await ReportesService.printCorte(newTicket);
      imprime(res.url);
      toast.success("Enviado...");
    } catch (error) {
      toast.error("Error. No enviado!");
    }
  };

  const imprime = async (url) => {
    console.log("url");
    console.log(url);
    if (!configuracionImpresion.envioAutomatico) {
      return;
    }
    console.log("PASS");
    toast.promise(
      ImpresionService.printUrl(url, configuracionImpresion.impresoraReportes),
      {
        pending: "Impresion pendiente",
        success: "Completado 👌",
        error: "Impresion rechazada 🤯",
      }
    );
  };

  const impresionManual = async (url) => {
    try {
      ImpresionService.printUrlDialog(url);
    } catch (error) {
      toast.error("Ha sucedido un error durante la impresión.");
    }
  };

  useEffect(() => {
    getTicket();
    getTicketPago();
    getTicketSalida();
    getPagos();
    getPagosPagos();
    getConfiguracionImpresion();
  }, [fechaEntrega, update]);

  const getConfiguracionImpresion = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionImpresion();
      setConfiguracionImpresion(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTicket = async () => {
    setLoading(true);
    try {
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsService.listCorte({
        fecha: `${fechaEntrega} 00:00:00`,
      });
      setTickets(data.data);
      setCorte(data.corte);

      for (const p of data.data) {
        ordenessaldo += parseInt(p.total) - parseInt(p.pagado);
        ordenestotal += parseInt(p.total);
      }
      sumarTotales(ordenestotal, ordenessaldo);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getTicketPago = async () => {
    setLoading(true);
    try {
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsService.listCortePagos({
        fecha: `${fechaEntrega} 00:00:00`,
      });
      setTicketsPagos(data.data);

      for (const p of data.data) {
        ordenessaldo += parseInt(p.total) - parseInt(p.pagado);
        ordenestotal += parseInt(p.total);
      }
      sumarTotalesPago(ordenestotal, ordenessaldo);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getTicketSalida = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsSalidaService.listCorte({
        fecha: `${fechaEntrega} 00:00:00`,
      });
      console.log("data salida -----------------");
      console.log(data);
      setTicketsSalida(data.data);

      for (const p of data.data) {
        ordenessaldo += parseInt(p.total) - parseInt(p.pagado);
        ordenestotal += parseInt(p.total);

        if (p.pagos[0].metodopago === "Efectivo") {
          efectivo += Number(p.pagos[0].precio);
        } else if (p.pagos[0].metodopago === "Tarjeta") {
          tarjeta += Number(p.pagos[0].precio);
        } else if (p.pagos[0].metodopago === "Transferencia") {
          transferencia += Number(p.pagos[0].precio);
        }
      }
      let newEgresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newEgresos);
      setEgresoss(newEgresos);
      setTotalEgresos(efectivo + tarjeta + transferencia);
      setTotalesSalida({ ordenes: ordenestotal, saldo: ordenessaldo });
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getPagos = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await TicketsService.listPagoFecha({
        fecha: `${fechaEntrega} 00:00:00`,
      });
      console.log(data);
      setPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresos(newIngresos);
      setPagoclientes(efectivo + tarjeta + transferencia);
      setTotalIngresos(efectivo + tarjeta + transferencia);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getPagosPagos = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await TicketsService.listPagoFechaPagos({
        fecha: `${fechaEntrega} 00:00:00`,
      });
      console.log(data);
      setPagosPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresosPagos(newIngresos);
      setPagoclientesPagos(efectivo + tarjeta + transferencia);
      setTotalIngresosPagos(efectivo + tarjeta + transferencia);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const totalTicket = (totalt) => {
    if (totalt > 0) {
      return <span class="d-block text-danger">${totalt}</span>;
    }
    return <span class="d-block text-success">${totalt}</span>;
  };

  const save = async () => {
    try {
      if (!fechaEntrega) {
        toast.info("Selecciona una fecha de entrega.");
        return;
      }
      if (!responsableServicio?.id) {
        toast.info("Selecciona un responsable de servicio.");
        return;
      }
      if (!dejoCambio) {
        toast.info("Falto agregar en cambio.");
        return;
      }
      if (!dejoSobre) {
        toast.info("Falto agregar en sobre.");
        return;
      }
      if (!dejoSdia) {
        toast.info("Falto agregar en Sdia.");
        return;
      }

      let entrega = new Date(fechaEntrega);

      const newCorte = {
        fecha: fechaEntrega,
        tickets: tickets.length,
        ingresos: ingresos,
        totalesingresos: totalIngresos,
        egresos: egresos,
        totalesegresos: totalEgresos,
        pagosClientes: pagoclientes,
        usuario: responsableServicio.id,
        dejoCambio: dejoCambio,
        dejoSobre: dejoSobre,
        dejoSdia: dejoSdia,
      };

      await Swal.fire({
        title: `<i class="fa fa-user fa-5x" aria-hidden="true"></i>`,
        input: "password",
        inputPlaceholder: "Contraseña",
        inputAttributes: {
          maxlength: "10",
          autocapitalize: "off",
          autocorrect: "off",
        },
        confirmButtonColor: "#9b242c",
        iconColor: "#9b242c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          newCorte.contrasena = result.value;
          await TicketsService.registrarCorte(newCorte);
          toast.success("Registrado");
          onUpdate();
        }
      });
    } catch (error) {
      toast.error("Error. No se ha registrado!");
    } finally {
      setLoading(false);
    }
  };

  const metodosPago = (listaMetodos) => {
    let sumaPagos = {
      Tarjeta: 0,
      Transferencia: 0,
      Efectivo: 0,
    };

    listaMetodos.map((metodoPago) => {
      sumaPagos[metodoPago.metodopago] += parseInt(metodoPago.precio);
    });

    return (
      <>
        <td
          class="text-end"
          style={sumaPagos.Efectivo > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Efectivo}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Transferencia > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Transferencia}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Tarjeta > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Tarjeta}
        </td>
      </>
    );
  };

  const sumarTotales = (ordentotal, ordensaldo) => {
    setTotales({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };
  const sumarTotalesPago = (ordentotal, ordensaldo) => {
    setTotalesPago({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };

  const metodosPagoSalida = (ordenmetodopago, monto) => {
    let sumaPagos = {
      Tarjeta: 0,
      Transferencia: 0,
      Efectivo: 0,
    };

    sumaPagos[ordenmetodopago] += parseInt(monto);

    return (
      <>
        <td
          class="text-end"
          style={sumaPagos.Efectivo > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Efectivo}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Transferencia > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Transferencia}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Tarjeta > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Tarjeta}
        </td>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <main id="middle" className="flex-fill">
            <Grid container className="section p-0 card border-0">
              <Grid container>
                <Grid sm={12} md={12} padding={2}>
                  <Typography className="fw-bold text-muted">
                    {FormatDate(fechaEntrega)}
                  </Typography>
                  <Typography className="fw-bold text-muted">
                    TURNO VESPERTINO
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div class="g-2 mb-3">
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div class="card border-0 h-100">
                    <div class="card-header px-4 border-bottom-0 fw-bold text-muted">
                      ENTREGADOS
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SALDO
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {tickets?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.createdAt)}
                                    </span>
                                  </td>
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span class="d-block">
                                      ${product.total}
                                    </span>
                                  </td>
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    {totalTicket(
                                      product.total - product.pagado
                                    )}
                                    <span class="badge bg-secondary rounded-pill">
                                      {/* {product.metodopago} */}
                                    </span>
                                  </td>
                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/detalles/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                TOTAL ORDENES
                              </th>
                              <th class="small text-muted text-center">
                                SALDO
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotal">
                              <td class="text-center"></td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block">${totales.ordenes}</span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                ${totales.saldo}
                                <span class="badge bg-secondary rounded-pill">
                                  {/* {product.metodopago} */}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">
                                  ${totales.ordenes - totales.saldo}
                                </span>
                              </td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid container>
              <div class="g-2 mb-3">
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div class="card border-0 h-100">
                    <div class="card-header px-4 border-bottom-0 fw-bold text-muted">
                      PXA Y RESTO
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SALDO
                              </th>
                              <th class="small text-muted text-center">
                                ESTADO
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {ticketsPagos?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.createdAt)}
                                    </span>
                                  </td>
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span class="d-block">
                                      ${product.total}
                                    </span>
                                  </td>
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    {totalTicket(
                                      product.total - product.pagado
                                    )}
                                    <span class="badge bg-secondary rounded-pill">
                                      {/* {product.metodopago} */}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span
                                      class="badge rounded-pill"
                                      style={
                                        product.estadoEntrega === "Entregado"
                                          ? { color: "#9b242c" }
                                          : { color: "#506690" }
                                      }
                                    >
                                      {product.estadoEntrega}
                                    </span>
                                  </td>

                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/detalles/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                TOTAL ORDENES
                              </th>
                              <th class="small text-muted text-center">
                                SALDO
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotal">
                              <td></td>
                              <td class="text-center"></td>
                              {/* <td class="text-center">
                                  {formatDateCrate(product.fecha)}
                                </td> */}
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block">
                                  ${totalesPago.ordenes}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                ${totalesPago.saldo}
                                <span class="badge bg-secondary rounded-pill">
                                  {/* {product.metodopago} */}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">
                                  ${totalesPago.ordenes - totalesPago.saldo}
                                </span>
                              </td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid container>
              {/* Tickets salida */}
              {/* <!-- Ticket items --> */}
              <div class="g-2 mb-3">
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div class="card border-0 h-100">
                    <div class="card-header px-4 border-bottom-0 fw-bold">
                      GASTOS
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted">EMISOR</th>
                              <th class="small text-muted text-center">
                                METODO
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {ticketsSalida?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.createdAt)}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {product.responsableservicio.nombre}
                                  </td>
                                  <td class="text-center">
                                    {product.pagos[0].metodopago}
                                  </td>
                                  <td class="text-center">
                                    ${product.pagos[0].precio}
                                  </td>
                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/salida/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotalegresos">
                              <td class="text-center"></td>
                              <td class="text-center"></td>
                              <td class="text-center"></td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">
                                  ${totalesSalida.ordenes - totalesSalida.saldo}
                                </span>
                              </td>
                              <td class="text-center"></td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <div class="col-12 card border-0 mb-0">
              <div>
                <div class="col-12 card-header d-flex gap-3 ">
                  <span className="fw-bold text-muted">Corte:</span>
                  <span className="text-muted">
                    <input
                      type="date"
                      max={fechaLimit}
                      defaultValue={fechaEntrega}
                      onChange={handleFechaEntrega}
                      style={{
                        border: "none",
                        paddingY: "0.5rem",
                      }}
                    />
                  </span>
                </div>
              </div>
              <div></div>
            </div>
            <div className="row g-2">
              {/* <!-- side --> */}
              <div class="col-xl-4">
                <div class="card border-0 h-100 p-4">
                  {/*User*/}
                  <div class=" mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Totales</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <div class="d-flex align-items-center">
                          <div class="small">
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "120px" }}
                              >
                                Total entregas
                              </span>
                              <span>${totales.ordenes - totales.saldo}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "120px" }}
                              >
                                Total PXA
                              </span>
                              <span>
                                + ${totalesPago.ordenes - totalesPago.saldo}
                              </span>
                            </div>
                            <div className="hr my-2"></div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "120px" }}
                              >
                                Total
                              </span>
                              <span>${totalIngresos}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "120px" }}
                              >
                                Total GASTOS
                              </span>
                              <span>- ${totalEgresos}</span>
                            </div>
                            <div className="hr my-2"></div>
                            <div class="d-flex justify-content-between">
                              <span
                                class="flex-none text-muted"
                                style={{ width: "120px" }}
                              >
                                Total
                              </span>
                              <span>${totalIngresos - totalEgresos}</span>
                            </div>
                            <p
                              class="list-item"
                              style={{ marginTop: "1.5rem" }}
                            >
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <TextField
                                  fullWidth
                                  label="Dejo en cambio"
                                  onChange={(event) =>
                                    setDejoCambio(event.target.value)
                                  }
                                  type="number"
                                  value={dejoCambio}
                                />
                              </a>
                              {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                            </p>
                            <p class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <TextField
                                  fullWidth
                                  label="Dejo en sobre"
                                  onChange={(event) =>
                                    setDejoSobre(event.target.value)
                                  }
                                  type="number"
                                  value={dejoSobre}
                                />
                              </a>
                              {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                            </p>
                            <p class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <TextField
                                  fullWidth
                                  label="Dejo en sobre del dia"
                                  onChange={(event) =>
                                    setDejoSdia(event.target.value)
                                  }
                                  type="number"
                                  value={dejoSdia}
                                />
                              </a>
                              {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="card border-0 h-100 p-4">
                  {/*User*/}
                  <div class=" mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Encargado</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          {corte ? (
                            <li class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none d-block"
                                href="javascript:void(0)"
                              >
                                {corte.responsableservicio.nombre}
                              </a>
                              <a
                                class="link-muted small"
                                href="javascript:void(0)"
                              >
                                {formatDateCrate(corte.fecha)}
                              </a>
                            </li>
                          ) : (
                            <>
                              <li class="list-item">
                                {/* <!-- customer name, orders --> */}
                                <a
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                >
                                  <BuscarUsuario
                                    onSumit={handleResponsableServicio}
                                    responsableServicio={responsableServicio}
                                  />
                                </a>
                                {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                              </li>

                              <li class="list-item pt-4">
                                {/* <!-- customer name, orders --> */}
                                <button
                                  className="btn btn-primary w-100 text-uppercase"
                                  onClick={() => save()}
                                >
                                  <svg
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <polyline points="20 6 9 17 4 12" />
                                  </svg>
                                  {"  "}
                                  <span className="small">Registrar</span>
                                </button>
                              </li>
                              <li>
                                <small>Hora: {formatDate()}</small>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="card border-0 h-100 p-4">
                  {/*User*/}
                  <div class=" mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Opciones</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            {configuracionImpresion?.envioAutomatico ? (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                                role="button"
                                onClick={() => enviarImpresion()}
                              >
                                <PrintIcon fontSize="small" />
                                <span class="d-sm-inline-block ps-2">
                                  Imprime
                                </span>
                              </a>
                            ) : (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <PrintIcon fontSize="small" />
                                <PDFDownloadLink
                                  document={<Document fecha={fechaEntrega} />}
                                  fileName={`LET_ORDEN_${newdate.toLocaleDateString()}.pdf`}
                                  style={{ padding: "0.5rem" }}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? (
                                      "Cargando..."
                                    ) : (
                                      <a
                                        className="text-decoration-none"
                                        href="javascript:void(0);"
                                        onClick={() => impresionManual(url)}
                                      >
                                        <span
                                          style={{ marginInline: "0.5rem" }}
                                        >
                                          Imprime
                                        </span>
                                      </a>
                                    )
                                  }
                                </PDFDownloadLink>
                              </a>
                            )}
                          </li>
                          <li class="list-item">
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              role="button"
                            >
                              <PictureAsPdfIcon fontSize="small" />
                              <span class="d-sm-inline-block ps-2">
                                <PDFDownloadLink
                                  document={<Document fecha={fechaEntrega} />}
                                  fileName={`LET_CORTE_${newdate.toLocaleDateString()}.pdf`}
                                  style={{ padding: "0.5rem" }}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? "Cargando..." : "Descargar PDF"
                                  }
                                </PDFDownloadLink>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaCorte;
