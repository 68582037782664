import { useState } from "react";
import { FormControlLabel, Radio, TextareaAutosize } from "@mui/material";
import BuscarUsuario from "./BuscarUsuario";

const RegistrarEntrega = ({ onSumit, closeModal }) => {
  const [nota, setNota] = useState("");
  const [selectedValue, setSelectedValue] = useState("En existencia");
  const [responsableServicio, setResponsableServicio] = useState(null);
  const [result, setResult] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleResponsableServicio = (newCliente) => {
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableServicio(changecliente);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  function validarString(str) {
    // Eliminar espacios en blanco
    str = str.trim();

    // Verificar si el string es vacío
    if (str.length === 0) {
      return false;
    }

    // Verificar si el string contiene solo caracteres especiales
    const regex = /^[\s\n\t]+$/;
    if (regex.test(str)) {
      return false;
    }

    return true;
  }

  const agregar = async () => {
    if (selectedValue === "En existencia") {
      closeModal();
      return;
    }
    onSumit({
      selectedValue,
      nota: validarString(nota) ? nota : "",
      responsableEntrega: responsableServicio?.id,
      contrasena: result,
    });
    closeModal();
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    agregar();
  };

  return (
    <>
      <div class="modal-body">
        <div class="form-floating">
          <div class="mb-5">
            <div class="fw-bold d-flex align-items-center mb-3">
              <span class="w-100">Entrega</span>
            </div>

            <div class="small">
              {" "}
              <FormControlLabel
                value="En existencia"
                control={
                  <Radio
                    {...controlProps("En existencia")}
                    sx={{
                      "&.Mui-checked": {
                        color: "#9b242c",
                      },
                    }}
                  />
                }
                label="En existencia"
              />
              <FormControlLabel
                value="Entregada"
                control={
                  <Radio
                    {...controlProps("Entregada")}
                    sx={{
                      "&.Mui-checked": {
                        color: "#9b242c",
                      },
                    }}
                  />
                }
                label="Entregada"
              />
            </div>
          </div>
        </div>
        <div class="form-check mt-2" style={{ paddingLeft: "0" }}>
          <label className="me-3">Notas:</label>
          <TextareaAutosize
            value={nota}
            style={{
              width: "100%",
              height: "100px",
              border: "solid 1px lightgrey",
              borderRadius: "8px",
              marginTop: "0.5rem",
            }}
            minRows={2}
            maxRows={2}
            onChange={(event) => {
              setNota(event.target.value);
            }}
          />
        </div>

        <BuscarUsuario
          onSumit={handleResponsableServicio}
          responsableServicio={responsableServicio}
          title="Seleccionar responsable"
          style={{}}
        />

        <label style={{}}>Contraseña</label>
        <input
          maxlength="18"
          autocapitalize="off"
          autocorrect="off"
          class="swal2-input"
          placeholder="Contraseña"
          type="password"
          style={{ display: "flex", width: "100%", margin: "0" }}
          value={result}
          onChange={(event) => setResult(event.target.value)}
          onKeyDown={handleKeyDown}
        ></input>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" onClick={agregar}>
          <svg
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span>Registrar</span>
        </button>
      </div>
    </>
  );
};

export default RegistrarEntrega;
