import { useEffect, useState } from "react";
import ClienteService from "../../../services/Cliente.service";
import { Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";

const BuscarCliente = ({ onSumit, closeModal }) => {
  const [productos, setProductos] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [cantidad, setCantidad] = useState(1);

  const agregar = () => {
    if (!value) {
      toast.info("Selecciona un cliente");
      return;
    }
    onSumit({ value });
    closeModal();
  };
  const moreCantidad = () => {
    setCantidad((prev) => prev + 1);
  };

  const subCantidad = () => {
    if (cantidad > 1) {
      setCantidad((prev) => prev - 1);
    }
  };

  const getProductos = async () => {
    try {
      const data = await ClienteService.getAll();
      let productosFormat = [];
      data.map((prod) => {
        productosFormat.push({
          label: prod.nombre.toUpperCase(),
          id: prod.id,
          uuid: prod.uuid,
          correo: prod.correo,
          telefono: prod.telefono,
          direccion: prod.direccion,
          requerimientos: prod.requerimientos,
          fechaNacimiento: prod.fechaNacimiento,
        });
      });
      console.log(data);
      setProductos(productosFormat);
    } catch (error) {}
  };

  useEffect(() => {
    getProductos();
  }, []);

  return (
    <>
      <div class="modal-body">
        <div class="form-floating">
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disablePortal
            id="combo-box-demo"
            options={productos}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Cliente" />}
          />
          <div class="mt-2" id="search-container">
            <div class="py-2">
              <label>Correo:</label>
              {/* <!-- appended item --> */}
              <a href="javascript:void(0);" class="link-normal"></a>
              <a
                href="javascrip:void(0);"
                class="text-decoration-none ms-2"
                style={{ textTransform: "none" }}
              >
                {value?.correo}
              </a>
            </div>
            <div class="py-2">
              <label>Telefono:</label>
              {/* <!-- appended item --> */}
              <a href="javascript:void(0);" class="link-normal"></a>
              <a href="javascrip:void(0);" class="text-decoration-none ms-2">
                {value?.telefono}
              </a>
            </div>
            <div class="py-2">
              <label>Dirección:</label>
              {/* <!-- appended item --> */}
              <a href="javascript:void(0);" class="link-normal"></a>
              <a href="javascrip:void(0);" class="text-decoration-none ms-2">
                {value?.direccion}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" onClick={agregar}>
          <svg
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span>Cambiar cliente</span>
        </button>
      </div>
    </>
  );
};

export default BuscarCliente;
