import httpClient from "./HttpClient";
const prefix = "/ticket";

export default class TicketsSalidaService {
  static async create(servicio) {
    return (await httpClient.post(`${prefix}/salida`, servicio)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/salida/${id}`)).data;
  }

  static async getById(data) {
    return (await httpClient.post(`${prefix}-salida/`, data)).data;
  }

  static async listByCliente(data) {
    return (await httpClient.post(`${prefix}s/salida-responsable/`, data)).data;
  }

  static async listCorte(data) {
    return (await httpClient.post(`${prefix}s/salida-corte/`, data)).data;
  }

  static async listFecha(data) {
    return (await httpClient.post(`${prefix}s/salida-fecha/`, data)).data;
  }

  static async resendPrint(servicio) {
    return (await httpClient.post(`${prefix}-resendprint/salida`, servicio))
      .data;
  }
}
