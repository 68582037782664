/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

/* Servicios */
import ConfiguracionService from "../../../services/Configuracion.service";
import ImpresionService from "../../../services/Impresion.service";
import CrearImpresora from "./CrearImpresora";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FontPreview from "./FontPreview";

const Impresion = () => {
  const [loading, setLoading] = useState(false);
  const [serie, setSerie] = useState("Default");
  const [impresoraReportes, setImpresoraReportes] = useState("Default");
  const [automatizar, setAutomatizar] = useState(false);
  const [openModalSerie, setOpenModalSerie] = useState(false);
  const [impresoras, setImpresoras] = useState(["Default"]);
  const [openModalImpresora, setOpenModalImpresora] = useState(false);
  const [update, setUpdate] = useState(false);
  const [fontFamilyValue, setFontFamilyValue] = useState(
    "CourierPrime-Regular"
  );
  const [fontFamilyBoldValue, setFontFamilyBoldValue] =
    useState("CourierPrime-Bold");
  const [fontSizeValue, setFontSizeValue] = useState(9);
  const [fontSizeBoldValue, setFontSizeBoldValue] = useState(12);

  const [pageWidthValue, setPageWidthValue] = useState(75);
  const navigate = useNavigate();

  const handleOpenModalImpresora = () => {
    setOpenModalImpresora(!openModalImpresora);
  };

  useEffect(() => {
    getConfiguracion();
  }, [update]);

  const onUpdate = () => {
    setUpdate(!update);
  };

  const getConfiguracion = async () => {
    setLoading(true);
    try {
      const data = await ConfiguracionService.getConfiguracionImpresion();
      setSerie(data.impresora);
      setImpresoraReportes(data.impresoraReportes);
      setAutomatizar(data.envioAutomatico);
      setFontFamilyValue(data.fontFamily);
      setFontFamilyBoldValue(data.fontFamilyBold);
      setFontSizeValue(data.fontSize);
      setFontSizeBoldValue(data.fontSizeBold);
      setPageWidthValue(data.pageWidth);

      if (data.impresoras) {
        setImpresoras(data.impresoras);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateConfiguracionImpresion = async (updateData) => {
    setLoading(true);
    try {
      await ConfiguracionService.updateConfiguracionImpresion(updateData);
      toast.success("Guardado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      onUpdate();
    }
  };

  const reemplazarEspaciosConEntidad = (cadena) =>
    cadena.replaceAll(" ", "%20");

  const cambiarSerie = (newSerie) => {
    setSerie(newSerie);
  };

  const cambiarImpresoraReportes = (newSerie) => {
    setImpresoraReportes(newSerie);
  };

  const handleChangeAutomatizar = (event) => {
    setAutomatizar(event.target.checked);
  };

  const save = () => {
    if (
      !fontSizeValue ||
      fontSizeValue < 1 ||
      !fontSizeBoldValue ||
      fontSizeBoldValue < 1
    ) {
      toast.info("Error, tienes que agregar un tamaño de fuente");
      return;
    }
    if (!pageWidthValue || pageWidthValue < 1) {
      toast.info("Error, tienes que agregar un ancho de página");
      return;
    }
    updateConfiguracionImpresion({
      impresora: serie,
      impresoraReportes: impresoraReportes,
      envioAutomatico: automatizar,
      impresoras: impresoras,
      fontFamily: fontFamilyValue,
      fontFamilyBold: fontFamilyBoldValue,
      fontSize: fontSizeValue,
      fontSizeBold: fontSizeBoldValue,
      pageWidth: pageWidthValue,
    });
  };

  const addImpresora = (newImpresora) => {
    let oldImpresoras = impresoras;
    oldImpresoras.push(newImpresora.selectedValue);
    updateConfiguracionImpresion({
      impresora: serie,
      envioAutomatico: automatizar,
      impresoras: oldImpresoras,
    });
  };

  const testPrint = async () => {
    console.log("imprime");

    toast.promise(
      ImpresionService.printUrl(
        "https://tintoreriaesperanzaapi.karimnot.com/resources/ticket.pdf",
        reemplazarEspaciosConEntidad(serie)
      ),
      {
        pending: "Impresion pendiente",
        success: "Completado 👌",
        error: "Impresion rechazada 🤯",
      }
    );
  };

  const testPrintreportes = async () => {
    console.log("imprime");

    toast.promise(
      ImpresionService.printUrl(
        "https://parzibyte.github.io/plugin-silent-pdf-print-examples/ticket.pdf",
        reemplazarEspaciosConEntidad(impresoraReportes)
      ),
      {
        pending: "Impresion pendiente",
        success: "Completado 👌",
        error: "Impresion rechazada 🤯",
      }
    );
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <header>
              <h1 class="h4">Impresion</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/configuracion")}
                    >
                      Configuracion
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Impresion
                  </li>
                </ol>
              </nav>
            </header>

            {/* <!-- notifications --> */}
            <div class="section p-xl-4">
              <h3 class="h5 mb-5"></h3>
              <div class="mt-6 row">
                <div className="col-12">
                  <span class="fw-medium">
                    Fuente
                    <span class="d-block smaller text-muted">
                      Selecciona la fuente a utilizar para la impresión del
                      ticket
                    </span>
                  </span>
                </div>
                <div className="col-12 col-md-6">
                  <div class="row gutters-sm mt-3">
                    <div className="col-12">
                      <FormControl
                        fullWidth
                        className=" col-12 col-md-7"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <NativeSelect
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={fontFamilyValue}
                          onChange={(e) => setFontFamilyValue(e.target.value)}
                        >
                          <option value="CourierPrime-Regular">
                            CourierPrime-Regular
                          </option>
                          <option value="MartialMono-Regular">
                            MartialMono-Regular
                          </option>
                          <option value="Roboto-Regular">Roboto-Regular</option>
                          <option value="FiraMono-Regular">
                            FiraMono-Regular
                          </option>
                          <option value="SpaceMono-Regular">
                            SpaceMono-Regular
                          </option>
                          <option value="Antonio-Regular">
                            Antonio-Regular
                          </option>
                          <option value="Poppins-Regular">
                            Poppins-Regular
                          </option>
                          <option value="Bodoni9pt-Regular">
                            Bodoni9pt-Regular
                          </option>
                          <option value="Bodoni18pt-Regular">
                            Bodoni18pt-Regular
                          </option>
                          <option value="Jost-Regular">Jost-Regular</option>
                          <option value="Monserrat-Regular">
                            Monserrat-Regular
                          </option>
                          <option value="MuseoModerno-Regular">
                            MuseoModerno-Regular
                          </option>
                        </NativeSelect>
                        <FormHelperText>Fuente normal</FormHelperText>
                      </FormControl>
                      <FormControl
                        fullWidth
                        className="col-12 col-md-4"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <Input
                          type="number"
                          variant="standard"
                          value={fontSizeValue}
                          onChange={(e) => setFontSizeValue(e.target.value)}
                          label="Tamaño"
                        />
                        <FormHelperText>Tamaño de la fuente</FormHelperText>
                      </FormControl>
                    </div>
                    <p class="fw-medium pt-4 pb-1">
                      <span class="d-block smaller text-muted">
                        Vista previa
                      </span>
                    </p>
                    <FontPreview fontFamily={fontFamilyValue} />
                  </div>
                </div>
                <div className="col-12 col-md-6 justify-content-center align-items-center fw-bolder">
                  <div class="row gutters-sm mt-3">
                    <div className="col-12">
                      <FormControl
                        fullWidth
                        className="col-12 col-md-7"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <NativeSelect
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={fontFamilyBoldValue}
                          onChange={(e) =>
                            setFontFamilyBoldValue(e.target.value)
                          }
                        >
                          <option value="CourierPrime-Bold">
                            CourierPrime-Bold
                          </option>
                          <option value="MartialMono-Bold">
                            MartialMono-Bold
                          </option>
                          <option value="Roboto-Bold">Roboto-Bold</option>
                          <option value="FiraMono-Bold">FiraMono-Bold</option>
                          <option value="SpaceMono-Bold">SpaceMono-Bold</option>
                          <option value="Antonio-Bold">Antonio-Bold</option>
                          <option value="Poppins-Bold">Poppins-Bold</option>
                          <option value="Bodoni9pt-Bold">Bodoni9pt-Bold</option>
                          <option value="Bodoni18pt-Bold">
                            Bodoni18pt-Bold
                          </option>
                          <option value="Jost-Bold">Jost-Bold</option>
                          <option value="Monserrat-Bold">Monserrat-Bold</option>
                          <option value="MuseoModerno-Bold">
                            MuseoModerno-Bold
                          </option>
                        </NativeSelect>
                        <FormHelperText>Fuente negrita</FormHelperText>
                      </FormControl>
                      <FormControl
                        className="col-12 col-md-4"
                        fullWidth
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <Input
                          type="number"
                          variant="standard"
                          value={fontSizeBoldValue}
                          onChange={(e) => setFontSizeBoldValue(e.target.value)}
                          label="Tamaño"
                        />
                        <FormHelperText>Tamaño de la fuente</FormHelperText>
                      </FormControl>
                    </div>
                    <p class="fw-medium pt-4 pb-1">
                      <span class="d-block smaller text-muted">
                        Vista previa
                      </span>
                    </p>
                    <FontPreview fontFamily={fontFamilyBoldValue} />
                  </div>
                </div>
              </div>

              <div class="mt-6 row">
                <div className="col-12">
                  <span class="fw-medium">
                    Página
                    <span class="d-block smaller text-muted">
                      Configuración de la página para la impresión del ticket
                    </span>
                  </span>
                </div>
                <div className="col-12 col-md-6">
                  <div class="row gutters-sm mt-3">
                    <div className="col-12">
                      <FormControl fullWidth sx={{ minWidth: 120 }}>
                        <Input
                          type="number"
                          variant="standard"
                          value={pageWidthValue}
                          onChange={(e) => setPageWidthValue(e.target.value)}
                          label="Ancho"
                        />
                        <FormHelperText>Ancho de la página</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div class="row gutters-sm border border-dashed">
                    <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                      <FormHelperText className="text-muted">
                        Toma en cuenta estas notas al momento de realizar
                        ajustes.
                        <br /> • Si el ticket sale cortado de forma horizontal,
                        el valor del ancho de la página debe ser menor. <br /> •
                        Si sobra espacio de forma horizontal el ancho de la
                        página debe ser mayor.
                        <br /> • El tamaño de la fuente negrita solo hace
                        referencia al numero de orden,nombre del cliente y
                        teléfono,
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div class="border border-dashed border-bottom-0 my-3">
                {/* <!-- divider --> */}
              </div>

              <div class="mt-6 row">
                <div className="col-12 col-md-6">
                  <span class="fw-medium">
                    Impresora
                    <span class="d-block smaller text-muted">
                      Selecciona la impresora a utilizar
                    </span>
                  </span>
                  <div class="row gutters-sm">
                    {impresoras.map((permiso, index) => (
                      <div key={index} class="col-4 mb-3 mt-3">
                        <a
                          href="javascript:void(0);"
                          class="d-block bg-white shadow shadow-md-hover transition-hover-top transition-all-ease-250 rounded px-1 py-1 text-center text-decoration-none"
                          onClick={() => cambiarSerie(permiso.toUpperCase())}
                          style={
                            permiso.toUpperCase() === serie.toUpperCase()
                              ? {
                                  color: "#9b242c",
                                  height: "100%",
                                  alignContent: "center",
                                }
                              : {
                                  color: "#506690",
                                  height: "100%",
                                  alignContent: "center",
                                }
                          }
                        >
                          {/* <FactCheckIcon
                        fontSize="large"
                        style={{ width: "50px", height: "50px" }}
                      /> */}
                          <p class="fw-medium mb-1 mt-1">
                            {permiso.toUpperCase()}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-w justify-content-center align-items-center fw-bolder">
                  <div style={{ fontSize: "2rem" }}>{serie.toUpperCase()}</div>
                </div>
              </div>

              <div class="border border-dashed border-bottom-0 my-3">
                {/* <!-- divider --> */}
              </div>

              <div class="mt-6 row">
                <div className="col-12 col-md-6">
                  <span class="fw-medium">
                    Impresora reportes
                    <span class="d-block smaller text-muted">
                      Selecciona la impresora a utilizar
                    </span>
                  </span>
                  <div class="row gutters-sm">
                    {impresoras.map((permiso, index) => (
                      <div key={index} class="col-4 mb-3 mt-3">
                        <a
                          href="javascript:void(0);"
                          class="d-block bg-white shadow shadow-md-hover transition-hover-top transition-all-ease-250 rounded px-1 py-1 text-center text-decoration-none"
                          onClick={() =>
                            cambiarImpresoraReportes(permiso.toUpperCase())
                          }
                          style={
                            permiso.toUpperCase() ===
                            impresoraReportes?.toUpperCase()
                              ? {
                                  color: "#9b242c",
                                  height: "100%",
                                  alignContent: "center",
                                }
                              : {
                                  color: "#506690",
                                  height: "100%",
                                  alignContent: "center",
                                }
                          }
                        >
                          {/* <FactCheckIcon
                        fontSize="large"
                        style={{ width: "50px", height: "50px" }}
                      /> */}
                          <p class="fw-medium mb-1 mt-1">
                            {permiso.toUpperCase()}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-w justify-content-center align-items-center fw-bolder">
                  <div style={{ fontSize: "2rem" }}>
                    {impresoraReportes?.toUpperCase()}
                  </div>
                </div>
              </div>

              <div class="border border-dashed border-bottom-0 my-3">
                {/* <!-- divider --> */}
              </div>

              <div class="d-flex justify-content-between">
                <span class="fw-medium">
                  Enviar a impresora
                  <Switch
                    checked={automatizar}
                    onChange={handleChangeAutomatizar}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span class="d-block smaller text-muted"></span>
                  <span className="d-block">
                    {automatizar ? (
                      <span class="fw-small small text-success">
                        Se enviara a impresora
                      </span>
                    ) : (
                      <span class="fw-small small text-muted">
                        No se enviara a impresora
                      </span>
                    )}
                  </span>
                </span>
                <div className="d-flex"></div>
              </div>

              <div class="border border-dashed border-bottom-0 my-3">
                {/* <!-- divider --> */}
              </div>

              <div class="d-flex justify-content-between">
                <span class="fw-medium">
                  Opciones
                  <span
                    class="d-block smaller text-muted"
                    style={{ marginBottom: "1rem" }}
                  ></span>
                  <a
                    className="d-block"
                    href="javascript:void(0)"
                    onClick={() => handleOpenModalImpresora()}
                  >
                    <span
                      class="fw-small small text-muted"
                      style={{ borderBottom: "1px solid #506690" }}
                    >
                      Agregar impresora
                    </span>
                  </a>
                  <a
                    className="d-block"
                    href="javascript:void(0)"
                    onClick={() => testPrint()}
                  >
                    <span
                      class="fw-small small text-muted"
                      style={{ borderBottom: "1px solid #506690" }}
                    >
                      Probar impresion
                    </span>
                  </a>
                  <a
                    className="d-block"
                    href="javascript:void(0)"
                    onClick={() => testPrintreportes()}
                  >
                    <span
                      class="fw-small small text-muted"
                      style={{ borderBottom: "1px solid #506690" }}
                    >
                      Probar impresion reportes
                    </span>
                  </a>
                </span>
                <div className="d-flex"></div>
              </div>

              <div class="border border-dashed border-bottom-0 my-3">
                {/* <!-- divider --> */}
              </div>

              <div class="text-end">
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={() => save()}
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
          {/* <!-- Modal : pago add --> */}

          <div
            className={openModalImpresora ? "modal fade show" : "modal fade"}
            id="pago-add"
            tabindex="-2"
            aria-labelledby="pago-add-label"
            aria-hidden={!openModalImpresora}
            aria-modal={openModalImpresora}
            role="dialog"
            style={
              openModalImpresora
                ? {
                    display: "block",
                    backgroundColor: "#a2313738",
                  }
                : {
                    zIndex: "-2",
                    display: "block",
                  }
            }
          >
            <div
              method="post"
              action="#"
              className={
                openModalImpresora
                  ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                  : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
              }
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="pago-add-label">
                    Agregar impresora
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleOpenModalImpresora}
                  ></button>
                </div>
                <CrearImpresora
                  onSumit={addImpresora}
                  closeModal={handleOpenModalImpresora}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Impresion;
