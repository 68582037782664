/* React */
import { useNavigate } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";

/* Components & dependencies */
import { Button, TablePagination } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FullPageLoading from "../../FullPageLoading";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";

/* Service */
import ServicioService from "../../../services/Servicio.service";

const TablaServicios = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [servicios, setServicios] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    loadServicios();
  }, [page, limit]);

  const loadServicios = async () => {
    setLoading(true);
    try {
      const results = await ServicioService.list(limit, page * limit);
      setServicios(results.data);
      setTotal(results.total);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">SERVICIOS</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Hay {servicios.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div className="section p-0">
                <div style={{ padding: "1.8rem" }}>
                  <div className="card-header p-4">
                    <div className="row g-3">
                      <div className="order-2 order-md-1 col">
                        <form
                          className="position-relative d-flex align-items-center"
                          method="get"
                          action="#"
                        ></form>
                      </div>
                      {/* options */}
                      <div className="order-1 order-md-2 col-md-auto">
                        <a
                          href="javascript:void(0)"
                          onClick={() => navigate(`nuevo`)}
                          className="w-100 btn btn-sm btn-primary"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1={12} y1={5} x2={12} y2={19} />
                            <line x1={5} y1={12} x2={19} y2={12} />
                          </svg>
                          <span>Nuevo</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* item list */}
                    <div className="table-responsive-md">
                      <table
                        className="table"
                        style={{ overflowX: "auto", width: "300px !important" }}
                      >
                        <thead>
                          <tr>
                            <th
                              className="small text-muted"
                              style={{ width: 250 }}
                            >
                              TÍTULO
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 300 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by inventory"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2">SUBTÍTULO</span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 600 }}
                            >
                              DESCRIPCIÓN
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 200, marginLeft: "20px" }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by inventory"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2">
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  />{" "}
                                  CREACIÓN
                                </span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 250 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by inventory"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2">
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  />{" "}
                                  EDICIÓN
                                </span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 20 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by price"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2"></span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 20 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by price"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2"></span>
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {servicios.map((record, index) => {
                            const regex = /<[^>]'>/gim;
                            return (
                              <tr key={`${record.id}_${record.titulo}`}>
                                <td>
                                  {record?.titulo
                                    .replace(regex, "")
                                    .slice(0, 30)}
                                </td>
                                <td>
                                  {record?.subtitulo
                                    .replace(regex, "")
                                    .slice(0, 30)}
                                </td>
                                <td>
                                  <p
                                    className="lead"
                                    dangerouslySetInnerHTML={{
                                      __html: String(record.descripcion).slice(
                                        0,
                                        100
                                      ),
                                    }}
                                    style={{
                                      textAlign: "justify",
                                      fontWeight: "light",
                                      fontSize: "1rem",
                                    }}
                                  ></p>
                                </td>
                                <td>
                                  <i
                                    className="fa fa-calendar fa-1"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.createdAt).slice(0, 10)}
                                  <br />{" "}
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.createdAt).slice(11, 16)}
                                </td>
                                <td>
                                  <i
                                    className="fa fa-calendar fa-1"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.updateAt).slice(0, 10)}
                                  <br />{" "}
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.updateAt).slice(11, 16)}
                                </td>

                                <td
                                  style={{
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-sm link-normal"
                                  >
                                    <span
                                      onClick={() =>
                                        navigate(`editar/${record.id}`)
                                      }
                                      className="material-icons-sharp"
                                    >
                                      {" "}
                                      edit{" "}
                                    </span>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-sm link-normal"
                                  >
                                    <span
                                      className="material-icons-sharp"
                                      onClick={() =>
                                        navigate(`eliminar/${record.id}`)
                                      }
                                    >
                                      {" "}
                                      delete{" "}
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* pagination, selected items */}
                    <div className="row">
                      <div className="col py-3 text-center text-md-start">
                        {/* selected items 
                      <div className="dropup">
                        <a
                          className="btn btn-sm btn-primary"
                          href="#"
                          role="button"
                          id="ddSelected"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="group-icon">
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1={18} y1={6} x2={6} y2={18} />
                              <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>
                          </span>
                          <span>Selected items</span>
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-clean my-2"
                          aria-labelledby="ddSelected"
                        >
                          <li>
                            <a className="dropdown-item" href="#form-list">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                              <span>Active</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Innactive</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Banned</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-danger"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6" />
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                <line x1={10} y1={11} x2={10} y2={17} />
                                <line x1={14} y1={11} x2={14} y2={17} />
                              </svg>
                              <span className="w-100">Delete</span>
                            </a>
                          </li>
                        </ul>
                      </div>*/}
                      </div>
                      <div className="col-md-auto py-3">
                        {/* pagination */}
                        <nav
                          className="w-100 text-center"
                          aria-label="Pagination"
                        >
                          {/* pagination : desktop */}
                          <nav
                            className="text-center float-md-end mt-3 mt-md-0 d-none d-md-flex"
                            aria-label="Pagination"
                          >
                            <TablePagination
                              style={{ color: "var(--color-dark-variant)" }}
                              component="div"
                              classes="recent-orders"
                              labelRowsPerPage="Items por pagina"
                              count={total}
                              page={page}
                              onPageChange={handleChangePage}
                              rowsPerPage={limit}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </nav>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaServicios;
