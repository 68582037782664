import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  headerCol: {
    backgroundColor: "#d3d3d3",
  },
});

const formatDateCrate = (fecha) => {
  const newdatec = new Date(fecha);
  return newdatec.toLocaleString();
};
const toMoney = (value) => {
  const money = Number(value);

  if (isNaN(money)) {
    return value;
  }

  return money.toLocaleString("en", {
    style: "currency",
    currency: "MXN",
  });
};
const totalTicket = (totalt) => {
  if (totalt > 0) {
    return <Text style={styles.tableCell}>${totalt}</Text>;
  }
  return <Text style={styles.tableCell}>${totalt}</Text>;
};

// Componente que renderiza la tabla
const MyTable = ({ rows, pagos }) => {
  return (
    <View style={styles.table} wrap={true}>
      {/* Fila del header */}
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>PRODUCTO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>CANTIDAD</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>PRECIO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>SUBTOTAL</Text>
        </View>
      </View>
      {rows?.map((row, index) => (
        <View key={index} style={styles.tableRow} wrap={false}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{row.value.label}</Text>
            <Text style={styles.tableCell}>{row.value.categoria}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{row.cantidad}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{toMoney(row.value.precio)}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {toMoney(Number(row.value.precio) * Number(row.cantidad))}
            </Text>
          </View>
        </View>
      ))}
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>Fecha</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>Pago</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>Metodo de pago</Text>
        </View>
      </View>
      {pagos?.map((productpago, index) => (
        <View key={index} style={styles.tableRow} wrap={false}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {formatDateCrate(productpago.createdAt)}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>${productpago.precio}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{productpago.metodopago}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

export default MyTable;
