/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

/* Servicios */
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

const TablaCategoriaBlog = () => {
  const [categorias, setCategorias] = useState({});
  const [categoria, setCategoria] = useState({});
  const [categoriatipo, setCategoriaTipo] = useState({});
  const [estado, setEstado] = useState("Nuevo");

  const [activo, setActivo] = useState(true);

  const [selecionColor, setSelecionColor] = useState(false);

  const [categoriaEdit, setCategoriaEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [update, setUpdate] = useState(true);
  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);
  const [openEliminar, setOpenEliminar] = useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const onUpdate = () => {
    setUpdate(!update);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEliminar = () => {
    setOpenEliminar(true);
  };
  const handleCloseEliminar = () => {
    setOpenEliminar(false);
  };

  useEffect(() => {
    getCategorias();
  }, [page, limit, update]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      console.log("get");
      setCategorias(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveCategoria = async () => {
    if (!categoria.tipo) {
      toast.info("Error, tienes que colocar un tipo");
    } else {
      setLoading(true);
      try {
        const datosCategoria = categoria;
        await CategoriaBlogService.create(datosCategoria);
        console.log("save");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getCategorias();
        onUpdate();
        categoria.tipo = "";
      }
    }
  };

  const activeBoton = (record) => {
    return (
      <a
        className="btn btn-sm link-normal"
        style={{ width: "15%" }}
        href="javascript:void(0);"
      >
        <span
          onClick={() => {
            setCategoriaEdit(record);
            updateCategoria(record.id);
          }}
          className="material-icons-sharp"
        >
          {" "}
          check{" "}
        </span>
      </a>
    );
  };

  const updateCategoria = async (id) => {
    if (!categoriaEdit.tipo) {
      toast.info("Error, tienes que colocar un tipo");
    } else {
      setLoading(true);
      try {
        const datosCategoria = categoriaEdit;
        await CategoriaBlogService.update(datosCategoria);
        setCategoria({});
        setActivo(true);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        onUpdate();
        // window.location.reload(false);
      }
    }
  };

  const deleteCategoria = async () => {
    setLoading(true);
    try {
      const carruselBorrado = await CategoriaBlogService.remove(
        categoriaEdit.id
      );
      toast.info("Eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      handleCloseEliminar();
      onUpdate();
    }
  };

  const setBackgroundColor = (id) => {
    try {
      const linea = document.getElementById(`key_${id}`);
      linea.style.backgroundColor = "#abbcd5";
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">CATEGORÍAS BLOG</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Hay {categorias.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>

            {/* user list */}
            <div class="row g-4">
              <div className="col">
                <div className="section mb-4">
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="order-2 order-md-1 col">
                        <form
                          className="position-relative d-flex align-items-center"
                          method="get"
                          action="#"
                        ></form>
                      </div>
                    </div>
                    <div className="card-header p-4">
                      <div className="row g-3">
                        <div className="order-2 order-md-1 col">
                          <form
                            className="position-relative d-flex align-items-center"
                            method="get"
                            action="#"
                          >
                            <div className="dropdown w-100">
                              <input
                                type="text"
                                className="form-control form-control-sm border-0 shadow-none ps-5 bg-gray-100 text-uppercase"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                placeholder="AGREGAR NUEVA CATEGORÍA"
                                onChange={(e) => {
                                  setCategoria({
                                    ...categoria,
                                    tipo: e.target.value.toUpperCase(),
                                  });
                                }}
                              />
                            </div>
                          </form>
                        </div>
                        {/* options */}
                        <div className="order-1 order-md-2 col-md-auto">
                          <a
                            href="javascript:void(0);"
                            onClick={() => saveCategoria()}
                            className="w-100 btn btn-sm btn-primary"
                          >
                            <svg
                              width="18px"
                              height="18px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1={12} y1={5} x2={12} y2={19} />
                              <line x1={5} y1={12} x2={19} y2={12} />
                            </svg>
                            <span>{estado}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-1">
                      {/* item list */}
                      <div className="table-responsive-md">
                        <table
                          className="table"
                          style={{
                            overflowX: "auto",
                            width: "300px !important",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                className="small text-muted"
                                style={{ width: "600px" }}
                              >
                                TIPO
                              </th>
                              <th
                                className="small text-muted"
                                style={{ width: 30 }}
                              ></th>
                              <th
                                className="small text-muted"
                                style={{ width: 200, marginLeft: "20px" }}
                              >
                                <a
                                  href="javascript:void(0);"
                                  className="d-flex link-muted"
                                  title="order by inventory"
                                  aria-label="order by inventory"
                                >
                                  <span className="ms-2">
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    />{" "}
                                    CREACIÓN
                                  </span>
                                </a>
                              </th>
                              <th
                                className="small text-muted"
                                style={{ width: 250 }}
                              >
                                <a
                                  href="javascript:void(0);"
                                  className="d-flex link-muted"
                                  title="order by inventory"
                                  aria-label="order by inventory"
                                >
                                  <span className="ms-2">
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    />{" "}
                                    EDICIÓN
                                  </span>
                                </a>
                              </th>
                              <th
                                className="small text-muted"
                                style={{ width: 20 }}
                              >
                                <a
                                  href="javascript:void(0);"
                                  className="d-flex link-muted"
                                  title="order by price"
                                  aria-label="order by inventory"
                                >
                                  <span className="ms-2"></span>
                                </a>
                              </th>
                              <th
                                className="small text-muted"
                                style={{ width: 20 }}
                              >
                                <a
                                  href="javascript:void(0);"
                                  className="d-flex link-muted"
                                  title="order by price"
                                  aria-label="order by inventory"
                                >
                                  <span className="ms-2"></span>
                                </a>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {categorias.map((record, index) => {
                              const regex = /<[^>]'>/gim;
                              return (
                                <tr
                                  key={`key_${record.id}`}
                                  id={`key_${record.id}`}
                                >
                                  <td className="link-normal fw-medium">
                                    <div
                                      className="row"
                                      style={{ width: "100%" }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control form-control-sm border-0 shadow-none ps-5 bg-gray-100 text-uppercase"
                                        placeholder="Agregar nuevo"
                                        onChange={(e) => {
                                          setCategoriaEdit({
                                            ...categoriaEdit,
                                            tipo: e.target.value.toUpperCase(),
                                          });
                                        }}
                                        defaultValue={record.tipo}
                                        disabled={activo}
                                        style={{
                                          width: "80%",
                                          marginLeft: "10px",
                                        }}
                                      />
                                      <a
                                        className="btn btn-sm link-normal"
                                        href="javascript:void(0);"
                                        style={{ width: "15%" }}
                                        id={`keyboton_${record.id}`}
                                      >
                                        {!activo && (
                                          <span
                                            onClick={() => {
                                              setCategoriaEdit(record);
                                              updateCategoria(record.id);
                                            }}
                                            className="material-icons-sharp"
                                          >
                                            {" "}
                                            check{" "}
                                          </span>
                                        )}
                                      </a>
                                    </div>
                                  </td>
                                  <td></td>
                                  <td>
                                    <i
                                      className="fa fa-calendar fa-1"
                                      aria-hidden="true"
                                    />{" "}
                                    {String(record.createdAt).slice(0, 10)}
                                    <br />{" "}
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    />{" "}
                                    {String(record.createdAt).slice(11, 16)}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-calendar fa-1"
                                      aria-hidden="true"
                                    />{" "}
                                    {String(record.updateAt).slice(0, 10)}
                                    <br />{" "}
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    />{" "}
                                    {String(record.updateAt).slice(11, 16)}
                                  </td>
                                  <td>
                                    <a
                                      className="btn btn-sm link-normal"
                                      href="javascript:void(0);"
                                    >
                                      <span
                                        onClick={() => {
                                          setCategoriaTipo(record.tipo);
                                          setBackgroundColor(record.id);
                                          setCategoriaEdit(record);
                                          setActivo(false);
                                        }}
                                        className="material-icons-sharp"
                                      >
                                        {" "}
                                        edit{" "}
                                      </span>
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      className="btn btn-sm link-normal"
                                    >
                                      <span
                                        className="material-icons-sharp"
                                        onClick={() => {
                                          setCategoriaEdit(record);
                                          handleOpenEliminar();
                                        }}
                                      >
                                        {" "}
                                        delete{" "}
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {/* pagination, selected items */}
                      <div className="row">
                        <div className="col py-3 text-center text-md-start"></div>
                        <div className="col-md-auto py-3">
                          {/* pagination */}
                          <nav
                            className="w-100 text-center"
                            aria-label="Pagination"
                          >
                            {/* pagination : desktop */}
                            <nav
                              className="text-center float-md-end mt-3 mt-md-0 d-none d-md-flex"
                              aria-label="Pagination"
                            >
                              {/* <TablePagination
                                style={{ color: "var(--color-dark-variant)" }}
                                component="div"
                                classes="recent-orders"
                                labelRowsPerPage="Items por pagina"
                                count={total}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={limit}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              /> */}
                            </nav>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          {/** ELIMINAR */}
          <Modal
            open={openEliminar}
            onClose={handleCloseEliminar}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="text-uppercase">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Eliminar
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="row g-3 mb-3">
                  <div className="col-lg-6">
                    <p style={{ color: "#ff7782" }}>{categoriaEdit.tipo}</p>
                  </div>
                  <div className="col-lg-2">
                    <Button
                      variant="outlined"
                      color="error"
                      form="form1"
                      onClick={() => deleteCategoria()}
                      style={{ marginLeft: "150%" }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </div>
              </Typography>
            </Box>
          </Modal>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaCategoriaBlog;
