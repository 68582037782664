import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import TicketsService from "../../../services/Tickets.service";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontFamily: "Lato",
    textTransform: "uppercase",
  },
  header: { height: "10%", margin: 10, flexDirection: "row", fontSize: 10 },
  logoheader: { height: "40px", width: "136px", margin: 10 },
  textheader: { position: "absolute", right: 10, width: "100px" },
  textsubtitleright: { position: "absolute", right: 10, width: "100px" },
  footer: {
    margin: 20,
    fontSize: 8,
    color: "#7b818d",
  },
  section: { color: "white", textAlign: "center", margin: 10 },
  item: {
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    borderBottom: "1px solid #dde4ea",
    borderTop: "1px solid #dde4ea",
  },
  itemcontent: {
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    marginHorizontal: 10,
    marginTop: 5,
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  itemcliente: { width: "25%", textAlign: "left", fontSize: 10 },
  itemdetalles: {
    textAlign: "left",
    width: "70%",
    fontSize: 10,
    textTransform: "uppercase",
  },
  itemfooter: {
    textAlign: "left",
    width: "100%",
    flexDirection: "row",
    fontSize: 10,
    color: "#506690",
  },
  itemfooteritem: { marginHorizontal: 10, marginBottom: 5 },
  fontnegrita: { fontFamily: "Lato Bold" },
  texttitle: { marginLeft: 20, fontSize: 10 },
  textdireccion: { marginTop: 20, fontSize: 12 },
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// Create Document Component
const MyDocument = () => {
  const today = new Date();
  const [tickets, setTickets] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState(0);

  const [ticketsNormal, setTicketsNormal] = useState([]);
  const [ticketsExpress, setTicketsExpress] = useState([]);
  const [ticketsHora, setTicketsHora] = useState([]);

  useEffect(() => {
    getTickets();
  }, []);

  useEffect(() => {
    clientesCount();
  }, [ticketsExpress, ticketsNormal, ticketsHora]);

  const getTickets = async () => {
    setLoading(true);
    try {
      const list = await TicketsService.listFechaGroup({ fecha: today });
      if (list) {
        setTicketsNormal((old) => list.dataNormal);
        setTicketsExpress((old) => list.dataExpress);
        setTicketsHora((old) => list.dataHora);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const clientesCount = () => {
    let totalClientes = 0;
    let cliente = [];
    ticketsExpress?.map((tick) => {
      if (!cliente.includes(tick.cliente?.correo)) {
        totalClientes++;
        cliente.push(tick.cliente?.correo);
      }
    });
    ticketsNormal?.map((tick) => {
      if (!cliente.includes(tick.cliente?.correo)) {
        totalClientes++;
        cliente.push(tick.cliente?.correo);
      }
    });
    ticketsHora?.map((tick) => {
      if (!cliente.includes(tick.cliente?.correo)) {
        totalClientes++;
        cliente.push(tick.cliente?.correo);
      }
    });
    setClientes(totalClientes);
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleString();
  };

  return (
    <Document pdfVersion="1.7">
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.header}>
          <Image
            style={styles.logoheader}
            src="/static/media/logo2.77456c09d5f4412e909f.png"
          />
          <View style={styles.textheader}>
            {/* <Text>Entregas: {total}</Text> */}
            <Text>{today.toLocaleDateString()}</Text>
          </View>
        </View>
        <View style={styles.texttitle}>
          <Text>ORDENES DE SERVICIO EXPRESS {ticketsExpress.length}</Text>
          <View style={styles.textsubtitleright}>
            <Text>Clientes {clientes}</Text>
          </View>
        </View>
        <View style={styles.section} wrap={true}>
          {ticketsExpress?.map((ticket, index) => (
            <View key={index} style={styles.item} wrap={false}>
              <View style={styles.itemcontent}>
                <View style={styles.itemdetalles}>
                  <Text style={styles.fontnegrita}>{ticket.uuid}</Text>
                  {!ticket.nota ? (
                    <Text>Sin notas</Text>
                  ) : (
                    <Text>{ticket.nota}</Text>
                  )}
                  <Text style={styles.textdireccion}>
                    {ticket.cliente?.direccion}
                  </Text>
                </View>
                <View style={styles.itemcliente}>
                  <Text style={styles.fontnegrita}>
                    {ticket.cliente?.nombre}
                  </Text>
                  <Text>{ticket.cliente?.correo}</Text>
                  <Text>{ticket.cliente?.telefono}</Text>
                </View>
              </View>
              <View style={styles.itemfooter}>
                <Text style={styles.itemfooteritem}>{ticket.estadopago}</Text>
                <Text style={styles.itemfooteritem}>
                  {formatDate(ticket.fecha)}
                </Text>
                <Text style={styles.itemfooteritem}></Text>
              </View>
            </View>
          ))}
        </View>

        <View style={styles.texttitle}>
          <Text>ORDENES DE SERVICIO HORA ESPECIFICA {ticketsHora.length}</Text>
          <View style={styles.textsubtitleright}>
            <Text></Text>
          </View>
        </View>
        <View style={styles.section} wrap={true}>
          {ticketsHora?.map((ticket, index) => (
            <View key={index} style={styles.item} wrap={false}>
              <View style={styles.itemcontent}>
                <View style={styles.itemdetalles}>
                  <Text style={styles.fontnegrita}>{ticket.uuid}</Text>
                  {!ticket.nota ? (
                    <Text>Sin notas</Text>
                  ) : (
                    <Text>{ticket.nota}</Text>
                  )}
                  <Text style={styles.textdireccion}>
                    {ticket.cliente?.direccion}
                  </Text>
                </View>
                <View style={styles.itemcliente}>
                  <Text style={styles.fontnegrita}>
                    {ticket.cliente?.nombre}
                  </Text>
                  <Text>{ticket.cliente?.correo}</Text>
                  <Text>{ticket.cliente?.telefono}</Text>
                </View>
              </View>
              <View style={styles.itemfooter}>
                <Text style={styles.itemfooteritem}>{ticket.estadopago}</Text>
                <Text style={styles.itemfooteritem}>
                  {formatDate(ticket.fecha)}
                </Text>
                <Text style={styles.itemfooteritem}></Text>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.footer}>
          <Text>La Esperanza | CUIDAMOS LA EXCELENCIA DE TU IMAGEN</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
