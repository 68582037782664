import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import TicketsService from "../../../../../services/Tickets.service";
import Header from "./Header";
import MyTable from "./Table";
import TicketsSalidaService from "../../../../../services/TicketsSalida.service";
import TableEgresos from "./TableEgresos";

// Create styles
const styles = StyleSheet.create({
  page: { backgroundColor: "white", fontDamily: "Lato", padding: 30 },
  header: { height: "10%", margin: 10, flexDirection: "row", fontSize: 14 },
  logoheader: { height: "40px", width: "136px", margin: 10 },
  textheader: { position: "absolute", top: 10, right: 10, width: "100px" },
  textsubtitleright: { position: "absolute", right: 10, width: "200px" },
  totalessection: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 10,
    flexDirection: "row",
  },
  totalsection: { marginRight: 20 },
  textheadertable: { fontSize: 10, marginTop: 15 },
  footer: {
    fontSize: "8px",
    color: "#7b818d",
    marginTop: 20,
  },
  section: { color: "white", textAlign: "center", margin: 10 },
  item: {
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    margin: 10,
    borderBottom: "1px solid #dde4ea",
    borderTop: "1px solid #dde4ea",
  },
  itemcontent: {
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    margin: 10,
    flexDirection: "row",
  },
  itemcliente: { width: "30%", textAlign: "left", fontSize: 14 },
  itemdetalles: { textAlign: "left", width: "70%", fontSize: 14 },
  itemfooter: {
    textAlign: "left",
    width: "100%",
    flexDirection: "row",
    fontSize: "14px",
    color: "#506690",
  },
  itemfooteritem: { margin: 10 },
  fontnegrita: { fontFamily: "Lato Bold" },
  texttitle: { marginBottom: 20 },
  textdireccion: { marginTop: 20 },
  totaldivisor: { borderBottom: "1px solid #000", height: "1px" },
  metodo: { flexDirection: "row", justifyContent: "space-between" },
  tableseparator: { marginBottom: 20, height: "1px" },
  monto: { marginLeft: 3 },
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// Create Document Component
const MyDocument = ({ fecha }) => {
  const today = new Date();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState(0);
  const [pagos, setPagos] = useState([]);
  const [pagoclientes, setPagoclientes] = useState(0);
  const newdate = new Date(`${fecha} 00:00:00`);
  const [corte, setCorte] = useState(null);

  const [tickets, setTickets] = useState([]);
  const [ticketsSalida, setTicketsSalida] = useState([]);

  const [ingresos, setIngresos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [egresos, setEgresoss] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });

  const [totales, setTotales] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesSalida, setTotalesSalida] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalIngresos, setTotalIngresos] = useState(0);
  const [totalEgresos, setTotalEgresos] = useState(0);

  useEffect(() => {
    getTicket();
    // getTicketSalida();
    // getPagos();
  }, [fecha]);

  const getTicket = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsService.listFechaGeneradas({
        fecha: `${fecha} 00:00:00`,
      });
      setTickets(data.data);
      setCorte(data.corte);
      console.log("data fecha ordenes");
      console.log(data);
      for (const p of data.data) {
        ordenessaldo += parseInt(p.total) - parseInt(p.pagado);
        ordenestotal += parseInt(p.total);

        for (const pa of p.pagos) {
          if (pa.metodopago === "Efectivo") {
            efectivo += Number(pa.precio);
          } else if (pa.metodopago === "Tarjeta") {
            tarjeta += Number(pa.precio);
          } else if (pa.metodopago === "Transferencia") {
            transferencia += Number(pa.precio);
          }
        }
      }
      sumarTotales(ordenestotal, ordenessaldo);
      setIngresos({
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const sumarTotales = (ordentotal, ordensaldo) => {
    setTotales({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };

  const getTicketSalida = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsSalidaService.listCorte({
        fecha: `${fecha} 00:00:00`,
      });
      console.log("data salida -----------------");
      console.log(data);
      setTicketsSalida(data.data);

      for (const p of data.data) {
        ordenessaldo += parseInt(p.total) - parseInt(p.pagado);
        ordenestotal += parseInt(p.total);

        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.monto);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.monto);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.monto);
        }
      }
      let newEgresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newEgresos);
      setEgresoss(newEgresos);
      setTotalEgresos(efectivo + tarjeta + transferencia);
      setTotalesSalida({ ordenes: ordenestotal, saldo: ordenessaldo });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getPagos = async () => {
    setLoading(true);
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await TicketsService.listPagoFecha({
        fecha: `${newdate}`,
      });
      console.log(data);
      setPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresos(newIngresos);
      setPagoclientes(efectivo + tarjeta + transferencia);
      setTotalIngresos(efectivo + tarjeta + transferencia);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const clientesCount = () => {
    let totalClientes = 0;
    let oldCliente = "";
    tickets.map((tick) => {
      if (tick.cliente?.correo !== oldCliente) {
        oldCliente = tick.cliente?.correo;
        totalClientes++;
      }
    });
    setClientes(totalClientes);
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleString();
  };

  return (
    <Document pdfVersion="1.7">
      <Page size="A4" style={styles.page} wrap={true}>
        <Header
          src="/static/media/logo2.77456c09d5f4412e909f.png"
          date={newdate.toLocaleDateString()}
          text="La Esperanza | CUIDAMOS LA EXCELENCIA DE TU IMAGEN"
        />
        <View style={styles.textheadertable}>
          <Text style={styles.fontnegrita}>ORDENES GENERADAS</Text>
        </View>
        <MyTable rows={tickets} ingresos={ingresos} totales={totales} />
        <View style={styles.tableseparator}></View>

        <View style={styles.totalessection} wrap={false}></View>
        <View style={styles.footer}>
          <Text>La Esperanza | CUIDAMOS LA EXCELENCIA DE TU IMAGEN</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
