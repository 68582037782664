import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Slide from "./Slide";
//import styles from "../layout.module.css";
//      <div className={styles.container} id="containerZoom">
/*
<body id="tema" className="white-theme-variables">
      <div className={styles.container} id="containerZoom">
        <Slide />
        <main>
          <Header />
          <Outlet />
        </main>
      </div>
    </body>
    */

/**<body
      className="layout-admin aside-sticky bg-lime header-sticky"
      data-s2t-class="btn-primary btn-sm bg-gradient-default rounded-circle border-0"
    >
      <div id="wrapper" className="d-flex align-items-stretch flex-column">
        <main>
          <div id="wrapper_content" className="d-flex flex-fill">
            <Slide />
            <Outlet />
          </div>
        </main>
      </div>
    </body>
     */
const PrivateLayout = () => {
  useEffect(() => {
    //require("./css/public.css");
    //require("./main.scss");
    //require('./html_frontend/assets/css/core.css');
    require("./assets/css/core.min.css");
    //require('./html_frontend/assets/css/vendor_bundle.css');
    require("./html_admin/assets/css/vendor_bundle.min.css");
  }, []);

  return (
    <body id="body" className="layout-admin aside-sticky bg-lime header-sticky">
      <div
        id="wrapper"
        className="d-flex align-items-stretch flex-column text-uppercase"
      >
        <Header />
        <div id="wrapper_content" className="d-flex flex-fill">
          <Slide />
          <main className="flex-fill mx-auto" style={{ maxWidth: "100%" }}>
            <Outlet />
          </main>
        </div>
      </div>
    </body>
  );
};

export default PrivateLayout;
