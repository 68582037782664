/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

/* Servicios */
import S3Service from "../../../services/S3.service";
import ServicioService from "../../../services/Servicio.service";

import Preloader from "../../utils/Preloader";

const EliminarServicio = () => {
  //#region variables
  const { id } = useParams();
  const navigate = useNavigate();
  const [servicio, setServicio] = useState({});
  const [loading, setLoading] = useState(true);
  const [imagen, setImagen] = useState("");
  useEffect(() => {
    getPago();
  }, []);

  const getPago = async () => {
    setLoading(true);
    try {
      const data = await ServicioService.getById(id);
      setServicio(data);
      if (data.imagenes) {
        const result = await S3Service.get(data.imagenes[0]);
        setImagen(result.result);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePago = async () => {
    setLoading(true);
    try {
      console.log("ELIMINAR");

      const pagoBorrado = await ServicioService.remove(id);

      toast.info("Eliminado");
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/servicio");
    }
  };
  //#endregion

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Editar servicio</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/servicio")}
                    >
                      Servicios
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold">
                      Servicio
                    </div>
                    <div className="card-body">
                      <div className="row g-3">
                        {servicio.imagenes ? (
                          <div>
                            <div className="col-lg-12">
                              <div style={{ textAlign: "center" }}>
                                <img
                                  src={String(imagen)}
                                  style={{
                                    height: 300,
                                    objectFit: "contain",
                                    width: "-webkit-fill-available",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.titulo}
                              disabled={true}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.subtitulo}
                              disabled={true}
                            />
                            <label>Subtítulo</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.tiempo}
                              disabled={true}
                            />
                            <label>Tiempo</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.descuento}
                              disabled={true}
                            />
                            <label>Descuento</label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: servicio.descripcion,
                              }}
                              style={{
                                textAlign: "justify",
                                marginRight: "10%",
                              }}
                            ></p>
                          </div>

                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: servicio.incluye,
                              }}
                              style={{
                                textAlign: "justify",
                                marginRight: "10%",
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => deletePago()}
                >
                  <span className="small">Eliminar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EliminarServicio;
