import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMediaQuery } from "@mui/material";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import OutputIcon from "@mui/icons-material/Output";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import HistoryIcon from "@mui/icons-material/History";

import icon from "./logo2.png";
import logo from "./logoTicket.png";

const Slide = () => {
  const { logout } = useContext(AuthContext);
  const [active, setActive] = useState("");
  const { currentUser } = useContext(AuthContext);
  const matchesmd = useMediaQuery(`(min-width:992px)`);
  let navigate = useNavigate();

  const toggleCloseAsideBar = () => {
    let element;
    let elementbg;
    element = document.querySelector("#aside_responsive");
    elementbg = document.querySelector("#aside_background");
    if (element.style.marginLeft === "-100%") {
    } else {
      element.style.marginLeft = "-100%";
      elementbg.style.width = "0";
    }
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  const navegar = (direccion) => {
    if (direccion === "Dashboard") {
      navigate("/");
      setActive(direccion);
      if (!matchesmd) {
        toggleCloseAsideBar();
      }
      return;
    }
    navigate(direccion);
    setActive(direccion);
    if (!matchesmd) {
      toggleCloseAsideBar();
    }
  };

  const comprobarPermiso = (permisoStatus) => {
    let permiso = currentUser?.permisos?.filter(
      (p) => p.permiso?.nombre === permisoStatus
    );

    if (permiso && permiso[0]) {
      return permiso[0].norma.split(",")[0] === "Ver";
    }
    return false;
  };

  useEffect(() => {
    let url = window.location.href;
    let urlArray = url.split("/");
    let path = urlArray[urlArray.length - 1].split("#");
    // console.log(urlArray[3]);
    if (path[0] === "admin") {
      setActive("Dashboard");
    } else if (urlArray[3]) {
      setActive(urlArray[3]);
    }
    // console.log(currentUser);
  }, [window.location.href]);

  return (
    <>
      <div
        id="aside_background"
        style={{
          position: "absolute",
          height: "92vh",
          zIndex: "5",
        }}
        onClick={() => toggleCloseAsideBar()}
      ></div>
      {/* sidebar */}
      <aside
        id="aside_responsive"
        className="aside-start aside-hide-md bg-white shadow-sm d-flex flex-column px-2 h-auto"
        style={matchesmd ? null : { marginLeft: "-100%" }}
      >
        {/* sidebar : logo */}
        <div className="py-2 px-3 mb-3 mt-1">
          <a href="javascript:void(0);">
            <img src={icon} width={200} height={60} alt="..." />
            <img
              src={logo}
              width={200}
              height={60}
              alt="...logo"
              className="d-none"
            />
          </a>
        </div>
        {/* /sidebar : logo */}
        {/* sidebar : navigation */}
        <div className="aside-wrapper scrollable-vertical scrollable-styled-light align-self-baseline h-100 w-100">
          {/*
  
          All parent open navs are closed on click!
          To ignore this feature, add .js-ignore to .nav-deep
      
          Links height (paddings):
            .nav-deep-xs
            .nav-deep-sm
            .nav-deep-md
  
          .nav-deep-hover     hover background slightly different
          .nav-deep-bordered  bordered links
  
        */}
          <nav className="nav-deep nav-deep-sm nav-deep-light">
            <ul className="nav flex-column">
              <li
                className={
                  active === "Dashboard" || active === ""
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className="nav-link"
                  href="javascript:void(0);"
                  onClick={() => {
                    navegar("Dashboard");
                  }}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi bi-house-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                    />
                  </svg>
                  <span>Inicio</span>
                </a>
              </li>
              {(comprobarPermiso("producto") ||
                comprobarPermiso("reportes") ||
                comprobarPermiso("clientes") ||
                comprobarPermiso("tickets") ||
                comprobarPermiso("realizar corte") ||
                comprobarPermiso("configuracion")) && (
                <li className="nav-title mt-4">
                  <h6 className="mb-0 smaller text-muted text-uppercase">
                    Administración
                  </h6>
                </li>
              )}

              {comprobarPermiso("tickets") && (
                <li
                  className={
                    active === "ticket" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("ticket/crear")}
                  >
                    <FeaturedPlayListOutlinedIcon fontSize="small" />
                    <span>Orden de servicio</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("tickets") && (
                <li
                  className={
                    active === "ticket-salida" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("ticket-salida/crear")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <OutputIcon fontSize="small" />
                    <span>Salida de efectivo</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("tickets") && (
                <li
                  className={
                    active === "tickets" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("tickets")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FactCheckOutlinedIcon fontSize="small" />
                    <span>Historial de ordenes</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("tickets") && (
                <li
                  className={
                    active === "salidas" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("salidas")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FactCheckOutlinedIcon fontSize="small" />
                    <span>Historial de salidas</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("realizar corte") && (
                <li
                  className={
                    active === "corte" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("corte")}
                  >
                    <PointOfSaleOutlinedIcon fontSize="small" />
                    <span>Corte</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("realizar corte") && (
                <li
                  className={
                    active === "cortes" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("cortes/historial")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <HistoryIcon fontSize="small" />
                    <span>HISTORIAL DE CORTES</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}

              {comprobarPermiso("clientes") && (
                <li
                  className={
                    active === "clientes" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("clientes")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-people"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                    </svg>
                    <span>Clientes</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {/* {comprobarPermiso("inventario") && (
                <li
                  className={
                    active === "inventario" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("inventario")}
                  >
                    <InventoryOutlinedIcon fontSize="small" />
                    <span>Inventario</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )} */}

              {comprobarPermiso("producto") && (
                <li
                  className={
                    active === "producto" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("producto")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-blockquote-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm5 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm-5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm.79-5.373c.112-.078.26-.17.444-.275L3.524 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282c.024-.203.065-.37.123-.498a1.38 1.38 0 0 1 .252-.37 1.94 1.94 0 0 1 .346-.298zm2.167 0c.113-.078.262-.17.445-.275L5.692 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282a1.75 1.75 0 0 1 .118-.492c.058-.13.144-.254.257-.375a1.94 1.94 0 0 1 .346-.3z" />
                    </svg>
                    <span>Producto</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("reportes") && (
                <li
                  className={
                    active === "reportes" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("reportes")}
                  >
                    <AssignmentOutlinedIcon fontsize="small" />
                    <span>Reportes</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}

              {comprobarPermiso("configuracion") && (
                <li
                  className={
                    active === "configuracion" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("configuracion")}
                  >
                    <SettingsOutlinedIcon fontSize="small" />
                    <span>Configuracion</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {(comprobarPermiso("pagina inicio") ||
                comprobarPermiso("carousel") ||
                comprobarPermiso("blog") ||
                comprobarPermiso("servicios") ||
                comprobarPermiso("usuarios")) && (
                <li className="nav-title mt-4">
                  <h6 className="mb-0 smaller text-muted text-uppercase">
                    página WEB
                  </h6>
                </li>
              )}

              {comprobarPermiso("carousel") && (
                <li
                  className={
                    active === "carousel" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("carousel")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-images"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                    </svg>
                    <span>Carrusel</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("servicios") && (
                <li
                  className={
                    active === "servicio" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("servicio")}
                  >
                    <svg
                      width="18px"
                      height="18px"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3z"
                      />
                      <path d="M13 3V2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z" />
                    </svg>
                    <span>Servicios</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("blog") && (
                <li
                  className={active === "blog" ? "nav-item active" : "nav-item"}
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("blog")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-blockquote-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm5 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm-5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm.79-5.373c.112-.078.26-.17.444-.275L3.524 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282c.024-.203.065-.37.123-.498a1.38 1.38 0 0 1 .252-.37 1.94 1.94 0 0 1 .346-.298zm2.167 0c.113-.078.262-.17.445-.275L5.692 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282a1.75 1.75 0 0 1 .118-.492c.058-.13.144-.254.257-.375a1.94 1.94 0 0 1 .346-.3z" />
                    </svg>
                    <span>Blog</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {comprobarPermiso("usuarios") && (
                <li
                  className={
                    active === "usuario" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("usuario")}
                  >
                    <AssignmentIndOutlinedIcon fontSize="small" />
                    <span>Usuarios</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}

              {comprobarPermiso("pagina inicio") && (
                <li
                  className={
                    active === "index" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("index")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <span>Páginas</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}

              {(comprobarPermiso("categorias de producto") ||
                comprobarPermiso("categorias de blog") ||
                // comprobarPermiso("categorias de inventario") ||
                comprobarPermiso("mensajes")) && (
                <li className="nav-title mt-4">
                  <h6 className="mb-0 smaller text-muted text-uppercase">
                    Catálogos
                  </h6>
                </li>
              )}
              {comprobarPermiso("categorias de producto") && (
                <li
                  className={
                    active === "categoriaProducto"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("categoriaProducto")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <CategoryIcon fontSize="small" />
                    <span>Categorías de producto</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
              {/* {comprobarPermiso("categorias de inventario") && (
                <li
                  className={
                    active === "categoriaInventario"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("categoriaInventario")}
                  >
                    <InventoryIcon fontSize="small" />
                    <span>Categorías de inventario</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )} */}

              {comprobarPermiso("categorias de blog") && (
                <li
                  className={
                    active === "categoriaBlog" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("categoriaBlog")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-table"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                    </svg>
                    <span>Categorías del Blog</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}

              {comprobarPermiso("mensajes") && (
                <li
                  className={
                    active === "mensajes" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("mensajes")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chat-left-text"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                    <span>Mensajes</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}

              {(comprobarPermiso("aviso privacidad") ||
                comprobarPermiso("terminos y condiciones") ||
                comprobarPermiso("footer")) && (
                <li className="nav-title mt-4">
                  <h6 className="mb-0 smaller text-muted text-uppercase">
                    Datos generales
                  </h6>
                </li>
              )}

              {comprobarPermiso("aviso privacidad") && (
                <li
                  className={
                    active === "aviso" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("aviso")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <span>Aviso de privacidad</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}

              {comprobarPermiso("terminos y condiciones") && (
                <li
                  className={
                    active === "terminos" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("terminos")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <span>Terminos y condiciones</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}

              {comprobarPermiso("footer") && (
                <li
                  className={
                    active === "footer" ? "nav-item active" : "nav-item"
                  }
                >
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => navegar("footer")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <span>Footer</span>
                    <span className="group-icon float-end">
                      <i className="fi fi-arrow-end" />
                      <i className="fi fi-arrow-down" />
                    </span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
        {/* /sidebar : navigation */}
        {/* sidebar : footer */}
        <div className="d-flex align-self-baseline w-100 py-3 px-3 border-top border-light small">
          <p className="d-inline-grid gap-auto-3 mb-0">
            <span className="material-icons-sharp"> logout </span>

            <a
              href="javascript:void(0);"
              onClick={() => salir()}
              className="link-normal text-dashed"
            >
              Logout
            </a>
          </p>
        </div>
        {/* /sidebar : footer */}
      </aside>
      {/* /sidebar */}
    </>
  );
};
export default Slide;
