/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import TodayIcon from "@mui/icons-material/Today";
import PrintIcon from "@mui/icons-material/Print";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const TablaConfiguracion = () => {
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const navigate = useNavigate();

  const onUpdate = () => {
    setUpdate(!update);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <header>
              <h1 class="h4">Configuracion</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Configuracion</a>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  ></li>
                </ol>
              </nav>
            </header>

            {/* <!-- top 10 categories : highest traffic --> */}
            <div class="mt-6">
              {/* <h2 class="h5 mb-3 text-secondary fw-normal">
                <span class="text-danger fw-medium">2.178.322</span> items{" "}
                <span class="text-gray-500">/ explore by category</span>
              </h2> */}

              <div class="row gutters-sm">
                <div class="col-6 col-md-4 col-lg-5th mb-3">
                  <a
                    href="javascript:void(0);"
                    class="d-block bg-white shadow shadow-md-hover transition-hover-top transition-all-ease-250 rounded px-3 py-4 text-center text-decoration-none"
                    onClick={() => navigate("/configuracion/tickets")}
                    style={{ minHeight: "100%" }}
                  >
                    <FactCheckIcon
                      fontSize="large"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <p class="text-muted fw-medium mb-0 mt-3">
                      ORDENES DE SERVICIO
                    </p>
                  </a>
                </div>

                <div class="col-6 col-md-4 col-lg-5th mb-3">
                  <a
                    href="javascript:void(0);"
                    class="d-block bg-white shadow shadow-md-hover transition-hover-top transition-all-ease-250 rounded px-3 py-4 text-center text-decoration-none"
                    onClick={() => navigate("/configuracion/salidas")}
                    style={{ minHeight: "100%" }}
                  >
                    <ReceiptLongIcon
                      fontSize="large"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <p class="text-muted fw-medium mb-0 mt-3">
                      SALIDAS DE EFECTIVO
                    </p>
                  </a>
                </div>

                <div class="col-6 col-md-4 col-lg-5th mb-3">
                  <a
                    href="javascript:void(0);"
                    class="d-block bg-white shadow shadow-md-hover transition-hover-top transition-all-ease-250 rounded px-3 py-4 text-center text-decoration-none"
                    onClick={() => navigate("/configuracion/calendario")}
                    style={{ minHeight: "100%" }}
                  >
                    {/* <!-- badge --> */}
                    {/* <div class="badge bg-success-soft position-absolute top-0 end-0 m-1">
                      NEW
                    </div> */}
                    <TodayIcon
                      fontSize="large"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <p class="text-muted fw-medium mb-0 mt-3">Calendario</p>
                  </a>
                </div>

                <div class="col-6 col-md-4 col-lg-5th mb-3">
                  <a
                    href="javascript:void(0);"
                    class="d-block bg-white shadow shadow-md-hover transition-hover-top transition-all-ease-250 rounded px-3 py-4 text-center text-decoration-none"
                    onClick={() => navigate("/configuracion/impresion")}
                    style={{ minHeight: "100%" }}
                  >
                    {/* <!-- badge --> */}
                    {/* <div class="badge bg-success-soft position-absolute top-0 end-0 m-1">
                      NEW
                    </div> */}
                    <PrintIcon
                      fontSize="large"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <p class="text-muted fw-medium mb-0 mt-3">Impresión</p>
                  </a>
                </div>
              </div>
            </div>
            <div class="mt-6 absolute-bottom text-gray-300 text-align-end">
              V.dfd21ce
            </div>
            {/* <!-- /top 10 categories : highest traffic --> */}
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaConfiguracion;
