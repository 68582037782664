import { useState } from "react";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

const Busqueda = ({ searchFunction, placeholder }) => {
  const [value, setValue] = useState("");
  const [state, setState] = useState(false);

  const buscar = () => {
    searchFunction(value);
    if (value) {
      setState(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    if (!value) {
      vaciar();
    } else {
      buscar();
    }
  };

  const vaciar = () => {
    searchFunction("");
    setValue("");
    setState(false);
  };

  return (
    <div class="card-header p-4">
      <div class="row g-3">
        <div class="order-2 order-md-1 col">
          <div class="position-relative d-flex align-items-center">
            {/* <!-- search icon --> */}
            {!state ? (
              <svg
                class="z-index-1 position-absolute start-0 ms-3 text-primary"
                width="18px"
                height="18px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            ) : (
              <a
                href="javascript:void(0);"
                class="z-index-1 position-absolute start-0 ms-3 "
                style={{ cursor: "pointer" }}
                onClick={() => vaciar()}
              >
                <CleaningServicesIcon
                  style={{ width: "17px", height: "17px" }}
                />
              </a>
            )}

            {/*Input */}
            <div class="dropdown w-100">
              <input
                type="text"
                class="dropdown-toggle form-control form-control-sm border-0 shadow-none ps-5 bg-gray-100 text-uppercase"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
                value={value}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>

        {/* <!-- options --> */}
        <div class="order-1 order-md-2 col-md-auto">
          <div
            class="btn-group w-100"
            role="group"
            aria-label="Product options"
          >
            <a
              href="#!"
              role="button"
              class="btn btn-sm small btn-secondary"
              onClick={() => buscar()}
            >
              Buscar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Busqueda;
