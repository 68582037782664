import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableRowTotales: {
    margin: "auto",
    flexDirection: "row",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    textAlign: "left",
    width: "100%",
    columnGap: 3,
  },
  tableCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellTotales: {
    margin: "auto",
    marginTop: 2,
    fontSize: 9,
    marginLeft: 3,
  },
  tableColA: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColB: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 9,
  },
  headerCol: {
    backgroundColor: "#d3d3d3",
  },
  fontnegrita: { fontFamily: "Lato Bold" },
});

const formatDateCrate = (fecha) => {
  if (!fecha) {
    return "0000-00-00";
  }
  const newdatec = new Date(fecha);
  return new Intl.DateTimeFormat("az", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "numeric",
  }).format(newdatec);
};

const totalTicket = (totalt) => {
  if (totalt > 0) {
    return <Text style={styles.tableCell}>${totalt}</Text>;
  }
  return <Text style={styles.tableCell}>${totalt}</Text>;
};

const metodosPagoSalida = (ordenmetodopago, monto) => {
  let sumaPagos = {
    Tarjeta: 0,
    Transferencia: 0,
    Efectivo: 0,
  };

  sumaPagos[ordenmetodopago] += Number(monto);

  return (
    <>
      <View style={[styles.tableCol, styles.fontnegrita]}>
        <Text style={styles.tableCell}>${monto}</Text>
      </View>
      <View style={[styles.tableCol]}>
        <Text style={styles.tableCell}>{ordenmetodopago}</Text>
      </View>
    </>
  );
};

// Componente que renderiza la tabla
const TableEgresos = ({ rows, egresos, totales }) => {
  return (
    <View style={styles.table} wrap={true}>
      {/* Fila del header */}
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableColA, styles.headerCol]}>
          <Text style={styles.tableCell}>ORDEN</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>FECHA</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>MONTO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>MÉTODO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>TOTAL</Text>
        </View>
        <View style={[styles.tableColB, styles.headerCol]}>
          <Text style={styles.tableCell}>EMISOR</Text>
        </View>
      </View>
      {rows?.map((row, index) => (
        <>
          <View key={index} style={styles.tableRow} wrap={false}>
            <View style={styles.tableColA}>
              <Text style={styles.tableCell}>{row.uuid}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatDateCrate(row.fecha)}</Text>
            </View>
            {metodosPagoSalida(row.pagos[0].metodopago, row.pagos[0].precio)}
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>${row.total}</Text>
            </View>
            <View style={styles.tableColB}>
              <Text style={styles.tableCell}>
                {row.responsableservicio.nombre}
              </Text>
            </View>
          </View>
        </>
      ))}

      <View key="totalesindex" style={styles.tableRowTotales} wrap={false}>
        <View style={styles.tableCellTotales}>
          <Text>TOTALES: </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            EFECTIVO: ${egresos.efectivo}
          </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            TRANSFERENCIA: ${egresos.transferencia}
          </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            TARJETA: ${egresos.tarjeta}
          </Text>
        </View>
        <View>
          <Text style={styles.tableCellTotales}>
            ORDENES: ${totales.ordenes}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default TableEgresos;
