export const validarEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

export const validarNumeroTelefono = (numero) => {
  const numeroLimpio = numero.replace(/\D/g, "");

  if (numeroLimpio.length !== 10) {
    return false;
  }
  return true;
};

export const validarDireccion = (direccion) => {
  return /.{1,}/.test(direccion);
};

export const validarContrasenaSimple = (contrasena) => {
  const longitudValida = /.{6,}/.test(contrasena);
  const sinEspacios = !/\s/.test(contrasena);
  const contieneNumeros = /\d/.test(contrasena); // Agregamos esta condición para verificar si hay al menos un número

  if (longitudValida && sinEspacios && contieneNumeros) {
    return true;
  } else {
    return false;
  }
};

// Función flecha para validar un nombre completo
export const validarNombreCompleto = (nombre) => {
  // Verificar longitud mínima y máxima (por ejemplo, entre 5 y 50 caracteres)
  const longitudValida = /.{1,50}/.test(nombre);

  // Verificar espacios en blanco al principio o al final
  const sinEspaciosExtremos = /^[^\s].*[^\s]$/.test(nombre);

  // Verificar caracteres alfabéticos y espacios (opcionalmente)
  const caracteresValidos = /^[a-zA-Z\s-]+$/.test(nombre);

  // Comprobar si cumple con los criterios
  if (longitudValida && sinEspaciosExtremos && caracteresValidos) {
    console.log("El nombre completo es válido.");
    return true;
  } else {
    console.log("El nombre completo no cumple con los criterios.");
    return false;
  }
};
