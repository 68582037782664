/* React */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Alert, TextareaAutosize } from "@mui/material";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { esES } from "@mui/x-date-pickers/locales";
import "dayjs/locale/es-mx";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import ServicioService from "../../../services/Servicio.service";
import ImpresionService from "../../../services/Impresion.service";
import BuscarPoducto from "./BuscarPoducto";
import TicketsService from "../../../services/Tickets.service";
import CrearPago from "./CrearPago";
import BuscarCliente from "./BuscarCliente";
import ConfiguracionService from "../../../services/Configuracion.service";
import CalendarioService from "../../../services/Calendario.service";
import BuscarUsuario from "./BuscarUsuario";
import Swal from "sweetalert2";
import NuevoCliente from "./NuevoUsuario";
import UsuarioRequeriments from "./UsuarioRequeriments";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";

import { usePDF, Document, Page } from "@react-pdf/renderer";
import Modal from "./Modal";
import ProductNota from "./ProductNota";
import CortesService from "../../../services/Corte.service";

const CrearTicket = () => {
  const { id } = useParams();
  const [responsableServicio, setResponsableServicio] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [updateuser, setUpdateuser] = useState(false);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [pagado, setPagado] = useState(0);
  const [nota, setNota] = useState("");
  const navigate = useNavigate();
  const [servicios, setServicios] = useState([]);
  const [latest, setLatest] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);
  const [openModalCliente, setOpenModalCliente] = useState(false);
  const [openModalClienteRequerimientos, setOpenModalClienteRequerimientos] =
    useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [metodoPago, setMetodoPago] = useState("Efectivo");
  const [tipoServicio, setTipoServicio] = useState("Normal");
  const [estadoPago, setEstadoPago] = useState("Pendiente");
  const [configuracion, setConfiguracion] = useState({
    serie: "A",
    digitos: 4,
    numberserie: 0,
  });
  const [configuracionDias, setConfiguracionDias] = useState(null);
  const [configuracionImpresion, setConfiguracionImpresion] = useState(null);
  const [clienteOption, setClienteOption] = useState("client");
  const [openModalProductoNota, setOpenModalProductoNota] = useState(false);
  const [productSelected, setProductSelected] = useState(null);
  const [jornada, setJornada] = useState(null);

  let today = new Date();
  const [fechaHoy, setFechaHoy] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );

  const handleUpdateuser = () => {
    setUpdateuser(!updateuser);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenModalPago = () => {
    setOpenModalPago(!openModalPago);
  };

  const handleOpenModalClienteRequrimientos = (newClientOption) => {
    setOpenModalClienteRequerimientos(!openModalClienteRequerimientos);
    // setClienteOption(newClientOption);
  };

  const handleOpenModalProductoNota = (newClientOption) => {
    if (openModalProductoNota) {
      setProductSelected(null);
    }
    setOpenModalProductoNota(!openModalProductoNota);
    setAreaExpanded(-1);
    // setClienteOption(newClientOption);
  };

  const handleOpenModalCliente = (newClientOption) => {
    setOpenModalCliente(!openModalCliente);
    setClienteOption(newClientOption);
  };

  const handleCliente = async (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setUsuario(changecliente);
    handleUpdateuser();
  };

  const handleResponsableServicio = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableServicio(changecliente);
  };

  const handleMetodoPago = (newMetodoPago) => {
    console.log(newMetodoPago);
    setMetodoPago(newMetodoPago.target.value);
  };

  const fechaLaborable = (fecha, diasNoLaborables) => {
    let dia = fecha.toLocaleString("es-MX", { weekday: "long" });

    return diasNoLaborables[dia];
  };

  const handleTipoServicio = async (newMetodoPago) => {
    let fechaservicioinicio = new Date(today.toString());
    let fechaservicioentrega = new Date(today.toString());
    let diaslaborables = 0;

    setTipoServicio(newMetodoPago.target.value);

    let servicio = servicios.find(
      (s) => s.titulo === newMetodoPago.target.value
    );

    while (diaslaborables < parseInt(servicio.precios[0].props.tiempo)) {
      fechaservicioentrega.setDate(fechaservicioentrega.getDate() + 1);
      if (!fechaLaborable(fechaservicioentrega, configuracionDias?.dias)) {
        console.log("Fecha No laborable");
        console.log(fechaservicioentrega);
        continue;
      }

      console.log("Fecha laborable");
      console.log(fechaservicioentrega);
      let diasFestivos = await CalendarioService.getDias(
        fechaservicioentrega,
        fechaservicioentrega
      );

      if (diasFestivos.total > 0) {
        continue;
      }
      console.log("diasFestivos");
      console.log(diasFestivos);
      diaslaborables++;

      console.log("ANTES DEL SALTO");
    }

    setFechaEntrega(
      new Intl.DateTimeFormat("az", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "numeric",
      }).format(fechaservicioentrega)
    );
  };

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  useEffect(() => {
    if (usuario?.uuid) {
      getLatestTickets(usuario?.uuid);
    } else if (id) {
      getCliente();
      getLatestTickets(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateuser]);

  useEffect(() => {
    cargarDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargarDatos = async () => {
    setLoading(true);
    Promise.all([
      getConfiguracion(),
      getConfiguracionDias(),
      getConfiguracionImpresion(),
      getServicios(),
      getJornada(),
    ]).finally(() => {
      setLoading(false);
    });
  };

  const getConfiguracion = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionTickets();
      setConfiguracion(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getConfiguracionDias = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionDias();
      setConfiguracionDias(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getConfiguracionImpresion = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionImpresion();
      setConfiguracionImpresion(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getCliente = async () => {
    console.log(id);
    // setLoading(true);
    try {
      const data = await ClienteService.getById(id);
      console.log(data);
      setUsuario(data);
    } catch (error) {
      toast.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const getLatestTickets = async (idCliente) => {
    console.log(id);
    try {
      const data = await TicketsService.getLatest(idCliente);
      console.log(data);
      setLatest(data);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const getServicios = async () => {
    try {
      setServicios([
        { titulo: "Normal", precios: [{ props: { tiempo: 1, precio: 0 } }] },
        { titulo: "Express", precios: [{ props: { tiempo: 0, precio: 0 } }] },
        {
          titulo: "Hora especifica",
          precios: [{ props: { tiempo: 1, precio: 0 } }],
        },
      ]);
      setTipoServicio("Normal");
    } catch (error) {
      toast.error(error);
    }
  };

  const addProduct = (newProduct) => {
    console.log(newProduct);
    let exist = false;
    let filter = productos.map((op) => {
      if (op.value.id === newProduct.value.id) {
        exist = true;
        let aproduct = {
          ...newProduct,
          cantidad: parseInt(newProduct.cantidad) + parseInt(op.cantidad),
        };
        return aproduct;
      }
      return op;
    });

    if (exist) {
      setProductos(filter);
    } else {
      setProductos([...productos, newProduct]);
    }

    sumTotal([...productos, newProduct]);
  };

  const addPago = (newProduct) => {
    console.log(newProduct);
    setPagado(newProduct.cantidad);
    setEstadoPago("Pendiente");
  };

  const removeProduct = (id) => {
    let remove = productos.filter((p) => p.value.id === id);
    let oldProductos = productos.filter((p) => p.value.id !== id);
    setAreaExpanded(-1);
    setProductos(oldProductos);
    sumTotal(oldProductos);
  };

  const sumTotal = (arrayProducts) => {
    let seudoTotal = 0;
    arrayProducts.map((p) => {
      seudoTotal += p.value.precio * p.cantidad;
    });

    setTotal(seudoTotal);
  };

  const formatDateNoTime = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleDateString();
  };

  const numberformat = () => {
    let cadena = configuracion.numberserie.toString();
    while (cadena.length < configuracion.digitos) {
      cadena = "0" + cadena;
    }
    cadena = configuracion.serie + "-" + cadena;
    return cadena.toUpperCase();
  };

  const enviarImpresion = async (url) => {
    console.log("url");
    console.log(url);
    if (!configuracionImpresion.envioAutomatico) {
      return;
    }
    console.log("PASS");
    toast.promise(
      ImpresionService.printUrl(url, configuracionImpresion.impresora),
      {
        pending: "Impresion pendiente",
        success: "Completado 👌",
        error: "Impresion rechazada 🤯",
      }
    );
  };

  function validarString(str) {
    // Eliminar espacios en blanco
    str = str.trim();

    // Verificar si el string es vacío
    if (str.length === 0) {
      return false;
    }

    // Verificar si el string contiene solo caracteres especiales
    const regex = /^[\s\n\t]+$/;
    if (regex.test(str)) {
      return false;
    }

    return true;
  }

  const saveTicket = async () => {
    try {
      if (!jornada) {
        toast.info("Jornada no iniciada.");
        return;
      }

      if (!fechaEntrega) {
        toast.info("Selecciona una fecha de entrega.");
        return;
      }
      if (!usuario?.id) {
        toast.info("Selecciona un cliente.");
        return;
      }
      if (!usuario?.id) {
        toast.info("Selecciona un cliente.");
        return;
      }
      if (!responsableServicio?.id) {
        toast.info("Selecciona un responsable de servicio.");
        return;
      }
      if (productos.length < 1) {
        toast.info("No has agregado productos");
        return;
      }

      let entrega = new Date(fechaEntrega);
      let fechaRecepcion = new Date();
      let uuid = numberformat();

      const newTicket = {
        uuid: uuid,
        fecha: entrega.getTime(),
        metodopago: metodoPago,
        estadopago: estadoPago,
        pagado: pagado,
        total: total,
        cliente: usuario.id,
        detalles: productos,
        tipoServicio: tipoServicio,
        nota: validarString(nota) ? nota : "",
        usuario: responsableServicio.id,
        fechaRecepcion: fechaRecepcion,
      };

      await Swal.fire({
        title: `<i class="fa fa-user fa-5x" aria-hidden="true"></i>`,
        input: "password",
        inputPlaceholder: "Contraseña",
        inputAttributes: {
          maxlength: "18",
          autocapitalize: "off",
          autocorrect: "off",
        },
        confirmButtonColor: "#9b242c",
        iconColor: "#9b242c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          newTicket.contrasena = result.value;
          const res = await TicketsService.create(newTicket);
          enviarImpresion(res.url);
          toast.success("Orden guardada.");
          navigate("/ticket/detalles/" + uuid);
        }
      });
    } catch (error) {
      toast.error("Error. No se ha guardado!");
    } finally {
      setLoading(false);
    }
  };

  const handleFechaEntrega = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    setFechaEntrega(nuevaFechaEntrega);
  };

  const getJornada = async () => {
    try {
      const data = await CortesService.revisarJornada();
      if (data && data.data[0]) {
        setJornada(data.data);
      } else {
        setJornada(null);
      }
    } catch (error) {
      console.log(error);
      toast.error("Datos de jornada no recuperados");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            {!jornada && <Alert severity="warning">jornada no iniciada</Alert>}

            <header style={jornada ? null : { marginTop: "0.5rem" }}>
              <div class="row g-1 align-items-center">
                <div class="col">
                  <h1 class="h4">ORDEN DE SERVICIO</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb small">
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">CREAR ORDEN</a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <div class="col-auto">
                  {/* <a
                    href="javascript:void(0);"
                    role="button"
                    class="btn btn-sm btn-primary d-inline-flex align-items-center"
                    onClick={() => navigate("/clientes")}
                  >
                    <span class="d-none d-sm-inline-block ps-2">Cancelar</span>
                  </a> */}
                </div>
              </div>
            </header>
            {/* <!-- Order items --> */}
            <div class="row g-2">
              <div class="col">
                {/* <!-- Order items --> */}
                <div class="card border-0 h-100">
                  <div class="card-header p-4 border-bottom-0 fw-bold">
                    Articulos ({productos?.length})
                  </div>
                  <div class="card-body p-4 h-100">
                    <div class="table-responsive-md mb-3">
                      <table class="table table-align-middle mb-0" role="grid">
                        <thead>
                          <tr>
                            <th
                              class="small text-muted"
                              style={{ width: "104px" }}
                            >
                              {/* <!-- image --> */}
                            </th>
                            <th class="small text-muted">PRODUCTO</th>
                            <th
                              class="small text-muted text-center"
                              style={{ width: "150px" }}
                            >
                              CANTIDAD
                            </th>
                            <th class="small text-muted text-center">PRECIO</th>
                            <th class="small text-muted text-center">
                              SUBTOTAL
                            </th>
                            <th
                              class="small text-muted"
                              style={{ width: "64px" }}
                            >
                              {/* <!-- options --> */}
                            </th>
                          </tr>
                        </thead>
                        <tbody id="checkall-list">
                          {!productos[0] && (
                            <tr>
                              <td></td>
                              <td>
                                {/* <!-- product --> */}
                                <a
                                  href="javascript:void(0)"
                                  class="link-normal fw-medium"
                                >
                                  -
                                </a>
                                <span class="d-block text-muted small"></span>
                              </td>
                              <td class="text-center">-</td>
                              <td class="text-center">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block text-success">-</span>
                              </td>
                              <td class="text-center fw-bold">
                                {/* <!-- subtotal --> */}-
                              </td>
                              <td class="dropstart text-end">
                                {/* <!-- options --> */}
                              </td>
                            </tr>
                          )}
                          {productos?.map((product, index) => (
                            <>
                              <tr
                                key={index}
                                style={
                                  product.nota
                                    ? { borderBottomColor: "white" }
                                    : null
                                }
                              >
                                <td>
                                  {/* <!-- image --> */}
                                  {/* <img
                                class="border border-light p-1"
                                width="80"
                                src="../html_frontend/demo.files/images/various/product/speakers/thumb/1-min.jpg"
                                alt="..."
                              /> */}
                                </td>
                                <td>
                                  {/* <!-- product --> */}
                                  <a
                                    href="javascript:void(0)"
                                    class="link-normal fw-medium"
                                  >
                                    {product.value.label}
                                  </a>
                                </td>
                                <td class="text-center">{product.cantidad}</td>
                                <td class="text-center">
                                  {/* <!-- unit price --> */}
                                  {product.value?.precioOriginal + "" !==
                                    product.value?.precio + "" && (
                                    <del class="d-block text-muted">
                                      ${product.value.precioOriginal}
                                    </del>
                                  )}
                                  <span class="d-block text-success">
                                    ${product.value.precio}
                                  </span>
                                </td>
                                <td class="text-center fw-bold">
                                  {/* <!-- subtotal --> */}$
                                  {Number(product.value.precio) *
                                    Number(product.cantidad)}
                                </td>
                                <td class="dropstart text-end">
                                  {/* <!-- options --> */}
                                  <a
                                    class="btn btn-sm btn-light btn-ghost btn-icon text-muted rounded-circle"
                                    href="javascript:void(0)"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded={index === areaExpanded}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      handleRowMenu(index);
                                    }}
                                  >
                                    <span class="group-icon">
                                      <svg
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                        ></path>
                                      </svg>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <line
                                          x1="18"
                                          y1="6"
                                          x2="6"
                                          y2="18"
                                        ></line>
                                        <line
                                          x1="6"
                                          y1="6"
                                          x2="18"
                                          y2="18"
                                        ></line>
                                      </svg>
                                    </span>
                                  </a>
                                  <ul
                                    className={
                                      index === areaExpanded
                                        ? "dropdown-menu dropdown-menu-clean show"
                                        : "dropdown-menu dropdown-menu-clean"
                                    }
                                    style={{
                                      position: "absolute",
                                      inset: "0px 0px auto auto",
                                      margin: "0px",
                                      transform: "translate(-51px, 11px)",
                                    }}
                                  >
                                    <li>
                                      {/* <a class="dropdown-item" href="javascript:void(0)">
                                      <svg
                                        class="text-muted"
                                        width="18px"
                                        height="18px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path d="M12 20h9"></path>
                                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                      </svg>
                                      <span>Edit</span>
                                    </a> */}
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          setProductSelected(product);
                                          handleOpenModalProductoNota();
                                        }}
                                      >
                                        <svg
                                          width="16px"
                                          height="16px"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                        </svg>
                                        <span class="w-100">Nota</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          removeProduct(product.value.id)
                                        }
                                      >
                                        <svg
                                          class="text-danger"
                                          width="18px"
                                          height="18px"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <polyline points="3 6 5 6 21 6"></polyline>
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                          <line
                                            x1="10"
                                            y1="11"
                                            x2="10"
                                            y2="17"
                                          ></line>
                                          <line
                                            x1="14"
                                            y1="11"
                                            x2="14"
                                            y2="17"
                                          ></line>
                                        </svg>
                                        <span class="w-100">Eliminar</span>
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>

                              {product.nota && (
                                <tr
                                  key={index + "nota"}
                                  style={{ borderTopColor: "white" }}
                                >
                                  <th></th>
                                  <td colSpan={4}>
                                    <span class="d-block text-muted smaller">
                                      {product.nota}
                                    </span>
                                  </td>
                                  <td></td>
                                </tr>
                              )}
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <Modal
                      status={openModalProductoNota}
                      handleStatus={handleOpenModalProductoNota}
                      headerText="Nota"
                    >
                      <ProductNota
                        product={productSelected}
                        onSumit={addProduct}
                        closeModal={handleOpenModalProductoNota}
                      />
                    </Modal>

                    <div class="row g-4">
                      <div class="order-2 order-md-1 col small">
                        <div className="d-flex">
                          <a
                            class="flex-none link-muted small d-inline-grid gap-auto-2"
                            href="javascript:void(0);"
                          >
                            <svg
                              width="16px"
                              height="16px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                          </a>
                          <strong className="w-100 px-2">Notas:</strong>
                        </div>
                        <TextareaAutosize
                          className="text-uppercase"
                          value={nota}
                          style={{
                            width: "100%",
                            height: "76px",
                            border: "solid 1px lightgrey",
                            borderRadius: "8px",
                            marginTop: "0.5rem",
                          }}
                          minRows={2}
                          maxRows={2}
                          onChange={(event) => {
                            setNota(event.target.value);
                          }}
                        />
                      </div>
                      <div class="order-1 order-md-2 col-md-3 text-end">
                        <button
                          type="button"
                          class="btn btn-sm btn-light text-uppercase"
                          data-bs-toggle="modal"
                          data-bs-target="#item-add"
                          onClick={handleOpenModal}
                        >
                          <svg
                            width="18px"
                            height="18px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                          <span>producto</span>
                        </button>
                        {/* <!-- Modal : item add --> */}

                        <div
                          className={
                            openModal ? "modal fade show" : "modal fade"
                          }
                          id="item-add"
                          tabindex="-1"
                          aria-labelledby="item-add-label"
                          aria-hidden={!openModal}
                          aria-modal={openModal}
                          role="dialog"
                          style={
                            openModal
                              ? {
                                  display: "block",
                                  backgroundColor: "#a2313738",
                                }
                              : {
                                  zIndex: "-1",
                                  display: "block",
                                }
                          }
                        >
                          <div
                            method="post"
                            action="#"
                            className={
                              openModal
                                ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                                : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                            }
                          >
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="item-add-label">
                                  Agregar producto
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleOpenModal}
                                ></button>
                              </div>
                              <BuscarPoducto
                                onSumit={addProduct}
                                closeModal={handleOpenModal}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row g-4 justify-content-end my-5">
                      <div class="col">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            <FormControl
                              sx={{ minWidth: "100%", marginBottom: 2 }}
                            >
                              <InputLabel id="simple-select-helper-label-nota">
                                Tipo de nota:
                              </InputLabel>
                              <Select
                                labelId="simple-select-helper-label-nota"
                                id="simple-select-nota"
                                value={tipoServicio}
                                label="Tipo de nota:"
                                onChange={handleTipoServicio}
                              >
                                {servicios?.map((servicio, index) => (
                                  <MenuItem
                                    key={index}
                                    value={servicio?.titulo}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {servicio?.titulo?.toUpperCase()}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </li>
                          <li>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              localeText={
                                esES.components.MuiLocalizationProvider
                                  .defaultProps.localeText
                              }
                              adapterLocale="es-mx"
                            >
                              <MobileDatePicker
                                label="Fecha de entrega:"
                                disablePast={true}
                                onChange={handleFechaEntrega}
                                value={dayjs(fechaEntrega)}
                                sx={{ minWidth: "100%", marginBottom: 2 }}
                              />
                              {tipoServicio !== "Normal" && (
                                <MobileTimePicker
                                  label="Hora de entrega:"
                                  disablePast={true}
                                  ampm
                                  onChange={handleFechaEntrega}
                                  value={dayjs(fechaEntrega)}
                                  sx={{ minWidth: "100%", marginBottom: 2 }}
                                />
                              )}
                            </LocalizationProvider>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-8 col-lg-7 text-end">
                        <dl class="row mb-0">
                          {/* <dt class="col-6">Subtotal:</dt>
                          <dd class="col-6">$57.98</dd> */}

                          {/* <dt class="col-6">Discount:</dt>
                          <dd class="col-6">-$7.99</dd>

                          <dt class="col-6">Shipping:</dt>
                          <dd class="col-6">$0.00</dd>

                          <dt class="col-6">Tax:</dt>
                          <dd class="col-6">$0.00</dd> */}

                          <dt class="col-10 fw-bold">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Total:
                            </dd>
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              ${total}
                            </dd>
                          </dt>
                          <dd class="col-2 fw-bold"></dd>

                          <dt class="col-10 text-muted">
                            <dd class="col-6 text-muted d-inline-grid gap-auto-2">
                              Anticipo:
                            </dd>
                            <dd class="col-6 text-muted d-inline-grid gap-auto-2">
                              ${pagado}
                            </dd>
                          </dt>
                          <dd class="col-2 text-muted">
                            <a
                              class="flex-none link-muted small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                              onClick={handleOpenModalPago}
                            >
                              {/* <span class="fw-normal">Agregar</span> */}
                              <AddCircleOutlineIcon />
                            </a>
                          </dd>
                          <dt class="col-10 fw-bold">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Saldo:
                            </dd>
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2 text-danger">
                              ${(total - pagado).toFixed(2)}
                            </dd>
                          </dt>
                          <dd class="col-2 fw-bold"></dd>
                        </dl>
                        {/* <!-- Modal : pago add --> */}

                        <div
                          className={
                            openModalPago ? "modal fade show" : "modal fade"
                          }
                          id="pago-add"
                          tabindex="-2"
                          aria-labelledby="pago-add-label"
                          aria-hidden={!openModalPago}
                          aria-modal={openModalPago}
                          role="dialog"
                          style={
                            openModalPago
                              ? {
                                  display: "block",
                                  backgroundColor: "#a2313738",
                                }
                              : {
                                  zIndex: "-2",
                                  display: "block",
                                }
                          }
                        >
                          <div
                            method="post"
                            action="#"
                            className={
                              openModalPago
                                ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                                : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                            }
                          >
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="pago-add-label">
                                  Agregar pago
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleOpenModalPago}
                                ></button>
                              </div>
                              <CrearPago
                                onSumit={addPago}
                                metodoPago={metodoPago}
                                total={total}
                                handleMetodoPago={handleMetodoPago}
                                closeModal={handleOpenModalPago}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer p-4">
                    {/* <small class="d-block text-muted">
                      Coupon used: T01778
                        </small>*/}
                    {/* <small class="d-block text-muted">
                      Giftcard used: &ndash;
                    </small> */}
                    <a
                      href="javascript:void(0);"
                      role="button"
                      class="btn btn-sm btn-primary d-inline-flex align-items-center"
                      onClick={saveTicket}
                    >
                      <span class="d-none d-sm-inline-block ps-2">
                        GUARDAR ORDEN
                      </span>
                    </a>
                  </div>
                </div>
                {/* <!-- /Order items --> */}
              </div>

              {/* <!-- Modal : item add --> */}
              <Modal
                status={openModalCliente}
                handleStatus={handleOpenModalCliente}
                headerText="Cliente"
              >
                {clienteOption === "client" ? (
                  <BuscarCliente
                    onSumit={handleCliente}
                    closeModal={handleOpenModalCliente}
                  />
                ) : (
                  <NuevoCliente
                    onSumit={handleCliente}
                    closeModal={handleOpenModalCliente}
                  />
                )}
              </Modal>

              {/* <!-- side --> */}
              <div class="col-xl-4">
                <div class="card border-0 h-100">
                  {/*User*/}
                  <div class="card-header p-4 mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Responsable del servicio</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- avatar --> */}
                      {/* <div
                        class="flex-none avatar avatar-lg bg-soft-primary rounded-circle"
                        style={{
                          backgroundImage:
                            "url(../html_frontend/demo.files/images/avatar/jessica_barden.jpg)",
                        }}
                      ></div> */}

                      {/* <!-- no avatar --> */}
                      {/* <!--  */}
                      {/* <div class="flex-none avatar avatar-lg bg-soft-primary rounded-circle">
                        <svg
                          class="opacity-25"
                          width="34px"
                          height="34px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 460.8 460.8"
                          fill="currentColor"
                        >
                          <path d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641S296.261,0,230.432,0z"></path>
                          <path d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898C436.8,341.682,437.322,338.024,435.755,334.89z"></path>
                        </svg>
                      </div> */}
                      {/* --> */}

                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            {/* <!-- customer name, orders --> */}
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                            >
                              <BuscarUsuario
                                onSumit={handleResponsableServicio}
                                responsableServicio={responsableServicio}
                              />
                            </a>
                            {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* */}
                  {/* <!-- Customer --> */}
                  <div class="card-header p-4 mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Cliente</span>
                      <div className="d-flex  flex-row gap-2"></div>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- avatar --> */}
                      {/* <div
                        class="flex-none avatar avatar-lg bg-soft-primary rounded-circle"
                        style={{
                          backgroundImage:
                            "url(../html_frontend/demo.files/images/avatar/jessica_barden.jpg)",
                        }}
                      ></div> */}

                      {/* <!-- no avatar --> */}
                      {/* <!--  */}
                      {/* <div class="flex-none avatar avatar-lg bg-soft-primary rounded-circle">
                        <svg
                          class="opacity-25"
                          width="34px"
                          height="34px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 460.8 460.8"
                          fill="currentColor"
                        >
                          <path d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641S296.261,0,230.432,0z"></path>
                          <path d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898C436.8,341.682,437.322,338.024,435.755,334.89z"></path>
                        </svg>
                      </div> */}
                      {/* --> */}

                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <span>Cliente:</span>
                              <span>{usuario?.uuid}</span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <span>nombre:</span>
                              <span>{usuario?.nombre}</span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer email --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <svg
                                class="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                ></path>
                              </svg>
                              <span style={{ textTransform: "none" }}>
                                {usuario?.correo}
                              </span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <svg
                                class="flex-none text-muted me-2"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                <circle cx="12" cy="10" r="3"></circle>
                              </svg>
                              <span>{usuario?.direccion}</span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2 text-muted"
                              href="javascript:void(0);"
                            >
                              <EventIcon fontSize="small" />
                              <span className="link-normal">
                                {usuario?.fechaNacimiento
                                  ? formatDateNoTime(usuario?.fechaNacimiento)
                                  : ""}
                              </span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                            >
                              <svg
                                class="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                ></path>
                              </svg>
                              <span>{usuario?.telefono}</span>
                            </a>
                          </li>
                          <li class="list-item">
                            {/* <!-- customer phone --> */}
                            <a
                              class="link-normal small d-inline-grid gap-auto-2"
                              href="javascript:void(0);"
                              onClick={() =>
                                handleOpenModalClienteRequrimientos()
                              }
                            >
                              <span>Requerimientos especiales:</span>
                              <span>
                                <svg
                                  width="16px"
                                  height="16px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </span>
                            </a>
                          </li>
                          <li class="list-item">
                            <span className="small d-inline-grid pt-2">
                              {usuario?.requerimientos
                                ? usuario.requerimientos
                                : "N/A"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!-- Modal : requerimientos --> */}
                    <Modal
                      status={openModalClienteRequerimientos}
                      handleStatus={handleOpenModalClienteRequrimientos}
                      headerText="Requerimientos del cliente"
                    >
                      <UsuarioRequeriments
                        usuarioDefault={usuario}
                        onSumit={handleCliente}
                        closeModal={handleOpenModalClienteRequrimientos}
                      />
                    </Modal>

                    <div class="fw-bold d-flex align-items-center mt-3">
                      <span class="w-100"></span>
                      <div className="d-flex  flex-row gap-2">
                        <a
                          class="flex-none link-muted small d-inline-grid gap-auto-2"
                          href="javascript:void(0);"
                          onClick={() => handleOpenModalCliente("client")}
                        >
                          <SwitchAccountIcon fontSize="small" />
                          <span>Cambiar</span>
                        </a>
                        <a
                          class="flex-none link-muted small d-inline-grid gap-auto-2"
                          href="javascript:void(0);"
                          onClick={() => handleOpenModalCliente("newClient")}
                        >
                          <PersonAddAlt1Icon fontSize="small" />
                          <span>Nuevo</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="card-body p-4 h-100">
                    {/* <!-- Billing Address --> */}
                    <div class="mb-3">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <svg
                          class="flex-none text-muted me-2"
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"></path>
                          <polyline points="8 10 12 14 16 10"></polyline>
                        </svg>
                        <span class="w-100">ULTIMAS NOTAS</span>
                      </div>

                      <div class="d-flex justify-content-between mt-2">
                        <span class="d-block" style={{ width: "159px" }}>
                          NOTA
                        </span>
                        <span class="d-blockl">ESTADO ENTREGA</span>
                        <span class="d-blockl">ESTADO PAGO</span>
                      </div>

                      {latest.map((t, index) => (
                        <div
                          class="d-flex justify-content-between mt-2"
                          key={index}
                        >
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              navigate(`/ticket/detalles/${t.uuid}`)
                            }
                            class="fw-medium d-block"
                            style={{ alignContent: "center" }}
                          >
                            {t.uuid}

                            {/* ${t.total} */}
                            <span class="d-block smaller text-muted">
                              {/* {t.uuid} */}
                            </span>
                          </a>
                          <span class="rounded-sm p-2 small">
                            {t.estadoEntrega}
                          </span>

                          <span class="rounded-sm p-2 small">
                            {t.estadopago}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div class="card-footer p-4">
                    <span class="text-muted small">
                      {/* Order placed from: [US] United States */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearTicket;
