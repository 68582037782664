import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const formatDateCrate = (fecha) => {
  const newdatec = new Date(fecha);
  return newdatec.toLocaleString();
};
const toMoney = (value) => {
  const money = Number(value);

  if (isNaN(money)) {
    return value;
  }

  // return money.toLocaleString("en", {
  //   style: "currency",
  //   currency: "MXN",
  // });
  return ` ${money.toFixed(2)}`;
};
const totalTicket = (totalt, styles) => {
  if (totalt > 0) {
    return <Text style={styles.tableCell}>${totalt}</Text>;
  }
  return <Text style={styles.tableCell}>${totalt}</Text>;
};

// Componente que renderiza la tabla
const MyTable = ({ rows, pagos, styles }) => {
  return (
    <View style={styles.table} wrap={true}>
      {/* Fila del header */}
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableColCant, styles.headerCol]}>
          <Text style={[styles.tableCell]}>CANT.</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={[styles.tableCell, styles.tableCellCenter]}>
            CONCEPTO
          </Text>
        </View>
        <View style={[styles.tableColImporte, styles.headerCol]}>
          <Text style={[styles.tableCell, styles.tableCellRight]}>IMPORTE</Text>
        </View>
      </View>
      {rows?.map((row, index) => (
        <View key={index} style={styles.tableRow} wrap={false}>
          <View style={styles.tableColCant}>
            <Text style={[styles.tableCell, styles.tableCellCenter]}>
              {row.cantidad}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.tableCellJustify]}>
              {row.value.label}
            </Text>
            {/* <Text style={styles.tableCell}>{row.value.categoria}</Text> */}
          </View>
          <View style={styles.tableColImporte}>
            <Text style={[styles.tableCell, styles.tableCellRight]}>
              {toMoney(Number(row.value.precio) * Number(row.cantidad))}
            </Text>
          </View>
        </View>
      ))}
      {/* <View style={styles.tableRow}>
        <View style={styles.tableColCant}>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>METODO DE PAGO</Text>
        </View>
        <View style={[styles.tableColImporte, styles.headerCol]}>
          <Text style={styles.tableCell}>PAGO</Text>
        </View>
      </View>
      {pagos?.map((productpago, index) => (
        <View key={index} style={styles.tableRow} wrap={false}>
          <View style={styles.tableColCant}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{productpago.metodopago}</Text>
          </View>
          <View style={styles.tableColImporte}>
            <Text style={styles.tableCell}>${productpago.precio}</Text>
          </View>
        </View>
      ))} */}
    </View>
  );
};

export default MyTable;
