import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";

/*Servicios*/
import S3Service from "../../../services/S3.service";
import NoticiaService from "../../../services/Noticia.service";

/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";
import ItemNoticia from "./ItemNoticia";
import ItemBlog from "./ItemBlog";
import ItemBlogBig from "./ItemBlogBig";
import BlogItem from "../../utils/BlogItem";
import ServicioService from "../../../services/Servicio.service";
import { AuthContext } from "../../../context/AuthContext";

const Blog = ({ ultimasNoticias, subtitle }) => {
  const [noticias, setNoticias] = useState([]);
  const [noticia, setNoticia] = useState([]);
  const [imagen, setImagen] = useState(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setImagen("");
    window.scrollTo(0, 0);
    getNoticias();
    window.scrollTo(0, 0);
  }, []);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(3, 0);
      setNoticia(data.data[0]);
      setNoticias(data.data);
      getImage();
    } catch (error) {
      console.log(error);
    }
  };

  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(12);
  const [tipo, setTipo] = useState(0);
  const { currentUser } = useContext(AuthContext);

  const getImage = async () => {
    try {
      S3Service.get(noticia.imagen).then((dataimagen) => {
        //document.getElementById(`imagen123`).src = dataimagen.result;
        setImagen(dataimagen.result);
        console.log("imaaaagen*****" + dataimagen.result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container" style={{ marginBottom: "-60px" }}>
        <div className="d-inline-block mx-auto">
          {/*<small className="fw-bold">Nuestras entradas</small>
          <h2 className="display-5 mb-7 text-center fw-bold">Blog</h2>
        */}
        </div>
        <div className="row g-4 g-lg-5" style={{}}>
          {/*noticias.map((record) => {
            return <ItemNoticia data={record} />;
          })*/}
          <>
            {/* blog news */}
            <div className="" style={{ marginTop: "-2%" }}>
              {/* News */}
              <h2 className="display-5 text-center fw-bold">Blog</h2>

              <h2 className="fw-bold mb-0">Entradas más recientes!</h2>
              <p
                className="fw-medium"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              ></p>
              {/* more articles / blog */}
              <div className="py-4 mt-3">
                <div className="row">
                  {/*noticias.map((record) => {
                    return <ItemBlog data={record} />;
                  })*/}
                  {noticias.map((record, index) => {
                    return <BlogItem data={record} />;
                  })}
                </div>
              </div>
              {/* /more articles / blog */}
            </div>
            {/* /blog news */}
          </>
        </div>
        <div style={{ textAlign: "center", marginTop: "-1px" }}>
          <button
            type="button"
            class="btn btn-outline-secondary"
            style={{ width: "30%" }}
            onClick={() => {
              navigate("/Blog");
            }}
          >
            Ver Todos
          </button>
        </div>
      </div>
    </>
  );
};

export default Blog;
