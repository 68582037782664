import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//servicio
import S3Service from "../../services/S3.service";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Carousel from "react-bootstrap/Carousel";

const BlogItem = ({
  data
}) => {
  const navigate = useNavigate();
  let precioFinal = 0;
  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState(0);
  let precios = 0;
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [imagenesItem, setImagenes] = useState([]);

  useEffect(() => {
    if (data.imagen) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      for (const record of data.imagen[0]) {
        const imagen = await S3Service.get(record);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
        console.log("------------" + imagen.result);
      }
      const result = await S3Service.get(data.imagen[0]);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imagen);
      const image = document.querySelector(`#imagenProducto${data.id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className="col-12 col-lg-4 mb-4">
        <div className="card border-0 shadow-primary-xs shadow-primary-md-hover transition-all-ease-250 transition-hover-top h-100 rounded overflow-hidden">
          <div className="clearfix">
            <img className="img-fluid" src={imag} alt="..." style={{width:"100%", height:"200px", objectFit: "cover"}}/>
            {/* bottom waves */}
            <svg
              style={{ marginTop: "-50px" }}
              className="mb-3"
              width="100%"
              height="50px"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 300"
              preserveAspectRatio="none"
            >
              <path
                style={{ opacity: "0.15" }}
                d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
              />
              <path
                style={{ opacity: "0.3" }}
                d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
              />
              <path
                style={{ opacity: 1 }}
                d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
              />
            </svg>
          </div>
          {/* lines, looks like through a glass */}
          <div className="absolute-full w-100 overflow-hidden">
            <img
              className="img-fluid"
              src="assets/images/masks/shape-line-lense.svg"
              alt="..."
            />
          </div>
          <div className="card-body fw-light mt-5">
            <h2
              className="h5 card-title mb-4 text-uppercase"
              style={{ marginTop: "-50px" }}
            >
              {data.titulo}
            </h2>

            <p
              className="lead"
              dangerouslySetInnerHTML={{
                __html: String(data.cuerpoNoticia).slice(0, 150).concat("..."),
              }}
              style={{
                textAlign: "justify",
                fontWeight: "light",
                fontSize: "1rem",
              }}
            ></p>
          </div>
          <div className="card-footer bg-transparent border-0">
            <span className="float-end fs-6 text-gray-500 p-2">Categoría:{" "} {data.categoria} </span>
            <a
              href="#"
              className="btn btn-sm btn-primary btn-soft opacity-6"
              onClick={() => navigate(`/blog/${data.id}`)}
            >
              Leer
            </a>
          </div>
        </div>
      </div>
      {/*<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: "center", overflowY: "auto" }}
      >
        <div>
          <div class="container pt-6" stylr>
            <div
              className="container"
              style={{ backgroundColor: "white", borderRadius: "10px" }}
            >
              <div>
                <div className="row">
                  <>
                    {/* PAGE TITLE 
                    <div
                      className="container pt-6"
                      style={{
                        marginLeft: "2%",
                        width: "80%",
                        marginTop: "-3%",
                      }}
                    >
                      <h1 className="display-5 fw-bold">{data.titulo}</h1>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fs-6">
                          <li className="breadcrumb-item">
                            <a href="/">Inicio</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="/Servicios">Servicios</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {data.titulo}
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {/* /PAGE TITLE }

                    <button
                      style={{ width: "10%", padding: "-30px" }}
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => handleClose()}
                    >
                      <span aria-hidden="true" style={{ fontSize: "50px" }}>
                        &times;
                      </span>
                    </button>
                  </>
                </div>
              </div>
              <div className="section" >
                <div className="container" style={{marginTop:"-5%"}}>
                  <div className="row">
                    <div className="col-12 col-lg-8 mb-5">
                      <div
                        className="swiper-container swiper-btn-group swiper-btn-group-end text-white p-0 overflow-hidden"
                        data-swiper='{
									"slidesPerView": 1,
									"spaceBetween": 0,
									"autoplay": { "delay" : 4500, "disableOnInteraction": false },
									"loop": true,
									"effect": "fade"
								}'
                        style={{ height: 500 }}
                      >
                        <div className="swiper-wrapper h-100">
                          <Carousel
                            slide={false}
                            nextLabel={""}
                            prevLabel={""}
                            style={{ height: "600px" }}
                          >
                            {data.imagenes.map((record, index) => {
                              return (
                                <Carousel.Item>
                                  <img
                                    className="d-block w-100"
                                    src={imagenesItem[index * 2]}
                                    alt="imagen"
                                    style={{
                                      filter: "brightness(70%)",
                                      height: "500px",
                                      borderRadius: "3%",
                                    }}
                                  />
                                  {console.log("slide:   " + record)}
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>
                        </div>
                        {/* Add Arrows }
                        <div className="swiper-button-next swiper-button-white" />
                        <div className="swiper-button-prev swiper-button-white" />
                      </div>
                      {/* /SWIPER }
                    </div>
                    <div className="col-12 col-lg-4 mb-5">
                      <div className="p-4 p-0-xs shadow-md shadow-none-xs rounded">
                        <h6 className="fw-normal mb-4">Tiempo: 10 min</h6>
                        <p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.descripcion,
                            }}
                            style={{ textAlign: "justify" }}
                          ></p>
                        </p>
                        <div className="border-start border-primary border-3 py-1 px-3 my-5">
                          <h2 className="mb-0 h5">Precio</h2>
                          <p className="mb-0 text-gray-500">$0.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                          </Modal>*/}
    </>
  );
};

export default BlogItem;
