/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

/* Servicios */
import UserService from "../../../services/User.Service";
import ConfiguracionService from "../../../services/Configuracion.service";
import CambiarSerie from "./CambiarSerie";

const Tickets = () => {
  const [loading, setLoading] = useState(false);
  const [serie, setSerie] = useState("A");
  const [digitos, setDigitos] = useState(new Array(4).fill(0));
  const [numberSerie, setNumberSerie] = useState(1111);
  const [openModalSerie, setOpenModalSerie] = useState(false);
  const handleOpenModalSerie = () => {
    setOpenModalSerie(!openModalSerie);
  };

  const [series, setSeries] = useState([
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "ñ",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ]);

  const [update, setUpdate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getConfiguracion();
  }, [update]);

  const onUpdate = () => {
    setUpdate(!update);
  };

  const getConfiguracion = async () => {
    setLoading(true);
    try {
      const data = await ConfiguracionService.getConfiguracionTickets();
      setSerie(data.serie);
      setDigitos(new Array(data.digitos).fill(0));
      setNumberSerie(data.numberserie);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateConfiguracionTickets = async (updateData) => {
    setLoading(true);

    try {
      const data = await ConfiguracionService.updateConfiguracionTicketse(
        updateData
      );
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      onUpdate();
    }
  };

  const cambiarSerie = (newSerie) => {
    setSerie(newSerie);
  };

  const cambiarDigitos = (newDigitos) => {
    let oldDigitos = digitos.length;
    let digitoslength = numberSerie.toString();
    if (newDigitos) {
      oldDigitos += 1;
    } else if (oldDigitos > digitoslength.length) {
      oldDigitos -= 1;
    }
    setDigitos(new Array(oldDigitos).fill(0));
  };

  const save = () => {
    updateConfiguracionTickets({ serie: serie, digitos: digitos.length });
  };

  const saveserie = (newnumber) => {
    updateConfiguracionTickets({ numberserie: newnumber });
  };

  const numberformat = () => {
    let cadena = numberSerie.toString();
    while (cadena.length < digitos.length) {
      cadena = "0" + cadena;
    }
    return Array.from(cadena);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <header>
              <h1 class="h4">ORDENES DE SERVICIO</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/configuracion")}
                    >
                      CONFIGURACION
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    ORDENES DE SERVICIO
                  </li>
                </ol>
              </nav>
            </header>

            {/* <!-- notifications --> */}
            <div class="section p-xl-4">
              <h3 class="h5 mb-5">Opciones</h3>

              <div class="mt-6 row">
                {/* <h2 class="h5 mb-3 text-secondary fw-normal">
                <span class="text-danger fw-medium">2.178.322</span> items{" "}
                <span class="text-gray-500">/ explore by category</span>
              </h2> */}

                <div className="col-12 col-md-6">
                  <span class="fw-medium">
                    Serie
                    <span class="d-block smaller text-muted">
                      Indicador de serie ORDEN (caracter de inicio id)
                    </span>
                  </span>
                  <div class="row gutters-sm">
                    {series.map((permiso, index) => (
                      <div class="col-1 mb-3 mt-3">
                        <a
                          href="javascript:void(0);"
                          class="d-block bg-white shadow shadow-md-hover transition-hover-top transition-all-ease-250 rounded px-1 py-1 text-center text-decoration-none"
                          onClick={() => cambiarSerie(permiso.toUpperCase())}
                          style={
                            permiso.toUpperCase() === serie
                              ? { color: "red" }
                              : { color: "#506690" }
                          }
                        >
                          {/* <FactCheckIcon
                        fontSize="large"
                        style={{ width: "50px", height: "50px" }}
                      /> */}
                          <p class="fw-medium mb-1 mt-1">
                            {permiso.toUpperCase()}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-w justify-content-center align-items-center fw-bolder">
                  <div style={{ fontSize: "5rem" }}>{serie}</div>
                </div>
              </div>

              <div class="border border-dashed border-bottom-0 my-3">
                {/* <!-- divider --> */}
              </div>

              <div class="d-flex justify-content-between">
                <span class="fw-medium">
                  Digitos
                  <span
                    class="d-block smaller text-muted"
                    style={{ marginBottom: "1rem" }}
                  >
                    Cantidad de digitos en id
                  </span>
                  <span class="fw-medium fs-3">
                    <span
                      class="fw-medium p-2 border"
                      style={{ cursor: "pointer" }}
                      onClick={() => cambiarDigitos(false)}
                    >
                      -
                    </span>
                    <span class="fw-medium p-2 border">{digitos.length}</span>
                    <span
                      class="fw-medium p-2 border"
                      style={{ cursor: "pointer" }}
                      onClick={() => cambiarDigitos(true)}
                    >
                      +
                    </span>
                  </span>
                </span>
                <div className="d-flex">
                  {digitos.map(() => (
                    <div style={{ fontSize: "5rem" }}>0</div>
                  ))}
                </div>
              </div>

              <div class="border border-dashed border-bottom-0 my-3">
                {/* <!-- divider --> */}
              </div>

              {/* <!-- Modal : serie change --> */}

              <div
                className={openModalSerie ? "modal fade show" : "modal fade"}
                id="pago-add"
                tabindex="-2"
                aria-labelledby="pago-add-label"
                aria-hidden={!openModalSerie}
                aria-modal={openModalSerie}
                role="dialog"
                style={
                  openModalSerie
                    ? {
                        display: "block",
                        backgroundColor: "#a2313738",
                      }
                    : {
                        zIndex: "-2",
                        display: "block",
                      }
                }
              >
                <div
                  method="post"
                  action="#"
                  className={
                    openModalSerie
                      ? "fade show modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                      : "fade modal-dialog form-validate modal-dialog modal-md modal-dialog-centered text-start"
                  }
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="pago-add-label">
                        Cambiar número de serie
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleOpenModalSerie}
                      ></button>
                    </div>
                    <CambiarSerie
                      onSumit={saveserie}
                      serie={serie}
                      digitos={digitos.length}
                      numberSerie={numberSerie}
                      closeModal={handleOpenModalSerie}
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <span class="col-4 fw-medium">
                  <span class="d-block smaller text-muted">
                    Vista previa serie actual:
                  </span>
                  <a
                    href="javascript:void(0)"
                    class="d-block smaller"
                    onClick={handleOpenModalSerie}
                  >
                    Cambiar número actual.
                  </a>
                </span>
                <div className="col-8 fw-bolder d-flex">
                  <div className="border" style={{ fontSize: "5rem" }}>
                    {serie}
                  </div>
                  <div className="border" style={{ fontSize: "5rem" }}>
                    -
                  </div>
                  {numberformat().map((digit, index) => (
                    <div
                      key={index}
                      className="border"
                      style={{ fontSize: "5rem" }}
                    >
                      {digit}
                    </div>
                  ))}
                </div>
              </div>

              <div class="text-end">
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={() => save()}
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default Tickets;
