import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

/* Servicios */
import TicketsService from "../../../services/Tickets.service";
import TicketsSalidaService from "../../../services/TicketsSalida.service";
import PrintIcon from "@mui/icons-material/Print";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./DocumentSalida/Document";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { usePrint } from "../../../hooks/usePrint";

const CrearTicketSalidaDetails = () => {
  const { id } = useParams();
  const [responsableServicio, setResponsableServicio] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [updateuser, setUpdateuser] = useState(false);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [pagado, setPagado] = useState(0);
  const [nota, setNota] = useState("");
  const [concepto, setConcepto] = useState("");
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [servicios, setServicios] = useState([]);
  const [latest, setLatest] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);
  const [openModalCliente, setOpenModalCliente] = useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [metodoPago, setMetodoPago] = useState("Efectivo");
  const [monto, setMonto] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const onUpdate = () => {
    setUpdate(!update);
  };
  const [estadoPago, setEstadoPago] = useState("Pendiente");
  const [configuracion, setConfiguracion] = useState({
    serie: "A",
    digitos: 4,
    numberserie: 0,
  });

  let today = new Date();
  const [fechaHoy, setFechaHoy] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );

  const [waitingPrint, setWaitingPrint] = useState(false);
  const { printUrl, printIsAuto } = usePrint();

  const toMoney = (value) => {
    const money = Number(value);

    if (isNaN(money)) {
      return value;
    }

    return money.toLocaleString("en", {
      style: "currency",
      currency: "MXN",
    });
  };

  useEffect(() => {
    getTicket();
  }, [id, update]);

  const getTicket = async () => {
    console.log(id);
    setLoading(true);
    try {
      const data = await TicketsService.getById(id);
      console.log(data);
      setFechaEntrega(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "numeric",
        }).format(new Date(data.fecha))
      );
      setConcepto(data.nota);
      setMetodoPago(data.pagos[0]?.metodopago);
      setPagado(Number(data.pagado));
      setTotal(Number(data.total));
      setMonto(Number(data.pagos[0]?.precio));
      setNota(data.nota);
      setSaldo(Number(data.saldo));
      setResponsableServicio(data.responsableservicio);

      const dataLatest = await TicketsSalidaService.getLatest(
        data.cliente.uuid
      );
      setLatest(dataLatest);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enviarImpresion = async (uuid) => {
    if (waitingPrint) {
      return;
    }

    setWaitingPrint(true);
    try {
      let newTicket = { id: uuid };
      const res = await TicketsSalidaService.resendPrint(newTicket);
      printUrl(res.url);
    } catch (error) {
      toast.error("Error. No enviado!");
    } finally {
      setWaitingPrint(false);
    }
  };

  const impresionManual = async (url) => {
    printUrl(url);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <header>
              <div class="row g-1 align-items-center">
                <div class="col">
                  <h1 class="h4">Salida de efectivo</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb small">
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">{id ? id : "N/A"}</a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <div class="col-auto">
                  <a
                    href="javascript:void(0);"
                    role="button"
                    class="btn btn-sm btn-primary d-inline-flex align-items-center"
                    onClick={() => navigate(-1)}
                  >
                    <span class="d-none d-sm-inline-block ps-2">Regresar</span>
                  </a>
                </div>
              </div>
            </header>
            {/* <!-- Order items --> */}
            <div class="row g-2">
              <div class="col">
                {/* <!-- Order items --> */}
                <div class="card border-0 h-100">
                  <div class="card-header px-4 border-bottom-0 fw-bold">
                    Concepto
                  </div>
                  <div class="card-body px-4 h-100">
                    <div
                      style={{
                        width: "100%",
                        minHeight: "76px",
                        border: "solid 1px lightgrey",
                        borderRadius: "8px",
                        marginTop: "0.5rem",
                        padding: "0.5rem",
                      }}
                    >
                      {concepto}
                    </div>

                    <div class="row g-4">
                      <div class="order-2 order-md-1 col small"></div>
                      <div class="order-1 order-md-2 col-md-3 text-end"></div>
                    </div>

                    <div class="row g-4 justify-content-end my-5">
                      <div class="col">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            <dd class="col-4 fw-bold d-inline-grid gap-auto-2">
                              Metodo:
                            </dd>
                            <dd class="col-8 fw-normal d-inline-grid gap-auto-2">
                              {metodoPago}
                            </dd>
                          </li>
                        </ul>
                        <div class="col-12 pt-4">
                          <dd class="col-4 fw-bold d-inline-grid gap-auto-2">
                            Fecha:
                          </dd>
                          <span className="text-muted">
                            <input
                              type="datetime-local"
                              min={fechaHoy}
                              value={fechaEntrega}
                              disabled={true}
                              style={{
                                border: "none",
                                paddingInline: "0.5rem",
                                borderBottom: "none",
                                padding: "0.2rem",
                                backgroundColor: "white",
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 text-end">
                        <dl class="row mb-0">
                          <dt class="fw-bold">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Total:
                            </dd>
                            <dd class="col-5 fw-bold d-inline-grid gap-auto-2 ms-2">
                              {toMoney(total)}
                            </dd>
                          </dt>

                          {/* <dt class="col-10 text-muted">
                            <dd class="col-6 text-muted d-inline-grid gap-auto-2">
                              Anticipo:
                            </dd>
                            <dd class="col-5 fw-bold d-inline-grid gap-auto-2 ms-2">
                              <TextField
                                type="number"
                                value={monto}
                                onChange={(event) =>
                                  setMonto(event.target.value)
                                }
                                label="$"
                              />
                            </dd>
                          </dt>
                          <dt class="col-10 fw-bold">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Saldo:
                            </dd>
                            <dd class="col-5 fw-bold d-inline-grid gap-auto-2 ms-2">
                              <TextField
                                value={saldo}
                                onChange={(event) =>
                                  setSaldo(event.target.value)
                                }
                                label="$"
                              />
                            </dd>
                          </dt> */}
                        </dl>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer p-4">
                    {/* <small class="d-block text-muted">
                      Coupon used: T01778
                        </small>*/}
                    {/* <small class="d-block text-muted">
                      Giftcard used: &ndash;
                    </small> */}
                  </div>
                </div>
                {/* <!-- /Order items --> */}
              </div>

              {/* <!-- side --> */}
              <div class="col-xl-4">
                <div class="card border-0 h-100">
                  <div class="card-header p-4 mb-3">
                    <div class="d-flex align-items-center">
                      <div class="w-100 ps-3">
                        <ul class="d-grid gap-2 list-unstyled m-0 p-0">
                          <li class="list-item">
                            {printIsAuto ? (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                                onClick={() => enviarImpresion(id)}
                              >
                                <PrintIcon fontSize="small" />
                                <span style={{ marginInline: "0.5rem" }}>
                                  Imprime
                                </span>
                              </a>
                            ) : (
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <PrintIcon fontSize="small" />
                                <PDFDownloadLink
                                  document={<Document id={id} />}
                                  fileName={`LET_ORDEN_${today.toLocaleDateString()}.pdf`}
                                  style={{ padding: "0.5rem" }}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? (
                                      "Cargando..."
                                    ) : (
                                      <a
                                        className="text-decoration-none"
                                        href="javascript:void(0);"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          impresionManual(url);
                                        }}
                                      >
                                        <span
                                          style={{ marginInline: "0.5rem" }}
                                        >
                                          Imprime
                                        </span>
                                      </a>
                                    )
                                  }
                                </PDFDownloadLink>
                              </a>
                            )}
                          </li>
                          <li class="list-item">
                            {/* <!-- customer name, orders --> */}
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                            >
                              <PictureAsPdfOutlinedIcon fontSize="small" />
                              <PDFDownloadLink
                                document={<Document id={id} />}
                                fileName={`LET_ORDEN_${today.toLocaleDateString()}.pdf`}
                                style={{ padding: "0.5rem" }}
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Cargando..." : "Descargar"
                                }
                              </PDFDownloadLink>
                            </a>
                            <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              {/* 4 orders */}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*User*/}
                  <div class="card-header p-4 mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Responsable</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- avatar --> */}
                      {/* <div
                        class="flex-none avatar avatar-lg bg-soft-primary rounded-circle"
                        style={{
                          backgroundImage:
                            "url(../html_frontend/demo.files/images/avatar/jessica_barden.jpg)",
                        }}
                      ></div> */}

                      {/* <!-- no avatar --> */}
                      {/* <!--  */}
                      {/* <div class="flex-none avatar avatar-lg bg-soft-primary rounded-circle">
                        <svg
                          class="opacity-25"
                          width="34px"
                          height="34px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 460.8 460.8"
                          fill="currentColor"
                        >
                          <path d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641S296.261,0,230.432,0z"></path>
                          <path d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898C436.8,341.682,437.322,338.024,435.755,334.89z"></path>
                        </svg>
                      </div> */}
                      {/* --> */}

                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            {/* <!-- customer name, orders --> */}
                            <a
                              class="text-decoration-none d-block"
                              href="javascript:void(0)"
                            >
                              {responsableServicio?.nombre}
                            </a>
                            <a
                              class="link-muted small"
                              href="javascript:void(0)"
                              style={{ textTransform: "none" }}
                            >
                              {responsableServicio?.correo}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* */}

                  <div class="card-body p-4 h-100">
                    {/* <!-- Billing Address --> */}
                    <div class="mb-3">
                      {/* <div class="fw-bold d-flex align-items-center mb-3">
                        <svg
                          class="flex-none text-muted me-2"
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"></path>
                          <polyline points="8 10 12 14 16 10"></polyline>
                        </svg>
                        <span class="w-100">Ultimos tickets</span>
                      </div>

                      <div class="d-flex mt-2">
                        <span class="d-block" style={{ width: "159px" }}>
                          Total
                        </span>
                        <span class="d-blockl">Estado pago:</span>
                      </div>

                      {latest.map((t, index) => (
                        <div
                          class="d-flex mt-2"
                          style={{ justifyContent: "space-between" }}
                          key={index}
                        >
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              navigate(`/ticket/detalles/${t.uuid}`)
                            }
                            class="fw-medium d-block"
                          >
                            ${t.total}
                            <span class="d-block smaller text-muted">
                              {t.uuid}
                            </span>
                          </a>

                          <span class="rounded-sm p-2 small">
                            {t.estadopago}
                          </span>
                        </div>
                      ))} */}
                    </div>
                  </div>

                  <div class="card-footer p-4">
                    <span class="text-muted small">
                      {/* Order placed from: [US] United States */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearTicketSalidaDetails;
