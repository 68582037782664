import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

/*Servicios*/
import GoogleMaps from "simple-react-google-maps";
import FooterService from "../../../services/Footer.service";
import MensajeService from "../../../services/Contacto.service";

/*Componentes*/

const Contacto = ({ subtitle }) => {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY;
  const [contactanos, setContactanos] = useState({ undefined });
  const [mensaje, setMensaje] = useState({ undefined });
  const [valido, setValido] = useState(true);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({ undefined });
  const [checked, setChecked] = useState(false);
  const [aceptar, setAceptar] = React.useState(false);
  const [locations, setLocations] = useState([
    {
      lat: 17.052747,
      lng: -96.72233,
    },
  ]);
  const [center, setCenter] = useState({
    lat: 17.052747,
    lng: -96.72233,
  });
  const navigate = useNavigate();

  useEffect(() => {
    getContactanos();
    window.scrollTo(0, 0);
  }, []);

  const getContactanos = async () => {
    setLoading(true);
    try {
      const foot = await FooterService.getAll();
      setInfo(foot[0]);
      if (
        foot[0]?.Direccion &&
        foot[0]?.Direccion?.lat !== 0 &&
        !isNaN(foot[0]?.Direccion.lat)
      ) {
        setLocations([
          {
            lat: Number(foot[0]?.Direccion?.lat),
            lng: Number(foot[0]?.Direccion?.long),
          },
        ]);
        setCenter({
          lat: Number(foot[0]?.Direccion?.lat),
          lng: Number(foot[0]?.Direccion?.long),
        });
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  function onChange(value) {
    setValido(false);
  }

  const enviarMensaje = async () => {
    if (!mensaje.Nombre) {
      toast.info("Error, tienes que colocar tu nombre");
    } else if (!mensaje.Email) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!mensaje.Telefono) {
      toast.info("Error, tienes que colocar un número telefónico");
    } else if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar una mensaje");
    } else if (!aceptar) {
      toast.info(
        "Para poder enviar tu mensaje debes de aceptar  los terminos y condiciones"
      );
    } else {
      setLoading(true);
      try {
        const datosMensaje = mensaje;
        datosMensaje.Status = "SinAbrir";
        await MensajeService.create(datosMensaje);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        const limpiar = {
          Nombre: "",
          Email: "",
          MensajeCuerpo: "",
          Telefono: "",
        };
        setMensaje(limpiar);
        setValido(true);
        toast.info("Gracias, mensaje enviado");
      }
    }
  };

  return (
    <>
      <>
        {/* block : contact */}
        <div
          id="section_contact"
          className="section bg-light"
          style={{ marginTop: "-10%" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 mb-5">
                <h2 className="fw-bold mb-0">Contáctanos</h2>
                <p
                  className="fw-medium mb-5"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                ></p>
                <div className="row">
                  {/* call us */}
                  <div className="col-12 col-sm-6 mb-4">
                    <h3 className="fw-medium text-primary h5 mb-4">Llamanos</h3>
                    <p className="m-0">
                      <a href={"tel:" + info.Telefono} className="link-muted">
                        {info.Telefono}
                      </a>
                    </p>
                    <p className="m-0">
                      <a href={"mailTo:" + info.Correo} className="link-muted">
                        {info.Correo}
                      </a>
                    </p>
                  </div>
                  {/* address */}
                  <div className="col-12 col-sm-6 mb-4">
                    <h3 className="fw-medium text-primary h5 mb-4">
                      Dirección
                    </h3>
                    <p className="m-0">{info?.Direccion?.Direccion}</p>
                  </div>
                </div>
                <div className="bg-white shadow-primary-xs rounded p-2 mt-5">
                  {/*
            Map
          */}
                  {loading ? null : (
                    <GoogleMaps
                      apiKey={google_apiKey}
                      style={{ height: "400px", width: "100%" }}
                      zoom={15}
                      center={center}
                      markers={locations} //optional
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="rounded bg-white shadow-xs d-flex">
                  <div className="m-5 m-4-xs">
                    <h2 className="mb-4">Envíanos un mensaje</h2>
                    <form className="bs-validate js-ajax">
                      {/* */}
                      <div className="form-floating mb-3">
                        <input
                          required=""
                          placeholder="Nombre"
                          id="contact_name"
                          name="contact_name"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            setMensaje({ ...mensaje, Nombre: e.target.value })
                          }
                          value={mensaje.Nombre}
                        />
                        <label htmlFor="contact_name">Nombre</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          required=""
                          placeholder="Correo electronico"
                          id="contact_email"
                          name="contact_email"
                          type="email"
                          className="form-control"
                          onChange={(e) =>
                            setMensaje({ ...mensaje, Email: e.target.value })
                          }
                          value={mensaje.Email}
                        />
                        <label htmlFor="contact_email">
                          Correo Electrónico
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          required=""
                          placeholder="Telefono"
                          id="contact_phone"
                          name="contact_phone"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            setMensaje({
                              ...mensaje,
                              Telefono: e.target.value,
                            })
                          }
                          value={mensaje.Telefono}
                        />
                        <label htmlFor="contact_phone">Teléfono</label>
                      </div>
                      <div className="form-floating mb-3">
                        <textarea
                          required=""
                          placeholder="Mensaje"
                          id="contact_message"
                          name="contact_message"
                          className="form-control"
                          rows={3}
                          style={{ minHeight: 120 }}
                          defaultValue={""}
                          onChange={(e) =>
                            setMensaje({
                              ...mensaje,
                              MensajeCuerpo: e.target.value,
                            })
                          }
                          value={mensaje.MensajeCuerpo}
                        />
                        <label htmlFor="contact_message">Mensaje</label>
                      </div>
                      {/* GDPR CONSENT */}
                      <div
                        className="mb-3 border p-3 rounded"
                        style={{
                          textAlign: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ReCAPTCHA
                            sitekey={"6LfAC18jAAAAAMzQTRN2K7shO2tZeskMJWv_Q7Rd"}
                            onChange={onChange}
                            style={{
                              transform: "scale(0.84)",
                              transformOrigin: "0 0",
                            }}
                          />
                        </p>
                        <div className="form-check">
                          <input
                            required=""
                            className="form-check-input"
                            id="contact_gdpr"
                            name="contact_gdpr"
                            type="checkbox"
                            defaultValue={1}
                            onClick={() => {
                              setAceptar(true);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="contact_gdpr"
                          >
                            Acepto términos y condiciones{" "}
                            <a
                              className="text-decoration-none"
                              href="javascript:void(0)"
                              onClick={() => navigate(`/aviso`)}
                            >
                              (Términos y condiciones)
                            </a>
                          </label>
                        </div>
                      </div>
                      {/* /GDPR CONSENT */}
                      {/* 
                Server detailed error
                !ONLY! If debug is enabled!
                Else, shown ony "Server Error!"
              */}
                      <div id="ajax_dd_contact_response_container" />
                      {/* {:err:unexpected:} internal server error */}
                      <div
                        id="contactErrorUnexpected"
                        className="hide alert alert-danger p-3"
                      >
                        Unexpected Error!
                      </div>
                      {/* {:err:required:} mandatory fields */}
                      <div
                        id="contactErrorMandatory"
                        className="hide alert alert-danger p-3"
                      >
                        Please, review your data and try again!
                      </div>
                      {/* {:success:} message sent */}
                      <div
                        id="contactSuccess"
                        className="hide alert alert-success p-3"
                      >
                        Gracias por tu mensaje
                      </div>
                    </form>
                    <button
                      className="btn btn-lg btn-primary w-100"
                      style={{
                        marginLeft: "3%",
                        backgroundColor: valido ? "#f3f3f3" : "black",
                      }}
                      disabled={valido}
                      onClick={() => enviarMensaje()}
                    >
                      Enviar mensaje
                    </button>
                    {/* /CONTACT FORM : AJAX */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /block : contact */}
      </>
    </>
  );
};

export default Contacto;
