import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//servicio
import S3Service from "../../services/S3.service";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Carousel from "react-bootstrap/Carousel";

const ServicioItem = ({ data, tipo }) => {
  const navigate = useNavigate();

  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState("0");
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  <>
    {/*if (data.descuento > 0) {
    const descuentoProps = data.descuento;
    const precioOriginal = precio;
    precioFinal = precioOriginal - 100 / (precioOriginal / descuentoProps);
  }
//tipo: Empresa o usuario
 /* if (tipo == "Empresa") {
    if (record.precioE == null) {
      precios = 0;
    } else {
      for (const pre of record.precioE) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  } else {
    if (record.precioU == null) {
      precios = 0;
    } else {
      for (const pre of record.precioU) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  }*/}
  </>;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [imagenesItem, setImagenes] = useState([]);

  useEffect(() => {
    if (data.imagenes.length > 0) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      for (const record of data.imagenes) {
        const imagen = await S3Service.get(record);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
        console.log("------------" + imagen.result);
      }
      const result = await S3Service.get(data.imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imagen);
      const image = document.querySelector(`#imagenProducto${data.id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const Precio = () => {
    if (data.descuento > 0) {
      return (
        <>
          <del className="text-muted">${data.precios[0].props.precio} {"   "} </del>
          <span className="text-dark">${data.precios[0].props.precio- (data.precios[0].props.precio*(data.descuento/100))}</span>
        </>
      );
    }else{
      return (
        <>
          <span className="text-dark">${data.precios[0].props.precio}</span>
        </>
      );
    }
  };

  return (
    <>
      <>
        {/* item */}
        <div className="order-2 col-6 col-lg-3">
          <div className="bg-white shadow-xs shadow-3d-hover transition-all-ease-250 transition-hover-top rounded show-hover-container p-2 h-100">
            <a
              href="#"
              className="d-block text-gray-800"
              onClick={() => navigate(`/Servicios/${data.id}`)}
            >
              {/* 
              
              3 ways to set the image

            */}
              {/* 1. without .bg-suprime - use image as it is */}
              {/*
            <figure class="text-center rounded-top overflow-hidden">
              <img class="img-fluid" src="image.jpg" alt="..."> 
            </figure>
            */}
              {/* 2. squared, as background */}
              {/*
            <figure class="text-center rounded-top overflow-hidden bg-cover" style="background-image:url('image.jpg')">
              <img class="w-100" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt="..."> 
            </figure>
            */}
              {/* 3. with .bg-suprime (remove white bg and add a gray bg) */}
              <figure className="text-center bg-gradient-radial-light rounded-top overflow-hidden">
                <img
                  className="img-fluid bg-suprime opacity-9"
                  src={imag}
                  alt="..."
                  style={{ height: "250px" }}
                />
              </figure>
              <span className="d-block fs-6 p-2">
                {/* rating */}
                {/* title */}
                <span className="d-block fw-medium text-uppercase" style={{height:"80px"}}>{data.titulo}</span>
                {/* price */}
                <span className="d-block fw-medium">
                  {Precio()}
                </span>
              </span>
            </a>
          </div>
        </div>
        {/* /item */}
      </>

      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ position: "center", overflowY: "auto" }}
        >
          <div>
            <div class="container pt-6" stylr>
              <div
                className="container"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              >
                <div>
                  <div className="row">
                    <>
                      {/* PAGE TITLE */}
                      <div
                        className="container pt-6"
                        style={{
                          marginLeft: "2%",
                          width: "80%",
                          marginTop: "-3%",
                        }}
                      >
                        <h1 className="display-5 fw-bold">{data.titulo}</h1>
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb fs-6">
                            <li className="breadcrumb-item">
                              <a href="/">Inicio</a>
                            </li>
                            <li className="breadcrumb-item">
                              <a href="/Servicios">Servicios</a>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              {data.titulo}
                            </li>
                          </ol>
                        </nav>
                      </div>
                      {/* /PAGE TITLE */}

                      <button
                        style={{ width: "10%", padding: "-30px" }}
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => handleClose()}
                      >
                        <span aria-hidden="true" style={{ fontSize: "50px" }}>
                          &times;
                        </span>
                      </button>
                    </>
                  </div>
                </div>
                <div className="section">
                  <div className="container" style={{ marginTop: "-5%" }}>
                    <div className="row">
                      <div className="col-12 col-lg-8 mb-5">
                        <div
                          className="swiper-container swiper-btn-group swiper-btn-group-end text-white p-0 overflow-hidden"
                          data-swiper='{
									"slidesPerView": 1,
									"spaceBetween": 0,
									"autoplay": { "delay" : 4500, "disableOnInteraction": false },
									"loop": true,
									"effect": "fade"
								}'
                          style={{ height: 500 }}
                        >
                          <div className="swiper-wrapper h-100">
                            <Carousel
                              slide={false}
                              nextLabel={""}
                              prevLabel={""}
                              style={{ height: "600px" }}
                            >
                              {data.imagenes.map((record, index) => {
                                return (
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={imagenesItem[index * 2]}
                                      alt="imagen"
                                      style={{
                                        filter: "brightness(70%)",
                                        height: "500px",
                                        borderRadius: "3%",
                                      }}
                                    />
                                    {console.log("slide:   " + data)}
                                  </Carousel.Item>
                                );
                              })}
                            </Carousel>
                          </div>
                          {/* Add Arrows */}
                          <div className="swiper-button-next swiper-button-white" />
                          <div className="swiper-button-prev swiper-button-white" />
                        </div>
                        {/* /SWIPER */}
                      </div>
                      <div className="col-12 col-lg-4 mb-5">
                        <div className="p-4 p-0-xs shadow-md shadow-none-xs rounded">
                          <h2 className="h4 fw-bold m-0">{data.subtitulo}</h2>
                          <h6 className="fw-normal mb-4">Tiempo: 10 min</h6>
                          <p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data.descripcion,
                              }}
                              style={{ textAlign: "justify" }}
                            ></p>
                          </p>
                          <div className="border-start border-primary border-3 py-1 px-3 my-5">
                            <h2 className="mb-0 h5">Precio</h2>
                            <p className="mb-0 text-gray-500">$0.00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default ServicioItem;
