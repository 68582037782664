import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColOrder: {
    width: "16%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "32%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
  },
  tableColB: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
  },
  tableColLeft: {
    width: "16%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  tableColSub: {},
  tableCell: {
    padding: "3",
    fontSize: 9,
    textAlign: "left",
    width: "100%",
    flexDirection: "row",
  },
  headerCol: {
    backgroundColor: "#d3d3d3",
  },
  fontnegrita: { fontFamily: "Lato Bold" },
  table: {},
  tableText: {},
});

const formatDateCrate = (fecha) => {
  const newdatec = new Date(fecha);
  return newdatec.toLocaleString();
};
const formatDate = (date) => {
  if (!date) {
    return "00/00/0000";
  }

  const newdate = new Date(date);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    hourCycle: "h12",
  };
  return newdate
    .toLocaleString("es-ES", options)
    .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
};

const formatDateLocal = (date, tipo) => {
  if (!date) {
    return "00/00/0000";
  }
  const newdate = new Date(date);

  if (tipo === "Hora especifica") {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return newdate
      .toLocaleString("es-ES", options)
      .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return newdate
    .toLocaleString("es-ES", options)
    .replace(/(\d+)\/(\d+)\/(\d+),/, "$1-$2-$3");
};
const totalTicket = (totalt) => {
  if (totalt > 0) {
    return <Text style={styles.tableCell}>${totalt}</Text>;
  }
  return <Text style={styles.tableCell}>${totalt}</Text>;
};

// Componente que renderiza la tabla
const MyTable = ({ rows }) => {
  return (
    <View style={styles.table} wrap={true}>
      {/* Fila del header */}
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableColLeft, styles.headerCol]}>
          <Text style={styles.tableCell}>ORDEN</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>CLIENTE</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>FECHAS</Text>
        </View>
        <View style={[styles.tableColB, styles.headerCol]}>
          <Text style={styles.tableCell}>ESTADOS</Text>
        </View>
      </View>
      {rows?.map((row, index) => (
        <View key={index} style={styles.tableRow} wrap={false}>
          <View style={styles.tableColLeft}>
            <Text style={styles.tableCell}>{row.uuid}</Text>
            <Text style={[styles.tableCell, styles.tableColSub]}>
              TOTAL: ${row.total}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{row.cliente?.nombre}</Text>
            <Text style={styles.tableCell}>
              RECEPCIÓN: {row.responsableservicio?.nombre}
            </Text>
            <Text style={styles.tableCell}>
              Entrega:{" "}
              {row.responsableEntrega?.nombre
                ? row.responsableEntrega?.nombre
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              RECEPCIÓN: {formatDate(row.fechaRecepcion)}
            </Text>
            <Text style={styles.tableCell}>
              ENTREGA: {formatDateLocal(row.fecha, row.tiposervicio)}
            </Text>
            <Text style={styles.tableCell}>
              SALIDA: {formatDate(row.fechaSalida)}
            </Text>
          </View>
          <View style={styles.tableColB}>
            <Text style={styles.tableCell}>PAGO: {row.estadopago}</Text>
            <Text style={styles.tableCell}>ENTREGA: {row.estadoEntrega}</Text>
          </View>
        </View>
      ))}
      {/* Fila del header */}
    </View>
  );
};

export default MyTable;
