import { useEffect, useMemo, useState } from "react";
import ClienteService from "../../../services/User.Service";
import { Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";

const BuscarUsuario = ({ onSumit, responsableServicio, style, title }) => {
  const [productos, setProductos] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [cantidad, setCantidad] = useState(1);

  useEffect(() => {
    setValue(responsableServicio);
  }, [responsableServicio]);

  const agregar = (val) => {
    if (!val) {
      toast.info("Selecciona un usuario");
      return;
    }
    onSumit({ value: val });
    setValue(val);
    // closeModal();
  };

  const getProductos = async () => {
    try {
      const data = await ClienteService.getAdministrativos();
      let productosFormat = [];
      let indexselected = -1;
      data.map((prod) => {
        productosFormat.push({
          label: prod.nombre.toUpperCase(),
          id: prod.id,
          correo: prod.correo,
          telefono: prod.tipo,
        });
      });
      setProductos(productosFormat);
    } catch (error) {}
  };

  useEffect(() => {
    getProductos();
  }, []);

  return (
    <>
      <div>
        <div class="form-floating" style={style}>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              agregar(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disablePortal
            id="combo-box-responsable"
            options={productos}
            fullWidth
            renderInput={(params) => <TextField {...params} label={title} />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <div class="mt-2" id="search-container">
            <div class="py-2">
              <label>Correo:</label>
              {/* <!-- appended item --> */}
              <a href="javascript:void(0);" class="link-normal"></a>
              <a href="javascrip:void(0);" class="text-decoration-none ms-2">
                {value?.correo}
              </a>
            </div>
            <div class="py-2">
              <label>Cargo:</label>
              <a href="javascript:void(0);" class="link-normal"></a>
              <a href="javascrip:void(0);" class="text-decoration-none ms-2">
                {value?.telefono}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuscarUsuario;
