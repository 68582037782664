import { width } from "@mui/system";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
//Imágen
import logo2 from "./logo2.png";
const HeaderPublicLayout = () => {
  //#region Variables
  const [scrollDirection, setScrollDirection] = useState(null);
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(undefined);
  const { logout } = useContext(AuthContext);

  const [inicio, setInicio] = useState("active");
  const [paquetes, setPaquetes] = useState("");
  const [blog, setBlog] = useState("");
  const [iniciarSesion, setIniciarSesion] = useState("");
  //#endregion

  //#region funciones
  const activeInicio = () => {
    setInicio("active");
    setPaquetes("");
    setBlog("");
    setIniciarSesion("");
  };

  const activePaquetes = () => {
    setInicio("");
    setPaquetes("active");
    setBlog("");
    setIniciarSesion("");
  };

  const activeBlog = () => {
    setInicio("");
    setPaquetes("");
    setBlog("active");
    setIniciarSesion("");
  };

  const activeIniciarSesion = () => {
    setInicio("");
    setPaquetes("");
    setBlog("");
    setIniciarSesion("active");
  };

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let url = window.location.href;
    if (currentUser == undefined) {
    } else {
      setUsuario(currentUser);
    }
    console.log("current user " + usuario);
    if (url.includes("login")) {
      setActive("login");
    } else if (url.includes("paquete")) {
      setActive("paquete");
    } else if (url.includes("blog")) {
      setActive("blog");
    } else {
      setActive("");
    }

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
      if (direction === "down") {
        let element;
        element = document.querySelector("#searchLupa");
        element.style.display = "none";
      }
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener

    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  const displayBurgerMenu = () => {
    let element;
    element = document.querySelector("#burgerMenu");
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  const toggleAsideBar = () => {
    let element;
    element = document.querySelector("#aside-main");
    if (element.style.marginLeft === "-100%") {
      element.style.marginLeft = "0";
    } else {
      element.style.marginLeft = "-100%";
    }
  };

  const displayUsuario = () => {
    let element;
    element = document.querySelector("#usuarioInformation");
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  const liLogin = () => {
    if (currentUser === undefined) {
      return (
        <li className="nav-item dropdown">
          <a
            href="javascript:void(0)"
            id="mainNavDocumentation"
            className="nav-link dropdown-toggle nav-link-caret-hide"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span>Iniciar sesión</span>
          </a>
          <div
            aria-labelledby="mainNavDocumentation"
            className="dropdown-menu dropdown-menu-clean dropdown-menu-hover end-0 w-300 dropdown-fadeinup"
          >
            <a
              href="javascript:void(0)"
              onClick={() => navigate("/iniciarsesion")}
              className="dropdown-item py-4 d-flex"
            >
              <span className="flex-none">
                <i className="fa fa-sign-in mr-0 fa-4x" aria-hidden="true" />
              </span>
              <span className="ps-3">
                <span className="d-block mb-1">Inciar sesión</span>
                <small className="d-block text-muted text-wrap">
                  Identificate con tu cuenta existente
                </small>
              </span>
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => navigate("/registrarse")}
              rel="noopener"
              className="dropdown-item py-4 d-flex border-top"
            >
              <span className="flex-none">
                <i className="fa fa-user-plus mr-0 fa-4x" aria-hidden="true" />
              </span>
              <span className="ps-3">
                <span className="d-block mb-1">Registrarse</span>
                <small className="d-block text-muted text-wrap">
                  Crear una nueva cuenta
                </small>
              </span>
            </a>
          </div>
        </li>
      );
    }
  };

  const botonUsuario = () => {
    if (currentUser != undefined) {
      return (
        <>
          {/* inicio */}
          <li className="nav-item dropdown">
            <a
              href="javascript:void(0)"
              id="mainNavPages"
              className="nav-link"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => navigate("/tickets")}
            >
              Mis tickets
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              href="javascript:void(0)"
              id="mainNavPages"
              className="nav-link"
              aria-haspopup="true"
              aria-expanded="false"
              data-bs-toggle="dropdown"
              role="button"
            >
              <i
                className="fa fa-user mr-0"
                aria-hidden="true"
                style={{ marginTop: "2px" }}
              />
              <span
                style={{
                  textDecoration: "overline",
                  textDecorationStyle: "wavy",
                }}
              >
                {currentUser.nombre}
              </span>
            </a>
            <div
              aria-labelledby="mainNavDocumentation"
              className="dropdown-menu dropdown-menu-clean dropdown-menu-hover end-0 w-300 dropdown-fadeinup"
            >
              <a
                className="dropdown-item py-4 d-flex"
                href="javascript:void(0)"
              >
                <span className="flex-none">
                  <i className="fa fa-user mr-0 fa-4x" aria-hidden="true" />
                </span>
                <span className="ps-3">
                  <span className="d-block mb-1">Bienvenido</span>
                  <small className="d-block text-muted text-wrap">
                    {currentUser.nombre}
                  </small>
                </span>
              </a>

              <a
                className="dropdown-item py-4 d-flex border-top"
                onClick={() => salir()}
                href="javascript:void(0)"
              >
                <span className="flex-none">
                  <button
                    className="input-group-text border-0 rounded-200 btn bg-primary"
                    type="submit"
                    onClick={() => salir()}
                  >
                    <span className="text-white text-uppercase font-weight-bold">
                      <i className="fa fa-power-off mr-0" aria-hidden="true" />
                    </span>
                  </button>
                </span>
                <span className="ps-3">
                  <span className="d-block mb-1">Cerrar Sesión</span>
                </span>
              </a>
            </div>
          </li>
        </>
      );
    } else {
      return <div />;
    }
  };
  //#endregion

  return (
    <>
      <>
        <div id="wrapper">
          {/* Header */}
          <header id="header" className="shadow-xs shadow-xs-hover:hover">
            {/* Navbar */}
            <div className="container position-relative">
              <nav className="navbar navbar-expand-lg navbar-light justify-content-lg-between justify-content-md-inherit">
                {/* navbar : brand (logo) */}
                <a
                  className="navbar-brand"
                  href="javascript:void(0)"
                  onClick={() => navigate("/")}
                >
                  <img src={logo2} width={300} height={50} alt="..." />
                </a>
                <div className="align-items-start">
                  {/* mobile menu button : show */}
                  <button
                    className="navbar-toggler border-0 m-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarMainNav"
                    aria-controls="navbarMainNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => toggleAsideBar()}
                  >
                    <svg width={25} viewBox="0 0 20 20">
                      <path d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z" />
                      <path d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z" />
                      <path d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z" />
                      <path d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z" />
                    </svg>
                  </button>
                </div>
                {/* Menu */}
                {/*

        Dropdown Classes (should be added to primary .dropdown-menu only, dropdown childs are also affected)
          .dropdown-menu-dark     - dark dropdown (desktop only, will be white on mobile)
          .dropdown-menu-hover    - open on hover
          .dropdown-menu-clean    - no background color on hover
          .dropdown-menu-invert     - open dropdown in oposite direction (left|right, according to RTL|LTR)
          .dropdown-menu-uppercase  - uppercase text (font-size is scalled down to 13px)
          .dropdown-click-ignore    - keep dropdown open on inside click (useful on forms inside dropdown)

          Repositioning long dropdown childs (Example: Pages->Account)
            .dropdown-menu-up-n100    - open up with top: -100px
            .dropdown-menu-up-n100    - open up with top: -150px
            .dropdown-menu-up-n180    - open up with top: -180px
            .dropdown-menu-up-n220    - open up with top: -220px
            .dropdown-menu-up-n250    - open up with top: -250px
            .dropdown-menu-up       - open up without negative class


          Dropdown prefix icon (optional, if enabled in variables.scss)
            .prefix-link-icon .prefix-icon-dot    - link prefix
            .prefix-link-icon .prefix-icon-line   - link prefix
            .prefix-link-icon .prefix-icon-ico    - link prefix
            .prefix-link-icon .prefix-icon-arrow  - link prefix

          .nav-link.nav-link-caret-hide   - no dropdown icon indicator on main link
          .nav-item.dropdown-mega     - required ONLY on fullwidth mega menu

          Mobile animation - add to .navbar-collapse:
          .navbar-animate-fadein
          .navbar-animate-fadeinup
          .navbar-animate-bouncein

      */}
                <div
                  className="collapse navbar-collapse justify-content-end navbar-animate-fadein"
                  id="navbarMainNav"
                >
                  {/* navbar : mobile menu */}
                  <div className="navbar-xs d-none">
                    {/* .sticky-top */}
                    {/* mobile menu button : close */}
                    <button
                      className="navbar-toggler pt-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarMainNav"
                      aria-controls="navbarMainNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <svg width={20} viewBox="0 0 20 20">
                        <path d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z" />
                      </svg>
                    </button>
                  </div>
                  {/* /navbar : mobile menu */}
                  {/* navbar : navigation */}
                  <ul className="navbar-nav">
                    {/* Menu */}
                    {/*

            Dropdown Classes (should be added to primary .dropdown-menu only, dropdown childs are also affected)
              .dropdown-menu-dark     - dark dropdown (desktop only, will be white on mobile)
              .dropdown-menu-hover    - open on hover
              .dropdown-menu-clean    - no background color on hover
              .dropdown-menu-invert     - open dropdown in oposite direction (left|right, according to RTL|LTR)
              .dropdown-menu-uppercase  - uppercase text (font-size is scalled down to 13px)
              .dropdown-click-ignore    - keep dropdown open on inside click (useful on forms inside dropdown)

              Repositioning long dropdown childs (Example: Pages->Account)
                .dropdown-menu-up-n100    - open up with top: -100px
                .dropdown-menu-up-n100    - open up with top: -150px
                .dropdown-menu-up-n180    - open up with top: -180px
                .dropdown-menu-up-n220    - open up with top: -220px
                .dropdown-menu-up-n250    - open up with top: -250px
                .dropdown-menu-up       - open up without negative class


              Dropdown prefix icon (optional, if enabled in variables.scss)
                .prefix-link-icon .prefix-icon-dot    - link prefix
                .prefix-link-icon .prefix-icon-line   - link prefix
                .prefix-link-icon .prefix-icon-ico    - link prefix
                .prefix-link-icon .prefix-icon-arrow  - link prefix

              .nav-link.nav-link-caret-hide   - no dropdown icon indicator on main link
              .nav-item.dropdown-mega     - required ONLY on fullwidth mega menu

          */}
                    {/* mobile only image + simple search (d-block d-sm-none) */}
                    <li className="nav-item d-block d-sm-none">
                      {/* image */}
                      <div className="mb-4"></div>
                      {/* search */}
                      <form
                        method="get"
                        action="#!search"
                        className="input-group-over mb-4 bg-light p-2 form-control-pill"
                      >
                        <input
                          type="text"
                          name="keyword"
                          defaultValue=""
                          placeholder="Quick search..."
                          className="form-control border-dashed"
                        />
                        <button className="btn btn-sm fi fi-search mx-3" />
                      </form>
                    </li>
                    {/* inicio */}
                    <li className="nav-item active">
                      <a
                        href="javascript:void(0)"
                        id="mainNavHome"
                        className="nav-link"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => navigate("/")}
                      >
                        Inicio
                      </a>
                      {/*<div
                        aria-labelledby="mainNavHome"
                        className="dropdown-menu dropdown-menu-clean dropdown-menu-hover dropdown-mega-md dropdown-fadeinup"
                      >
                       DESPLEGAR  optional line column separator .col-border-lg 
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <ul className="list-unstyled">
                              <li className="dropdown-item">
                                <h3 className="fs-6 text-muted py-3 px-lg-4">
                                  Niche pages
                                </h3>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="niche.restaurant.html"
                                  className="dropdown-link"
                                >
                                  Restaurant
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="niche.caffe.html"
                                  className="dropdown-link"
                                >
                                  Caffe
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="niche.tattoo.html"
                                  className="dropdown-link"
                                >
                                  Tattoo
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="niche.lawyer.html"
                                  className="dropdown-link"
                                >
                                  Lawyer
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="niche.hosting.html"
                                  className="dropdown-link"
                                >
                                  Hosting
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="niche.classifieds.html"
                                  className="dropdown-link"
                                >
                                  Classifieds
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="niche.realestate.html"
                                  className="dropdown-link"
                                >
                                  Real Estate
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a href="#" className="dropdown-link disabled">
                                  More soon
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-lg-6">
                            <ul className="list-unstyled">
                              <li className="dropdown-item">
                                <h3 className="fs-6 text-muted py-3 px-lg-4">
                                  Landing pages
                                </h3>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-0.html"
                                  className="dropdown-link"
                                >
                                  Default
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-1.html"
                                  className="dropdown-link"
                                >
                                  Landing 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-2.html"
                                  className="dropdown-link"
                                >
                                  Landing 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-3.html"
                                  className="dropdown-link"
                                >
                                  Landing 3
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-4.html"
                                  className="dropdown-link"
                                >
                                  Landing 4
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-5.html"
                                  className="dropdown-link"
                                >
                                  Landing 5
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-6.html"
                                  className="dropdown-link"
                                >
                                  Landing 6
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-7.html"
                                  className="dropdown-link"
                                >
                                  Landing 7
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="landing-8.html"
                                  className="dropdown-link"
                                >
                                  Landing 8
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <ul className="list-unstyled">
                          <li className="dropdown-divider" />
                          <li className="dropdown-item pt-2">
                            <a
                              href="index.html"
                              className="dropdown-link text-muted d-flex align-items-center"
                            >
                              <span className="pe-2">All Demos</span>
                              <svg
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="bi bi-arrow-right-short"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>*/}
                    </li>

                    {/* blog */}
                    <li className="nav-item dropdown">
                      <a
                        href="javascript:void(0)"
                        id="mainNavPages"
                        className="nav-link"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => navigate("/Blog")}
                      >
                        Blog
                      </a>
                      {/* <div
                        aria-labelledby="mainNavPages"
                        className="dropdown-menu dropdown-menu-clean dropdown-menu-hover dropdown-fadeinup"
                      >
                        <ul className="list-unstyled m-0 p-0">
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              About
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="about-us-1.html"
                                  className="dropdown-link"
                                >
                                  About Us 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="about-us-2.html"
                                  className="dropdown-link"
                                >
                                  About Us 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="about-us-3.html"
                                  className="dropdown-link"
                                >
                                  About Us 3
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="about-us-4.html"
                                  className="dropdown-link"
                                >
                                  About Us 4
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="about-us-5.html"
                                  className="dropdown-link"
                                >
                                  About Us 5
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="about-me-1.html"
                                  className="dropdown-link"
                                >
                                  About Me 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="about-me-2.html"
                                  className="dropdown-link"
                                >
                                  About Me 2
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Services
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="services-1.html"
                                  className="dropdown-link"
                                >
                                  Services 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="services-2.html"
                                  className="dropdown-link"
                                >
                                  Services 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="services-3.html"
                                  className="dropdown-link"
                                >
                                  Services 3
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="services-4.html"
                                  className="dropdown-link"
                                >
                                  Services 4
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="services-5.html"
                                  className="dropdown-link"
                                >
                                  Services 5
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Contact
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="contact-1.html"
                                  className="dropdown-link"
                                >
                                  Contact 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="contact-2.html"
                                  className="dropdown-link"
                                >
                                  Contact 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="contact-3.html"
                                  className="dropdown-link"
                                >
                                  Contact 3
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="contact-4.html"
                                  className="dropdown-link"
                                >
                                  Contact 4
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Pricing
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="pricing-1.html"
                                  className="dropdown-link"
                                >
                                  Pricing 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="pricing-2.html"
                                  className="dropdown-link"
                                >
                                  Pricing 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="pricing-3.html"
                                  className="dropdown-link"
                                >
                                  Pricing 3
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="pricing-4.html"
                                  className="dropdown-link"
                                >
                                  Pricing 4
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="pricing-5.html"
                                  className="dropdown-link"
                                >
                                  Pricing 5
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              FAQ
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a href="faq-1.html" className="dropdown-link">
                                  FAQ 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a href="faq-2.html" className="dropdown-link">
                                  FAQ 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a href="faq-3.html" className="dropdown-link">
                                  FAQ 3
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a href="faq-4.html" className="dropdown-link">
                                  FAQ 4
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Team
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a href="team-1.html" className="dropdown-link">
                                  Team 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a href="team-2.html" className="dropdown-link">
                                  Team 2
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Account
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="account-index.html"
                                  className="dropdown-link"
                                >
                                  Account pages (12)
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="account-full-signin-1.html"
                                  className="dropdown-link"
                                >
                                  Sign In/Up : Full 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="account-full-signin-2.html"
                                  className="dropdown-link"
                                >
                                  Sign In/Up : Full 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="account-onepage-signin.html"
                                  className="dropdown-link"
                                >
                                  Sign In/Up : Onepage
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="account-simple-signin.html"
                                  className="dropdown-link"
                                >
                                  Sign In/Up : Simple
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="account-modal-signin.html"
                                  className="dropdown-link"
                                >
                                  Sign In/Up : Modal
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Clients / Career
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="clients.html"
                                  className="dropdown-link"
                                >
                                  Clients
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a href="career.html" className="dropdown-link">
                                  Career
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Portfolio
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="portfolio-columns-2.html"
                                  className="dropdown-link"
                                >
                                  2 Columns
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="portfolio-columns-3.html"
                                  className="dropdown-link"
                                >
                                  3 Columns
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="portfolio-columns-4.html"
                                  className="dropdown-link"
                                >
                                  4 Columns
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="portfolio-single-1.html"
                                  className="dropdown-link"
                                >
                                  Single Item 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="portfolio-single-2.html"
                                  className="dropdown-link"
                                >
                                  Single Item 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="portfolio-single-3.html"
                                  className="dropdown-link"
                                >
                                  Single Item 3
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="portfolio-single-4.html"
                                  className="dropdown-link"
                                >
                                  Single Item 4
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Utility
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-up dropdown-menu-block-md shadow-lg border-0 m-0">
                              <li className="dropdown-item">
                                <a href="404-1.html" className="dropdown-link">
                                  Error 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a href="404-2.html" className="dropdown-link">
                                  Error 2
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a href="404-3.html" className="dropdown-link">
                                  Error 3
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="invoice.html"
                                  className="dropdown-link"
                                >
                                  Invoice
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="maintenance-1.html"
                                  className="dropdown-link"
                                >
                                  Maintenance 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="maintenance-2.html"
                                  className="dropdown-link"
                                >
                                  Maintenance 2
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="comingsoon-1.html"
                                  className="dropdown-link"
                                >
                                  Coming Soon 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="comingsoon-2.html"
                                  className="dropdown-link"
                                >
                                  Coming Soon 2
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="page-cookie.html"
                                  className="dropdown-link"
                                >
                                  GDPR Page &amp; Cookie Window
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div> */}
                    </li>

                    {/* servicios */}
                    <li className="nav-item dropdown">
                      <a
                        href="javascript:void(0)"
                        id="mainNavFeatures"
                        className="nav-link"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => navigate("/Servicios")}
                      >
                        Servicios
                      </a>
                      {/*<div
                        aria-labelledby="mainNavFeatures"
                        className="dropdown-menu dropdown-menu-clean dropdown-menu-hover dropdown-fadeinup"
                      >
                        <ul className="list-unstyled m-0 p-0">
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Header
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item dropdown">
                                <a
                                  href="#"
                                  className="dropdown-link fw-bold"
                                  data-bs-toggle="dropdown"
                                >
                                  Variants
                                </a>
                                <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                                  <li className="dropdown-item">
                                    <a
                                      href="header-variant-1.html"
                                      className="dropdown-link"
                                    >
                                      Header : Variant : 1
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="header-variant-2.html"
                                      className="dropdown-link"
                                    >
                                      Header : Variant : 2
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="header-variant-3.html"
                                      className="dropdown-link"
                                    >
                                      Header : Variant : 3
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="header-variant-4.html"
                                      className="dropdown-link"
                                    >
                                      Header : Variant : 4
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="header-variant-5.html"
                                      className="dropdown-link"
                                    >
                                      Header : Variant : 5
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="header-variant-6.html"
                                      className="dropdown-link"
                                    >
                                      Header : Variant : 6
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="header-option-light.html"
                                  className="dropdown-link"
                                >
                                  Header : Light{" "}
                                  <small className="text-muted">
                                    (default)
                                  </small>
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="header-option-dark.html"
                                  className="dropdown-link"
                                >
                                  Header : Dark
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="header-option-color.html"
                                  className="dropdown-link"
                                >
                                  Header : Color
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="header-option-transparent.html"
                                  className="dropdown-link"
                                >
                                  Header : Transparent
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="header-option-centered.html"
                                  className="dropdown-link"
                                >
                                  Header : Centered
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="header-option-bottom.html"
                                  className="dropdown-link"
                                >
                                  Header : Bottom
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="header-option-floating.html"
                                  className="dropdown-link"
                                >
                                  Header : Floating
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="header-option-fixed.html"
                                  className="dropdown-link"
                                >
                                  Header : Fixed
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="header-option-reveal.html"
                                  className="dropdown-link"
                                >
                                  Header : Reveal on Scroll
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="header-option-ajax-search-json.html"
                                  className="dropdown-link"
                                >
                                  Ajax Search : Json
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="header-option-ajax-search-html.html"
                                  className="dropdown-link"
                                >
                                  Ajax Search : Html
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Footer
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item dropdown">
                                <a
                                  href="#"
                                  className="dropdown-link fw-bold"
                                  data-bs-toggle="dropdown"
                                >
                                  Variants
                                </a>
                                <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                                  <li className="dropdown-item">
                                    <a
                                      href="footer-variant-1.html#footer"
                                      className="dropdown-link"
                                    >
                                      Footer : Variant : 1
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="footer-variant-2.html#footer"
                                      className="dropdown-link"
                                    >
                                      Footer : Variant : 2
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="footer-variant-3.html#footer"
                                      className="dropdown-link"
                                    >
                                      Footer : Variant : 3
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="footer-variant-4.html#footer"
                                      className="dropdown-link"
                                    >
                                      Footer : Variant : 4
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="footer-variant-5.html#footer"
                                      className="dropdown-link"
                                    >
                                      Footer : Variant : 5
                                    </a>
                                  </li>
                                  <li className="dropdown-item">
                                    <a
                                      href="footer-variant-6.html#footer"
                                      className="dropdown-link"
                                    >
                                      Footer : Variant : 6
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="footer-option-light.html"
                                  className="dropdown-link"
                                >
                                  Footer : Light
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="footer-option-dark.html"
                                  className="dropdown-link"
                                >
                                  Footer : Dark{" "}
                                  <small className="text-muted">
                                    (default)
                                  </small>
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="footer-option-image.html"
                                  className="dropdown-link"
                                >
                                  Footer : Image
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Sliders
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="slider-swiper.html"
                                  className="dropdown-link"
                                >
                                  Swiper Slider
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Page Title
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="page-title-classic.html"
                                  className="dropdown-link"
                                >
                                  Page Title : Classic
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="page-title-alternate.html"
                                  className="dropdown-link"
                                >
                                  Page Title : Alternate
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="page-title-color.html"
                                  className="dropdown-link"
                                >
                                  Page Title : Color + Nav
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="page-title-clean.html"
                                  className="dropdown-link"
                                >
                                  Page Title : Clean
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="page-title-parallax-1.html"
                                  className="dropdown-link"
                                >
                                  Page Title : Parallax 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="page-title-parallax-2.html"
                                  className="dropdown-link"
                                >
                                  Page Title : Parallax 2
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Sidebar
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="sidebar-float-cart.html"
                                  className="dropdown-link"
                                >
                                  Sidebar : Cart
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="sidebar-float-dark.html"
                                  className="dropdown-link"
                                >
                                  Sidebar : Float : Dark
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="sidebar-float-light.html"
                                  className="dropdown-link"
                                >
                                  Sidebar : Float : Light
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <a
                                  href="sidebar-static-dark.html"
                                  className="dropdown-link"
                                >
                                  Sidebar : Static : Dark
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="sidebar-static-light.html"
                                  className="dropdown-link"
                                >
                                  Sidebar : Static : Light
                                </a>
                              </li>
                              <li className="dropdown-divider" />
                              <li className="dropdown-item">
                                <span className="d-block text-muted py-2 px-4 small fw-bold">
                                  Same as admin
                                </span>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="sidebar-float-admin-color.html"
                                  className="dropdown-link"
                                >
                                  Sidebar : Float
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="sidebar-static-admin-color.html"
                                  className="dropdown-link"
                                >
                                  Sidebar : Static
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href="header-dropdown.html"
                              className="dropdown-link fw-medium"
                            >
                              Menu Dropdowns
                            </a>
                          </li>
                          <li className="dropdown-divider" />
                          <li className="dropdown-item">
                            <a
                              href="layout-boxed-1.html"
                              className="dropdown-link"
                            >
                              Boxed Layout
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href="layout-boxed-0.html"
                              className="dropdown-link"
                            >
                              Boxed + Header Over
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href="layout-boxed-2.html"
                              className="dropdown-link"
                            >
                              Boxed + Background
                            </a>
                          </li>
                        </ul>
                      </div>*/}
                    </li>

                    {/* contacto */}
                    <li className="nav-item dropdown">
                      <a
                        href="javascript:void(0)"
                        id="mainNavBlog"
                        className="nav-link"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => navigate("/contactanos")}
                      >
                        Contacto
                      </a>
                      {/*<div
                        aria-labelledby="mainNavBlog"
                        className="dropdown-menu dropdown-menu-clean dropdown-menu-hover dropdown-fadeinup"
                      >
                        <ul className="list-unstyled m-0 p-0">
                          <li className="dropdown-item">
                            <a
                              className="dropdown-link"
                              href="blog-page-sidebar.html"
                            >
                              With Sidebar
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              className="dropdown-link"
                              href="blog-page-sidebar-no.html"
                            >
                              Without Sidebar
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              className="dropdown-link"
                              href="blog-page-article-sidebar.html"
                            >
                              Article With Sidebar
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              className="dropdown-link"
                              href="blog-page-article-sidebar-no.html"
                            >
                              Article Without Sidebar
                            </a>
                          </li>
                        </ul>
                      </div>*/}
                    </li>
                    {/* demos 
                    <li className="nav-item dropdown active">
                      <a
                        href="#"
                        id="mainNavDemo"
                        className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Demos
                      </a>
                      <div
                        aria-labelledby="mainNavDemo"
                        className="dropdown-menu dropdown-menu-clean dropdown-menu-hover end-0 dropdown-fadeinup"
                      >
                        <ul className="list-unstyled m-0 p-0">
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Admin{" "}
                              <span className="small text-muted">
                                (2 layouts)
                              </span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="../html_admin/index.html"
                                  target="_blank"
                                  rel="noopener"
                                  className="dropdown-link"
                                >
                                  Layout 1
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="../html_admin/layout-2.html"
                                  target="_blank"
                                  rel="noopener"
                                  className="dropdown-link"
                                >
                                  Layout 2
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href="shop-index-1.html"
                              target="_blank"
                              rel="noopener"
                              className="dropdown-link"
                            >
                              Ecommerce{" "}
                              <span className="small text-muted">
                                (44 pages)
                              </span>
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href="niche.realestate.html"
                              target="_blank"
                              rel="noopener"
                              className="dropdown-link"
                            >
                              Real estate{" "}
                              <span className="small text-muted">
                                (5 pages)
                              </span>
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href="niche.classifieds.html"
                              target="_blank"
                              rel="noopener"
                              className="dropdown-link"
                            >
                              Classifieds{" "}
                              <span className="small text-muted">
                                (3 pages)
                              </span>
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href="fullajax-index.html"
                              target="_blank"
                              rel="noopener"
                              className="dropdown-link"
                            >
                              Full Ajax{" "}
                              <span className="small text-muted">
                                (14 pages)
                              </span>
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href="forum-index.html"
                              rel="noopener"
                              className="dropdown-link"
                            >
                              Forum{" "}
                              <span className="small text-muted">
                                (3 pages)
                              </span>
                            </a>
                          </li>
                          <li className="dropdown-item dropdown">
                            <a
                              href="#"
                              className="dropdown-link"
                              data-bs-toggle="dropdown"
                            >
                              Help center{" "}
                              <span className="small text-muted">
                                (2 layouts)
                              </span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg rounded-xl border-0 m-0">
                              <li className="dropdown-item">
                                <a
                                  href="help-center-1-index.html"
                                  className="dropdown-link"
                                >
                                  Layout 1{" "}
                                  <span className="small text-muted">
                                    (2 pages)
                                  </span>
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="help-center-2-index.html"
                                  className="dropdown-link"
                                >
                                  Layout 2{" "}
                                  <span className="small text-muted">
                                    (3 pages)
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>*/}
                    {/* Iniciar sesion */}
                    {/*<li className="nav-item dropdown">
                      <a
                        href="#"
                        id="mainNavDocumentation"
                        className="nav-link dropdown-toggle nav-link-caret-hide"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span>Documentation</span>
                      </a>
                      <div
                        aria-labelledby="mainNavDocumentation"
                        className="dropdown-menu dropdown-menu-clean dropdown-menu-hover end-0 w-300 dropdown-fadeinup"
                      >
                        <a
                          href="documentation/index.html"
                          className="dropdown-item py-4 d-flex"
                        >
                          <span className="flex-none">
                            <svg
                              width={26}
                              height={26}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              className="bi bi-file-earmark-medical"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                            </svg>
                          </span>
                          <span className="ps-3">
                            <span className="d-block mb-1">Documentation</span>
                            <small className="d-block text-muted text-wrap">
                              Your development guide to work with Smarty
                            </small>
                          </span>
                        </a>
                        <a
                          href="__elements.html"
                          target="_blank"
                          rel="noopener"
                          className="dropdown-item py-4 d-flex border-top"
                        >
                          <span className="flex-none">
                            <svg
                              width={26}
                              height={26}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              className="bi bi-layout-wtf"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 1v8H1V1h4zM1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm13 2v5H9V2h5zM9 1a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9zM5 13v2H3v-2h2zm-2-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3zm12-1v2H9v-2h6zm-6-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H9z" />
                            </svg>
                          </span>
                          <span className="ps-3">
                            <span className="d-block mb-1">Elements</span>
                            <small className="d-block text-muted text-wrap">
                              Various uncategorized elements ready to use
                            </small>
                          </span>
                        </a>
                      </div>
                    </li>*/}
                    {/* {liLogin()} */}
                    {botonUsuario()}

                    {/* social icons : mobile only */}
                    <li className="nav-item d-block d-sm-none text-center mb-4">
                      <h3 className="h6 text-muted">Follow Us</h3>
                      {/* facebook */}
                      <a
                        href="javascript:void(0)"
                        className="btn btn-sm btn-facebook transition-hover-top mb-2 rounded-circle text-white"
                        rel="noopener"
                      >
                        <i className="fi fi-social-facebook" />
                      </a>
                      {/* twitter */}
                      <a
                        href="javascript:void(0)"
                        className="btn btn-sm btn-twitter transition-hover-top mb-2 rounded-circle text-white"
                        rel="noopener"
                      >
                        <i className="fi fi-social-twitter" />
                      </a>
                      {/* linkedin */}
                      <a
                        href="javascript:void(0)"
                        className="btn btn-sm btn-linkedin transition-hover-top mb-2 rounded-circle text-white"
                        rel="noopener"
                      >
                        <i className="fi fi-social-linkedin" />
                      </a>
                      {/* youtube */}
                      <a
                        href="javascript:void(0)"
                        className="btn btn-sm btn-youtube transition-hover-top mb-2 rounded-circle text-white"
                        rel="noopener"
                      >
                        <i className="fi fi-social-youtube" />
                      </a>
                    </li>
                    {/* Get Smarty : mobile only (d-block d-sm-none)*/}
                    <li className="nav-item d-block d-sm-none">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="javascript:void(0)"
                        className="btn w-100 btn-primary shadow-none text-white m-0"
                      >
                        Get Smarty
                      </a>
                    </li>
                  </ul>
                  {/* /navbar : navigation */}
                </div>
              </nav>
            </div>
            {/* /Navbar */}
          </header>
          {/* /Header */}
        </div>
        {/* /offcanvas : demos */}
        {/* modal offer */}
        {/*
    <div class="hide js-onload js-ajax-modal" 
  data-href="_ajax/modal_offer.html" 
  data-ajax-modal-delay="1000" 
  data-ajax-modal-size="modal-lg" 
  data-ajax-modal-centered="true" 
  data-ajax-modal-backdrop=""></div>
    */}
      </>
    </>
  );
};

export default HeaderPublicLayout;
