/* React */
import { useNavigate } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";

/* Components & dependencies */
import { Button, TablePagination } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FullPageLoading from "../../FullPageLoading";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

/* Service */
import CarruselService from "../../../services/Carrusel.service";
//import styles from "../layout.module.css"

const CarruselTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [carrusel, setCarrusel] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    loadCarrusel();
  }, [page, limit]);

  const loadCarrusel = async () => {
    setLoading(true);
    try {
      const results = await CarruselService.list(limit, page * limit);
      setCarrusel(results.data);
      setTotal(results.total);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checarEstado = (objeto) => {
    if (objeto.isActive === true) {
      return (
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
          onClick={async () => {
            objeto.isActive = false;
            await CarruselService.update(objeto);
            loadCarrusel();
          }}
          label=""
          style={{ marginLeft: "0.3%" }}
          color="error"
        />
      );
    } else {
      return (
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} />}
          onClick={async () => {
            objeto.isActive = true;
            await CarruselService.update(objeto);
            loadCarrusel();
          }}
          label=""
          style={{ marginLeft: "0.3%" }}
          color="error"
        />
      );
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">CARRUSEL</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Hay {carrusel.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div className="section p-0">
                <div style={{ padding: "1.8rem" }}>
                  <div className="card-header p-4">
                    <div className="row g-3">
                      <div className="order-2 order-md-1 col">
                        <form
                          className="position-relative d-flex align-items-center"
                          method="get"
                          action="#"
                        >
                          {/* <svg
                          className="z-index-1 position-absolute start-0 ms-3 text-primary"
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx={11} cy={11} r={8} />
                          <line x1={21} y1={21} x2="16.65" y2="16.65" />
                        </svg>
                        <div className="dropdown w-100">
                          <input
                            type="text"
                            className="form-control form-control-sm border-0 shadow-none ps-5 bg-gray-100"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            placeholder="Name / email"
                          />
                          <div className="dropdown-menu dropdown-mega-sm shadow-lg p-4 mt-1">
                            <h6 className="mb-4">Filter</h6>
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                id="filter-status"
                                name="status"
                                aria-label="Status"
                              >
                                <option value="" selected="">
                                  Any
                                </option>
                                <option value={1}>Active</option>
                                <option value={0}>Innactive</option>
                                <option value={0}>Banned</option>
                              </select>
                              <label htmlFor="filter-status">Status</label>
                            </div>
                            <div className="mb-3">
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input form-check-input-primary"
                                  type="checkbox"
                                  id="filter-returning"
                                  name="sale"
                                  defaultValue={1}
                                />
                                <label
                                  className="form-check-label user-select-none"
                                  htmlFor="filter-returning"
                                >
                                  Returning
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input form-check-input-primary"
                                  type="checkbox"
                                  id="filter-abandoned"
                                  name="preorder"
                                  defaultValue={1}
                                />
                                <label
                                  className="form-check-label user-select-none"
                                  htmlFor="filter-abandoned"
                                >
                                  Abandoned checkouts
                                </label>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                              <button
                                type="submit"
                                className="btn btn-sm btn-primary"
                              >
                                Apply filter
                              </button>
                              <a
                                href="/product/product-list/"
                                className="btn btn-sm btn-danger  float-end"
                              >
                                Reset
                              </a>
                            </div>
                          </div>
                        </div>*/}
                        </form>
                      </div>
                      {/* options */}
                      <div className="order-1 order-md-2 col-md-auto">
                        <a
                          href="javascript:void(0)"
                          onClick={() => navigate(`nuevo`)}
                          className="w-100 btn btn-sm btn-primary"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1={12} y1={5} x2={12} y2={19} />
                            <line x1={5} y1={12} x2={19} y2={12} />
                          </svg>
                          <span>Nuevo</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* item list */}
                    <div className="table">
                      <table
                        className="table"
                        style={{ overflowX: "auto", width: "300px !important" }}
                      >
                        <thead>
                          <tr>
                            <th
                              className="small text-muted"
                              style={{ width: 100 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by inventory"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2">ACTIVO</span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 450 }}
                            >
                              TÍTULO
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 600 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by inventory"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2">MENSAJE</span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 200 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by inventory"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2">
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  />{" "}
                                  CREACIÓN
                                </span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 200 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by inventory"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2">
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  />{" "}
                                  EDICIÓN
                                </span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 20 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by price"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2"></span>
                              </a>
                            </th>
                            <th
                              className="small text-muted"
                              style={{ width: 20 }}
                            >
                              <a
                                href="javascript:void(0)"
                                className="d-flex link-muted"
                                title="order by price"
                                aria-label="order by inventory"
                              >
                                <span className="ms-2"></span>
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {carrusel.map((record, index) => {
                            return (
                              <tr key={`${record.id}_${record.titulo}`}>
                                <td>
                                  <Switch
                                    checked={record.isActive}
                                    color="error"
                                    onClick={async () => {
                                      record.isActive = !record.isActive;
                                      await CarruselService.update(record);
                                      loadCarrusel();
                                    }}
                                  />{" "}
                                </td>
                                <td className="link-normal fw-medium">
                                  <td>
                                    {record.titulo != null ? (
                                      String(record.titulo).slice(0, 30)
                                    ) : (
                                      <p className="text-muted">Sin título</p>
                                    )}
                                  </td>
                                </td>
                                <td>
                                  {record.subtitulo != null ? (
                                    String(record.subtitulo).slice(0, 25)
                                  ) : (
                                    <p className="text-muted">Sin mensaje</p>
                                  )}
                                </td>

                                <td>
                                  <i
                                    className="fa fa-calendar fa-1"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.createdAt).slice(0, 10)}
                                  <br />{" "}
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.createdAt).slice(11, 16)}
                                </td>
                                <td>
                                  <i
                                    className="fa fa-calendar fa-1"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.updateAt).slice(0, 10)}
                                  <br />{" "}
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.updateAt).slice(11, 16)}
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-sm link-normal"
                                  >
                                    <span
                                      onClick={() =>
                                        navigate(`editar/${record.id}`)
                                      }
                                      className="material-icons-sharp"
                                    >
                                      {" "}
                                      edit{" "}
                                    </span>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-sm link-normal"
                                  >
                                    <span
                                      className="material-icons-sharp"
                                      onClick={() =>
                                        navigate(`eliminar/${record.id}`)
                                      }
                                    >
                                      {" "}
                                      delete{" "}
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* pagination, selected items */}
                    <div className="row">
                      <div className="col py-3 text-center text-md-start">
                        {/* selected items 
                      <div className="dropup">
                        <a
                          className="btn btn-sm btn-primary"
                          href="#"
                          role="button"
                          id="ddSelected"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="group-icon">
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1={18} y1={6} x2={6} y2={18} />
                              <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>
                          </span>
                          <span>Selected items</span>
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-clean my-2"
                          aria-labelledby="ddSelected"
                        >
                          <li>
                            <a className="dropdown-item" href="#form-list">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                              <span>Active</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Innactive</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Banned</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-danger"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6" />
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                <line x1={10} y1={11} x2={10} y2={17} />
                                <line x1={14} y1={11} x2={14} y2={17} />
                              </svg>
                              <span className="w-100">Delete</span>
                            </a>
                          </li>
                        </ul>
                      </div>*/}
                      </div>
                      <div className="col-md-auto py-3">
                        {/* pagination */}
                        <nav
                          className="w-100 text-center"
                          aria-label="Pagination"
                        >
                          {/* pagination : desktop */}
                          <nav
                            className="text-center float-md-end mt-3 mt-md-0 d-none d-md-flex"
                            aria-label="Pagination"
                          >
                            <TablePagination
                              style={{ color: "var(--color-dark-variant)" }}
                              component="div"
                              classes="recent-orders"
                              labelRowsPerPage="Items por pagina"
                              count={total}
                              page={page}
                              onPageChange={handleChangePage}
                              rowsPerPage={limit}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </nav>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CarruselTable;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
