/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./Document/Document";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import BuscarUsuario from "./BuscarUsuario";
import Swal from "sweetalert2";

/* Servicios */
import TicketsService from "../../../services/Tickets.service";
import TicketsSalidaService from "../../../services/TicketsSalida.service";
import ConfiguracionService from "../../../services/Configuracion.service";
import ReportesService from "../../../services/Reportes.service";

import { FormatDate } from "../../utils/Format";
import { Grid, TextField, Typography } from "@mui/material";
import BuscarResponsable from "./BuscarResponsable";
import HistoryIcon from "@mui/icons-material/History";
import CortesService from "../../../services/Corte.service";
import { usePrint } from "../../../hooks/usePrint";

const TablaCorte = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [result, setResult] = useState("");

  const [tickets, setTickets] = useState([]);
  const [ticketsSalida, setTicketsSalida] = useState([]);
  const [ticketsPagos, setTicketsPagos] = useState([]);

  const [ingresos, setIngresos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [ingresosPagos, setIngresosPagos] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });
  const [egresos, setEgresoss] = useState({
    efectivo: 0,
    tarjeta: 0,
    transferencia: 0,
  });

  const [totales, setTotales] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesPago, setTotalesPago] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalesSalida, setTotalesSalida] = useState({
    ordenes: 0,
    saldo: 0,
  });

  const [totalIngresos, setTotalIngresos] = useState(0);
  const [totalIngresosPagos, setTotalIngresosPagos] = useState(0);
  const [totalEgresos, setTotalEgresos] = useState(0);

  const [pagos, setPagos] = useState([]);
  const [pagosPagos, setPagosPagos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [pagoclientes, setPagoclientes] = useState(0);
  const [pagoclientesPagos, setPagoclientesPagos] = useState(0);

  const [cortes, setCortes] = useState([]);
  const [corte, setCorte] = useState(null);
  const [update, setUpdate] = useState(false);
  const newdate = new Date();
  const [responsableServicio, setResponsableServicio] = useState(null);
  const [responsableTurno, setResponsableTurno] = useState(null);
  const [responsableEntrega, setResponsableEntrega] = useState(null);

  const today = new Date();
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [dejoCambio, setDejoCambio] = useState("");
  const [dejoSobre, setDejoSobre] = useState("");
  const [dejoSdia, setDejoSdia] = useState("");

  const [reciboCambio, setReciboCambio] = useState("0");
  const [reciboSobre, setReciboSobre] = useState("0");
  const [reciboSdia, setReciboSdia] = useState("0");

  const [jornada, setJornada] = useState(null);

  const [waitingPrint, setWaitingPrint] = useState(false);
  const { printUrl, printIsAuto } = usePrint();

  const onUpdate = () => {
    setUpdate(!update);
  };

  const handleResponsableServicio = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableServicio(changecliente);
  };

  const handleResponsalbeTurno = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableTurno(changecliente);
    setResponsableEntrega(changecliente);
  };

  const handleResponsalbeEntrega = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableEntrega(changecliente);
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return newdate
      .toLocaleString("es-ES", options)
      .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
  };

  const formatDateCrate = (date) => {
    if (!date) {
      return "00/00/0000";
    }

    const newdate = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return newdate
      .toLocaleString("es-ES", options)
      .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
  };

  const enviarImpresion = async (uuid) => {
    if (waitingPrint) {
      return;
    }

    setWaitingPrint(true);
    try {
      let newTicket = {
        fecha: today.toISOString(),
      };
      const res = await ReportesService.printCorte(newTicket);
      printUrl(res.url);
    } catch (error) {
      toast.error("Error. No enviado!");
    } finally {
      setWaitingPrint(false);
    }
  };

  const impresionManual = async (url) => {
    printUrl(url);
  };

  useEffect(() => {
    setLoading(true);

    Promise.all([
      getTicket(),
      getTicketPago(),
      getTicketSalida(),
      getPagos(),
      getPagosPagos(),
      getJornada(),
    ]).finally(() => {
      setLoading(false);
    });
  }, [fechaEntrega, update]);

  const getTicket = async () => {
    try {
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsService.listCorte({
        fecha: today.toISOString(),
      });
      setTickets(data.data);
      setCortes(data.cortes);

      for (const p of data.data) {
        ordenessaldo += Number(p.total) - Number(p.pagado);
        ordenestotal += Number(p.total);
      }
      sumarTotales(ordenestotal, ordenessaldo);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTicketPago = async () => {
    try {
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsService.listCortePagos({
        fecha: today.toISOString(),
      });
      setTicketsPagos(data.data);

      for (const p of data.data) {
        ordenessaldo += Number(p.total) - Number(p.pagado);
        ordenestotal += Number(p.total);
      }

      sumarTotalesPago(ordenestotal, ordenessaldo);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTicketSalida = async () => {
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      let ordenestotal = 0;
      let ordenessaldo = 0;
      const data = await TicketsSalidaService.listCorte({
        fecha: today.toISOString(),
      });
      console.log("data salida -----------------");
      console.log(data);
      setTicketsSalida(data.data);

      for (const p of data.data) {
        ordenessaldo += Number(p.total) - Number(p.pagado);
        ordenestotal += Number(p.total);

        if (p.pagos[0].metodopago === "Efectivo") {
          efectivo += Number(p.pagos[0].precio);
        } else if (p.pagos[0].metodopago === "Tarjeta") {
          tarjeta += Number(p.pagos[0].precio);
        } else if (p.pagos[0].metodopago === "Transferencia") {
          transferencia += Number(p.pagos[0].precio);
        }
      }
      let newEgresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newEgresos);
      setEgresoss(newEgresos);
      setTotalEgresos(efectivo + tarjeta + transferencia);
      setTotalesSalida({ ordenes: ordenestotal, saldo: ordenessaldo });
    } catch (error) {
      toast.error(error);
    }
  };

  const getPagos = async () => {
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await TicketsService.listPagoFecha({
        fecha: today.toISOString(),
      });
      console.log(data);
      setPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresos(newIngresos);
      setPagoclientes(efectivo + tarjeta + transferencia);
      setTotalIngresos(efectivo + tarjeta + transferencia);
    } catch (error) {
      toast.error(error);
    }
  };

  const getPagosPagos = async () => {
    try {
      let efectivo = 0;
      let tarjeta = 0;
      let transferencia = 0;
      const data = await TicketsService.listPagoFechaPagos({
        fecha: today.toISOString(),
      });
      console.log(data);
      setPagosPagos(data.data);
      for (const p of data.data) {
        if (p.metodopago === "Efectivo") {
          efectivo += Number(p.precio);
        } else if (p.metodopago === "Tarjeta") {
          tarjeta += Number(p.precio);
        } else if (p.metodopago === "Transferencia") {
          transferencia += Number(p.precio);
        }
      }
      let newIngresos = {
        efectivo: efectivo,
        tarjeta: tarjeta,
        transferencia: transferencia,
      };
      console.log(newIngresos);
      setIngresosPagos(newIngresos);
      setPagoclientesPagos(efectivo + tarjeta + transferencia);
      setTotalIngresosPagos(efectivo + tarjeta + transferencia);
    } catch (error) {
      toast.error(error);
    }
  };

  const totalTicket = (totalt) => {
    if (totalt > 0) {
      return <span class="d-block text-danger">${totalt.toFixed(2)}</span>;
    }
    return <span class="d-block text-success">${totalt.toFixed(2)}</span>;
  };

  const save = async () => {
    try {
      // if (!responsableServicio?.id) {
      //   toast.info("Selecciona un responsable de servicio.");
      //   return;
      // }
      if (!responsableEntrega?.id) {
        toast.info("Selecciona recibe turno.");
        return;
      }
      // if (!responsableTurno?.id) {
      //   toast.info("Selecciona un responsable de turno.");
      //   return;
      // }
      if (!dejoCambio) {
        toast.info("Falto agregar en cambio.");
        return;
      }
      if (!dejoSobre) {
        toast.info("Falto agregar en sobre.");
        return;
      }
      if (!dejoSdia) {
        toast.info("Falto agregar en sobre dia.");
        return;
      }

      if (!reciboCambio) {
        toast.info("Falto agregar recibo cambio.");
        return;
      }
      if (!reciboSobre) {
        toast.info("Falto agregar recibo sobre.");
        return;
      }
      if (!reciboSdia) {
        toast.info("Falto agregar recibo sobre dia.");
        return;
      }

      const newCorte = {
        fecha: today.toISOString(),
        tickets: tickets.length,
        ingresos: ingresos,
        totalesingresos: pagoclientes + totalIngresosPagos,
        egresos: egresos,
        totalesegresos: totalEgresos,
        pagosClientes: pagoclientes,
        usuario: responsableTurno.id,
        dejoCambio: dejoCambio,
        dejoSobre: dejoSobre,
        dejoSdia: dejoSdia,
        reciboCambio: reciboCambio,
        reciboSobre: reciboSobre,
        reciboSdia: reciboSdia,
        responsableTurno: responsableTurno,
      };

      if (responsableEntrega) {
        newCorte.responsableEntrega = responsableEntrega;
      }

      await Swal.fire({
        title: `<i class="fa fa-user fa-5x" aria-hidden="true"></i>`,
        input: "password",
        inputPlaceholder: "Contraseña",
        inputAttributes: {
          maxlength: "18",
          autocapitalize: "off",
          autocorrect: "off",
        },
        confirmButtonColor: "#9b242c",
        iconColor: "#9b242c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          newCorte.contrasena = result.value;
          await TicketsService.registrarCorte(newCorte);
          toast.success("Corte registrado");
          setReciboCambio("0");
          setReciboSobre("0");
          setReciboSdia("0");
          onUpdate();
        }
      });
    } catch (error) {
      toast.error("Error. No se ha registrado!");
    } finally {
      setLoading(false);
    }
  };

  const metodosPago = (listaMetodos) => {
    let sumaPagos = {
      Tarjeta: 0,
      Transferencia: 0,
      Efectivo: 0,
    };

    listaMetodos.map((metodoPago) => {
      sumaPagos[metodoPago.metodopago] += Number(metodoPago.precio);
    });

    return (
      <>
        <td
          class="text-end"
          style={sumaPagos.Efectivo > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Efectivo}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Transferencia > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Transferencia}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Tarjeta > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Tarjeta}
        </td>
      </>
    );
  };

  const sumarTotales = (ordentotal, ordensaldo) => {
    setTotales({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };
  const sumarTotalesPago = (ordentotal, ordensaldo) => {
    setTotalesPago({
      ordenes: ordentotal,
      saldo: ordensaldo,
    });
  };

  const metodosPagoSalida = (ordenmetodopago, monto) => {
    let sumaPagos = {
      Tarjeta: 0,
      Transferencia: 0,
      Efectivo: 0,
    };

    sumaPagos[ordenmetodopago] += Number(monto);

    return (
      <>
        <td
          class="text-end"
          style={sumaPagos.Efectivo > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Efectivo}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Transferencia > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Transferencia}
        </td>
        <td
          class="text-end"
          style={sumaPagos.Tarjeta > 0 ? { color: "#9b242c" } : null}
        >
          ${sumaPagos.Tarjeta}
        </td>
      </>
    );
  };

  const getJornada = async () => {
    try {
      const data = await CortesService.revisarJornada();
      if (data && data.data[0]) {
        setJornada(data.data[0]);
        setResponsableTurno(data.data[0].responsableInicio);
        setReciboCambio(data.data[0].reciboCambio);
        setReciboSobre(data.data[0].reciboSobre);
        setReciboSdia(data.data[0].reciboSdia);
        setResponsableEntrega(data.data[0].responsableInicio);
      } else {
        setJornada(null);
      }
    } catch (error) {
      console.log(error);
      toast.error("Datos de jornada no recuperados");
    }
  };

  const iniciarJornada = async () => {
    try {
      if (!reciboCambio) {
        toast.info("Falto agregar recibo cambio.");
        return;
      }
      if (!reciboSobre) {
        toast.info("Falto agregar recibo sobre.");
        return;
      }
      if (!reciboSdia) {
        toast.info("Falto agregar recibo sobre dia.");
        return;
      }

      if (!responsableTurno?.id) {
        toast.info("Selecciona un responsable de turno.");
        return;
      }

      if (!result) {
        toast.info("Contraseña vacía.");
        return;
      }

      let dataJornada = {
        fechaInicio: today.toISOString(),
        contrasena: result,
        reciboCambio: reciboCambio,
        reciboSobre: reciboSobre,
        reciboSdia: reciboSdia,
        usuario: responsableTurno.id,
        responsableTurno: responsableTurno,
      };
      const data = await CortesService.iniciarJornada(dataJornada);
      if (data && data.data) {
        setJornada(data.data);
      }
      toast.success("Jornada iniciada");
    } catch (error) {
      toast.error(
        error?.message ? error.message : "Datos de jornada no recuperados"
      );
    } finally {
      setResult("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    iniciarJornada();
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <main id="middle" className="flex-fill" style={{ maxWidth: "100%" }}>
            <Grid container className="section p-0 card border-0">
              <Grid container>
                <Grid sm={12} md={12} padding={2}>
                  <Typography className="fw-bold text-muted">
                    {/* {FormatDate(fechaEntrega)} */}
                  </Typography>
                  <Typography className="fw-bold text-muted"></Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div class="g-2 mb-3" style={{ width: "100%" }}>
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div
                    class="card border-0 h-100"
                    style={{ overflowX: "auto" }}
                  >
                    <div class="card-header px-4 border-bottom-0 fw-bold text-muted">
                      ENTREGADOS
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted">CLIENTE</th>
                              <th class="small text-muted">EMISOR</th>
                              <th class="small text-muted text-center">
                                EFECTIVO
                              </th>
                              <th class="small text-muted text-center">
                                TRANSFERENCIA
                              </th>
                              <th class="small text-muted text-center">
                                TARJETA
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center">
                                ESTADO
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {tickets?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.fechaRecepcion)}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {product.cliente.nombre}
                                  </td>
                                  <td class="text-center">
                                    {product.responsableservicio.nombre}
                                  </td>
                                  {metodosPago(product.pagos)}
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span class="d-block">
                                      ${product.total}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span
                                      class="badge rounded-pill"
                                      style={
                                        product.estadoEntrega === "Entregado"
                                          ? { color: "#9b242c" }
                                          : { color: "#506690" }
                                      }
                                    >
                                      {product.estadoEntrega}
                                    </span>
                                  </td>

                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/detalles/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL ORDENES
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotal">
                              <td></td>
                              <td class="text-center"></td>
                              <td class="text-center"></td>
                              <td class="text-end">${ingresos.efectivo}</td>
                              <td class="text-end">
                                ${ingresos.transferencia}
                              </td>
                              <td class="text-end">${ingresos.tarjeta}</td>
                              {/* <td class="text-center">
                                  {formatDateCrate(product.fecha)}
                                </td> */}
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block">${totales.ordenes}</span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">${pagoclientes}</span>
                              </td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid container>
              <div class="g-2 mb-3" style={{ width: "100%" }}>
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div
                    class="card border-0 h-100"
                    style={{ overflowX: "auto" }}
                  >
                    <div class="card-header px-4 border-bottom-0 fw-bold text-muted">
                      PAGOS POR ADELANTADO
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted">CLIENTE</th>
                              <th class="small text-muted">EMISOR</th>
                              <th class="small text-muted text-center">
                                EFECTIVO
                              </th>
                              <th class="small text-muted text-center">
                                TRANSFERENCIA
                              </th>
                              <th class="small text-muted text-center">
                                TARJETA
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SALDO
                              </th>
                              <th class="small text-muted text-center">
                                ESTADO
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {ticketsPagos?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.fechaRecepcion)}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {product.cliente.nombre}
                                  </td>
                                  <td class="text-center">
                                    {product.responsableservicio.nombre}
                                  </td>
                                  {metodosPago(product.pagos)}
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span class="d-block">
                                      ${product.total}
                                    </span>
                                  </td>
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    {totalTicket(
                                      product.total - product.pagado
                                    )}
                                    <span class="badge bg-secondary rounded-pill">
                                      {/* {product.metodopago} */}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span
                                      class="badge rounded-pill"
                                      style={
                                        product.estadoEntrega === "Entregado"
                                          ? { color: "#9b242c" }
                                          : { color: "#506690" }
                                      }
                                    >
                                      {product.estadoEntrega}
                                    </span>
                                  </td>

                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/detalles/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL ORDENES
                              </th>
                              <th class="small text-muted text-center">
                                SALDO
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotal">
                              <td></td>
                              <td class="text-center"></td>
                              <td class="text-center"></td>
                              <td class="text-end">
                                ${ingresosPagos.efectivo}
                              </td>
                              <td class="text-end">
                                ${ingresosPagos.transferencia}
                              </td>
                              <td class="text-end">${ingresosPagos.tarjeta}</td>
                              {/* <td class="text-center">
                                  {formatDateCrate(product.fecha)}
                                </td> */}
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block">
                                  ${totalesPago.ordenes}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                ${totalesPago.saldo.toFixed(2)}
                                <span class="badge bg-secondary rounded-pill">
                                  {/* {product.metodopago} */}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">
                                  ${totalIngresosPagos}
                                </span>
                              </td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid container>
              {/* Tickets salida */}
              {/* <!-- Ticket items --> */}
              <div class="g-2 mb-3" style={{ width: "100%" }}>
                <div class="col">
                  {/* <!-- Order items --> */}
                  <div
                    class="card border-0 h-100"
                    style={{ overflowX: "auto" }}
                  >
                    <div class="card-header px-4 border-bottom-0 fw-bold">
                      GASTOS
                    </div>
                    <div class="card-body px-4 h-100">
                      <div class="table-responsive-md mb-3">
                        <table
                          key="tableingresos"
                          class="table table-align-middle mb-0"
                          role="grid"
                        >
                          <thead key="headertableingresos">
                            <tr>
                              <th class="small text-muted">ORDEN</th>
                              <th class="small text-muted">EMISOR</th>
                              <th class="small text-muted text-center">
                                EFECTIVO
                              </th>
                              <th class="small text-muted text-center">
                                TRANSFERENCIA
                              </th>
                              <th class="small text-muted text-center">
                                TARJETA
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                          </thead>
                          <tbody id="checkall-list">
                            {ticketsSalida?.map((product, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      class="link-normal fw-medium"
                                    >
                                      {product.uuid}
                                    </a>
                                    <span class="d-block text-muted small">
                                      {formatDateCrate(product.fecha)}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    {product.responsableservicio.nombre}
                                  </td>
                                  {metodosPagoSalida(
                                    product.pagos[0].metodopago,
                                    product.pagos[0].precio
                                  )}
                                  <td class="text-end">
                                    {/* <!-- unit price --> */}
                                    {/* <del class="d-block text-muted">$50.00</del> */}
                                    <span class="d-block">
                                      ${product.total}
                                    </span>
                                  </td>
                                  <td class="dropstart text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        navigate(
                                          `/ticket/salida/${product.uuid}`
                                        )
                                      }
                                    >
                                      Ver
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                              <th class="small text-muted"></th>
                              <th class="small text-muted"></th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                SUBTOTAL
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL ORDENES
                              </th>
                              <th class="small text-muted text-center">
                                TOTAL
                              </th>
                              <th class="small text-muted text-center"></th>
                            </tr>
                            <tr key="indextotalegresos">
                              <td class="text-center"></td>
                              <td class="text-center"></td>
                              <td class="text-end">${egresos.efectivo}</td>
                              <td class="text-end">${egresos.transferencia}</td>
                              <td class="text-end">${egresos.tarjeta}</td>
                              {/* <td class="text-center">
                                  {formatDateCrate(product.fecha)}
                                </td> */}
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="d-block">
                                  ${totalesSalida.ordenes}
                                </span>
                              </td>
                              <td class="text-end">
                                {/* <!-- unit price --> */}
                                {/* <del class="d-block text-muted">$50.00</del> */}
                                <span class="text-muted">
                                  ${totalesSalida.ordenes - totalesSalida.saldo}
                                </span>
                              </td>

                              <td class="dropstart text-end"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <div class="col-12 card border-0 mb-0">
              <div>
                <Grid container className="card-header d-flex gap-3">
                  <span className="fw-bold text-muted">Fecha de corte:</span>
                  <span className="text-muted">
                    {FormatDate(today.toString())}
                  </span>
                </Grid>
              </div>
            </div>
            <div></div>
            {jornada ? (
              <div className="row g-2">
                <div class="col-xl-4">
                  <div class="card border-0 h-100 p-4">
                    {/*User*/}
                    <div class=" mb-3">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <span class="w-100">Registro del turno</span>
                      </div>
                      <div class="d-flex align-items-center mb-3 small">
                        {/* <!-- customer details --> */}
                        <div class="w-100 ps-4">
                          <ul class="list-unstyled m-0 p-0">
                            <p class="list-item m-0">
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted fw-bold"
                                  style={{ width: "140px" }}
                                >
                                  Recibo en cambio
                                </span>
                                <span>${reciboCambio}</span>
                              </div>
                            </p>
                            <p class="list-item m-0">
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted fw-bold"
                                  style={{ width: "140px" }}
                                >
                                  Recibo en sobre
                                </span>
                                <span>${reciboSobre}</span>
                              </div>
                            </p>
                            <p class="list-item">
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted fw-bold"
                                  style={{ width: "170px" }}
                                >
                                  Recibo en sobre del dia
                                </span>
                                <span>${reciboSdia}</span>
                              </div>
                            </p>
                            <li class="list-item">
                              {/* <!-- customer name, orders --> */}

                              <a
                                class="text-decoration-none d-block"
                                href="javascript:void(0)"
                              >
                                {jornada?.responsableInicio?.nombre
                                  ? jornada?.responsableInicio?.nombre
                                  : "N/A"}
                              </a>
                              <p class="link-muted small">
                                RESPONSABLE DEL TURNO
                              </p>
                            </li>
                            <li class="list-item">
                              {/* <!-- customer name, orders --> */}

                              <a
                                class="text-decoration-none d-block"
                                href="javascript:void(0)"
                              >
                                {formatDateCrate(jornada?.fechaInicio)}
                              </a>
                              <p class="link-muted small">INICIO JORNADA</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- side --> */}
                <div class="col-xl-5">
                  <div class="card border-0 h-100 p-4">
                    {/*User*/}
                    <div class=" mb-3">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <span class="w-100">Totales</span>
                      </div>
                      <div class="d-flex align-items-center">
                        {/* <!-- customer details --> */}
                        <div class="w-100 ps-4">
                          <div class="d-flex align-items-center">
                            <div class="small" style={{ width: "100%" }}>
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted"
                                  style={{ width: "140px" }}
                                >
                                  Total entregas
                                </span>
                                <span>${pagoclientes}</span>
                              </div>
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted"
                                  style={{ width: "140px" }}
                                >
                                  Total PXA
                                </span>
                                <span>+ ${totalIngresosPagos}</span>
                              </div>
                              <div className="hr my-2"></div>
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted fw-bold"
                                  style={{ width: "140px" }}
                                >
                                  Total ingreso
                                </span>
                                <span className="fw-bold">
                                  ${pagoclientes + totalIngresosPagos}
                                </span>
                              </div>
                              <div class="d-flex justify-content-between">
                                <span class="flex-none text-muted">
                                  Total transferencia
                                </span>
                                <span>
                                  - $
                                  {ingresosPagos.transferencia +
                                    ingresos.transferencia}
                                </span>
                              </div>
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted"
                                  style={{ width: "140px" }}
                                >
                                  Total tarjeta
                                </span>
                                <span>
                                  - ${ingresosPagos.tarjeta + ingresos.tarjeta}
                                </span>
                              </div>
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted"
                                  style={{ width: "140px" }}
                                >
                                  Total GASTOS
                                </span>
                                <span>- ${totalEgresos}</span>
                              </div>
                              <div className="hr my-2"></div>
                              <div class="d-flex justify-content-between">
                                <span
                                  class="flex-none text-muted fw-bold"
                                  style={{ width: "140px" }}
                                >
                                  Total del dia en efectivo
                                </span>
                                <span className="fw-bold">
                                  $
                                  {(
                                    pagoclientes +
                                    totalIngresosPagos -
                                    ingresosPagos.transferencia -
                                    ingresosPagos.tarjeta -
                                    ingresos.transferencia -
                                    ingresos.tarjeta -
                                    totalEgresos
                                  ).toFixed(2)}
                                </span>
                              </div>
                              <p
                                class="list-item"
                                style={{ marginTop: "1.5rem" }}
                              >
                                {/* <!-- customer name, orders --> */}
                                <a
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                >
                                  <TextField
                                    fullWidth
                                    disabled={corte?.dejoCambio}
                                    label="Dejo en cambio"
                                    onChange={(event) =>
                                      setDejoCambio(event.target.value)
                                    }
                                    type="number"
                                    value={dejoCambio}
                                  />
                                </a>
                                {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                              </p>
                              <p class="list-item">
                                {/* <!-- customer name, orders --> */}
                                <a
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                >
                                  <TextField
                                    fullWidth
                                    disabled={corte?.dejoSobre}
                                    label="Dejo en sobre"
                                    onChange={(event) =>
                                      setDejoSobre(event.target.value)
                                    }
                                    type="number"
                                    value={dejoSobre}
                                  />
                                </a>
                                {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                              </p>
                              <p class="list-item">
                                {/* <!-- customer name, orders --> */}
                                <a
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                >
                                  <TextField
                                    fullWidth
                                    disabled={corte?.dejoSdia}
                                    label="Dejo en sobre del dia"
                                    onChange={(event) =>
                                      setDejoSdia(event.target.value)
                                    }
                                    type="number"
                                    value={dejoSdia}
                                  />
                                </a>
                                {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        {/* <!-- customer details --> */}
                        <div class="w-100 ps-4">
                          <ul class="list-unstyled m-0 p-0">
                            {corte ? null : (
                              <>
                                <li class="list-item pt-4">
                                  {/* <!-- customer name, orders --> */}
                                  <button
                                    className="btn btn-primary w-100 text-uppercase"
                                    onClick={() => save()}
                                  >
                                    <svg
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    {"  "}
                                    <span className="small">
                                      FINALIZAR TURNO
                                    </span>
                                  </button>
                                </li>
                                <li>
                                  <small>
                                    Hora: {formatDate(today.toISOString())}
                                  </small>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-3">
                  <div class="card border-0 h-100 p-4">
                    {/*User*/}
                    <div class=" mb-3">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <span class="w-100">Opciones</span>
                      </div>
                      <div class="d-flex align-items-center">
                        {/* <!-- customer details --> */}
                        <div class="w-100">
                          <ul class="list-unstyled m-0 p-0">
                            <li class="list-item">
                              {printIsAuto ? (
                                <a
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                  role="button"
                                  onClick={() => enviarImpresion()}
                                >
                                  <PrintIcon fontSize="small" />
                                  <span class="d-sm-inline-block ps-2">
                                    Imprime
                                  </span>
                                </a>
                              ) : (
                                <a
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                >
                                  <PrintIcon fontSize="small" />
                                  <PDFDownloadLink
                                    document={<Document fecha={fechaEntrega} />}
                                    fileName={`LET_ORDEN_${newdate.toLocaleDateString()}.pdf`}
                                    style={{ padding: "0.5rem" }}
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? (
                                        "Cargando..."
                                      ) : (
                                        <a
                                          className="text-decoration-none"
                                          href="javascript:void(0);"
                                          onClick={(event) => {
                                            event.preventDefault();
                                            impresionManual(url);
                                          }}
                                        >
                                          <span
                                            style={{ marginInline: "0.5rem" }}
                                          >
                                            Imprime
                                          </span>
                                        </a>
                                      )
                                    }
                                  </PDFDownloadLink>
                                </a>
                              )}
                            </li>
                            <li class="list-item">
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                                role="button"
                              >
                                <PictureAsPdfIcon fontSize="small" />
                                <span class="d-sm-inline-block ps-2">
                                  <PDFDownloadLink
                                    document={<Document fecha={fechaEntrega} />}
                                    fileName={`LET_CORTE_${newdate.toLocaleDateString()}.pdf`}
                                    style={{ padding: "0.5rem" }}
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? "Cargando..." : "Descargar PDF"
                                    }
                                  </PDFDownloadLink>
                                </span>
                              </a>
                            </li>
                            <li class="list-item">
                              <span className="p-4"></span>
                            </li>
                            <li class="list-item">
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                                role="button"
                                onClick={() => navigate("/cortes/historial")}
                              >
                                <HistoryIcon fontSize="small" />
                                <span class="d-sm-inline-block ps-2">
                                  HISTORIAL DE CORTES
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row g-2">
                {/* <!-- side --> */}
                <div class="col-xl-4">
                  <div class="card border-0 h-100 p-4">
                    {/*User*/}
                    <div class=" mb-3">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <span class="w-100">Jornada</span>
                      </div>
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <span class="w-100">
                          <ul class="list-unstyled m-0 p-0">
                            <p
                              class="list-item"
                              style={{ marginTop: "1.5rem" }}
                            >
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <TextField
                                  fullWidth
                                  disabled={corte?.reciboCambio}
                                  label="Recibo en cambio"
                                  onChange={(event) =>
                                    setReciboCambio(event.target.value)
                                  }
                                  type="number"
                                  value={reciboCambio}
                                />
                              </a>
                              {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                            </p>
                            <p class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <TextField
                                  fullWidth
                                  disabled={corte?.reciboSobre}
                                  label="Recibo en sobre"
                                  onChange={(event) =>
                                    setReciboSobre(event.target.value)
                                  }
                                  type="number"
                                  value={reciboSobre}
                                />
                              </a>
                              {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                            </p>
                            <p class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <TextField
                                  fullWidth
                                  disabled={corte?.reciboSdia}
                                  label="Recibo en sobre del dia"
                                  onChange={(event) =>
                                    setReciboSdia(event.target.value)
                                  }
                                  type="number"
                                  value={reciboSdia}
                                />
                              </a>
                              {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                            </p>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="card border-0 h-100 p-4">
                    {/*User*/}
                    <div class=" mb-3">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <span class="w-100 p-4"></span>
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        {/* <!-- customer details --> */}
                        <div class="w-100 ps-4">
                          <ul class="list-unstyled m-0 p-0">
                            <li class="list-item">
                              {/* <!-- customer name, orders --> */}
                              <a
                                class="text-decoration-none"
                                href="javascript:void(0)"
                              >
                                <BuscarResponsable
                                  onSumit={handleResponsalbeTurno}
                                  responsableServicio={responsableTurno}
                                  label="RESPONSABLE TURNO:"
                                />
                              </a>
                              {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                            </li>
                            <li>
                              <p
                                class="list-item"
                                style={{ marginTop: "1.5rem" }}
                              >
                                {/* <!-- customer name, orders --> */}
                                <a
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                ></a>
                                {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                              </p>
                              <p class="list-item">
                                <label className="text-muted">Contraseña</label>
                                <input
                                  maxlength="18"
                                  autocapitalize="off"
                                  autocorrect="off"
                                  class="swal2-input"
                                  placeholder="Contraseña"
                                  type="password"
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    margin: "0",
                                  }}
                                  value={result}
                                  onChange={(event) =>
                                    setResult(event.target.value)
                                  }
                                  onKeyDown={handleKeyDown}
                                ></input>
                              </p>
                              <p class="list-item">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  onClick={iniciarJornada}
                                >
                                  <span>Iniciar jornada</span>
                                </button>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        {/* <!-- customer details --> */}
                        <div class="w-100 ps-4">
                          <ul class="list-unstyled m-0 p-0"></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="card border-0 h-100 p-4">
                    {/*User*/}
                    <div class=" mb-3">
                      <div class="fw-bold d-flex align-items-center mb-3">
                        <span class="w-100"></span>
                      </div>
                      <div class="d-flex align-items-center">
                        {/* <!-- customer details --> */}
                        <div class="w-100 ps-4">
                          <ul class="list-unstyled m-0 p-0"></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaCorte;
