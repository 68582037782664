import React from "react";

import { Link, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const DatosOrden = ({ serie, fecharecibido, fechaentrega,styles }) => (
  <View style={styles.rowcontent}>
    <View style={styles.row}></View>

    <View style={[styles.row, styles.blacktext, styles.sizeText]}>
      <View style={{ width: "30%" }}>
        <Text>ORDEN:</Text>
      </View>
      <View style={{ width: "70%", textAlign: "center" }}>
        <Text>{" " + serie}</Text>
      </View>
    </View>
    <View style={styles.row}>
      <Text>FECHA RECIBIDO:</Text>
      <Text>{" " + fecharecibido}</Text>
    </View>
    <View style={styles.row}>
      <Text>FECHA ENTREGA:</Text>
      <Text>{" " + fechaentrega}</Text>
    </View>
    <View style={styles.row}></View>
  </View>
);

export default DatosOrden;
