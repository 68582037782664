import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  headerCol: {
    backgroundColor: "#d3d3d3",
  },
  fontnegrita: { fontFamily: "Lato Bold" },
});

const formatDateCrate = (fecha) => {
  const newdatec = new Date(fecha);
  return newdatec.toLocaleString();
};

const totalTicket = (totalt) => {
  if (totalt > 0) {
    return <Text style={styles.tableCell}>${totalt}</Text>;
  }
  return <Text style={styles.tableCell}>${totalt}</Text>;
};

const metodosPago = (listaMetodos) => {
  let sumaPagos = {
    Tarjeta: 0,
    Transferencia: 0,
    Efectivo: 0,
  };

  listaMetodos.map((metodoPago) => {
    sumaPagos[metodoPago.metodopago] += parseInt(metodoPago.precio);
  });

  return (
    <>
      <View
        style={
          sumaPagos.Efectivo + sumaPagos.Transferencia + sumaPagos.Tarjeta > 0
            ? [styles.tableCol, styles.fontnegrita]
            : [styles.tableCol]
        }
      >
        <Text style={styles.tableCell}>
          ${sumaPagos.Efectivo + sumaPagos.Transferencia + sumaPagos.Tarjeta}
        </Text>
      </View>
    </>
  );
};

const formatDateStatus = (fecha, today) => {
  const newdatec = new Date(fecha);

  return (
    <>
      <View
        style={
          today > newdatec
            ? [styles.tableCol, styles.fontnegrita]
            : [styles.tableCol]
        }
      >
        <Text style={styles.tableCell}>{newdatec.toLocaleString()}</Text>
      </View>
    </>
  );
};
// Componente que renderiza la tabla
const MyTable = ({ rows, ingresos, totales }) => {
  let today = new Date();
  return (
    <View style={styles.table} wrap={true}>
      {/* Fila del header */}
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>ORDEN</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>CLIENTE</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>FECHA</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>FECHA ENTREGA</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>ANTICIPO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>TOTAL</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>SALDO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>ESTADO</Text>
        </View>
      </View>
      {rows?.map((row, index) => (
        <>
          <View key={index} style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.uuid}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.cliente.nombre}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatDateCrate(row.createdAt)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatDateStatus(row.fecha, today)}
              </Text>
            </View>
            {metodosPago(row.pagos)}
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>${row.total}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>${row.total - row.pagado}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{row.estadoEntrega}</Text>
            </View>
          </View>
        </>
      ))}
      {/* Fila del header */}
      <View style={styles.tableRow} wrap={false}>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>TOTAL</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>TOTAL ORDENES</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}>SALDO</Text>
        </View>
        <View style={[styles.tableCol, styles.headerCol]}>
          <Text style={styles.tableCell}></Text>
        </View>
      </View>
      <View key="totalesindex" style={styles.tableRow} wrap={false}>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            ${ingresos.efectivo + ingresos.transferencia + ingresos.tarjeta}
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>${totales.ordenes}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>${totales.saldo}</Text>
        </View>
        <View style={styles.tableCol}></View>
      </View>
    </View>
  );
};

export default MyTable;
