import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { TextField } from "@mui/material";

const CrearImpresora = ({
  onSumit,
  total,
  metodoPago,
  handleMetodoPago,
  closeModal,
  pagos,
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [subtotal, setSubtotal] = useState(0);

  const agregar = async () => {
    if (!value) {
      toast.info("Nombre no valido");
      return;
    }
    onSumit({ selectedValue: value, canteidad: cantidad });
    closeModal();
  };

  return (
    <>
      <div class="modal-body">
        <div class="form-floating">
          <div class="mb-2">
            <div class="fw-bold d-flex align-items-center">
              <span class="w-100"></span>
            </div>

            <div class="small d-flex flex-column align-items-center"></div>
          </div>
        </div>
        <div class="form-check" style={{ paddingLeft: "0" }}>
          <label className="me-3">Nombre:</label>
          <TextField
            id="outlined-controlled"
            label="Impresora"
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        </div>
        <div class="modal-footer" style={{ justifyContent: "space-between" }}>
          <button type="submit" class="btn btn-primary" onClick={agregar}>
            <svg
              width="18px"
              height="18px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            <span>Agregar</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CrearImpresora;
