import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const BlogItem = ({
  id,
  titulo,
  idAutor,
  fecha,
  categoria,
  imagenes,
  cuerpo,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  // Array con los nombres de los meses
  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const [fechatext, setFechaText] = useState("");

  useEffect(() => {
    getImagen();
    getAutor();
    getTiempo();
    console.log("use effect" + titulo);
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imag);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const getAutor = async () => {
    try {
      const result = await UserService.getById(id);
      setAutor(result.nombre);
      autorNombre = result.nombre;
      console.log("Nombre autor: " + autorNombre);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTiempo = async () => {
    try {
      //Tiempo
      const date = new Date(fecha);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFechaText(`${day} de ${monthNames[monthIndex]} de ${year}`);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  return (
    <>
      {/* post */}
      <a
        href="javascript:void(0)"
        onClick={() => navigate(`/blog/${id}`)}
        className="col-12 col-md-6 mb-5 position-relative text-dark clearfix text-decoration-none"
      >
        <figure className="d-block text-center overlay-dark-hover overlay-opacity-2 rounded overflow-hidden">
          <img
            className="img-fluid rounded"
            alt="..."
            src={imag}
            style={{ width: "100%", height: "300px", objectFit: "cover" }}
          />
        </figure>
        <p className="text-muted small">
          <time>{fechatext} </time>
        </p>
        <h3 className="h5">{titulo}</h3>
      </a>
      {/* /post */}
    </>
  );
};

export default BlogItem;
