import httpClient from "./HttpClient";

const prefix = "/cliente";

export default class ClienteService {
  static async create(user) {
    return (await httpClient.post(`${prefix}/`, user)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async list(filter) {
    return (await httpClient.get(`${prefix}s/${filter}`)).data;
  }

  static async listLimit(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}s/all`)).data;
  }

  static async update(user) {
    return (await httpClient.put(`${prefix}/${user.id}`, user)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async login(data) {
    return (await httpClient.post(`${prefix}/login`, data)).data;
  }

  static async listByUserSearch(data) {
    return (await httpClient.post(`${prefix}s-cliente/search`, data)).data;
  }

  static async allByUserSearch(data) {
    return (await httpClient.post(`${prefix}s-clientes/search`, data)).data;
  }
}
