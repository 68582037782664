import React, { useEffect, useState, useContext } from "react";
import PaqueteService from "../../../services/Paquete.service";
import Titulo from "./Titulo";
import AvisoPrivacidadService from "../../../services/Terminos.service";
import { useNavigate } from "react-router-dom";

const AvisoPrivacidad = () => {
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getAviso();
    window.scrollTo(0, 0);
  }, []);

  const getAviso = async () => {
    try {
      const data = await AvisoPrivacidadService.get();
      setAviso(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div className="main-wrapper packages-grid">
      {/*<Titulo />
      <section className="bg-smoke py-10">
        <div className="containerPublic">
          <div className="row">            
            <div className="col-md-12 col-lg-12 mb-5">
            <p style={{textAlign:"justify"}}
              dangerouslySetInnerHTML={{
                __html: aviso.cuerpo,
              }}
            ></p>
            </div>
          </div>
        </div>
      </section>*/}
      <>
        {/* page title */}
        <div className="container py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb small">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)" onClick={() => navigate(`/aviso`)}>
                  Inicio
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Términos y condiciones
              </li>
            </ol>
          </nav>
          <h1 className="h2 fw-bold">Términos y condiciones</h1>
        </div>
        {/* /page title */}
        {/* content */}
        <div className="container pb-7">
          <div className="row g-4">
            {/* content */}
            <div className="col-12 col-lg-8 order-1 order-lg-2">
              <article className="article-format">
                <h2 className="h4 fw-bold">Términos y condiciones</h2>
                <p
                  className="lead"
                  dangerouslySetInnerHTML={{ __html: aviso?.terminos }}
                ></p>
              </article>
            </div>
            {/* 
      sidebar navigation */}
            <div className="col-12 col-lg-4 order-2 order-lg-1">
              <div className="sticky-kit z-index-1">
                <div className="card mb-4">
                  <div className="card-body p-md-4">
                    <div className="border-bottom border-light mb-3 pb-2 text-gray-700">
                      <h2 className="h5">Have qustions?</h2>
                      <p className="small">
                        If you have questions realated to our products, please
                        do not hesitate to{" "}
                        <a
                          href="javascript:void(0)"
                          className="text-decoration-none"
                        >
                          contact us
                        </a>{" "}
                        anytime.
                      </p>
                    </div>
                    <ul className="nav flex-column m-0 p-0">
                      <li className="nav-item">
                        <a
                          className="nav-link px-0 link-normal text-primary"
                          href="javascript:void(0)"
                          onClick={() => navigate("/aviso")}
                        >
                          <svg
                            height="18px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="9 18 15 12 9 6" />
                          </svg>
                          Aviso de privacidad
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link px-0 link-normal"
                          href="javascript:void(0)"
                          onClick={() => navigate("/terminosycondiciones")}
                        >
                          <svg
                            height="18px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="9 18 15 12 9 6" />
                          </svg>
                          Terminos &amp; condiciones
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link px-0 link-normal"
                          href="javascript:void(0)"
                          onClick={() => navigate("/contactanos")}
                        >
                          <svg
                            height="18px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="9 18 15 12 9 6" />
                          </svg>
                          Contact us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body p-lg-4">
                    <h3 className="h6 fw-bold">Smarty Inc.</h3>
                    <ul className="list-unstyled mb-0 small">
                      <li>
                        <b>Phone:</b>{" "}
                        <a className="link-normal" href="tel:18005652390">
                          1-800-565-2390
                        </a>
                      </li>
                      <li>
                        <b>Address:</b>
                        <address className="mb-0 d-inline">
                          PO Box 21132, Melbourne 2355 Australia
                        </address>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /content */}
        {/* INFO BOX */}
        <div className="bg-gray-200">
          <div className="container py-3">
            <div className="row g-4">
              <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
                <img
                  width={60}
                  height={60}
                  className="lazy"
                  data-src="demo.files/svg/ecommerce/money_bag.svg"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  alt="..."
                />
                <div className="ps-3">
                  <h3 className="fs-5 mb-1">Money Back</h3>
                  <p className="m-0">Love it! Use it! Reuse it!</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
                <img
                  width={60}
                  height={60}
                  className="lazy"
                  data-src="demo.files/svg/ecommerce/free-delivery-truck.svg"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  alt="..."
                />
                <div className="ps-3">
                  <h3 className="fs-5 mb-1">Free Shipping</h3>
                  <p className="m-0">Shipping is on us</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
                <img
                  width={60}
                  height={60}
                  className="lazy"
                  data-src="demo.files/svg/ecommerce/24-hours-phone-service.svg"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  alt="..."
                />
                <div className="ps-3">
                  <h3 className="fs-5 mb-1">Free Support</h3>
                  <p className="m-0">24/24 available</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
                {/* link example */}
                <a
                  href="javascript:void(0)"
                  className="text-dark text-decoration-none d-flex"
                >
                  <img
                    width={60}
                    height={60}
                    className="lazy"
                    data-src="demo.files/svg/ecommerce/handshake.svg"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    alt="..."
                  />
                  <div className="ps-3">
                    <h3 className="fs-5 mb-1">Best Deal</h3>
                    <p className="m-0">Quality guaranteed</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /INFO BOX */}
      </>
    </div>
  );
};

export default AvisoPrivacidad;
