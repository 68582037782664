import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ContactanosService from "../../../services/Contactanos.service";
import GoogleMaps from "simple-react-google-maps";
import AOS from "aos";
import "aos/dist/aos.css";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

import FooterService from "../../../services/Footer.service";
import MensajeService from "../../../services/Contacto.service";

//imagenes
import imgServicio from "../layout/images/servicios2.jpg";
import iconServicio from "../layout/images/lavanderiaicon1.png";

const Contactanos = () => {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY;

  const [mensaje, setMensaje] = useState({ undefined });
  const [valido, setValido] = useState(null);
  const [aceptar, setAceptar] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [info, setInfo] = useState({ undefined });
  const [locations, setLocations] = useState([
    {
      lat: 17.052747,
      lng: -96.72233,
    },
  ]);
  const [center, setCenter] = useState({
    lat: 17.052747,
    lng: -96.72233,
  });
  useEffect(() => {
    AOS.init();
    getContactanos();
    window.scrollTo(0, 0);
  }, []);

  const getContactanos = async () => {
    setLoading(true);
    try {
      const foot = await FooterService.getAll();
      setInfo(foot[0]);

      if (
        foot[0]?.Direccion &&
        foot[0]?.Direccion?.lat !== 0 &&
        !isNaN(foot[0]?.Direccion.lat)
      ) {
        setLocations([
          {
            lat: Number(foot[0]?.Direccion?.lat),
            lng: Number(foot[0]?.Direccion?.long),
          },
        ]);
        setCenter({
          lat: Number(foot[0]?.Direccion?.lat),
          lng: Number(foot[0]?.Direccion?.long),
        });
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  function onChange(value) {
    setValido(value);
  }

  const enviarMensaje = async () => {
    if (!mensaje.Nombre) {
      toast.info("Error, tienes que colocar tu nombre");
    } else if (!mensaje.Email) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!mensaje.Telefono) {
      toast.info("Error, tienes que colocar un número telefónico");
    } else if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar una mensaje");
    } else if (!aceptar) {
      toast.info(
        "Para poder enviar tu mensaje debes de aceptar  los terminos y condiciones"
      );
    } else {
      setLoading(true);
      try {
        const datosMensaje = mensaje;
        datosMensaje.Status = "SinAbrir";
        await MensajeService.create(datosMensaje);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        const limpiar = {
          Nombre: "",
          Email: "",
          MensajeCuerpo: "",
          Telefono: "",
        };
        setMensaje(limpiar);
        setAceptar(false);
        setValido(null);
        toast.info("Gracias, mensaje enviado");
      }
    }
  };

  return (
    <>
      {/* Cover */}
      <div className="section p-0 jarallax overlay-dark overlay-opacity-7 text-white">
        {/* jarallax image */}
        <img className="jarallax-img" src={imgServicio} alt="..." />
        <div className="container py-7">
          <div className="d-middle justify-content-start col-12 col-md-6 py-7 text-center-xs">
            <div className="mt-7 overflow-hidden mb-4">
              <h1 className="display-3 fw-bold mb-0">
                <span className="text-warning">Contáctanos</span> Contáctanos
              </h1>
              <p className="lead">
                Si tienes alguna duda o solo curiosidad, por favor no dudes en
                contactarnos.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* /Cover */}
      {/* CONTACT FORM */}
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 mb-3">
              {/* map */}
              <div className="bg-white shadow-primary-xs rounded p-2 mb-7">
                {/*
                Map
              */}
                {loading ? null : (
                  <GoogleMaps
                    apiKey={google_apiKey}
                    style={{ height: "400px", width: "100%" }}
                    zoom={15}
                    center={center}
                    markers={locations} //optional
                  />
                )}
              </div>
              {/* form */}
              <div className="bg-light p-4 p-lg-5 rounded">
                <div style={{ marginTop: "-10%" }}>
                  <h2 className="mb-4">Envíanos un mensaje</h2>
                  <form className="bs-validate js-ajax">
                    {/* */}
                    <div className="form-floating mb-3">
                      <input
                        required=""
                        placeholder="Nombre"
                        id="contact_name"
                        name="contact_name"
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          setMensaje({ ...mensaje, Nombre: e.target.value })
                        }
                        value={mensaje.Nombre}
                      />
                      <label htmlFor="contact_name">Nombre</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        required=""
                        placeholder="Correo electronico"
                        id="contact_email"
                        name="contact_email"
                        type="email"
                        className="form-control"
                        onChange={(e) =>
                          setMensaje({ ...mensaje, Email: e.target.value })
                        }
                        value={mensaje.Email}
                      />
                      <label htmlFor="contact_email">Correo Electrónico</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        required=""
                        placeholder="Telefono"
                        id="contact_phone"
                        name="contact_phone"
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          setMensaje({
                            ...mensaje,
                            Telefono: e.target.value,
                          })
                        }
                        value={mensaje.Telefono}
                      />
                      <label htmlFor="contact_phone">Teléfono</label>
                    </div>
                    <div className="form-floating mb-3">
                      <textarea
                        required=""
                        placeholder="Mensaje"
                        id="contact_message"
                        name="contact_message"
                        className="form-control"
                        rows={3}
                        style={{ minHeight: 120 }}
                        defaultValue={""}
                        onChange={(e) =>
                          setMensaje({
                            ...mensaje,
                            MensajeCuerpo: e.target.value,
                          })
                        }
                        value={mensaje.MensajeCuerpo}
                      />
                      <label htmlFor="contact_message">Mensaje</label>
                    </div>
                    {/* GDPR CONSENT */}
                    <div
                      className="mb-3 border p-3 rounded"
                      style={{
                        textAlign: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ReCAPTCHA
                          sitekey={"6LfAC18jAAAAAMzQTRN2K7shO2tZeskMJWv_Q7Rd"}
                          onChange={onChange}
                          style={{
                            transform: "scale(0.84)",
                            transformOrigin: "0 0",
                          }}
                        />
                      </p>
                      <div className="form-check">
                        <input
                          required=""
                          className="form-check-input"
                          id="contact_gdpr"
                          name="contact_gdpr"
                          type="checkbox"
                          value={aceptar}
                          onClick={() => setAceptar((oldvalue) => !oldvalue)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="contact_gdpr"
                        >
                          Acepto términos y condiciones{" "}
                          <a
                            className="text-decoration-none"
                            href="javascript:void(0)"
                            onClick={() => navigate(`/aviso`)}
                          >
                            (Términos y condiciones)
                          </a>
                        </label>
                      </div>
                    </div>
                    {/* /GDPR CONSENT */}
                    {/* 
                Server detailed error
                !ONLY! If debug is enabled!
                Else, shown ony "Server Error!"
              */}
                    <div id="ajax_dd_contact_response_container" />
                    {/* {:err:unexpected:} internal server error */}
                    <div
                      id="contactErrorUnexpected"
                      className="hide alert alert-danger p-3"
                    >
                      Unexpected Error!
                    </div>
                    {/* {:err:required:} mandatory fields */}
                    <div
                      id="contactErrorMandatory"
                      className="hide alert alert-danger p-3"
                    >
                      Please, review your data and try again!
                    </div>
                    {/* {:success:} message sent */}
                    <div
                      id="contactSuccess"
                      className="hide alert alert-success p-3"
                    >
                      Gracias por tu mensaje
                    </div>
                  </form>
                  <button
                    className="btn btn-lg btn-primary w-100"
                    style={{
                      marginLeft: "3%",
                      backgroundColor: !(valido && aceptar)
                        ? "#f3f3f3"
                        : "black",
                    }}
                    disabled={!(valido && aceptar)}
                    onClick={() => enviarMensaje()}
                  >
                    Enviar mensaje
                  </button>
                  {/* /CONTACT FORM : AJAX */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 mb-3 ">
              <div
                className="sticky-top z-index-0 pt-5 pt-lg-0"
                style={{ top: 120 }}
              >
                <div
                  className="mx-3 mb-3 position-relative z-index-1"
                  data-aos="fade-in"
                  data-aos-delay={300}
                >
                  <div className="bg-light p-4 rounded-xl">
                    {/* mobile : top arrow : documentation/util-misc.html */}
                    <i className="arrow arrow-lg arrow-top arrow-center border-light d-block d-md-none" />
                    {/* desktop side arrow : documentation/util-misc.html */}
                    <i className="arrow arrow-lg arrow-start mt-5 border-light d-none d-md-inline-block d-lg-inline-block d-xl-inline-block" />
                    <div className="d-flex">
                      <div className="float-none me-3">
                        <i className="fi fi-shape-abstract-dots text-gray-500 float-start fs-2" />
                      </div>
                      <div>
                        <h2 className="fs-5">LA ESPERANZA</h2>
                        <ul className="list-unstyled m-0 fs-6">
                          <li className="list-item text-muted">
                            <a href={"mailTo:" + info.Correo}>{info.Correo}</a>
                          </li>
                          <li className="mt-3 list-item text-muted">
                            {info?.Direccion?.Direccion}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex mt-4">
                      <div className="float-none me-3">
                        <i className="fi fi-time text-gray-500 float-start fs-2" />
                      </div>
                      <div>
                        <h3 className="h5">Horario</h3>
                        <ul className="list-unstyled m-0 fs-6">
                          <li className="list-item text-muted">
                            Lunes a Viernes : {info.semana}
                          </li>
                          <li className="list-item text-muted">
                            Sabados : {info.sabado}
                          </li>
                          <li className="list-item text-muted">
                            Domingos : {info.domingo}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex mt-4">
                      <div className="float-none me-3">
                        <i className="fi fi-phone text-gray-500 float-start fs-2" />
                      </div>
                      <div>
                        <h3 className="h5"> Número telefonico</h3>
                        <ul className="list-unstyled m-0">
                          <li className="list-item mb-2 text-gray-500">
                            <a
                              href={"tel:" + info.Telefono}
                              className="link-muted"
                            >
                              {info.Telefono}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /CONTACT FORM */}
      {/* INFO BOX */}
      <div className="bg-gray-200">
        <div className="container py-3">
          <div className="row g-4">
            <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
              <img
                width={60}
                height={60}
                className="lazy"
                src={iconServicio}
                alt="..."
              />
              <div className="ps-3">
                <h3 className="fs-5 mb-1">Limpieza Profesional</h3>
                <p className="m-0">Calidad impecable para tus prendas</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
              <img
                width={60}
                height={60}
                className="lazy"
                src={iconServicio}
                alt="..."
              />
              <div className="ps-3">
                <h3 className="fs-5 mb-1">Entrega Rápida</h3>
                <p className="m-0">Servicio exprés para tu conveniencia</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
              <img
                width={60}
                height={60}
                className="lazy"
                src={iconServicio}
                alt="..."
              />
              <div className="ps-3">
                <h3 className="fs-5 mb-1">Descuentos Especiales</h3>
                <p className="m-0">Ahorra en tus servicios de limpieza</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
              {/* link example */}
              <a
                href="javascript:void(0)"
                className="text-dark text-decoration-none d-flex"
              >
                <img
                  width={60}
                  height={60}
                  className="lazy"
                  src={iconServicio}
                  alt="..."
                />
                <div className="ps-3">
                  <h3 className="fs-5 mb-1">Cuidado Delicado</h3>
                  <p className="m-0">
                    Atención especial para tus prendas delicadas
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* /INFO BOX */}
    </>
  );
};

export default Contactanos;
