/* React */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/PreloaderElement";
import {
  Alert,
  Grid,
  NativeSelect,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { esES } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "dayjs/locale/es-mx";

/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import ServicioService from "../../../services/Servicio.service";
import TicketsService from "../../../services/TicketsSalida.service";
import ConfiguracionService from "../../../services/Configuracion.service";
import CalendarioService from "../../../services/Calendario.service";
import BuscarUsuario from "./BuscarUsuario";
import Swal from "sweetalert2";
import ImpresionService from "../../../services/Impresion.service";
import Modal from "./Modal";
import PrintIcon from "@mui/icons-material/Print";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./DocumentSalida/Document";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CortesService from "../../../services/Corte.service";

const CrearTicketSalida = () => {
  const { id } = useParams();
  const [idTicketSalida, setIdTicketSalida] = useState(-1);
  const [responsableServicio, setResponsableServicio] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [updateuser, setUpdateuser] = useState(false);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [pagado, setPagado] = useState(0);
  const [nota, setNota] = useState("");
  const [concepto, setConcepto] = useState("");
  const navigate = useNavigate();
  const [servicios, setServicios] = useState([]);
  const [latest, setLatest] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);
  const [openModalCliente, setOpenModalCliente] = useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [metodoPago, setMetodoPago] = useState("Efectivo");
  const [monto, setMonto] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [estadoPago, setEstadoPago] = useState("Pendiente");
  const [configuracionImpresion, setConfiguracionImpresion] = useState(null);
  const [configuracion, setConfiguracion] = useState({
    serie: "A",
    digitos: 4,
    numberserie: 0,
  });
  const [jornada, setJornada] = useState(null);

  const [configuracionDias, setConfiguracionDias] = useState(null);
  let today = new Date();
  const [fechaHoy, setFechaHoy] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );
  const [fechaEntrega, setFechaEntrega] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );

  const [openModalImprime, setOpenModalImprime] = useState(false);

  const handleUpdateuser = () => {
    setUpdateuser(!updateuser);
  };

  const handleOpenModalImprime = () => {
    navigate("/salidas");
  };

  const handleOpenModalPago = () => {
    setOpenModalPago(!openModalPago);
  };

  const handleOpenModalCliente = () => {
    setOpenModalCliente(!openModalCliente);
  };

  const handleCliente = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setUsuario(changecliente);
    handleUpdateuser();
  };

  const handleResponsableServicio = (newCliente) => {
    console.log(newCliente);
    let changecliente = { ...newCliente.value, nombre: newCliente.value.label };
    setResponsableServicio(changecliente);
  };

  const handleMetodoPago = (newMetodoPago) => {
    console.log(newMetodoPago);
    setMetodoPago(newMetodoPago.target.value);
  };

  const fechaLaborable = (fecha, diasNoLaborables) => {
    let dia = fecha.toLocaleString("es-MX", { weekday: "long" });

    return diasNoLaborables[dia];
  };

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  function validarString(str) {
    // Eliminar espacios en blanco
    str = str.trim();

    // Verificar si el string es vacío
    if (str.length === 0) {
      return false;
    }

    // Verificar si el string contiene solo caracteres especiales
    const regex = /^[\s\n\t]+$/;
    if (regex.test(str)) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (usuario?.uuid) {
      getLatestTickets(usuario?.uuid);
    } else if (id) {
      getCliente();
      getLatestTickets(id);
    }
  }, [updateuser]);

  useEffect(() => {
    cargarDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargarDatos = async () => {
    setLoading(true);
    Promise.all([
      getConfiguracion(),
      getConfiguracionDias(),
      getConfiguracionImpresion(),
      getServicios(),
      getJornada(),
    ]).finally(() => {
      setLoading(false);
    });
  };

  const getJornada = async () => {
    try {
      const data = await CortesService.revisarJornada();
      if (data && data.data[0]) {
        setJornada(data.data);
      } else {
        setJornada(null);
      }
    } catch (error) {
      console.log(error);
      toast.error("Datos de jornada no recuperados");
    }
  };

  const getConfiguracion = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionTickets();
      setConfiguracion(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getConfiguracionImpresion = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionImpresion();
      setConfiguracionImpresion(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getConfiguracionDias = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionDias();
      setConfiguracionDias(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getCliente = async () => {
    console.log(id);
    // setLoading(true);
    try {
      const data = await ClienteService.getById(id);
      console.log(data);
      setUsuario(data);
    } catch (error) {
      toast.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const getLatestTickets = async (idCliente) => {
    console.log(id);
    try {
      const data = await TicketsService.getLatest(idCliente);
      console.log(data);
      setLatest(data);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const getServicios = async () => {
    try {
      const data = await ServicioService.getAll();
      console.log(data);
      setServicios(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const addProduct = (newProduct) => {
    console.log(newProduct);
    let exist = false;
    let filter = productos.map((op) => {
      if (op.value.id === newProduct.value.id) {
        exist = true;
        let aproduct = {
          ...newProduct,
          cantidad: newProduct.cantidad + op.cantidad,
        };
        return aproduct;
      }
      return op;
    });

    if (exist) {
      setProductos(filter);
    } else {
      setProductos([...productos, newProduct]);
    }

    setTotal(
      (prev) =>
        prev + Number(newProduct.value.precio) * Number(newProduct.cantidad)
    );
  };

  const addPago = (newProduct) => {
    console.log(newProduct);
    setPagado(newProduct.cantidad);
    if (newProduct.cantidad < 1) {
      setEstadoPago("Pendiente");
    } else {
      setEstadoPago("Abonado");
    }
  };

  const impresionManual = async (url) => {
    try {
      ImpresionService.printUrlDialog(url);
    } catch (error) {
      toast.error("Ha sucedido un error durante la impresión.");
    }
  };

  const removeProduct = (id) => {
    let remove = productos.filter((p) => p.value.id === id);
    let oldProductos = productos.filter((p) => p.value.id !== id);
    setAreaExpanded(-1);
    setProductos(oldProductos);
    setTotal(
      (prev) =>
        prev - Number(remove[0].value.precio) * Number(remove[0].cantidad)
    );
  };

  const numberformat = () => {
    try {
      let cadena = configuracion.numberserieSalida.toString();
      while (cadena.length < configuracion.digitosSalida) {
        cadena = "0" + cadena;
      }
      cadena = configuracion.serieSalida + "-" + cadena + "-S";
      return cadena.toUpperCase();
    } catch (error) {
      toast.error("Error en configuración de salida de efectivo");
    }
  };

  const saveTicket = async () => {
    try {
      if (!jornada) {
        toast.info("Jornada no iniciada.");
        return;
      }

      if (!fechaEntrega) {
        toast.info("Selecciona una fecha de entrega.");
        return;
      }
      if (!responsableServicio?.id) {
        toast.info("Selecciona un responsable de servicio.");
        return;
      }
      if (validarString(concepto) === false) {
        toast.info("Especifica un concepto.");
        return;
      }
      if (!total || !(total > 0)) {
        toast.info("No es una cantidad valida.");
        return;
      }

      let entrega = new Date(fechaEntrega);
      let fechaRecepcion = new Date();
      let uuidTicket = numberformat();

      let newTicket = {
        uuid: uuidTicket,
        fecha: entrega.getTime(),
        concepto: concepto,
        metodopago: metodoPago,
        pagado: pagado,
        total: total,
        monto: pagado,
        nota: nota,
        saldo: 0,
        usuario: responsableServicio.id,
        fechaRecepcion: fechaRecepcion.toISOString(),
      };

      await Swal.fire({
        title: `<i class="fa fa-user fa-5x" aria-hidden="true"></i>`,
        input: "password",
        inputPlaceholder: "Contraseña",
        inputAttributes: {
          maxlength: "18",
          autocapitalize: "off",
          autocorrect: "off",
        },
        confirmButtonColor: "#9b242c",
        iconColor: "#9b242c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          newTicket.contrasena = result.value;
          await TicketsService.create(newTicket);
          toast.success("Guardado");
          navigate("/salidas");
        }
      });
    } catch (error) {
      toast.error("Error. No se ha guardado!");
      setLoading(false);
    }
  };

  const saveTicketPrint = async () => {
    try {
      if (!jornada) {
        toast.info("Jornada no iniciada.");
        return;
      }

      if (!fechaEntrega) {
        toast.info("Selecciona una fecha de entrega.");
        return;
      }
      if (!responsableServicio?.id) {
        toast.info("Selecciona un responsable de servicio.");
        return;
      }
      if (!concepto) {
        toast.info("Especifica un concepto.");
        return;
      }
      if (!total || !(total > 0)) {
        toast.info("No es una cantidad valida.");
        return;
      }

      let entrega = new Date(fechaEntrega);
      let fechaRecepcion = new Date();
      let uuidTicket = numberformat();

      let newTicket = {
        uuid: uuidTicket,
        fecha: entrega.getTime(),
        concepto: concepto,
        metodopago: metodoPago,
        pagado: pagado,
        total: total,
        monto: pagado,
        nota: nota,
        saldo: 0,
        usuario: responsableServicio.id,
        fechaRecepcion: fechaRecepcion.toISOString(),
        printFile: true,
      };

      await Swal.fire({
        title: `<i class="fa fa-user fa-5x" aria-hidden="true"></i>`,
        input: "password",
        inputPlaceholder: "Contraseña",
        inputAttributes: {
          maxlength: "10",
          autocapitalize: "off",
          autocorrect: "off",
        },
        confirmButtonColor: "#9b242c",
        iconColor: "#9b242c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          newTicket.contrasena = result.value;
          const res = await TicketsService.create(newTicket);
          toast.success("Guardado");
          await enviarImpresion(res.url);
          setIdTicketSalida(uuidTicket);
        }
      });
    } catch (error) {
      toast.error("Error. No se ha guardado!");
      setLoading(false);
    }
  };

  const enviarImpresion = async (url) => {
    console.log("url");
    console.log(url);
    if (configuracionImpresion?.envioAutomatico) {
      console.log("PASS");
      toast.promise(
        ImpresionService.printUrl(url, configuracionImpresion.impresora),
        {
          pending: "Impresion pendiente",
          success: "Completado 👌",
          error: "Impresion rechazada 🤯",
        }
      );

      navigate("/salidas");
    }
    setOpenModalImprime(true);
  };

  const enviarImpresionPrint = async (url) => {
    try {
      ImpresionService.printUrlDialog(url);
    } catch (error) {
      toast.error("Ha sucedido un error durante la impresión.");
    }
  };

  const handleFechaEntrega = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    setFechaEntrega(nuevaFechaEntrega);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            {!jornada && <Alert severity="warning">jornada no iniciada</Alert>}

            <header style={jornada ? null : { marginTop: "0.5rem" }}>
              <div class="row g-1 align-items-center">
                <div class="col">
                  <h1 class="h4">Salida de efectivo</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb small">
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">REGISTRAR SALIDA</a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <div class="col-auto">
                  {/* <a
                    href="javascript:void(0);"
                    role="button"
                    class="btn btn-sm btn-primary d-inline-flex align-items-center"
                    onClick={() => navigate("/clientes")}
                  >
                    <span class="d-none d-sm-inline-block ps-2">Cancelar</span>
                  </a> */}
                </div>
              </div>
            </header>
            {/* <!-- Order items --> */}
            <div class="row g-2">
              <div class="col">
                {/* <!-- Order items --> */}
                <div class="card border-0 h-100 p-4">
                  <div className="d-flex ">
                    <strong className="w-100 px-2">Concepto:</strong>
                    <a
                      class="flex-none link-muted small d-inline-grid gap-auto-2"
                      href="javascript:void(0);"
                    >
                      <svg
                        width="16px"
                        height="16px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    </a>
                  </div>
                  <div class="card-body p-4 h-100">
                    <TextareaAutosize
                      className="text-uppercase"
                      value={concepto}
                      style={{
                        width: "100%",
                        height: "76px",
                        border: "solid 1px lightgrey",
                        borderRadius: "8px",
                        marginTop: "0.5rem",
                      }}
                      minRows={2}
                      maxRows={2}
                      onChange={(event) => {
                        setConcepto(event.target.value);
                      }}
                    />

                    <div class="row g-4 justify-content-end my-5">
                      <div class="col">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            <FormControl
                              sx={{ width: "100%", marginBottom: 2 }}
                            >
                              <InputLabel id="simple-select-helper-label-nota">
                                Metodo:
                              </InputLabel>
                              <Select
                                labelId="simple-select-helper-label-nota"
                                id="simple-select-nota"
                                value={metodoPago}
                                label="Metodo:"
                                onChange={handleMetodoPago}
                              >
                                <MenuItem
                                  key="Efectivo"
                                  value="Efectivo"
                                  className="text-uppercase"
                                >
                                  Efectivo
                                </MenuItem>
                                <MenuItem
                                  key="Tarjeta"
                                  value="Tarjeta"
                                  className="text-uppercase"
                                >
                                  TARJETA
                                </MenuItem>
                                <MenuItem
                                  key="Transferencia"
                                  value="Transferencia"
                                  className="text-uppercase"
                                >
                                  TRANSFERENCIA
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </li>
                        </ul>
                        <div class="col-12 pt-4">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={
                              esES.components.MuiLocalizationProvider
                                .defaultProps.localeText
                            }
                            adapterLocale="es-mx"
                          >
                            <DateTimePicker
                              label="Fecha:"
                              disablePast={true}
                              ampm
                              value={dayjs(fechaEntrega)}
                              onChange={handleFechaEntrega}
                              sx={{ width: "100%" }}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 text-end">
                        <dl class="mb-0">
                          <dt class="fw-bold">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Total:
                            </dd>
                            <dd class="col-5 fw-bold d-inline-grid gap-auto-2 ms-2">
                              <TextField
                                type="number"
                                value={total}
                                onChange={(event) => {
                                  setTotal(event.target.value);
                                  setPagado(event.target.value);
                                }}
                                label="$"
                              />
                            </dd>
                          </dt>
                          <dd class="col-2 fw-bold"></dd>

                          {/* <dt class="col-10 text-muted">
                            <dd class="col-6 text-muted d-inline-grid gap-auto-2">
                              Anticipo:
                            </dd>
                            <dd class="col-5 fw-bold d-inline-grid gap-auto-2 ms-2">
                              <TextField
                                type="number"
                                value={monto}
                                onChange={(event) =>
                                  setMonto(event.target.value)
                                }
                                label="$"
                              />
                            </dd>
                          </dt>
                          <dt class="col-10 fw-bold">
                            <dd class="col-6 fw-bold d-inline-grid gap-auto-2">
                              Saldo:
                            </dd>
                            <dd class="col-5 fw-bold d-inline-grid gap-auto-2 ms-2">
                              <TextField
                                value={saldo}
                                onChange={(event) =>
                                  setSaldo(event.target.value)
                                }
                                label="$"
                              />
                            </dd>
                          </dt> */}
                          <dd class="col-2 fw-bold"></dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer p-4">
                    <a
                      href="javascript:void(0);"
                      role="button"
                      class="btn btn-sm btn-primary d-inline-flex align-items-center"
                      onClick={saveTicket}
                    >
                      <span class="d-none d-sm-inline-block ps-2">
                        GUARDAR GASTO
                      </span>
                    </a>
                    <a
                      href="javascript:void(0);"
                      role="button"
                      class="btn btn-sm btn-primary d-inline-flex align-items-center"
                      onClick={saveTicketPrint}
                    >
                      <span class="d-none d-sm-inline-block ps-2">
                        GUARDAR E IMPRIMIR GASTO
                      </span>
                    </a>
                  </div>
                </div>
                {/* <!-- /Order items --> */}
              </div>

              {/* <!-- side --> */}
              <div class="col-xl-4">
                <div class="card border-0 h-100">
                  {/*User*/}
                  <div class="card-header p-4 mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <span class="w-100">Responsable</span>
                    </div>
                    <div class="d-flex align-items-center">
                      {/* <!-- avatar --> */}
                      {/* <div
                        class="flex-none avatar avatar-lg bg-soft-primary rounded-circle"
                        style={{
                          backgroundImage:
                            "url(../html_frontend/demo.files/images/avatar/jessica_barden.jpg)",
                        }}
                      ></div> */}

                      {/* <!-- no avatar --> */}
                      {/* <!--  */}
                      {/* <div class="flex-none avatar avatar-lg bg-soft-primary rounded-circle">
                        <svg
                          class="opacity-25"
                          width="34px"
                          height="34px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 460.8 460.8"
                          fill="currentColor"
                        >
                          <path d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641S296.261,0,230.432,0z"></path>
                          <path d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898C436.8,341.682,437.322,338.024,435.755,334.89z"></path>
                        </svg>
                      </div> */}
                      {/* --> */}

                      {/* <!-- customer details --> */}
                      <div class="w-100 ps-4">
                        <ul class="list-unstyled m-0 p-0">
                          <li class="list-item">
                            {/* <!-- customer name, orders --> */}
                            <a
                              class="text-decoration-none"
                              href="javascript:void(0)"
                            >
                              <BuscarUsuario
                                onSumit={handleResponsableServicio}
                                responsableServicio={responsableServicio}
                              />
                            </a>
                            {/* <a
                              class="link-muted small ms-2"
                              href="javascript:void(0)"
                            >
                              4 orders
                            </a> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* */}

                  <div class="card-body p-4 h-100">
                    {/* <!-- Billing Address --> */}
                    <div class="mb-3">
                      {/* <div class="fw-bold d-flex align-items-center mb-3">
                        <svg
                          class="flex-none text-muted me-2"
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"></path>
                          <polyline points="8 10 12 14 16 10"></polyline>
                        </svg>
                        <span class="w-100">Ultimos tickets</span>
                      </div>

                      <div class="d-flex mt-2">
                        <span class="d-block" style={{ width: "159px" }}>
                          Total
                        </span>
                        <span class="d-blockl">Estado pago:</span>
                      </div>

                      {latest.map((t, index) => (
                        <div
                          class="d-flex mt-2"
                          style={{ justifyContent: "space-between" }}
                          key={index}
                        >
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              navigate(`/ticket/detalles/${t.uuid}`)
                            }
                            class="fw-medium d-block"
                          >
                            ${t.total}
                            <span class="d-block smaller text-muted">
                              {t.uuid}
                            </span>
                          </a>

                          <span class="rounded-sm p-2 small">
                            {t.estadopago}
                          </span>
                        </div>
                      ))} */}
                    </div>
                  </div>

                  <div class="card-footer p-4">
                    <span class="text-muted small">
                      {/* Order placed from: [US] United States */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
      <Modal
        status={openModalImprime}
        handleStatus={handleOpenModalImprime}
        headerText="Gasto guardado"
      >
        <Grid container className="card-body">
          <Grid sm={6}>
            <a class="text-decoration-none" href="javascript:void(0)">
              <PrintIcon fontSize="small" />
              <PDFDownloadLink
                document={<Document id={idTicketSalida} />}
                fileName={`LET_ORDEN_${today.toLocaleDateString()}.pdf`}
                style={{ padding: "0.5rem" }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Cargando..."
                  ) : (
                    <a
                      className="text-decoration-none"
                      href="javascript:void(0);"
                      onClick={() => impresionManual(url)}
                    >
                      <span style={{ marginInline: "0.5rem" }}>Imprime</span>
                    </a>
                  )
                }
              </PDFDownloadLink>
            </a>
          </Grid>
          <Grid sm={6}>
            <a
              class="text-decoration-none"
              href="javascript:void(0)"
              onClick={() => navigate("/salidas")}
            >
              <ArrowCircleRightIcon fontSize="small" />
              Continuar
            </a>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CrearTicketSalida;
