import { useEffect, useState } from "react";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Autocomplete, Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es-mx";
import { esES } from "@mui/x-date-pickers/locales";

const Filtrar = ({ filtroValue, filtros, handleFiltros }) => {
  const today = new Date();
  const [value, setValue] = useState([]);
  const [seleccionarRango, setSeleccionarRango] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(
    dayjs(
      new Intl.DateTimeFormat("az", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(today)
    )
  );
  const [fechaFinal, setFechaFinal] = useState(
    dayjs(
      new Intl.DateTimeFormat("az", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(today)
    )
  );
  const [state, setState] = useState(false);
  const [listFiltros, setListFiltros] = useState([
    { title: "Liquidada", tipo: "Estado pago", propiedad: "estadopago" },
    { title: "Pendiente", tipo: "Estado pago", propiedad: "estadopago" },
    {
      title: "En existencia",
      tipo: "Estado entrega",
      propiedad: "estadoEntrega",
    },
    { title: "Entregada", tipo: "Estado entrega", propiedad: "estadoEntrega" },
    { title: "Fecha Recepción", tipo: "Fecha", propiedad: "fechaIngreso" },
    { title: "Fecha Entrega", tipo: "Fecha", propiedad: "fechaSalida" },
  ]);

  const vaciar = () => {
    handleFiltros([]);
  };

  const handleValue = (event, newValues) => {
    let filtero = {
      estadopago: "",
      estadoEntrega: "",
      fechaIngreso: "",
      fechaSalida: "",
      rangoInicio: "",
      rangoFinal: "",
    };

    let updatedValues = newValues.reduce((acc, newValue) => {
      let existingValueIndex = acc.findIndex(
        (value) => value.tipo === newValue.tipo
      );
      if (existingValueIndex !== -1) {
        // Reemplazar el valor anterior con el nuevo
        acc[existingValueIndex] = newValue;
      } else {
        acc.push(newValue);
      }
      return acc;
    }, []);
    setSeleccionarRango(false);

    updatedValues.forEach((element) => {
      if (element.tipo === "Fecha") {
        setSeleccionarRango(true);
        filtero.rangoInicio = fechaInicio;
        filtero.rangoFinal = fechaFinal;
      }
      filtero[element.propiedad] = element.title;
    });

    handleFiltros(updatedValues, filtero);
  };

  const addFiiltro = (newFiltro) => {
    handleFiltros([...filtros, newFiltro]);
  };

  const removeFiltro = (deleteFiltro) => {
    let oldFiltros = filtros.filter((f) => f !== deleteFiltro);
    handleFiltros(oldFiltros);
  };

  const handleFechaInicio = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    let newFiltrosFecha = {
      ...filtroValue,
      rangoInicio: newFecha.toDate(),
    };
    setFechaInicio(nuevaFechaEntrega);
    handleFiltros(filtros, newFiltrosFecha);
  };

  const handleFechaFinal = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    let newFiltrosFecha = {
      ...filtroValue,
      rangoFinal: newFecha.toDate(),
    };
    setFechaFinal(nuevaFechaEntrega);
    handleFiltros(filtros, newFiltrosFecha);
  };

  return (
    <div class="card-header p-4">
      <div class="row g-3">
        <div class="order-2 order-md-1 col">
          <div class="position-relative d-flex align-items-center">
            {/* <!-- search icon --> */}

            {!state ? null : (
              <a
                href="javascript:void(0);"
                class="z-index-1 position-absolute start-0 ms-3 "
                style={{ cursor: "pointer" }}
                onClick={() => vaciar()}
              >
                <CleaningServicesIcon
                  style={{ width: "17px", height: "17px" }}
                />
              </a>
            )}

            {/*Input */}
            <div class="dropdown w-100">
              <Autocomplete
                multiple
                id="size-small-standard-multi"
                size="small"
                options={listFiltros}
                getOptionLabel={(option) => option.title}
                value={filtros}
                groupBy={(option) => option.tipo}
                onChange={handleValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filtrar"
                    placeholder="Filtrar por"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {seleccionarRango && (
        <div class="row g-3 mt-4">
          <div class="order-2 order-md-1 col">
            <p
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Periodo de búsqueda:
            </p>
            <div class="position-relative d-flex align-items-center">
              {/* <!-- search icon --> */}
              {/*Input */}
              <div class="dropdown w-100 d-flex">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={
                    esES.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                  adapterLocale="es-mx"
                >
                  <DatePicker
                    label="Fecha inicio:"
                    ampm
                    value={dayjs(fechaInicio)}
                    onChange={handleFechaInicio}
                    sx={{ width: "100%" }}
                  />
                  <DatePicker
                    label="Fecha final:"
                    ampm
                    value={dayjs(fechaFinal)}
                    onChange={handleFechaFinal}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filtrar;
