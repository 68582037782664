import React, { useEffect, useState } from "react";
import Slide from "./Slide";
import Blog from "./Blog";
import Contacto from "./Contacto";
import Search from "./Search";
import MejoresOfertas from "./MejoresOfertas";
import Noticias from "./Noticias";
import Counter from "./Counter";
import Servicios from "./Servicios";
import IndexService from "../../../services/Index.service";
import { useNavigate } from "react-router-dom";

import Spline from "@splinetool/react-spline";
import AOS from "aos";
import "aos/dist/aos.css";
import Typed from "react-typed";

/**Imágenes */
import iconServicio from "../layout/images/lavanderiaicon1.png";
import iconExpress from "../layout/images/venta-express.png";
import iconPremium from "../layout/images/calidad-premium.png";

const Inicio = () => {
  const [informacion, setInformacion] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
    getInformacion();
  }, []);

  const getInformacion = async () => {
    let data = await IndexService.get();
    setInformacion(data[0]);
  };

  return (
    <>
      <div id="wrapper">
        <Slide />
        {/* hero */}
        <div className="section pb-0 position-relative">
          <div
            class="section py-5 position-relative mb-1"
            style={{ marginTop: "-100px" }}
          >
            <div class="container position-relative z-index-1">
              <div class="row text-center-xs">
                <div class="col-12 col-md-8">
                  <h3 className="m-0">Estamos a tus servicio!</h3>
                  <p className="m-0">Deja tu lavado a los profesionales</p>
                </div>

                <div class="col-12 mt-4 d-block d-md-none">
                  {/* <!-- mobile spacer --> */}
                </div>

                <div class="col-12 col-md-4 text-align-end">
                  <a
                    href="javascript:void(0)"
                    className="btn btn-pill btn-primary d-block-xs"
                    onClick={() => navigate(`/contactanos`)}
                  >
                    <b className="fw-medium">contáctanos</b> / contactános
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- svg shape --> */}
            <svg
              class="absolute-full z-index-0"
              width="100%"
              height="100%"
              viewBox="0 0 1920 90"
              preserveAspectRatio="none"
            >
              <path
                fill="rgba(55,125,255,.04)"
                d="M1920,0C1217,0,120.574,155.567,0,0v90h1920V0z"
              ></path>
            </svg>
          </div>

          {/* block : icons : 3 */}
          <div
            className="container bg-gray-200 rounded-xl mt-7"
            data-aos="fade-in"
            data-aos-delay={200}
            data-aos-offset={0}
          >
            <div
              className="row col-border text-center overflow-hidden"
              style={{ marginTop: "-92px" }}
            >
              <div
                className="col-12 col-md-4 py-5 z-index-1 border-top-xs"
                data-aos="fade-up"
                data-aos-delay={450}
                data-aos-offset={0}
              >
                <a
                  href="javascript:void(0)"
                  className="d-block text-decoration-none text-dark transition-hover-top transition-all-ease-250"
                >
                  <div
                    style={{ width: 120, height: 120 }}
                    className="bg-white mb-4 border border-light rounded-circle shadow-md d-inline-flex justify-content-center align-items-center"
                  >
                    <img
                      width={60}
                      height={60}
                      className="lazy"
                      src={iconPremium}
                      alt="..."
                    />
                  </div>
                  <h3 className="h4">Experiencia premium</h3>
                  <p className="max-w-250 mx-auto fs-6">
                    Cuidado impecable para tu ropa. Descubre por qué somos la
                    opción preferida de aquellos que buscan lo mejor.
                  </p>
                </a>
              </div>
              <div
                className="col-6 col-md-4 py-5 z-index-1"
                data-aos="fade-up"
                data-aos-delay={50}
                data-aos-offset={0}
              >
                <a
                  href="javascript:void(0)"
                  className="d-block text-decoration-none text-dark transition-hover-top transition-all-ease-250"
                >
                  <div
                    style={{ width: 120, height: 120 }}
                    className="bg-white mb-4 border border-light rounded-circle shadow-md d-inline-flex justify-content-center align-items-center"
                  >
                    <img
                      width={60}
                      height={60}
                      className="lazy"
                      src={iconServicio}
                      alt="..."
                    />
                  </div>
                  <h3 className="h4">Atención excepcional</h3>
                  <p className="max-w-250 mx-auto fs-6">
                    Experiencia y atención excepcional para cuidar de tu
                    ropa.Ven y experimenta la excelencia en cada prenda.
                  </p>
                </a>
              </div>
              <div
                className="col-6 col-md-4 py-5 z-index-1"
                data-aos="fade-up"
                data-aos-delay={250}
                data-aos-offset={0}
              >
                <a
                  href="javascript:void(0)"
                  className="scroll-to d-block text-decoration-none text-dark transition-hover-top transition-all-ease-250"
                >
                  <div
                    style={{ width: 120, height: 120 }}
                    className="bg-white mb-4 border border-light rounded-circle shadow-md d-inline-flex justify-content-center align-items-center"
                  >
                    <img
                      width={60}
                      height={60}
                      className="lazy"
                      src={iconExpress}
                      alt="..."
                    />
                  </div>
                  <h3 className="h4">Servicio express</h3>
                  <p className="max-w-250 mx-auto fs-6">
                    Servicio rápido, resultados extraordinarios.Descubre la
                    conveniencia y la calidad en un solo lugar.
                  </p>
                </a>
              </div>
            </div>
          </div>
          {/* /block : icons : 3 */}
        </div>

        {/* sticky quick nav */}
        <div className="sticky-top text-center bg-white shadow-primary-xs mt-n2">
          <ul id="demos-scrollspy" className="list-inline mb-0 py-3 fw-medium">
            <li className="list-inline-item">
              <a href="#inicio" className="scroll-to d-block py-2 link-normal">
                Inicio
              </a>
            </li>
            <li className="list-inline-item">
              <span className="py-2 px-2 text-gray-300">•</span>
            </li>
            <li className="list-inline-item">
              <a href="#blog" className="scroll-to d-block py-2 link-normal">
                Blog
              </a>
            </li>
            <li className="list-inline-item">
              <span className="py-2 px-2 text-gray-300">•</span>
            </li>
            <li className="list-inline-item">
              <a
                href="#servicios"
                className="scroll-to d-block py-2 link-normal"
              >
                Servicios
              </a>
            </li>
            <li className="list-inline-item">
              <span className="py-2 px-2 text-gray-300">•</span>
            </li>
            <li className="list-inline-item">
              <a
                href="#contacto"
                className="scroll-to d-block py-2 link-normal"
              >
                Contacto
              </a>
            </li>
            {/*<li className="list-inline-item">
          <span className="py-2 px-2 text-gray-300">•</span>
        </li>
        <li className="list-inline-item">
          <a href="#admin" className="scroll-to d-block py-2 link-normal">
            Admin
          </a>
        </li>*/}
          </ul>
        </div>

        {/* block : blog */}
        <div id="blog" className="section bg-light">
          <Blog subtitle={informacion?.blog} />
        </div>
        {/* /block : blog  */}

        {/* block : servicios */}
        <div id="servicios" className="section">
          <Servicios />
        </div>

        {/* /block : servicios */}

        {/* block : contacto */}
        <div id="contacto" className="section">
          <Contacto subtitle={informacion?.contactanos} />
        </div>
        {/* /block : contacto */}
      </div>
      {/* /#wrapper */}
    </>
  );
};

export default Inicio;
