import React, { useEffect, Suspense } from "react";
import { Outlet } from "react-router-dom";
import FooterPublicLayout from "./FooterPublicLayout";
import HeaderPublicLayout from "./HeaderPublicLayout";

//import HeaderPublicLayout from "./html_frontend/HeaderPublicLayout";
import { CartProvider } from "react-use-cart";
import Sidebar from "./Sidebar";

const PublicLayout = () => {
  useEffect(() => {
    //require("./css/public.css");
    require("./css/main.scss");
    //require('./html_frontend/assets/css/core.css');
    require("./html_frontend/assets/css/core.min.css");
    //require('./html_frontend/assets/css/vendor_bundle.css');
    require("./html_frontend/assets/css/vendor_bundle.min.css");
  }, []);

  return (
    <CartProvider>
      <body id="body" class="up-scroll">
        <HeaderPublicLayout />
        <Sidebar />
        <Outlet />
        <FooterPublicLayout />
      </body>
    </CartProvider>
  );
};

export default PublicLayout;
