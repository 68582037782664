import React, { useState, useEffect } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";

import Swal from "sweetalert2";
import Preloader from "../../utils/PreloaderElement";
import { toast } from "react-toastify";
import MensajeService from "../../../services/Contacto.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormatDate } from "../../utils/Format";

const Mensaje = ({ id, onSumit, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (id) {
      loadMensaje();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadMensaje = async () => {
    setLoading(true);
    try {
      const result = await MensajeService.getById(id);
      setMessage(result);
      console.log(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const enprocesoMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿ESTAS SEGURO DE CAMBIAR EL ESTADO A: EN PROCESO ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
        cancelButtonText: `CANCELAR`,
      }).then((result) => {
        if (result.isConfirmed) {
          message.status = "EnProceso";
          MensajeService.update(message)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          // window.location.reload();
          onSumit();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const cerradoMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿ESTAS SEGURO DE CAMBIAR EL ESTADO A: CERRADO ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
        cancelButtonText: `CANCELAR`,
      }).then((result) => {
        if (result.isConfirmed) {
          message.status = "Cerrado";
          MensajeService.update(message)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          // window.location.reload();
          onSumit();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const revisarMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿ESTAS SEGURO DE CAMBIAR EL ESTADO A: REVISAR ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
        cancelButtonText: `CANCELAR`,
      }).then((result) => {
        if (result.isConfirmed) {
          message.status = "Revisar";
          MensajeService.update(message)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          // window.location.reload();
          onSumit();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {loading || message == null ? (
        <Preloader />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} className="d-flex gap-3 align-items-center">
            <IconButton onClick={() => onClose()}>
              <ArrowBackIcon />
            </IconButton>
            <Typography typography="h6">Mensaje</Typography>
          </Grid>
          <Grid item xs={12} md={4} className="d-flex gap-3 align-items-center">
            {FormatDate(message.createdAt)}
            <Typography typography="h6"></Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography typography="subtitle2" className="fw-semibold">
              Nombre:<span>{message?.nombre?.toUpperCase()}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography typography="subtitle2" className="fw-semibold">
              Email:<span>{message?.correo?.toUpperCase()}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} className="pb-3">
            <Typography typography="body2">{message?.MensajeCuerpo}</Typography>
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-start gap-3">
            <Button
              variant={
                message?.status === "EnProceso" ? "contained" : "outlined"
              }
              color="warning"
              onClick={() => enprocesoMensaje(id)}
            >
              En proceso
            </Button>
            <Button
              variant={message?.status === "Cerrado" ? "contained" : "outlined"}
              color="success"
              onClick={() => cerradoMensaje(id)}
            >
              Cerrado
            </Button>
            <Button
              variant={message?.status === "Revisar" ? "contained" : "outlined"}
              color="error"
              onClick={() => revisarMensaje(id)}
            >
              Revisar
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Mensaje;
