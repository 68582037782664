/* React */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Button, TablePagination, TextField } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import TicketsService from "../../../services/Tickets.service";
import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.service";
import { AuthContext } from "../../../context/AuthContext";

const ListaTickets = () => {
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(null);
  const [imagen, setImagen] = useState("");
  const [tickets, setTickets] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [order, setOrder] = useState(false);

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  useEffect(() => {
    getUsuarios();
  }, []);

  useEffect(() => {
    getTickets();
  }, [page, limit]);

  const getTickets = async () => {
    setLoading(true);
    try {
      const data = await TicketsService.listByCliente({
        limit: limit,
        offset: limit * page,
        id: currentUser.uuid,
      });

      setTickets(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getUsuarios = async () => {
    setLoading(true);

    try {
      const data = await ClienteService.getById(currentUser.uuid);

      setUsuario(data);

      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        console.log(imagen);
        setImagen(imagen.result);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleDateString("es", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "numeric",
    });
  };

  const orderAsc = (a, b) => {
    if (a.total < b.total) {
      return -1;
    }
    if (a.total > b.total) {
      return 1;
    }
    return 0;
  };

  const orderDesc = (a, b) => {
    if (a.total > b.total) {
      return -1;
    }
    if (a.total < b.total) {
      return 1;
    }
    return 0;
  };

  const orderTotal = () => {
    let orderT = tickets.sort(order ? orderAsc : orderDesc);
    setTickets(orderT);
    setOrder(!order);
  };

  const orderFAsc = (a, b) => {
    let fechaa = new Date(a.fecha);
    let fechab = new Date(b.fecha);
    if (fechaa < fechab) {
      return -1;
    }
    if (fechaa > fechab) {
      return 1;
    }
    return 0;
  };

  const orderFDesc = (a, b) => {
    let fechaa = new Date(a.fecha);
    let fechab = new Date(b.fecha);
    if (fechaa > fechab) {
      return -1;
    }
    if (fechaa < fechab) {
      return 1;
    }
    return 0;
  };

  const orderFecha = () => {
    let orderF = tickets.sort(order ? orderFAsc : orderFDesc);
    setTickets(orderF);
    setOrder(!order);
  };

  const usersFilter = () => {
    if (filter) {
      return tickets.filter((usuario) =>
        usuario.uuid.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return tickets;
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <div class="container py-5">
              <h1 class="h2 fw-bold">Mis tickets</h1>
            </div>

            <div class="container pb-6">
              <div class="row g-4">
                {/* <!-- sidebar --> */}
                <div class="col-12 col-lg-3">
                  <nav class="sticky-kit nav-deep nav-deep-light">
                    <div class="card">
                      <div class="card-body p-0 p-md-4">
                        {/* <!-- mobile only --> */}
                        <button
                          class="clearfix btn btn-toggle btn-sm w-100 text-align-left shadow-md border rounded d-block d-lg-none"
                          data-bs-target="#nav_responsive"
                          data-toggle-container-class="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
                        >
                          <span class="group-icon px-2 py-2 float-start">
                            <i class="fi fi-bars-2"></i>
                            <i class="fi fi-close"></i>
                          </span>

                          <span class="h5 py-2 m-0 float-start">
                            Account Menu
                          </span>
                        </button>

                        {/* <!-- navbar : navigation --> */}
                        <ul
                          id="nav_responsive"
                          class="nav flex-column d-none d-lg-block mt-1 mt-lg-0"
                        >
                          <li>
                            {/* <!-- account name --> */}
                            <div class="d-flex align-items-center mb-2 pb-4 border-bottom">
                              <div class="flex-none p-3 border rounded-circle">
                                <svg
                                  class="text-gray-300"
                                  width="34px"
                                  height="34px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 460.8 460.8"
                                  fill="currentColor"
                                >
                                  <path d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641S296.261,0,230.432,0z"></path>
                                  <path d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898C436.8,341.682,437.322,338.024,435.755,334.89z"></path>
                                </svg>
                              </div>
                              <div class="w-100 px-3">
                                <span>Bienvenido,</span>
                                <span class="d-block fw-bold">
                                  {usuario?.nombre}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link px-0 d-flex align-items-center"
                              href="javascrip:void(0)"
                            >
                              <svg
                                class="text-gray-600 float-start"
                                width="18px"
                                height="18px"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M8 3.293l6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                                ></path>
                              </svg>
                              <span>Cliente</span>
                            </a>
                          </li>
                          <li class="nav-item active">
                            <a
                              class="nav-link px-0 d-flex align-items-center"
                              href="javascrip:void(0)"
                            >
                              <svg
                                class="text-gray-600 float-start"
                                width="18px"
                                height="18px"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3zm4.354 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                ></path>
                              </svg>
                              <span>Tickets {total}</span>
                            </a>
                          </li>

                          <li class="nav-item border-top my-3"></li>

                          <li class="nav-item"></li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>

                {/* <!-- content --> */}
                <div class="col-12 col-lg-9">
                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="dropdown">
                        <a
                          id="dropdownMenuFilter"
                          href="javascript:void(0)"
                          class="text-dark"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>Buscar ticket</span>
                        </a>
                      </div>
                      <div class="dropdown w-100">
                        <input
                          type="text"
                          class="dropdown-toggle form-control form-control-sm border-0 shadow-none ps-5 bg-gray-100"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          placeholder="ID"
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            paddingTop: "0.5rem",
                          }}
                        >
                          <a
                            href="javascript:void(0);"
                            class="d-flex link-muted"
                            title="order by price"
                            aria-label="order by inventory"
                            onClick={() => orderTotal()}
                          >
                            <span class="d-flex flex-column lh-1">
                              <svg
                                class="lh-1 text-primary"
                                width="13px"
                                height="13px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 15l7-7 7 7"
                                ></path>
                              </svg>

                              <svg
                                class="lh-1 mt-n1 text-muted"
                                width="13px"
                                height="13px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 9l-7 7-7-7"
                                ></path>
                              </svg>
                            </span>
                            <span class="ms-2">TOTAL</span>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="d-flex link-muted"
                            title="order by inventory"
                            aria-label="order by inventory"
                            onClick={() => orderFecha()}
                          >
                            <span class="d-flex flex-column lh-1">
                              <svg
                                class="lh-1 text-primary"
                                width="13px"
                                height="13px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 15l7-7 7 7"
                                ></path>
                              </svg>

                              <svg
                                class="lh-1 mt-n1 text-muted"
                                width="13px"
                                height="13px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 9l-7 7-7-7"
                                ></path>
                              </svg>
                            </span>
                            <span class="ms-2">Fecha entrega</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {usersFilter().length < 1 && (
                    <a href="javascript:void(0)" class="card mb-3 link-normal">
                      <div class="card-body p-lg-4">
                        <div class="row">
                          <div class="col">
                            <h5 class="fw-bold">Sin coincidencias</h5>
                            <p class="mb-0 text-dark small"></p>
                          </div>

                          <div class="col-12 border-top border-light my-2 d-lg-none">
                            {/* <!-- mobile divider --> */}
                          </div>

                          <div class="col-lg-4 text-lg-end text-dark">
                            <span class="d-block fw-medium text-info"></span>
                            <span class="d-block small"></span>
                          </div>
                        </div>
                      </div>
                    </a>
                  )}

                  {/* <!-- item --> */}
                  {usersFilter().map((record, index) => (
                    <a
                      href="javascript:void(0)"
                      class="card mb-3 link-normal"
                      onClick={() => navigate(`/ticket/${record.uuid}`)}
                    >
                      <div class="card-body p-lg-4">
                        <div class="row">
                          <div class="col">
                            <h5 class="fw-bold">Ticket no. {record.uuid}</h5>
                            <p class="mb-0 text-dark small">
                              Fecha: {formatDate(record.fecha)}| Total: $
                              {record.total}
                            </p>
                          </div>

                          <div class="col-12 border-top border-light my-2 d-lg-none">
                            {/* <!-- mobile divider --> */}
                          </div>

                          <div class="col-lg-4 text-lg-end text-dark">
                            <span class="d-block fw-medium text-info">
                              {record.pagado}
                            </span>
                            <span class="d-block small">
                              {record.estadopago}
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}

                  <div class="col-md-auto py-3">
                    {/* <!-- pagination --> */}
                    <TablePagination
                      style={{ color: "var(--color-dark-variant)" }}
                      component="div"
                      classes="recent-orders"
                      labelRowsPerPage="Items por pagina"
                      count={total}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default ListaTickets;
