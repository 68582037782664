/* React */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import {
  Button,
  Fade,
  TablePagination,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";
import { AuthContext } from "../../../context/AuthContext";
/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import TicketsService from "../../../services/Tickets.service";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Document from "./Document/Document";

const TicketDetails = () => {
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(null);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [pagado, setPagado] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [nota, setNota] = useState("");
  const newdate = new Date();
  const [ticket, setTicket] = useState(null);
  const [latest, setLatest] = useState([]);
  const [openModalPago, setOpenModalPago] = useState(false);
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [metodoPago, setMetodoPago] = useState("Efectivo");
  const [estadoPago, setEstadoPago] = useState("Pendiente");
  const [pagos, setPagos] = useState([]);
  const [update, setUpdate] = useState(false);

  const onUpdate = () => {
    setUpdate(!update);
  };
  const handleOpenModalPago = () => {
    setOpenModalPago(!openModalPago);
  };

  const handleMetodoPago = (newMetodoPago) => {
    console.log(newMetodoPago);
    setMetodoPago(newMetodoPago.target.value);
  };

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleDateString("es", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "numeric",
    });
  };

  useEffect(() => {
    getTicket();
    // getLatestTickets();
  }, [id, update]);

  const getTicket = async () => {
    console.log(id);
    setLoading(true);
    try {
      const data = await TicketsService.getById(id);
      console.log(data);
      setProductos([]);
      setTotal(0);
      setUsuario(data.cliente);
      setTicket(data);
      setNota(data.nota);
      setPagado(Number(data.pagado));
      setPagos(data.pagos);
      for (const detalle of data.detallesTiket) {
        let newProduct = {
          cantidad: detalle.cantidad,
          value: {
            label: detalle.producto.nombre,
            id: detalle.producto.id,
            categoria: detalle.producto.categoria?.tipo,
            precio: detalle.producto.precios[0]?.precio,
            descuento: detalle.producto.descuento,
          },
        };
        addProduct(newProduct);
      }
      // data.detallesTiket.map((detalle) => {
      //   addProduct({
      //     cantidad: detalle.cantidad,
      //     value: {
      //       label: detalle.producto.nombre,
      //       id: detalle.producto.id,
      //       categoria: detalle.producto.categoria?.tipo,
      //       precio: detalle.producto.precios[0]?.precio,
      //       descuento: detalle.producto.descuento,
      //     },
      //   });
      // });
      const dataLatest = await TicketsService.getLatest(data.cliente.uuid);
      setLatest(dataLatest);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getLatestTickets = async () => {
    console.log(id);
    setLoading(true);
    try {
      const data = await TicketsService.getLatest(usuario.uuid);
      console.log(data);
      setLatest(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const addPago = async (cantidad) => {
    if (cantidad.cantidad <= 0) {
      return;
    }
    setLoading(true);
    try {
      let data = {
        uuid: id,
        cantidad: cantidad.cantidad,
        metodopago: metodoPago,
        usuario: currentUser.id,
      };
      const result = await TicketsService.pago(data);
      setPagos([]);
      toast.success("Pago guardado");
      onUpdate();
    } catch (error) {
      toast.error("No se guardado el pago");
      setLoading(false);
    }
  };

  const addProduct = (newProduct) => {
    console.log(newProduct);
    setProductos((prev) => [...prev, newProduct]);

    setTotal(
      (prev) =>
        prev + Number(newProduct.value.precio) * Number(newProduct.cantidad)
    );
  };

  const toMoney = (value) => {
    const money = Number(value);

    if (isNaN(money)) {
      return value;
    }

    return money.toLocaleString("en", {
      style: "currency",
      currency: "MXN",
    });
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <div class="container py-5">
              <h1 class="h2 fw-bold">Detalles ticket</h1>

              <ul class="list-inline small text-muted m-0">
                <li class="list-inline-item">
                  <a
                    href="javascript:void(0)"
                    class="d-flex align-items-center link-muted"
                    onClick={() => navigate("/tickets")}
                  >
                    <svg
                      width="18px"
                      height="18px"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                      ></path>
                    </svg>
                    <span class="text-muted px-1">regresar a tickets</span>
                  </a>
                </li>
              </ul>
            </div>
            {/* <!-- /PAGE TITLE --> */}
            <div class="container pb-6">
              <div class="row g-4">
                <div class="col-12 col-lg-3">
                  <div class="mb-3">
                    <div class="fw-bold d-flex align-items-center mb-3">
                      <svg
                        class="flex-none text-muted me-2"
                        width="18px"
                        height="18px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"></path>
                        <polyline points="8 10 12 14 16 10"></polyline>
                      </svg>
                      <span class="w-100">Ultimos tickets</span>
                    </div>

                    <div class="d-flex mt-2">
                      <span class="d-block" style={{ width: "159px" }}>
                        Total
                      </span>
                      <span class="d-blockl">Estado pago:</span>
                    </div>

                    {latest.map((t) => (
                      <div class="d-flex mt-2">
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigate(`/ticket/${t.uuid}`)}
                          class="fw-medium d-block"
                        >
                          {toMoney(t.total)}
                          <span class="d-block smaller text-muted">
                            {t.uuid}
                          </span>
                        </a>

                        <span class="rounded-sm p-2 small">{t.estadopago}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div class="col-12 col-lg-9">
                  {/* <!-- Order info --> */}
                  <div class="card mb-4">
                    <div class="card-body p-lg-4">
                      <h4 class="mb-0">Ticket no. {ticket.uuid}</h4>

                      <ul class="list-unstyled m-0 p-0">
                        <li class="list-item">
                          <span class="text-muted small">
                            Date:{formatDate(ticket.createdAt)}
                          </span>
                        </li>

                        <li class="list-item">
                          <span class="badge bg-success-soft rounded-pill">
                            <span class="bull bull-lg animate-pulse-success bg-success me-2"></span>
                            <span class="text-dark">{ticket.estadopago}</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- /Order info --> */}

                  {/* <!-- Addresses --> */}
                  <div class="card">
                    <div class="card-body p-lg-4">
                      <div class="row g-4">
                        <div class="col-md-6 col-xl-4">
                          <h5 class="fw-bold mb-4">Contacto</h5>

                          <div class="small">
                            <span class="d-block text-muted">Nombre</span>
                            <p>
                              {usuario.nombre} <br />
                              {usuario.telefono}
                              <br />
                              {usuario.correo}
                            </p>

                            <span class="d-block text-muted">Dirección</span>
                            <p class="mb-0">{usuario.direccion}</p>
                          </div>
                        </div>

                        {/* <!-- separatos --> */}
                        <div class="col-12 border-top d-md-none">
                          {/* <!-- mobile --> */}
                        </div>
                        <div
                          class="col px-0 border-start d-none d-md-block"
                          style={{ maxWidth: "1px" }}
                        >
                          {/* <!-- desktop --> */}
                        </div>

                        <div class="col-md-5 col-xl-4">
                          <h5 class="fw-bold mb-4">Pagos</h5>

                          <div class="small">
                            {pagos?.map((pag) => (
                              <>
                                <div class="d-flex">
                                  <span
                                    class="flex-none text-muted"
                                    style={{ width: "120px" }}
                                  >
                                    <span class="badge bg-secondary"></span>
                                    Monto
                                  </span>
                                  <span>{toMoney(pag.precio)}</span>
                                </div>
                                <a
                                  class="link-muted small"
                                  href="javascript:void(0)"
                                >
                                  {formatDate(pag.createdAt)} ${pag.metodopago}
                                </a>
                              </>
                            ))}
                          </div>
                        </div>

                        {/* <!-- separatos --> */}
                        <div class="col-12 border-top d-xl-none">
                          {/* <!-- mobile --> */}
                        </div>
                        <div
                          class="col px-0 border-start d-none d-xl-block"
                          style={{ maxWidth: "1px" }}
                        >
                          {/* <!-- desktop --> */}
                        </div>

                        <div class="col d-flex flex-column">
                          <div class="h-100">
                            <h5 class="fw-bold mb-4"></h5>
                            <p>Tipo de servicio: {ticket.tiposervicio}</p>
                            <p>Fecha de entrega:{formatDate(ticket?.fecha)}</p>
                          </div>

                          {/* <!-- invoice print --> */}
                          <div class="mt-3 self-align-baseline">
                            <p>Total ticket: {toMoney(total)}</p>

                            <a
                              href="javascript:void(0)"
                              class="text-decoration-none border border-primary border-dashed rounded d-block text-center px-2 p-1"
                            >
                              <svg
                                class="me-2"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="6 9 6 2 18 2 18 9"></polyline>
                                <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
                                <rect x="6" y="14" width="12" height="8"></rect>
                              </svg>
                              <PDFDownloadLink
                                document={<Document id={id} />}
                                fileName={`LET_TICKET_${newdate.toLocaleDateString()}.pdf`}
                                style={{ padding: "0.5rem" }}
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Cargando..." : "Descargar PDF"
                                }
                              </PDFDownloadLink>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Delivery method --> */}
                  <div class="py-1 small border-top pt-3 mt-6"></div>

                  {/* <!-- Items --> */}
                  {productos?.map((product, index) => (
                    <div class="">
                      {/* <!-- item --> */}
                      <div class="border-bottom pb-3 mb-3 d-flex">
                        <a
                          class="flex-none link-normal fw-medium me-3"
                          href="javascript:void(0)"
                        >
                          <div
                            class="ratio ratio-1x1"
                            style={{ width: "80px" }}
                          >
                            <span class="d-flex justify-content-center align-items-center">
                              {/* <img
                                class="h-100 border border-light p-1"
                                src="demo.files/images/unsplash/products/speakers/12-min.jpg"
                                alt="..."
                              /> */}
                            </span>
                          </div>
                        </a>

                        <div class="row g-3 w-100">
                          <div class="col">
                            <a
                              href="javascript:void(0)"
                              class="link-normal fw-medium"
                            >
                              {product.value.label}
                            </a>
                            <span class="d-block text-muted small">
                              {product.value.categoria}
                            </span>
                            <span class="d-block text-muted small text-uppercase">
                              {toMoney(product.value.precio)}
                            </span>
                          </div>
                          <div class="col-lg-3 text-lg-end">
                            {toMoney(
                              Number(product.value.precio) *
                                Number(product.cantidad)
                            )}
                            <small class="d-block">
                              {product.cantidad} unidades
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <!-- Subtotals --> */}
                  <div class="row g-4 justify-content-end">
                    <div class="col"></div>

                    <div class="col-md-8 col-lg-7 text-end">
                      <dl class="row mb-0">
                        <dt class="col-6 fw-normal">Articulos:</dt>
                        <dd class="col-6 fw-normal">{productos?.length}</dd>
                        <dt class="col-6 fw-normal">Pagado por cliente:</dt>
                        <dd class="col-6 fw-normal">{toMoney(pagado)}</dd>

                        <dt class="col-6 fw-bold text-danger">
                          Total a pagar:
                        </dt>
                        <dd class="col-6 fw-bold text-danger">
                          {toMoney(total)}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /Order info --> */}
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TicketDetails;
