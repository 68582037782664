import React from "react";
import { Link, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  subcontainer: {
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "center",
    textTransform: "uppercase",
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    textTransform: "uppercase",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  name: {
    fontWeight: "bold",
  },
  subtitle: {
    justifySelf: "flex-end",
  },
  link: {
    color: "black",
    textDecoration: "none",
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  rowcontent: {
    marginTop: 2,
    paddingBottom: 2,
    borderBottomWidth: 0.5,
    borderBottomColor: "gray",
    borderBottomStyle: "dashed",
    width: "97%",
    opacity: 0.7,
    rowGap: 5,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    flexWrap: "wrap",
    textAlign: "center",
    rowGap: 5,
  },
  logo: { width: "98%", marginTop: 4 },
});

const Header = ({ src, date, text }) => (
  <>
    <View style={styles.container}>
      <View style={styles.subcontainer}>
        <Image style={styles.logo} src={src} />
        {/* <Text style={styles.subtitle}>{text}</Text> */}
      </View>
    </View>
    <View style={styles.rowcontent}>
      <View style={styles.row}>
        <Text>{text.propietario}</Text>
      </View>
      <View style={styles.row}>
        <Text>{text.direccion}</Text>
        <Text>{text.estado + "C.P. " + text.cp}</Text>
      </View>
      <View style={styles.row}>
        <Text>TELS {text.tel}</Text>
        <Text> RFC {text.rfc}</Text>
      </View>
      <View style={styles.row}></View>
    </View>
  </>
);

export default Header;
