import httpClient from "./HttpClient";
const prefix = "/ticket";

export default class TicketsService {
  static async create(servicio) {
    return (await httpClient.post(`${prefix}/`, servicio)).data;
  }

  static async pago(servicio) {
    return (await httpClient.post(`${prefix}-pago/`, servicio)).data;
  }

  static async resendEmail(servicio) {
    return (await httpClient.post(`${prefix}-resendemail/`, servicio)).data;
  }

  static async resendPrint(servicio) {
    return (await httpClient.post(`${prefix}-resendprint/`, servicio)).data;
  }

  static async update(servicio) {
    return (await httpClient.put(`${prefix}/${servicio.uuid}`, servicio)).data;
  }

  static async updateTicket(servicio) {
    return (await httpClient.put(`${prefix}E/${servicio.uuid}`, servicio)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async getTotal() {
    return (await httpClient.get(`${prefix}-total/`)).data;
  }

  static async getLatest(id) {
    return (await httpClient.get(`${prefix}-latest/${id}`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }

  static async listSalida(limit, offset) {
    return (await httpClient.get(`${prefix}-salidas/${limit}/${offset}`)).data;
  }
  static async listByCliente(data) {
    return (await httpClient.post(`${prefix}s-cliente/`, data)).data;
  }

  static async listByClienteSearch(data) {
    return (await httpClient.post(`${prefix}s-cliente/search`, data)).data;
  }

  static async allByClienteSearch(data) {
    return (await httpClient.post(`${prefix}s-tickets/search`, data)).data;
  }

  static async listBySearchSalida(data) {
    return (await httpClient.post(`${prefix}s-salida/search`, data)).data;
  }

  static async listCorte(data) {
    return (await httpClient.post(`${prefix}s-corte/`, data)).data;
  }

  static async listCortePagos(data) {
    return (await httpClient.post(`${prefix}s-cortepagos/`, data)).data;
  }

  static async registrarCorte(data) {
    return (await httpClient.post(`${prefix}s-corte/registrar`, data)).data;
  }

  static async listFecha(data) {
    return (await httpClient.post(`${prefix}s-fecha/`, data)).data;
  }
  static async listFechaGroup(data) {
    return (await httpClient.post(`${prefix}s-fecha-group/`, data)).data;
  }
  static async listPagoFecha(data) {
    return (await httpClient.post(`${prefix}s-pagofecha/`, data)).data;
  }
  static async listPagoFechaPagos(data) {
    return (await httpClient.post(`${prefix}s-pagofechapagos/`, data)).data;
  }
  static async listFechaGeneradas(data) {
    return (await httpClient.post(`${prefix}s-fecha/generados`, data)).data;
  }
  static async listFechaPendientes(data) {
    return (await httpClient.post(`${prefix}s-fecha/pendientes`, data)).data;
  }
}
