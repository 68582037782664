/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Switch, Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import DropComponent from "../imagesDrop";

/* Servicios */
import NoticiaService from "../../../services/Producto.service";
import S3Service from "../../../services/S3.service";
import CategoriaBlogService from "../../../services/CategoriaProducto.service";

const EditarProducto = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});

  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contenido, setContenido] = useState("");
  const [imagenesProducto, setImagenesProducto] = useState([]);

  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [descuento, setDescuento] = useState(0);
  const [categoria, setCategoria] = useState(undefined);
  const [precio, setPrecio] = useState(0);
  const [disponible, setDisponible] = useState(false);

  useEffect(() => {
    getNoticia();
    getCategorias();
  }, []);

  const handleDisponible = () => {
    setDisponible(!disponible);
  };

  const getNoticia = async () => {
    try {
      const data = await NoticiaService.getById(id);

      setNombre(data.nombre);
      setDescuento(data.descuento);
      setDescripcion(data.descripcion);
      setPrecio(data.precios[0]?.precio);
      setCategoria(data.categoria);
      setDisponible(data.disponible);

      const contentHTML = convertFromHTML(data.descripcion);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );

      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);
      console.log("cateegoria: " + data.categoria);

      let imagenesOld = [];
      for (const imagen of data.imagenes) {
        console.log(imagen);
        const result = await S3Service.get(imagen);
        // setImagenesProducto((imagenesProducto) => [
        //   ...imagenesProducto,
        //   result.result,
        // ]);
        imagenesOld.push(result.result);
        console.log(result.result);
      }
      console.log(imagenesOld);
      setImagenesProducto((prev) => [...prev, ...imagenesOld]);
      console.log(imagenesProducto);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategorias(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  const updateNoticia = async () => {
    if (!nombre) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (descripcion.length < 5) {
      toast.info("Error, tienes que colocar una descripcion");
    } else {
      setLoading(true);
      try {
        let imagenesArray = []; //Array para los id del s3

        for (const file of imagenesProducto) {
          if (!file.urlImaen) {
            const resultFile = await S3Service.upload(file);
            imagenesArray.push(resultFile.result.data);
          } else {
            const idImagen = file.urlImaen.split("/")[3].split("?")[0];
            imagenesArray.push(idImagen);
          }
        }

        const dataNoticia = {
          id,
          nombre,
          descripcion,
          descuento,
          precios: [{ precio: precio }],
          imagenes: imagenesArray,
          disponible,
        };

        if (categoria) {
          dataNoticia.categoria = categoria;
        }
        const updateNoticia = await NoticiaService.update(dataNoticia);

        toast.success("Guardado!");
        navigate("/producto");
      } catch (error) {
        toast.error("No se ha podido guardar");
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePrecio = (e) => {
    setPrecio(e.target.value);
  };

  const myTheme = createTheme({});

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto text-uppercase">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Editar</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/producto")}
                    >
                      Producto
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Editar
                  </li>
                </ol>
              </nav>
            </header>

            <div class="card border-0 h-100">
              <div class="card-body p-4 h-100 row g-4">
                <div class="col-sm-12 col-lg-6 mb-5">
                  <div class="fw-bold d-flex align-items-center mb-3">
                    <span class="w-100">Nuevo producto</span>
                  </div>

                  <div class="small" style={{ marginLeft: "1.5rem" }}>
                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Nombre
                      </span>
                      <span style={{ width: "297px" }}>
                        <input
                          type="text"
                          className="form-control shadow-none text-uppercase"
                          placeholder="NOMBRE"
                          onChange={(e) =>
                            setNombre(e.target.value.toUpperCase())
                          }
                          value={nombre}
                        />
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Disponible
                      </span>
                      <span>
                        <Switch
                          checked={disponible}
                          onChange={handleDisponible}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Precio
                      </span>
                      <span style={{ width: "297px" }}>
                        <input
                          type="number"
                          className="form-control shadow-none"
                          placeholder="Precio $"
                          onChange={handlePrecio}
                          value={precio}
                        />
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Descuento
                      </span>
                      <span style={{ width: "297px" }}>
                        <input
                          type="number"
                          className="form-control shadow-none"
                          placeholder="Descuento %"
                          onChange={(e) => setDescuento(e.target.value)}
                          value={descuento}
                        />
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Categoria
                      </span>
                      <span>
                        <select
                          class="form-select shadow-none"
                          id="shipping__country"
                          name="shipping[country]"
                          aria-label="Country"
                          onChange={(e) => {
                            setCategoria(e.target.value);
                          }}
                          defaultValue={categoria}
                        >
                          <option disabled="disabled" selected="selected">
                            SELECCIONA UNA
                          </option>
                          {categorias.map((record, index) => {
                            if (record.id === categoria) {
                              return (
                                <option value={record.id} selected="selected">
                                  {record.tipo.toUpperCase()}
                                </option>
                              );
                            } else {
                              return (
                                <option value={record.id}>
                                  {record.tipo.toUpperCase()}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Descripcion
                      </span>
                      <span>
                        {" "}
                        <ThemeProvider theme={myTheme}>
                          <MUIRichTextEditor
                            label="Descripcion... "
                            controls={[
                              "italic",
                              "underline",
                              "strikethrough",
                              "highlight",
                              "undo",
                              "redo",
                              "link",
                              "numberList",
                              "bulletList",
                              "quote",
                              "code",
                              "clear",
                              // "save"
                            ]}
                            // onSave={save}
                            inlineToolbar={true}
                            onChange={(value) =>
                              setDescripcion(
                                stateToHTML(value.getCurrentContent())
                              )
                            }
                            defaultValue={contenido}
                          />
                        </ThemeProvider>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 mb-3">
                  <div
                    style={{
                      textAlign: "center",
                      filter: "grayscale(80%)",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src={
                        "/img/vecteezy_laundry-service-posters_red-min-min.svg"
                      }
                      style={{
                        height: "100%",
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right", marginTop: "1.8rem" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => updateNoticia()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span>Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EditarProducto;
