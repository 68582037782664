import httpClient from "./HttpClient";
const prefix = "/cortes";

export default class CortesService {
  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async listByCorteSearch(data) {
    return (await httpClient.post(`${prefix}/search`, data)).data;
  }

  static async listCortesById(data) {
    return (await httpClient.post(`${prefix}-list/${data.id}`, data)).data;
  }

  static async listCortePagos(data) {
    return (await httpClient.post(`${prefix}-cortepagos/`, data)).data;
  }

  static async listCorte(data) {
    return (await httpClient.post(`${prefix}/salida-corte/`, data)).data;
  }
  static async listPagoFechaPagos(data) {
    return (await httpClient.post(`${prefix}-pagofechapagos/`, data)).data;
  }
  static async listPagoFecha(data) {
    return (await httpClient.post(`${prefix}-pagofecha/`, data)).data;
  }

  static async iniciarJornada(data) {
    return (await httpClient.post(`${prefix}-iniciarJornada/`, data)).data;
  }

  static async revisarJornada() {
    return (await httpClient.get(`${prefix}-jornada/`)).data;
  }
}
