import React from "react";

import { Link, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const DatosOrden = ({ serie, fecharecibido, fechaentrega, styles }) => (
  <View style={styles.rowcontentOrden}>
    <View style={styles.rowOrden}></View>

    <View
      style={[styles.rowOrden, styles.blacktextOrden, styles.sizeTextOrden]}
    >
      <View style={{ width: "30%" }}>
        <Text>ORDEN:</Text>
      </View>
      <View style={{ width: "70%", textAlign: "center" }}>
        <Text>{" " + serie}</Text>
      </View>
    </View>

    <View style={styles.rowOrden}>
      <Text>FECHA ENTREGA:</Text>
      <Text>{" " + fechaentrega}</Text>
    </View>
    <View style={styles.rowOrden}></View>
  </View>
);

export default DatosOrden;
