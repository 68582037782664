import { useEffect, useMemo, useState } from "react";
import ClienteService from "../../../services/User.Service";
import { Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";

const BuscarResponsable = ({ onSumit, responsableServicio, label }) => {
  const [productos, setProductos] = useState([]);
  const [value, setValue] = useState(responsableServicio);
  const [inputValue, setInputValue] = useState("");
  const [cantidad, setCantidad] = useState(1);

  const agregar = (val) => {
    if (!val) {
      toast.info("Selecciona un usuario");
      return;
    }
    onSumit({ value: val });
    setValue(val);
    // closeModal();
  };
  const moreCantidad = () => {
    setCantidad((prev) => prev + 1);
  };

  const subCantidad = () => {
    if (cantidad > 1) {
      setCantidad((prev) => prev - 1);
    }
  };

  const getProductos = async () => {
    try {
      const data = await ClienteService.getAll();
      let productosFormat = [];
      let indexselected = -1;
      data.map((prod) => {
        return productosFormat.push({
          label: prod.nombre.toUpperCase(),
          id: prod.id,
          correo: prod.correo,
          telefono: prod.tipo,
        });
      });
      console.log(data);
      setProductos(productosFormat);
    } catch (error) {}
  };

  useEffect(() => {
    getProductos();
  }, []);

  return (
    <>
      <div>
        <div>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              event.preventDefault();
              agregar(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disablePortal
            id="combo-box-responsable"
            componentName="combo-box-responsable"
            options={productos}
            fullWidth
            renderInput={(params) => <TextField {...params} label={label} />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <div class="mt-2" id="search-container">
            <div class="py-2">
              <label>Cargo:</label>
              {/* <!-- appended item --> */}
              <a href="javascript:void(0);" class="link-normal"></a>
              <a href="javascrip:void(0);" class="text-decoration-none ms-2">
                {value?.telefono}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuscarResponsable;
