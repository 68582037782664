/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import Button from "@mui/material/Button";
import DropZone from "../../DropZone";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";

/* services */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.service";

const EliminarCarrusel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [carrusel, setCarrusel] = useState();

  useEffect(() => {
    loadCarruselItem();
  }, []);

  const loadCarruselItem = async () => {
    setLoading(true);
    try {
      const result = await CarruselService.getById(parseInt(id));
      if (result.imagen) {
        const imagen = await S3Service.get(result.imagen);
        setImagen(imagen.result);
      }
      setCarrusel(result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(true);
      return;
    }
  };

  const eliminarCarrusel = async () => {
    setLoading(true);
    try {
      await CarruselService.remove(parseInt(id));
      toast.info("Item de carrusel eliminado");
      navigate("/carousel");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Editar Item</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/carousel")}
                    >
                      Carrusel
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Eliminar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold">
                      Eliminar imagen
                    </div>
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={carrusel?.titulo}
                              disabled={true}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              value={carrusel?.subtitulo}
                              disabled={true}
                              placeholder="Mensaje"
                            />
                            <label htmlFor="shipping__last_name">Mensaje</label>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="form-floating my-3">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              value={carrusel?.url}
                              disabled={true}
                              placeholder="Url"
                            />
                            <label
                              className="w-100 text-truncate"
                              htmlFor="shipping__address_1"
                            >
                              Url
                            </label>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-floating my-3">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              value={carrusel?.boton}
                              disabled={true}
                              placeholder="Texto del botón. Ej:ver más"
                            />
                            <label
                              className="w-100 text-truncate"
                              htmlFor="shipping__address_1"
                            >
                              Texto
                            </label>
                          </div>
                        </div>
                        {!file && carrusel.imagen ? (
                          <>
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={String(imagen)}
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                  width: "-webkit-fill-available",
                                }}
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => eliminarCarrusel()}
                >
                  {" "}
                  {"  "}
                  <span className="small">Eliminar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EliminarCarrusel;
