import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";

const CambiarSerie = ({ onSumit, numberSerie, digitos, serie, closeModal }) => {
  const [value, setValue] = useState(null);
  const [cantidad, setCantidad] = useState(0);

  const agregar = async () => {
    if (!cantidad) {
      toast.info("Ingresa un valor valido");
      return;
    }
    let cadena = cantidad.toString();
    if (cantidad >= 0 && cadena.length <= digitos) {
      onSumit(cantidad);

      closeModal();
    } else if (cadena.length > digitos) {
      toast.info("La cantidad de dígitos no es valida");
    } else {
      toast.info("Ingresa un valor valido");
    }
  };

  return (
    <>
      <div class="modal-body">
        <div class="form-floating">
          <div class="mb-5">serie</div>
        </div>
        <div
          class="form-check mt-2 d-flex"
          style={{ paddingLeft: "0", fontSize: "2rem" }}
        >
          <label className="me-3">{serie}</label>
          <CurrencyInput
            id="input-pago"
            name="input-pago"
            placeholder="Ingresa el número"
            prefix="-"
            allowNegativeValue={false}
            defaultValue={numberSerie}
            decimalsLimit={0}
            onValueChange={(value, name) => setCantidad(value)}
            style={{
              border: "none",
              backgroundColor: "whitesmoke",
              padding: "0.2rem",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>
      <div class="modal-footer" style={{ justifyContent: "space-between" }}>
        <div></div>
        <div></div>
        <button type="submit" class="btn btn-primary" onClick={agregar}>
          <svg
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span>Cambiar</span>
        </button>
      </div>
    </>
  );
};

export default CambiarSerie;
