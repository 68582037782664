import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
const ItemPaquete = ({
  id,
  titulo,
  precioU,
  precioE,
  descuento,
  imagenes,
  descripcion,
  dias,
  tipo,
}) => {
  const navigate = useNavigate();
  let precioFinal = 0;
  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState(0);
  let precios = 0;
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  if (descuento > 0) {
    const descuentoProps = descuento;
    const precioOriginal = precio;
    precioFinal = precioOriginal - 100 / (precioOriginal / descuentoProps);
  }

  if (tipo == "Empresa") {
    if (precioE == null) {
      precios = 0;
    } else {
      for (const pre of precioE) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  } else {
    if (precioU == null) {
      precios = 0;
    } else {
      for (const pre of precioU) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  }

  useEffect(() => {
    if (imagenes.length > 0) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imag);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const ImpDescuento = () => {
    if (descuento > 0) {
      return (
        <div className="badge badge-primary badge-rounded-circle">
          <span className="d-block">
            {" "}
            {descuento}%
            <br />
            off{" "}
          </span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <div className="col-md-6 col-xl-4 mb-5">
      <a href="javascript:void(0)" onClick={() => navigate(`/paquete/${id}`)}>
        <div className="card card-hover">
          <a href="javascript:void(0)" className="position-relative">
            <img
              className="card-img-top lazyestload"
              data-src="assets/img/home/deal/deal-01.jpg"
              src={imag}
              alt="Card image cap"
              height="250"
              width="250"
            />
            <div className="card-img-overlay card-hover-overlay rounded-top d-flex flex-column">
              {ImpDescuento()}
              <ul className="list-unstyled d-flex mt-auto text-warning mb-0">
                <li>
                  <i className="fa fa-star mr-1" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star mr-1" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star mr-1" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star mr-1" aria-hidden="true" />
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true" />
                </li>
              </ul>
              <small>
                <ul className="list-unstyled d-flex text-white font-weight-bold mb-0">
                  <li className="border-right border-white pr-2">
                    Días: {dias?.length}
                  </li>
                  <li className="border-right border-white px-2">
                    Noches: {dias?.length - 1}
                  </li>
                  <li className="pl-2">15 min</li>
                </ul>
              </small>
            </div>
          </a>
          <div className="card-body px-4">
            <h5 style={{ height: "35px" }}>
              <a
                href="javascript:void(0)"
                className="card-title text-uppercase"
              >
                {titulo}
              </a>
            </h5>
            <p
              dangerouslySetInnerHTML={{
                __html: descripcion.slice(0, 211),
              }}
              style={{ textAlign: "justify", minHeight: "146px" }}
            ></p>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ height: "60px" }}
            >
              <div>
                <p className="mb-0 text-capitalize">Desde</p>
                <h3 className="text-primary">${precios}</h3>
              </div>
              <div>
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#inquiry"
                  className="btn btn-xs btn-outline-secondary text-uppercase"
                >
                  Detalles
                </a>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </a>
    </div>
  );
};

export default ItemPaquete;
