import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useContext, useEffect, useState } from "react";

const Sidebar = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(undefined);

  const salir = () => {
    logout();
    navigate("/");
  };

  const toggleCloseAsideBar = () => {
    let element;
    element = document.querySelector("#aside-main");
    if (element.style.marginLeft === "-100%") {
    } else {
      element.style.marginLeft = "-100%";
    }
  };

  useEffect(() => {
    if (currentUser === undefined) {
      setUsuario(undefined);
    } else {
      setUsuario(currentUser);
    }
  }, [currentUser]);

  return (
    <aside
      id="aside-main"
      class="aside-start aside-secondary fw-light aside-hide-md shadow-xs d-flex align-items-stretch justify-content-lg-between align-items-start flex-column"
      style={{
        height: "100vh",
        position: "fixed",
        zIndex: "9999",
        marginLeft: "-100%",
      }}
    >
      {/* <!-- 
    OPTIONAL LOGO 
    visibility : desktop only
    .d-none + .d-sm-block
--> */}
      <div class="clearfix px-3 py-4 mb-1 text-center border-bottom">
        {/* <i class="fi fi-shield-ok fs-1"></i>] */}
        <h2 class="h5">LA ESPERANZA</h2>
      </div>
      {/* <!-- /LOGO --> */}

      <div class="aside-wrapper scrollable-vertical scrollable-styled-dark align-self-baseline h-100 w-100">
        {/* <!--

        All parent open navs are closed on click!
        To ignore this feature, add .js-ignore to .nav-deep

        Links height (paddings):
            .nav-deep-xs
            .nav-deep-sm
            .nav-deep-md  	(default, ununsed class)

        .nav-deep-hover 	hover background slightly different
        .nav-deep-bordered	bordered links
    --> */}
        <nav class="nav-deep nav-deep-light">
          <ul class="nav flex-column">
            <li class="nav-item active">
              <a
                class="nav-link"
                href="javascript:void(0)"
                onClick={() => {
                  toggleCloseAsideBar();
                  navigate("/");
                }}
              >
                <i class="fi fi-home"></i>
                <b>Inicio</b>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                href="javascript:void(0)"
                onClick={() => {
                  toggleCloseAsideBar();
                  navigate("/Blog");
                }}
              >
                <i class="fi fi-share"></i>
                Blog
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                href="javascript:void(0)"
                onClick={() => {
                  toggleCloseAsideBar();
                  navigate("/Servicios");
                }}
              >
                <i class="fi fi-share"></i>
                Servicios
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                href="javascript:void(0)"
                onClick={() => {
                  toggleCloseAsideBar();
                  navigate("/contactanos");
                }}
              >
                <i class="fi fi-share"></i>
                Contacto
              </a>
            </li>

            {usuario === undefined ? (
              <>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    onClick={() => {
                      toggleCloseAsideBar();
                      navigate("/iniciarsesion");
                    }}
                  >
                    <i class="fi fi-share"></i>
                    Iniciar sesión
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    onClick={() => {
                      toggleCloseAsideBar();
                      navigate("/registrarse");
                    }}
                  >
                    <i class="fi fi-share"></i>
                    Registrarse
                  </a>
                </li>
              </>
            ) : (
              <>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    onClick={() => {
                      toggleCloseAsideBar();
                      navigate("/tickets");
                    }}
                  >
                    <i class="fi fi-share"></i>
                    Mis tickets
                  </a>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    onClick={() => {
                      toggleCloseAsideBar();
                      salir();
                    }}
                  >
                    <i class="fi fi-share"></i>
                    Salir
                  </a>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
