/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

/* Components & dependencies */
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
import Carousel from "react-bootstrap/Carousel";
import { useCart } from "react-use-cart";

/* services */
import S3Service from "../../../services/S3.service";
import ServicioService from "../../../services/Servicio.service";
import UbicacionService from "../../../services/UbicacionService";
import iconServicio from "../layout/images/lavanderiaicon1.png";

const ServicioIndividual = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [servicio, setServicio] = useState({});
  const [imagenes, setImagenes] = useState([]);
  const [imagenesItem, setImagenesItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [precio, setPrecio] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [Extra, setExtra] = useState(0);
  const [Nino, setNino] = useState(0);
  const [ExtraPrecio, setExtraPrecio] = useState(0);
  const [NinoPrecio, setNinoPrecio] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalMin, setTotalMin] = useState(0);
  const [fecha, setFecha] = useState();
  const [ubicacion, setUbicacion] = useState();

  let dias = 0;
  let cargaPrecio = 0;
  var today = new Date();
  let check = 0;
  let adultoPrecio = 0;
  let adultoQuantity = 0;

  const {
    items,
    addItem,
    removeItem,
    removeLineItem,
    clearCart,
    lineItemsCount,
  } = useCart();

  useEffect(() => {
    getData();
    vaciarCart();
  }, []);

  const vaciarCart = async () => {
    items.map((record, index) => {
      removeItem(record.id);
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const result = await ServicioService.getById(parseInt(id));
      setServicio(result);

      for (const record of result.imagenes) {
        const imagen = await S3Service.get(record);
        setImagenesItem((imagenes) => [...imagenes, imagen.result]);
        console.log("------------" + imagen.result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const Precio = () => {
    if (servicio.descuento > 0) {
      return (
        <>
          <h3 className="mb-0 d-inline-grid gap-auto-3">
            <del className="fw-normal text-muted">
              ${servicio.precios[0].props.precio}
            </del>
            <span className="fw-bold text-indigo">
              $
              {servicio.precios[0].props.precio -
                servicio.precios[0].props.precio * (servicio.descuento / 100)}
            </span>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h3 className="mb-0 d-inline-grid gap-auto-3">
            <span className="fw-bold text-indigo">
              ${servicio.precios[0].props.precio}
            </span>
          </h3>
        </>
      );
    }
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };

  const locale = "en-gb";

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* PRODUCT */}
          <div className="bg-gray-200 py-5">
            <div className="container">
              {/* breadcrumb */}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/Servicios")}
                    >
                      Servicios
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0)">{servicio.titulo}</a>
                  </li>
                </ol>
              </nav>
              {/* /breadcrumb */}
              <h1 className="h2 fw-bold mb-5">{servicio.titulo}</h1>
              <div className="row">
                {/* images */}
                <div
                  className="col-lg-7 order-1 mb-5"
                  style={{ height: "518px" }}
                >
                  {/*
								Thumbnails are initialized first!
								Order changed using .order-* classes
							*/}
                  <div className="card p-1 h-100">
                    <div className="row">
                      {/* thumbnail slider */}
                      <div className="col-12 order-2">
                        <div
                          id="swiper_secondary"
                          className="swiper-container swiper-thumbs mt-3"
                          data-swiper='{
												"slidesPerView": 3,
												"spaceBetween": 3,
												"autoplay": false,
												"loop": false,
												"zoom": false,
												"effect": "slide",
												"pagination": null,
												"watchSlidesVisibility": true,
			      								"watchSlidesProgress": true,
			      								"loopedSlides": 1,
			      								"thumbs": { 
			      									"slideThumbActiveClass": "bg-light" 
			      								},
												"breakpoints": {
													"768": 	{ "slidesPerView": "5" }
												}
											}'
                        >
                          <div className="swiper-wrapper text-center">
                            <Carousel
                              slide={false}
                              nextLabel={""}
                              prevLabel={""}
                              style={{ height: "600px", marginTop: "-13px" }}
                            >
                              {servicio.imagenes.map((record, index) => {
                                return (
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={imagenesItem[index * 2]}
                                      alt="imagen"
                                      style={{
                                        filter: "brightness(70%)",
                                        height: "500px",
                                        borderRadius: "3px",
                                      }}
                                    />
                                  </Carousel.Item>
                                );
                              })}
                            </Carousel>
                          </div>
                        </div>
                      </div>
                      {/* /thumbnail slider (hidden on mobile) */}
                    </div>
                  </div>
                </div>
                {/* options */}
                <div
                  className="col-lg-5 order-2 mb-5"
                  style={{ height: "518px" }}
                >
                  <form
                    noValidate=""
                    className="card p-4 p-xl-5 h-100 bs-validate"
                    method="post"
                    action="#"
                    data-error-scroll-up="true"
                  >
                    {/* price */}
                    <div className="mb-4">
                      <p className="mb-0 text-muted text-uppercase">
                        {servicio.subtitulo}
                      </p>
                    </div>
                    {Precio()}
                    {/* shipping/return */}
                    <div
                      className="accordion border border-light bg-white rounded mb-4"
                      id="accordion-product"
                    >
                      {/* shipping */}
                      <div className=" shadow-none border-0">
                        <h2
                          className="accordion-header"
                          id="accordion-product-1"
                        >
                          <div
                            style={{ marginTop: "40px" }}
                            className="border-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion-product-collapse-1"
                            aria-expanded="false"
                            aria-controls="accordion-product-collapse-1"
                          >
                            <img
                              width={30}
                              height={30}
                              className="lazy text-gray-500"
                              src={iconServicio}
                              alt="..."
                            />
                            <span
                              className="d-inline-block px-3 small"
                              style={{ fontSize: "1.1rem" }}
                            >
                              Tiempo: {"  "}
                              {servicio.precios[0]?.props?.tiempo}{" "}
                              {servicio.precios[0]?.props?.tiempo > 1
                                ? "Días"
                                : "Día"}
                            </span>
                          </div>
                        </h2>
                      </div>
                      {/* /shipping */}
                      <hr className="my-0 bg-gray-300" />

                      {/* /return */}
                    </div>
                    {/* help */}
                    <div className="py-3 text-end">
                      <p className="d-inline-grid gap-auto-3">
                        <span className="text-gray-600">
                          ¿Tienes alguna pregunta?{" "}
                          <a
                            href="javascript:void(0)"
                            onClick={() => navigate("/Contactanos")}
                            className="link-secondary text-dashed"
                          >
                            Contáctanos
                          </a>
                        </span>
                        <svg
                          className="text-gray-500"
                          height="28px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512.052 512.052"
                          fill="currentColor"
                        >
                          <path d="M208.026,16.026c-114.7,0-208,78.9-208,176c0,39.8,15.7,77.9,44.5,108.9l-39.8,39.8c-4.6,4.6-6,11.5-3.5,17.4c2.5,6,8.3,9.9,14.8,9.9h192c114.7,0,208-78.9,208-176S322.726,16.026,208.026,16.026z" />
                          <path
                            opacity="0.5"
                            d="M467.526,428.926c28.8-30.9,44.5-69.1,44.5-108.9c0-49.8-24.6-94.9-64-126.9c-0.9,114.1-108.2,206.9-240,206.9h-89.2c34.5,56.9,104.6,96,185.2,96h192c6.5,0,12.3-3.9,14.8-9.9c2.5-6,1.1-12.9-3.5-17.4L467.526,428.926z"
                          />
                          <path
                            fill="#ffffff"
                            d="M304.026,144.026h-192c-8.8,0-16,7.2-16,16s7.2,16,16,16h192c8.8,0,16-7.2,16-16S312.826,144.026,304.026,144.026z"
                          />
                          <path
                            fill="#ffffff"
                            d="M240.026,208.026h-128c-8.8,0-16,7.2-16,16s7.2,16,16,16h128c8.8,0,16-7.2,16-16S248.826,208.026,240.026,208.026z"
                          />
                        </svg>
                      </p>
                    </div>
                    {/* /help */}
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* /PRODUCT */}

          {/* DESCRIPTION + SEPCIFICATIONS */}
          <div className="section">
            <div className="container">
              <div className="row g-4 g-xl-5">
                <div className="col-lg-6 mb-5">
                  <h2 className="fw-bold mb-4">Descripción</h2>
                  <div className="article-format">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: servicio.descripcion,
                      }}
                      style={{ textAlign: "justify" }}
                    ></p>
                  </div>
                  {/* last update info 
                  <div className="fs-6 fw-light mt-1 text-muted">
                    Last updated on{" "}
                    <time dateTime="2019-11-19">Nov 19th, 2019</time>
                  </div>*/}
                </div>
                <div className="col-lg-6 mb-5">
                  <h2 className="fw-bold mb-4">Especificaciones</h2>
                  <div className="table-responsive">
                    <h3 className="h5">Incluye</h3>
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td className="text-muted w-50">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: servicio.incluye,
                              }}
                              style={{ textAlign: "justify" }}
                            ></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="m-0"></p>
                </div>
              </div>
            </div>
          </div>
          {/* /DESCRIPTION + SEPCIFICATIONS */}

          {/* INFO BOX */}
          <div className="bg-gray-200">
            <div className="container py-3">
              <div className="row g-4">
                <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
                  <img
                    width={60}
                    height={60}
                    className="lazy"
                    src={iconServicio}
                    alt="..."
                  />
                  <div className="ps-3">
                    <h3 className="fs-5 mb-1">Limpieza Profesional</h3>
                    <p className="m-0">Calidad impecable para tus prendas</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
                  <img
                    width={60}
                    height={60}
                    className="lazy"
                    src={iconServicio}
                    alt="..."
                  />
                  <div className="ps-3">
                    <h3 className="fs-5 mb-1">Entrega Rápida</h3>
                    <p className="m-0">Servicio exprés para tu conveniencia</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
                  <img
                    width={60}
                    height={60}
                    className="lazy"
                    src={iconServicio}
                    alt="..."
                  />
                  <div className="ps-3">
                    <h3 className="fs-5 mb-1">Descuentos Especiales</h3>
                    <p className="m-0">Ahorra en tus servicios de limpieza</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 d-flex py-2 py-sm-3 py-lg-4">
                  {/* link example */}
                  <a
                    href="javascript:void(0)"
                    className="text-dark text-decoration-none d-flex"
                  >
                    <img
                      width={60}
                      height={60}
                      className="lazy"
                      src={iconServicio}
                      alt="..."
                    />
                    <div className="ps-3">
                      <h3 className="fs-5 mb-1">Cuidado Delicado</h3>
                      <p className="m-0">
                        Atención especial para tus prendas delicadas
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* /INFO BOX */}
        </>
      )}
    </>
  );
};

export default ServicioIndividual;
