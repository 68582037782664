/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/PreloaderElement";
import { TablePagination } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import TicketsService from "../../../services/Tickets.service";
import InventarioService from "../../../services/Inventario.service";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ConfiguracionService from "../../../services/Configuracion.service";
import Busqueda from "./Busqueda";
import TicketsSalidaService from "../../../services/TicketsSalida.service";
import Filtrar from "./Filtrar";
import { usePrint } from "../../../hooks/usePrint";

const TablaTickets = () => {
  const today = new Date();
  const primerDiaDelMes = new Date(today.getFullYear(), today.getMonth(), 1);
  const [tickets, setTickets] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [order, setOrder] = useState(false);
  const [openModalEntrega, setOpenModalEntrega] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [search, setSearch] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [filtros, setFiltros] = useState([
    { propiedad: "fecha", tipo: "Fecha", title: "Fecha" },
  ]);
  const [filtero, setFiltero] = useState({
    fecha: "Fecha",
    rangoInicio: primerDiaDelMes.toISOString(),
    rangoFinal: today.toISOString(),
  });

  const [waitingPrint, setWaitingPrint] = useState(false);
  const { printUrl, printIsAuto } = usePrint();

  const handleFiltro = (newfiltros, newFiltero) => {
    setFiltros(newfiltros);
    setFiltero(newFiltero);
    onUpdate();
  };

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  const handleFilterSearch = (newFilterSearch) => {
    setFilterSearch(newFilterSearch);
  };

  const onUpdate = () => {
    setUpdate(!update);
  };

  useEffect(() => {
    searchFunctionPage(filterSearch);
  }, [page, limit, update]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return newdate
      .toLocaleString("es-ES", options)
      .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+) ([APM]+)/, "$1-$2-$3 $4 $5");
  };

  const orderAsc = (a, b) => {
    if (Number(a.total) < Number(b.total)) {
      return -1;
    }
    if (Number(a.total) > Number(b.total)) {
      return 1;
    }
    return 0;
  };

  const orderDesc = (a, b) => {
    if (Number(a.total) > Number(b.total)) {
      return -1;
    }
    if (Number(a.total) < Number(b.total)) {
      return 1;
    }
    return 0;
  };

  const orderTotal = () => {
    let orderT = tickets.sort(order ? orderAsc : orderDesc);
    setTickets(orderT);
    setOrder(!order);
  };

  const orderFAsc = (a, b) => {
    let fechaa = new Date(a.fecha);
    let fechab = new Date(b.fecha);
    if (fechaa < fechab) {
      return -1;
    }
    if (fechaa > fechab) {
      return 1;
    }
    return 0;
  };

  const orderFDesc = (a, b) => {
    let fechaa = new Date(a.fecha);
    let fechab = new Date(b.fecha);
    if (fechaa > fechab) {
      return -1;
    }
    if (fechaa < fechab) {
      return 1;
    }
    return 0;
  };

  const orderFecha = () => {
    let orderF = tickets.sort(order ? orderFAsc : orderFDesc);
    setTickets(orderF);
    setOrder(!order);
  };

  const usersFilter = () => {
    if (filter) {
      return tickets.filter((usuario) =>
        usuario.cliente.nombre.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return tickets;
  };

  const searchFunctionPage = async (search) => {
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
        filters: filtero,
      };
      const data = await TicketsService.listBySearchSalida(searchData);

      setTickets(data.data);
      setTotal(data.total);
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchFunction = async (search) => {
    setFilterSearch(search);
    setPage(0);
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
        filters: filtero,
      };
      const data = await TicketsService.listBySearchSalida(searchData);

      setTickets(data.data);
      setTotal(data.total);
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enviarImpresion = async (uuid) => {
    if (waitingPrint) {
      return;
    }

    handleRowMenu(-1);
    setWaitingPrint(true);

    try {
      let newTicket = { id: uuid };
      const res = await TicketsSalidaService.resendPrint(newTicket);
      printUrl(res.url);
    } catch (error) {
      toast.error("Error. No enviado!");
    } finally {
      setWaitingPrint(false);
    }
  };

  return (
    <>
      {/* main */}
      <main id="middle" className="flex-fill mx-auto">
        {/* <!-- PAGE TITLE --> */}
        <header>
          <div class="row g-1 align-items-center">
            <div class="col">
              <h1 class="h4">Salidas de efectivo</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Historial</a>
                  </li>
                </ol>
              </nav>
            </div>

            <div class="col-auto">
              <a
                href="javascript:void(0);"
                role="button"
                class="btn btn-sm btn-primary d-inline-flex align-items-center"
                onClick={() => navigate("/ticket-salida/crear/")}
              >
                <svg
                  width="18px"
                  height="18px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                <span class="d-none d-sm-inline-block ps-2">Crear salida</span>
              </a>
            </div>
          </div>
        </header>

        <div class="section p-0 text-uppercase">
          {/* <div class="card-header p-4">
            <div class="row g-3">
              <div class="order-2 order-md-1 col">
                <form
                  method="get"
                  class="position-relative d-flex align-items-center"
                >
                  <svg
                    class="z-index-1 position-absolute start-0 ms-3 text-primary"
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>

                  <div class="dropdown w-100">
                    <input
                      type="text"
                      class="dropdown-toggle form-control form-control-sm border-0 shadow-none ps-5 bg-gray-100"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      placeholder="Nombre"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div class="order-1 order-md-2 col-md-auto">
                <div
                      class="btn-group w-100"
                      role="group"
                      aria-label="Product options"
                    >
                      <a
                        href="#!"
                        role="button"
                        class="btn btn-sm small btn-secondary"
                      >
                        Export
                      </a>
                    </div>
              </div>
            </div>
          </div> */}

          <Busqueda searchFunction={searchFunction} placeholder="uuid salida" />
          <Filtrar
            filtroValue={filtero}
            filtros={filtros}
            handleFiltros={handleFiltro}
          />
          <div class="card-body pt-1">
            {/* <!-- item list --> */}
            <div class="table-responsive-md">
              {loading ? (
                <div style={{ width: "100%", height: "20rem" }}>
                  <Preloader />
                </div>
              ) : (
                <table
                  class="table table-align-middle"
                  role="grid"
                  aria-describedby="mobile-page-info"
                >
                  <thead>
                    <tr>
                      <th style={{ width: "46px" }}>
                        <div class="form-check">
                          {/* <!-- check all --> */}
                          {/* <input
                              data-checkall-container="#checkall-list"
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                            /> */}
                        </div>
                      </th>
                      <th class="small text-muted" style={{ width: "150px" }}>
                        Salida
                      </th>
                      <th class="small text-muted" style={{ width: "350px" }}>
                        <span class="ms-2">RESPONSABLE</span>
                      </th>
                      <th class="small text-muted" style={{ width: "150px" }}>
                        <a
                          href="javascript:void(0);"
                          class="d-flex link-muted"
                          title="order by price"
                          aria-label="order by inventory"
                          onClick={() => orderTotal()}
                        >
                          <span class="d-flex flex-column lh-1">
                            <svg
                              class="lh-1 text-primary"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 15l7-7 7 7"
                              ></path>
                            </svg>

                            <svg
                              class="lh-1 mt-n1 text-muted"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </span>
                          <span class="ms-2">TOTAL</span>
                        </a>
                      </th>
                      <th class="small text-muted" style={{ width: "200px" }}>
                        <a
                          href="javascript:void(0);"
                          class="d-flex link-muted"
                          title="order by inventory"
                          aria-label="order by inventory"
                          onClick={() => orderFecha()}
                        >
                          <span class="d-flex flex-column lh-1">
                            <svg
                              class="lh-1 text-primary"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 15l7-7 7 7"
                              ></path>
                            </svg>

                            <svg
                              class="lh-1 mt-n1 text-muted"
                              width="13px"
                              height="13px"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </span>
                          <span class="ms-2">Fecha entrega</span>
                        </a>
                      </th>
                      <th class="small text-muted" style={{ width: "100px" }}>
                        Método pago
                      </th>
                      <th class="small text-muted" style={{ width: "64px" }}>
                        {/* <!-- options --> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody id="checkall-list">
                    {usersFilter().length < 1 && (
                      <tr>
                        <th>
                          {/* <!-- check --> */}
                          <div class="form-check">
                            {/* <input
                              class="form-check-input form-check-input-primary"
                              type="checkbox"
                              value=""
                            /> */}
                          </div>
                        </th>
                        <td class="position-relative">
                          {/* <!-- order --> */}
                          <a
                            href="javascript:void(0);"
                            class="link-normal fw-medium stretched-link d-block"
                          >
                            Sin coincidencias
                          </a>
                          <span class="d-block smaller text-muted">
                            {/* John Doe / john.doe@gmail.com */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- price --> */}
                          <span class="d-block text-success">
                            {/* $149.99 */}
                          </span>
                          <span class="d-block text-muted smaller">
                            {/* 2 items */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- date --> */}
                          <span class="d-block text-muted small">
                            {/* Jan 01 2022, 20:38 */}
                          </span>
                          <span class="d-block text-muted small">
                            {/* 26 days ago */}
                          </span>
                        </td>
                        <td>
                          {/* <!-- status --> */}
                          <span class="d-block text-info">
                            {/* Awayting shipment */}
                          </span>
                          <span class="badge bg-secondary rounded-pill">
                            {/* cash on delivery */}
                          </span>
                        </td>
                        <td class="dropstart">{/* <!-- options --> */}</td>
                      </tr>
                    )}

                    {usersFilter().map((record, index) => {
                      return (
                        <tr key={index}>
                          <th>
                            {/* <!-- check --> */}
                            <div class="form-check">
                              {/* <input
                                  class="form-check-input form-check-input-primary"
                                  type="checkbox"
                                  value=""
                                /> */}
                            </div>
                          </th>
                          <td class="position-relative">
                            {/* <!-- order --> */}
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                record.tiposervicio === "Gasto"
                                  ? navigate(`/ticket/salida/${record.uuid}`)
                                  : navigate(`/ticket/detalles/${record.uuid}`)
                              }
                              class="link-normal fw-medium stretched-link d-block"
                            >
                              <span class="d-block smaller text-muted">
                                {record.uuid}
                              </span>
                            </a>
                            {record.tiposervicio === "Gasto" ? (
                              <span class="d-block smaller text-red-500"></span>
                            ) : (
                              <span class="d-block smaller text-muted">
                                {record.responsableservicio?.nombre}
                              </span>
                            )}
                          </td>
                          <td>{record.responsableservicio?.nombre}</td>
                          <td>
                            {/* <!-- price --> */}
                            <span class="d-block text-success">
                              ${record.total}
                            </span>
                            <span class="d-block text-muted smaller">
                              {/* 2 items */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- date --> */}
                            <span class="d-block text-muted small">
                              {formatDate(record.fecha)}
                            </span>
                            <span class="d-block text-muted small">
                              {/* 26 days ago */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- status --> */}
                            <span
                              class="d-block text-muted"
                              style={{ fontSize: "0.75em", fontWeight: "700" }}
                            >
                              {record?.pagos[0]?.metodopago}
                            </span>
                          </td>
                          <td>
                            {/* <!-- status --> */}
                            <span class="d-block text-info"></span>
                          </td>
                          <td class="dropstart">
                            {/* <!-- options --> */}
                            <a
                              class="btn btn-sm btn-light btn-icon btn-ghost text-muted rounded-circle dropdown-toggle"
                              href="javascript:void(0)"
                              role="button"
                              data-bs-toggle="dropdown"
                              data-bs-offset="0,0"
                              aria-expanded={index === areaExpanded}
                              onClick={(event) => {
                                event.preventDefault();
                                handleRowMenu(index);
                              }}
                            >
                              <span class="group-icon">
                                <svg
                                  height="18px"
                                  viewBox="0 0 16 16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                  ></path>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </span>
                            </a>
                            <ul
                              className={
                                index === areaExpanded
                                  ? "dropdown-menu dropdown-menu-clean show"
                                  : "dropdown-menu dropdown-menu-clean"
                              }
                              style={{
                                position: "absolute",
                                inset: "0px 0px auto auto",
                                margin: "0px",
                                transform: "translate(-56px, 11px)",
                              }}
                            >
                              <li class="small px-3 py-2 text-muted">
                                Opciones
                              </li>
                              {printIsAuto ? (
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={() => enviarImpresion(record.uuid)}
                                  >
                                    <LocalPrintshopOutlinedIcon fontSize="small" />
                                    <span class="w-100">Imprime</span>
                                  </a>
                                </li>
                              ) : (
                                <li>
                                  <span class="d-block smaller text-muted">
                                    No disponible
                                  </span>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>

            {/* <!-- pagination, selected items --> */}
            <div class="row">
              <div class="col py-3 text-center text-md-start"></div>

              <div class="col-md-auto py-3">
                {/* <!-- pagination --> */}
                <TablePagination
                  style={{ color: "var(--color-dark-variant)" }}
                  component="div"
                  classes="recent-orders"
                  labelRowsPerPage="Items por pagina"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* /main */}
    </>
  );
};

export default TablaTickets;
