/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import DropComponent from "../imagesDrop";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

/*Services */
import S3Service from "../../../services/S3.service";
import FullPageLoading from "../../FullPageLoading";
import CardDay from "./CardDay";
import CardServicioPrecio from "./CardServicioPrecio";
import ServicioService from "../../../services/Servicio.service";
import Preloader from "../../utils/Preloader";

const CrearCarrusel = () => {
  //#region variables
  const { id } = useParams();
  const navigate = useNavigate();
  const [servicio, setServicio] = useState({});
  const [loading, setLoading] = useState(true);

  const [imagenesProducto, setImagenesProducto] = useState([]);
  const [datos, setDatos] = useState({});
  const [talla, setTalla] = useState({});
  const [tallasArray, setTallasArray] = useState([]);
  const [etiquetas, setEtiquetas] = useState("");
  const [etiquetasArray, setEtiquetasArray] = useState([]);
  let arrayOriginal = [];
  const [contador, setContador] = useState(1);

  const [mostrarI, setMostrarI] = useState(true);
  const [destacados, setDestacados] = useState(true);
  const myTheme = createTheme({});
  const [file, setFile] = useState(undefined);

  const [dia, setDia] = useState({});
  const [diasArray, setDiasArray] = useState([]);
  const [diaContador, setDiaContador] = useState(0);

  const [servicioPrecioU, setservicioPrecioU] = useState({});
  const [servicioPrecioArrayU, setservicioPrecioArrayU] = useState([]);
  const [precioUContador, setPrecioUContador] = useState(0);

  const [servicioPrecioE, setservicioPrecioE] = useState({});
  const [servicioPrecioArrayE, setservicioPrecioArrayE] = useState([]);
  const [precioEContador, setPrecioEContador] = useState(0);
  const [ubicaciones, setUbicaciones] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [ubicacion, setUbicacion] = useState([]);
  const [ubicacion_NEW, setUbicacion_NEW] = useState({});
  const [mostrarI_NEW, setMostrarI_NEW] = useState(false);
  const [ciudad, setCiudad] = useState("");
  const [contenido, setContenido] = useState({});
  const [contenidoestado, setContenidoestado] = useState(0);

  const [incluye, setIncluye] = useState({});
  const [incluyeEstado, setIncluyeEstado] = useState(0);

  let pasoUsuario = 0;
  let pasoEmpresa = 0;
  let pasoDia = 0;

  let arrayOriginalU = [];
  let arrayOriginalE = [];
  let arrayPaqEmpresa = [];
  let arrayPaqUser = [];

  let arrayDiasJSon = [];
  let diasjson = []; //Array para almecenar los dias en formatos JSON y guardarlos en la BD
  let arrayOriginalDiasJson = [];
  //#endregion

  useEffect(() => {
    getServicio();
  }, []);

  const getServicio = async () => {
    try {
      const data = await ServicioService.getById(id);
      setServicio(data);

      setMostrarI(data.mostrarInicio);
      setDestacados(data.destacados);

      if (data.imagenes) {
        for (const imagen of data.imagenes) {
          const result = await S3Service.get(imagen);
          setImagenesProducto((imagenesProducto) => [
            ...imagenesProducto,
            result.result,
          ]);
        }
      }

      if (data.descripcion) {
        const contentHTML = convertFromHTML(data.descripcion);
        const state = ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        );
        const content = JSON.stringify(convertToRaw(state));
        setContenido(content);
        setContenidoestado(1);
      }

      if (data.incluye) {
        const contentHTML = convertFromHTML(data.incluye);
        const state = ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        );
        const content = JSON.stringify(convertToRaw(state));
        setIncluye(content);
        setIncluyeEstado(1);
      }

      if (data.precios && pasoUsuario == 0) {
        let contadorServiciosUsuario = 1;
        pasoUsuario = 1;
        let contadorTemp = 1;
        for (const record of data.precios) {
          const newServicioPrecio = (
            <CardServicioPrecio
              id={contadorServiciosUsuario}
              cantidad={record.props.cantidad}
              tiempo={record.props.tiempo}
              precio={record.props.precio}
              user={"Usuario"}
              eliminarActual={(data) => eliminarServicioUsuario(data)}
            />
          );
          arrayOriginalU.push(newServicioPrecio);
          contadorServiciosUsuario += 1;
        }
        setservicioPrecioArrayU(arrayOriginalU);
        arrayPaqUser = arrayOriginalU;
        setContador(contadorTemp);
      }

      if (data.diasJson && pasoDia == 0) {
        let contadorDiasJ = 1;
        pasoDia = 1;
        for (const record of data.diasJson) {
          const imagen = await S3Service.get(record.imagen);
          const newDay = (
            <CardDay
              id={contadorDiasJ}
              titulo={record.titulo}
              descripcion={record.descripcion}
              url={imagen.result}
              eliminarActual={(data) => eliminarDay(data)}
            />
          );
          arrayOriginalDiasJson.push(newDay);
          contadorDiasJ += 1;
        }
        setDiasArray(arrayOriginalDiasJson);
        setContador(contadorDiasJ);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const limpiarItinerario = () => {
    try {
      dia.titulo = "";
      dia.descripcion = "";
    } catch (error) {
    } finally {
    }
  };

  //#region Dias
  const saveDay = async () => {
    setDiaContador(diaContador + 1);
    console.log("id  del dia: " + diaContador);
    try {
      const newDay = (
        <CardDay
          id={diaContador}
          titulo={dia.titulo}
          descripcion={dia.descripcion}
          imagen={file}
          url={URL.createObjectURL(file)}
          eliminarActual={(data) => {
            eliminarDay(data);
          }}
        />
      );
      setDiasArray((diasArray) => [...diasArray, newDay]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarDay = (id) => {
    console.log("Eliminar id:" + id);

    try {
      let arrayOriginal = diasArray;
      console.log("dias array eliminar, length: " + diasArray);
      console.log("****ELIMINAR: " + arrayOriginal.length);
      let nuevoArray = [];
      arrayOriginal.map((record) => {
        if (record.props.id != id) {
          console.log("****push ID: " + record.props.id);
          nuevoArray.push(record);
        }
      });
      setDiasArray(nuevoArray);
      //arrayOriginalDiasJson=nuevoArray;
      //arrayDiasJSon = nuevoArray;
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region servicioUsuario
  const saveServicioPrecioU = (userType) => {
    try {
      const newServicioPrecio = (
        <CardServicioPrecio
          cantidad={servicioPrecioU.cant}
          tiempo={servicioPrecioU.tiempo}
          precio={servicioPrecioU.precio}
          user={userType}
          id={precioUContador}
          eliminarActual={(data) => {
            eliminarServicioUsuario(data);
          }}
        />
      );

      setservicioPrecioArrayU([newServicioPrecio]);
    } catch (error) {
      console.log(error);
    }
  };
  const eliminarServicioUsuario = (id) => {
    try {
      let arrayOriginal = servicioPrecioArrayU;
      let nuevoArray = [];
      setservicioPrecioArrayU(nuevoArray);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region servicioEmpresa

  //#endregion

  const updateServicio = async () => {
    if (!servicio.titulo) {
      toast.info("Error, tienes que colocar un titulo");
    } else if (!servicio.subtitulo) {
      toast.info("Error, tienes que agregar un subtitulo");
    } else if (!servicio.descripcion) {
      toast.info("Error, tienes que colocar una descripcion");
    } else if (servicioPrecioArrayU.length <= 0) {
      toast.info("Error, tienes que agregar los precios para los usuario");
    } else {
      setLoading(true);
      try {
        const informacion = { ...datos, ...servicio };

        let imagenesArray = []; //Array para los id del s3
        let tallasJson = []; //Array para almecenar los dias en formatos JSON y guardarlos en la BD

        /* Subida de imagenes del carrusel */
        for (const file of imagenesProducto) {
          if (!file.urlImaen) {
            const resultFile = await S3Service.upload(file);
            imagenesArray.push(resultFile.result.data);
          } else {
            const idImagen = file.urlImaen.split("/")[3].split("?")[0];
            imagenesArray.push(idImagen);
          }
        }

        /* Subida de imagenes de los dias */
        for (const dia of diasArray) {
          const resultImagenDia = await S3Service.upload(dia.props.imagen);
          const diaJSON = {
            titulo: dia.props.titulo,
            descripcion: dia.props.descripcion,
            imagen: resultImagenDia.result.data,
          };
          diasjson.push(diaJSON);
        }

        const { titulo, subtitulo, tiempo, descripcion, incluye, descuento } =
          servicio;

        const dataServicio = {
          id: id,
          titulo,
          subtitulo,
          tiempo,
          descripcion,
          incluye,
          descuento: parseInt(descuento),
          imagenes: imagenesArray,
          precios: servicioPrecioArrayU,
          mostrarInicio: mostrarI,
          destacados: destacados,
        };
        console.log("***" + dataServicio.id);
        console.log("***" + dataServicio.titulo);
        console.log("***" + dataServicio.subtitulo);
        console.log("***" + dataServicio.descripcion);
        console.log("***" + dataServicio.imagenes);
        console.log("***" + dataServicio.precios);

        const actualizacionProducto = await ServicioService.update(
          dataServicio
        );

        toast.success("Servicio editado con exito");
        setLoading(false);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/servicio");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Editar servicio</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/servicio")}
                    >
                      Servicios
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Editar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="h5 card-header border-bottom-0 fw-bold text-bg-dark text-center">
                    Servicio
                  </div>
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold">
                      Servicio
                    </div>
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-2">
                          <div className="form-floating">
                            Mostrar al inicio
                            <Switch
                              checked={mostrarI}
                              onClick={() => {
                                mostrarI
                                  ? setMostrarI(false)
                                  : setMostrarI(true);
                                console.log("mostrar inicio:" + !mostrarI);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-floating">
                            Servicio destacado
                            <Switch
                              checked={destacados}
                              onClick={() => {
                                destacados
                                  ? setDestacados(false)
                                  : setDestacados(true);
                                console.log(
                                  "mostrar destacados:" + !destacados
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating"></div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.titulo}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  titulo: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.descuento}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  descuento: e.target.value,
                                })
                              }
                            />
                            <label>Descuento %</label>
                          </div>
                        </div>
                        {/* <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.tiempo}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  tiempo: e.target.value,
                                })
                              }
                            />
                            <label>Tiempo</label>
                          </div>
                        </div> */}
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={servicio?.subtitulo}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  subtitulo: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            <label>Subtítulo</label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <div>
                              <ThemeProvider theme={myTheme}>
                                <MUIRichTextEditor
                                  label="Descripción del servicio... "
                                  controls={[
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "highlight",
                                    "undo",
                                    "redo",
                                    "link",
                                    "numberList",
                                    "bulletList",
                                    "quote",
                                    "code",
                                    "clear",
                                    // "save"
                                  ]}
                                  // onSave={save}
                                  inlineToolbar={true}
                                  onChange={(value) =>
                                    setServicio({
                                      ...servicio,
                                      descripcion: stateToHTML(
                                        value.getCurrentContent()
                                      ),
                                    })
                                  }
                                  value={
                                    contenidoestado == 0 ? null : contenido
                                  }
                                />
                              </ThemeProvider>
                              <br></br>
                              <br></br>
                            </div>
                          </div>

                          <div>
                            <ThemeProvider theme={myTheme}>
                              <MUIRichTextEditor
                                id="incluye"
                                label="El servicio incluye..."
                                controls={[
                                  "italic",
                                  "underline",
                                  "strikethrough",
                                  "highlight",
                                  "undo",
                                  "redo",
                                  "link",
                                  "numberList",
                                  "bulletList",
                                  "quote",
                                  "code",
                                  "clear",
                                ]}
                                inlineToolbar={true}
                                onChange={(value) =>
                                  setServicio({
                                    ...servicio,
                                    incluye: stateToHTML(
                                      value.getCurrentContent()
                                    ),
                                  })
                                }
                                value={incluyeEstado == 0 ? null : incluye}
                              />
                            </ThemeProvider>
                            <br></br>
                          </div>

                          {file ? (
                            <div>
                              <div className="col-lg-12">
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    style={{
                                      height: 300,
                                      objectFit: "contain",
                                      width: "-webkit-fill-available",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    style={{
                                      width: "-webkit-fill-available",
                                    }}
                                  >
                                    {file.name}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropComponent
                              subirImagen={(data) => setImagenesProducto(data)}
                              imagenesDefault={imagenesProducto}
                            />
                          </div>
                        </div>
                        <h4>Precios</h4>
                        {/* PRECIOS  */}
                        <div className="col-sm-3">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              value={servicioPrecioU.cant}
                              onChange={(e) =>
                                setservicioPrecioU({
                                  ...servicioPrecioU,
                                  cant: e.target.value,
                                })
                              }
                            />
                            <label>Cantidad</label>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              value={servicioPrecioU.tiempo}
                              onChange={(e) =>
                                setservicioPrecioU({
                                  ...servicioPrecioU,
                                  tiempo: e.target.value,
                                })
                              }
                            />
                            <label>Tiempo (días habiles)</label>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              value={servicioPrecioU.precio}
                              onChange={(e) =>
                                setservicioPrecioU({
                                  ...servicioPrecioU,
                                  precio: e.target.value,
                                })
                              }
                            />
                            <label>Precio (MXM)</label>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-floating">
                            <Button
                              id="guardarUsuario"
                              variant="outlined"
                              color="success"
                              onClick={(userType) =>
                                saveServicioPrecioU("Usuario")
                              }
                            >
                              Guardar
                            </Button>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            {servicioPrecioArrayU.map((record, index) => {
                              return (
                                <>
                                  <div
                                    id="row__posterLarge"
                                    style={{
                                      padding: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {record}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => updateServicio()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className="small">Editar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
