import { useEffect, useState } from "react";
import ConfiguracionService from "../services/Configuracion.service";
import { toast } from "react-toastify";
import ImpresionService from "../services/Impresion.service";

export function usePrint() {
  const [configuracionImpresion, setConfiguracionImpresion] = useState(null);
  const [loading, setLoading] = useState(false);

  const getConfiguracionImpresion = async () => {
    try {
      const data = await ConfiguracionService.getConfiguracionImpresion();
      setConfiguracionImpresion(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const imprimeUrl = async (url) => {
    if (!loading) {
      setLoading(true);
      try {
        if (configuracionImpresion.envioAutomatico) {
          await toast.promise(
            ImpresionService.printUrl(url, configuracionImpresion.impresora),
            {
              pending: "Impresion pendiente",
              success:
                "Impresion completa (salvo que se haya indicado un error por parte de PDFtoPrinter) 👌",
            }
          );
          setLoading(false);
        } else {
          await ImpresionService.printUrlDialog(url);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Ha sucedido un error durante la impresión." + error);
      }
    }
  };

  const imprimeFile = async (url) => {
    if (!loading) {
      setLoading(true);
      try {
        if (configuracionImpresion.envioAutomatico) {
          await toast.promise(
            ImpresionService.printFile(url, configuracionImpresion.impresora),
            {
              pending: "Impresion pendiente",
              success:
                "Impreso correctamente (salvo que se haya indicado un error por parte de PDFtoPrinter 👌",
            }
          );
          setLoading(false);
        } else {
          await ImpresionService.printUrlDialog(url);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Ha sucedido un error durante la impresión.");
      }
    }
  };

  const handleStatus = (status) => {
    setLoading(status);
  };

  useEffect(() => {
    getConfiguracionImpresion();
  }, []);

  const actions = {
    printUrl: imprimeUrl,
    printFile: imprimeFile,
    printStatus: loading,
    handleStatus: handleStatus,
    printIsAuto: configuracionImpresion
      ? configuracionImpresion.envioAutomatico
      : false,
  };

  return actions;
}
