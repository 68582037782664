import React, { useEffect, useState } from "react";
import Titulo from "./Titulo";
import Pagination from "./Pagination";
import BlogItem from "./BlogItem";
import BlogCard from "./BlogCard";
import { useNavigate } from "react-router-dom";

import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import NoticiaService from "../../../services/Blog.service";
import BlogTabs from "./BlogTabs";
import BlogCategorias from "./BlogCategorias";
import BlogTabsMovil from "./BlogTabsMovil";
import { TablePagination } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

//imagenes
import imgServicio from "../layout/images/servicios2.jpg";

const BlogGrid = () => {
  const [loading, setLoading] = useState(true);
  const [Entradas, setEntradas] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(0);
  const [precio, setPrecio] = useState("normal");
  const [categorias, setCategorias] = useState([]);
  const [tag, setTag] = useState(false);
  const [noticias, setNoticias] = useState([]);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  const [buscar, setBuscar] = useState("");
  const [ultimasNoticias, setultimasNoticias] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
    getEntradas();
    getCategorias();
    getNoticias();
    getUltimasNoticias();
    window.scrollTo(0, 0);
  }, [page, limit]);

  const getEntradas = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      const data = await NoticiaService.list(limit, page * limit);
      setEntradas(data.data);
      setTotal(data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getEntradasFiltroCategoria = async (filtro) => {
    setLoading(true);
    setEntradas([]);
    try {
      console.log("el filtro es:" + filtro);
      const data = await NoticiaService.filtroCategoria(
        filtro,
        limit,
        page * limit
      );
      console.log("total: " + data.total);
      setEntradas(data.data);
      setTotal(data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getEntradasFiltroBuscar = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      if (buscar == "") {
        const data = await NoticiaService.list(limit, page * limit);
        setEntradas(data.data);
        setTotal(data.total);
      } else {
        console.log("el texto del filtro es:" + buscar);
        const data = await NoticiaService.filtroBuscar(buscar);
        console.log("total: " + data.total);
        setEntradas(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      console.log("get");
      setCategorias(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      console.log("NOTICIIAAS" + data.data);
      setultimasNoticias(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUltimasNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      console.log("NOTICIIAAS" + data.data);
      setNoticias(data.data);
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {/* PAGE TITLE */}
      <div className="section jarallax vh-75 text-center-xs overlay-dark overlay-opacity-6 text-white">
        {/* jarallax image */}
        <img className="jarallax-img" src={imgServicio} alt="..." />
        <div className="container h-100">
          <div className="d-table">
            <div className="d-table-cell align-middle">
              <h1 className="display-3 fw-bold mb-0">
                <span className="text-warning">Nuestro</span> Blog
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small justify-content-md-start justify-content-center">
                  <li className="breadcrumb-item">
                    <a
                      className="text-white"
                      href="javascript:void(0)"
                      onClick={() => navigate("/")}
                    >
                      Inicio
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Blog
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /PAGE TITLE */}

      <>
        {/* start :: blog content */}
        <div className="section">
          <div className="container">
            <div className="row g-xl-5">
              {/* Izquierda */}
              <>
                <div className="col-lg-9 order-2 order-lg-1">
                  {/* posts */}
                  <div className="row">
                    {Entradas.map((record, index) => {
                      return (
                        <BlogItem
                          id={record.id}
                          titulo={record.titulo}
                          idAutor={record.usuarioId}
                          fecha={record.createdAt}
                          categoria={record.categoria}
                          imagenes={record.imagen[0]}
                          cuerpo={record.cuerpoNoticia}
                        />
                      );
                    })}
                  </div>
                  {/* /posts */}
                  {/**Pagination */}
                  <>
                    {/**Escritorio */}
                    <div className="">
                      <div style={{ width: "100%" }}>
                        <div style={{ marginRight: "" }}>
                          <TablePagination
                            style={{
                              color: "var(--color-dark-variant)",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                            component="div"
                            classes="recent-orders"
                            labelRowsPerPage="Items por página"
                            //  rowsPerPageOptions={[]}
                            count={total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </>
              {/* Derecha */}
              <>
                <div className="col-lg-3 order-1 order-lg-2 mb-5">
                  {/* SEARCH */}
                  <form className="input-group-over d-block mb-2">
                    <div className="form-floating mb-3">
                      <input
                        required=""
                        placeholder="Buscar en el blog..."
                        id="blog_search"
                        name="keywords"
                        type="text"
                        className="form-control"
                        value={buscar}
                        onChange={(e) => {
                          setBuscar(e.target.value.toUpperCase());
                        }}
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            getEntradasFiltroBuscar();
                          }
                        }}
                      />
                      <label htmlFor="blog_search">Buscar en el blog...</label>
                    </div>
                    <button
                      className="btn smaller"
                      onClick={() => {
                        getEntradasFiltroBuscar();
                      }}
                    >
                      <i className="fi fi-search m-0" />
                    </button>
                  </form>
                  {/* /SEARCH */}
                  {/* CATEGORIES */}
                  <nav className="nav-deep nav-deep-light mb-2">
                    {/* mobile only */}
                    <button
                      className="clearfix btn btn-toggle btn-sm w-100 text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
                      data-bs-target="#nav_responsive"
                      data-toggle-container-class="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
                    >
                      <span className="group-icon px-2 py-2 float-start">
                        <i className="fi fi-bars-2" />
                        <i className="fi fi-close" />
                      </span>
                      <span className="h5 py-2 m-0 float-start">
                        Categories
                      </span>
                    </button>
                    {/* desktop only */}
                    <h3 className="h5 py-3 m-0 d-none d-lg-block">
                      Categorias
                    </h3>
                    {/* navbar : navigation */}
                    <ul
                      id="nav_responsive"
                      className="nav flex-column d-none d-lg-block"
                    >
                      <li className="nav-item active">
                        <a
                          className="nav-link px-0"
                          href="javascript:void(0)"
                          onClick={() => {
                            getEntradas();
                            setTag(true);
                          }}
                        >
                          <i className="fi fi-arrow-end m-0 smaller" />
                          <span className="px-2 d-inline-block">Todo</span>
                        </a>
                      </li>
                      {categorias.map((record, index) => {
                        return (
                          <li className="nav-item">
                            <a
                              className="nav-link px-0"
                              href="javascript:void(0)"
                              onClick={() => {
                                getEntradasFiltroCategoria(record.tipo);
                                setTag(true);
                              }}
                            >
                              <i className="fi fi-arrow-end m-0 smaller" />
                              <span className="px-2 d-inline-block">
                                {record.tipo}
                              </span>
                            </a>
                          </li>
                        );
                      })}
                      -
                    </ul>
                  </nav>
                  {/* /CATEGORIES */}
                  {/* mobile trigger : expand */}
                  <button
                    className="clearfix btn btn-toggle btn-sm w-100 text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
                    data-bs-target="#sidebar_expand_mobile"
                    data-toggle-container-class="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
                  >
                    <span className="group-icon px-2 py-2 float-start">
                      <i className="fi fi-bars-2" />
                      <i className="fi fi-close" />
                    </span>
                    <span className="h5 py-2 m-0 float-start">Explore</span>
                  </button>
                  <div id="sidebar_expand_mobile" className="d-none d-lg-block">
                    {/* RECOMMENDED */}
                    <h3 className="h5 mt-5 mt-0-xs">Recientes</h3>
                    <ul className="list-unstyled">
                      {ultimasNoticias.map((record, index) => {
                        return (
                          <li className="list-item border-bottom py-1">
                            <a
                              className="text-dark fw-light fs-6"
                              href="javascript:void(0)"
                              onClick={() => navigate(`/blog/${record.id}`)}
                            >
                              {record.titulo}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                    {/* /RECOMMENDED */}
                    {/* BANNER */}
                    <a
                      href="javascript:void(0)"
                      className="mt-5 d-block text-center overlay-dark-hover overlay-opacity-2 rounded overflow-hidden"
                    >
                      <img
                        className="w-100 img-fluid rounded"
                        src="demo.files/images/unsplash/portfolio/thumb_330/boxed-water-is-better-6RTh34xCS1M-unsplash-min.jpg"
                        alt="..."
                      />
                    </a>
                    <h4 className="small text-gray-500 fw-normal mt-1 mb-0">
                      Sponsored Ad
                    </h4>
                    {/* /BANNER */}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        {/* end :: blog content */}
      </>
    </>
  );
};

export default BlogGrid;
