import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TextareaAutosize } from "@mui/material";

const ProductNota = ({ onSumit, closeModal, product }) => {
  const [requerimientos, setRequerimientos] = useState("");

  function validarString(str) {
    // Eliminar espacios en blanco
    str = str.trim();

    // Verificar si el string es vacío
    if (str.length === 0) {
      return false;
    }

    // Verificar si el string contiene solo caracteres especiales
    const regex = /^[\s\n\t]+$/;
    if (regex.test(str)) {
      return false;
    }

    return true;
  }

  const actualizarNota = async () => {
    try {
      let data = {
        ...product,
        nota: validarString(requerimientos) ? requerimientos : "",
      };

      toast.success("Nota agregada");
      onSumit(data);
      closeModal();
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    setRequerimientos(product?.nota ? product.nota : "");
  }, [product]);

  return (
    <>
      {product ? (
        <main id="middle">
          <header style={{ marginBottom: "0" }}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb small">
                <li className="breadcrumb-item active">
                  {product?.value?.label}
                </li>
                <li
                  className="breadcrumb-item text-muted active"
                  aria-current="page"
                >
                  Editar nota
                </li>
              </ol>
            </nav>
          </header>
          <form className="form-validate">
            <div className="row g-4">
              <div className="col">
                <div className="card-body">
                  <div style={{ width: "-webkit-fill-available" }}></div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-floating">
                        <TextareaAutosize
                          type="text"
                          className="form-control shadow-none"
                          placeholder="Nota"
                          value={requerimientos}
                          minRows={5}
                          onChange={(e) =>
                            setRequerimientos(e.target.value.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="col-12">
            <div
              className="card-footer border-light"
              style={{ textAlign: "right" }}
            >
              <button
                className="btn btn-primary"
                onClick={() => actualizarNota()}
              >
                <svg
                  width="18px"
                  height="18px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
                <span className="small">Guardar</span>
              </button>
            </div>
          </div>
        </main>
      ) : (
        <main id="middle" className="flex-fill mx-auto">
          <header style={{ marginBottom: "0" }}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb small">
                <li
                  className="breadcrumb-item text-muted active"
                  aria-current="page"
                >
                  Producto no seleccionado
                </li>
              </ol>
            </nav>
          </header>
        </main>
      )}
    </>
  );
};

export default ProductNota;
