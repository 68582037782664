/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Button, TablePagination, TextField } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import TicketsService from "../../../services/Tickets.service";
import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.service";

const ListaTickets = () => {
  const { id } = useParams();
  const [usuario, setUsuario] = useState(null);
  const [imagen, setImagen] = useState("");
  const [tickets, setTickets] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [areaExpanded, setAreaExpanded] = useState(-1);
  const [order, setOrder] = useState(false);

  const handleRowMenu = (indice) => {
    if (indice === areaExpanded) {
      setAreaExpanded(-1);
    } else {
      setAreaExpanded(indice);
    }
  };

  useEffect(() => {
    getUsuarios();
  }, []);

  useEffect(() => {
    getTickets();
  }, [page, limit]);

  const getTickets = async () => {
    setLoading(true);
    try {
      const data = await TicketsService.listByCliente({
        limit: limit,
        offset: limit * page,
        id: id,
      });

      setTickets(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getUsuarios = async () => {
    setLoading(true);

    try {
      const data = await ClienteService.getById(id);

      setUsuario(data);

      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        console.log(imagen);
        setImagen(imagen.result);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const resendEmailTicket = async (id) => {
    handleRowMenu(-1);
    try {
      let newdata = {
        id: id,
      };
      await TicketsService.resendEmail(newdata);
      toast.success("Reenviado");
    } catch (error) {
      toast.error("Error,No se ha reenviado");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toLocaleString();
  };

  const orderAsc = (a, b) => {
    if (a.total < b.total) {
      return -1;
    }
    if (a.total > b.total) {
      return 1;
    }
    return 0;
  };

  const orderDesc = (a, b) => {
    if (a.total > b.total) {
      return -1;
    }
    if (a.total < b.total) {
      return 1;
    }
    return 0;
  };

  const orderTotal = () => {
    let orderT = tickets.sort(order ? orderAsc : orderDesc);
    setTickets(orderT);
    setOrder(!order);
  };

  const orderFAsc = (a, b) => {
    let fechaa = new Date(a.fecha);
    let fechab = new Date(b.fecha);
    if (fechaa < fechab) {
      return -1;
    }
    if (fechaa > fechab) {
      return 1;
    }
    return 0;
  };

  const orderFDesc = (a, b) => {
    let fechaa = new Date(a.fecha);
    let fechab = new Date(b.fecha);
    if (fechaa > fechab) {
      return -1;
    }
    if (fechaa < fechab) {
      return 1;
    }
    return 0;
  };

  const orderFecha = () => {
    let orderF = tickets.sort(order ? orderFAsc : orderFDesc);
    setTickets(orderF);
    setOrder(!order);
  };

  const usersFilter = () => {
    if (filter) {
      return tickets.filter((usuario) =>
        usuario.uuid.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return tickets;
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <header>
              <div class="row g-1 align-items-center">
                <div class="col">
                  <h1 class="h4">ORDENES DE SERVICIO</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb small">
                      <li class="breadcrumb-item">
                        <a
                          href="javascript:void(0);"
                          onClick={() => navigate("/clientes")}
                        >
                          CLIENTES
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <div class="col-auto">
                  {/* <a
                    href="javascript:void(0);"
                    role="button"
                    class="btn btn-sm btn-primary d-inline-flex align-items-center"
                  >
                    <svg
                      width="18px"
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    <span class="d-none d-sm-inline-block ps-2">Ticket</span>
                  </a> */}
                </div>
              </div>
            </header>

            {/* <!-- profile overview --> */}
            <div class="section p-xl-4">
              <div class="d-flex">
                {/* <!-- image (desktop only) --> */}
                {/* <div
                  class="me-4 d-none d-md-block"
                  style={{
                    height: "210px",
                    width: "140px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <img
                    height="210"
                    src={imagen}
                    alt="Foto"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div> */}

                <div class="w-100 position-relative">
                  <h5>
                    {/* <!-- name --> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                        fill="#00A3FF"
                      ></path>
                      <path
                        class="permanent"
                        d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                        fill="white"
                      ></path>
                    </svg>
                    <span class="fw-bold">{usuario?.nombre}</span>
                  </h5>

                  {/* <!-- attributes --> */}
                  <div class="d-lg-flex small mb-2">
                    <a
                      href="javascript:void(0)"
                      class="link-muted d-flex align-items-center me-3 mb-1"
                    >
                      <svg
                        width="18px"
                        height="18px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill="currentColor"
                          opacity="0.3"
                          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                        ></path>
                      </svg>
                      <span class="ms-2">Cliente</span>
                    </a>
                    {/* <a
                      href="javascript:void(0)"
                      class="link-muted d-flex align-items-center me-3 mb-1"
                    >
                      <svg
                        width="18px"
                        height="18px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill="currentColor"
                          opacity="0.3"
                          d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                        ></path>
                      </svg>
                      <span class="ms-2">Mostrador</span>
                    </a> */}
                  </div>

                  {/* <!-- skill boxes --> */}
                  <div class="d-xl-flex">
                    {/* <!-- senior --> */}
                    <div class="border border-dashed p-3 rounded w-100 max-w-400 me-2 mb-3">
                      <div class="d-flex align-items-center">
                        <div class="w-100 me-4 pe-3">
                          <h4 class="mb-0">ORDENES REALIZADAS</h4>
                          <p class="mb-0 text-muted">{total}</p>
                        </div>
                        {/* 
                        <div class="w-100 me-4">
                          <h4 class="mb-0">Pendientes</h4>
                          <p class="mb-0 text-muted">140+</p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="section p-0">
              <div class="card-header p-4">
                <div class="row g-3">
                  {/* <!-- product filter --> */}
                  <div class="order-2 order-md-1 col">
                    {/* <!-- search icon --> */}
                    <svg
                      class="z-index-1 position-absolute start-0 ms-3 text-primary"
                      width="18px"
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>

                    <div class="dropdown w-100">
                      <input
                        type="text"
                        class="dropdown-toggle form-control form-control-sm border-0 shadow-none ps-5 bg-gray-100"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        placeholder="ID"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* <!-- options --> */}
                  <div class="order-1 order-md-2 col-md-auto">
                    {/* <div
                      class="btn-group w-100"
                      role="group"
                      aria-label="Product options"
                    >
                      <a
                        href="#!"
                        role="button"
                        class="btn btn-sm small btn-secondary"
                      >
                        Export
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="card-body pt-1">
                {/* <!-- item list --> */}
                <div class="table-responsive-md">
                  <table
                    class="table table-align-middle"
                    role="grid"
                    aria-describedby="mobile-page-info"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "46px" }}>
                          <div class="form-check">
                            {/* <!-- check all --> */}
                            {/* <input
                              data-checkall-container="#checkall-list"
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                            /> */}
                          </div>
                        </th>
                        <th class="small text-muted">ORDEN</th>
                        <th class="small text-muted" style={{ width: "150px" }}>
                          <a
                            href="javascript:void(0);"
                            class="d-flex link-muted"
                            title="order by price"
                            aria-label="order by inventory"
                            onClick={() => orderTotal()}
                          >
                            <span class="d-flex flex-column lh-1">
                              <svg
                                class="lh-1 text-primary"
                                width="13px"
                                height="13px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 15l7-7 7 7"
                                ></path>
                              </svg>

                              <svg
                                class="lh-1 mt-n1 text-muted"
                                width="13px"
                                height="13px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 9l-7 7-7-7"
                                ></path>
                              </svg>
                            </span>
                            <span class="ms-2">TOTAL</span>
                          </a>
                        </th>
                        <th class="small text-muted" style={{ width: "200px" }}>
                          <a
                            href="javascript:void(0);"
                            class="d-flex link-muted"
                            title="order by inventory"
                            aria-label="order by inventory"
                            onClick={() => orderFecha()}
                          >
                            <span class="d-flex flex-column lh-1">
                              <svg
                                class="lh-1 text-primary"
                                width="13px"
                                height="13px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 15l7-7 7 7"
                                ></path>
                              </svg>

                              <svg
                                class="lh-1 mt-n1 text-muted"
                                width="13px"
                                height="13px"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 9l-7 7-7-7"
                                ></path>
                              </svg>
                            </span>
                            <span class="ms-2">Fecha entrega</span>
                          </a>
                        </th>
                        <th class="small text-muted" style={{ width: "200px" }}>
                          Estado
                        </th>
                        <th class="small text-muted" style={{ width: "64px" }}>
                          {/* <!-- options --> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody id="checkall-list">
                      {usersFilter().length < 1 && (
                        <tr>
                          <th>
                            {/* <!-- check --> */}
                            <div class="form-check">
                              {/* <input
                              class="form-check-input form-check-input-primary"
                              type="checkbox"
                              value=""
                            /> */}
                            </div>
                          </th>
                          <td class="position-relative">
                            {/* <!-- order --> */}
                            <a
                              href="javascript:void(0);"
                              class="link-normal fw-medium stretched-link d-block"
                            >
                              Sin coincidencias
                            </a>
                            <span class="d-block smaller text-muted">
                              {/* John Doe / john.doe@gmail.com */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- price --> */}
                            <span class="d-block text-success">
                              {/* $149.99 */}
                            </span>
                            <span class="d-block text-muted smaller">
                              {/* 2 items */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- date --> */}
                            <span class="d-block text-muted small">
                              {/* Jan 01 2022, 20:38 */}
                            </span>
                            <span class="d-block text-muted small">
                              {/* 26 days ago */}
                            </span>
                          </td>
                          <td>
                            {/* <!-- status --> */}
                            <span class="d-block text-info">
                              {/* Awayting shipment */}
                            </span>
                            <span class="badge bg-secondary rounded-pill">
                              {/* cash on delivery */}
                            </span>
                          </td>
                          <td class="dropstart">{/* <!-- options --> */}</td>
                        </tr>
                      )}

                      {usersFilter().map((record, index) => {
                        return (
                          <tr key={index}>
                            <th>
                              {/* <!-- check --> */}
                              <div class="form-check">
                                {/* <input
                                  class="form-check-input form-check-input-primary"
                                  type="checkbox"
                                  value=""
                                /> */}
                              </div>
                            </th>
                            <td class="position-relative">
                              {/* <!-- order --> */}
                              <a
                                href="javascript:void(0);"
                                onClick={() =>
                                  navigate(`/ticket/detalles/${record.uuid}`)
                                }
                                class="link-normal fw-medium stretched-link d-block"
                              >
                                {record.uuid}
                              </a>
                              <span class="d-block smaller text-muted">
                                {record.cliente.nombre}
                              </span>
                            </td>
                            <td>
                              {/* <!-- price --> */}
                              <span class="d-block text-success">
                                ${record.total}
                              </span>
                              <span class="d-block text-muted smaller">
                                {/* 2 items */}
                              </span>
                            </td>
                            <td>
                              {/* <!-- date --> */}
                              <span class="d-block text-muted small">
                                {formatDate(record.fecha)}
                              </span>
                              <span class="d-block text-muted small">
                                {/* 26 days ago */}
                              </span>
                            </td>
                            <td>
                              {/* <!-- status --> */}
                              <span class="d-block text-info">
                                {record.metodopago}
                              </span>
                              <span class="badge bg-secondary rounded-pill">
                                {record.estadopago}
                              </span>
                            </td>
                            <td class="dropstart">
                              {/* <!-- options --> */}
                              <a
                                class="btn btn-sm btn-light btn-icon btn-ghost text-muted rounded-circle dropdown-toggle"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded={index === areaExpanded}
                                data-bs-offset="0,0"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleRowMenu(index);
                                }}
                              >
                                <span class="group-icon">
                                  <svg
                                    height="18px"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                    ></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                </span>
                              </a>
                              <ul
                                className={
                                  index === areaExpanded
                                    ? "dropdown-menu dropdown-menu-clean show"
                                    : "dropdown-menu dropdown-menu-clean"
                                }
                                style={{
                                  position: "absolute",
                                  inset: "0px 0px auto auto",
                                  margin: "0px",
                                  transform: "translate(-56px, 11px)",
                                }}
                              >
                                <li class="small px-3 py-2 text-muted">
                                  Opciones
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      resendEmailTicket(record.uuid)
                                    }
                                  >
                                    <svg
                                      class="text-muted"
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                    <span>REENVIAR ORDEN</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                  >
                                    <svg
                                      class="text-muted"
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                                      ></path>
                                    </svg>
                                    <span class="w-100">Imprime</span>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* <!-- pagination, selected items --> */}
                <div class="row">
                  <div class="col py-3 text-center text-md-start"></div>

                  <div class="col-md-auto py-3">
                    {/* <!-- pagination --> */}
                    <TablePagination
                      style={{ color: "var(--color-dark-variant)" }}
                      component="div"
                      classes="recent-orders"
                      labelRowsPerPage="Items por pagina"
                      count={total}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default ListaTickets;
