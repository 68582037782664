import { useNavigate } from "react-router-dom";
import { useState } from "react";

/* Components & dependencies */
import { toast } from "react-toastify";
import Preloader from "../../utils/Preloader";
import { nanoid } from "nanoid";
import { TextareaAutosize } from "@mui/material";

/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import {
  validarContrasenaSimple,
  validarDireccion,
  validarEmail,
  validarNombreCompleto,
  validarNumeroTelefono,
} from "../../utils/Validator";

const CrearCarrusel = () => {
  const [usuario, setUsuario] = useState({ contraseña: "123456" });
  const [loading, setLoading] = useState(false);
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [file, setFile] = useState(undefined);
  const navigate = useNavigate();
  const today = new Date();
  const [requerimientos, setRequerimientos] = useState("");

  function validarString(str) {
    // Eliminar espacios en blanco
    str = str.trim();

    // Verificar si el string es vacío
    if (str.length === 0) {
      return false;
    }

    // Verificar si el string contiene solo caracteres especiales
    const regex = /^[\s\n\t]+$/;
    if (regex.test(str)) {
      return false;
    }

    return true;
  }

  const saveUsuario = async () => {
    let year = new Intl.DateTimeFormat("az", {
      year: "2-digit",
    }).format(today);
    let month = new Intl.DateTimeFormat("az", {
      month: "2-digit",
    }).format(today);

    if (!usuario.nombre || !validarNombreCompleto(usuario.nombre)) {
      toast.info(
        "Error, tienes que colocar un nombre con solo caracteres alfabéticos y espacios"
      );
    } else if (!usuario.correo || !validarEmail(usuario.correo)) {
      toast.info("Error, tienes que colocar un correo valido");
    } else if (!usuario.telefono || !validarNumeroTelefono(usuario.telefono)) {
      toast.info("Error, tienes que colocar una numero de 10 dígitos");
    } else if (!usuario.direccion || !validarDireccion(usuario.direccion)) {
      toast.info("Error, tienes que colocar una dirección");
    } else if (!usuario.fechaNacimiento) {
      toast.info("Error, tienes que colocar una fecha de nacimiento");
    } else {
      setLoading(true);
      try {
        const datosUsuario = usuario;
        datosUsuario.uuid = `C-${year}${month}${nanoid(4)}`.toUpperCase();
        datosUsuario.requerimientos = validarString(requerimientos)
          ? requerimientos
          : "";

        await ClienteService.create(datosUsuario);

        toast.success("guardado!");
        navigate("/clientes");
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Clientes</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/clientes")}
                    >
                      Clientes
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold"></div>
                    <div className="card-body">
                      <div style={{ width: "-webkit-fill-available" }}></div>
                      <div className="row g-3">
                        <div>
                          <h3>Información personal</h3>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Nombre"
                              value={usuario?.nombre}
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  nombre: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            <label>Nombre</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="date"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  fechaNacimiento: e.target.value,
                                })
                              }
                              defaultValue={fechaNacimiento}
                              placeholder="Fecha nacimiento"
                            />
                            <label htmlFor="shipping__last_name">
                              Fecha de nacimiento
                            </label>
                          </div>
                        </div>
                        <div>
                          <h3>Detalles de contacto</h3>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  telefono: `${e.target.value}`,
                                })
                              }
                              value={usuario.telefono}
                              placeholder="Telefono"
                            />
                            <label>Telefono</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              autoComplete="off"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  correo: e.target.value,
                                })
                              }
                              value={usuario.correo}
                              placeholder="Correo"
                            />
                            <label htmlFor="shipping__last_name">Correo</label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  direccion: e.target.value.toUpperCase(),
                                })
                              }
                              value={usuario.direccion}
                              placeholder="Direccion"
                            />
                            <label htmlFor="shipping__last_name">
                              Direccion
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <TextareaAutosize
                            type="text"
                            className="form-control shadow-none"
                            placeholder="Requerimientos"
                            value={requerimientos}
                            minRows={5}
                            onChange={(e) =>
                              setRequerimientos(e.target.value.toUpperCase())
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => saveUsuario()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className="small">Añadir</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
