/* Dependencies */
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FullPageLoading from "./utils/FullPageLoading";
import PublicRoutes from "./routes/PublicRoutes";
import AdminRoutes from "./routes/AdminRoutes";

function App() {
  const { currentUser, checkUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
      checkUser();
      setLoading(false);
    }, 500);
  });

  const getRoute = () => {
    if (loading) {
      return (
        <Routes>
          <Route path="/" element={<FullPageLoading />} />
        </Routes>
      );
    }

    if (!currentUser) {
      return <PublicRoutes />;
    } else if (
      currentUser.tipo === "Administrador" ||
      currentUser.tipo === "General" ||
      currentUser.tipo === "Administrativo" ||
      currentUser.tipo === "Ventas"
    ) {
      return <AdminRoutes />;
    } else {
      return <PublicRoutes />;
    }
  };

  return (
    <>
      <BrowserRouter>{getRoute()}</BrowserRouter>
    </>
  );
}

export default App;
