import { useNavigate } from "react-router-dom";
import { useState } from "react";

/* Components & dependencies */
import { toast } from "react-toastify";
import { nanoid } from "nanoid";

/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import PreloaderElement from "../../utils/PreloaderElement";

const CrearCarrusel = ({ onSumit, closeModal }) => {
  const [usuario, setUsuario] = useState({ contraseña: "123456" });
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const navigate = useNavigate();
  const today = new Date();

  const saveUsuario = async () => {
    let year = new Intl.DateTimeFormat("az", {
      year: "2-digit",
    }).format(today);
    let month = new Intl.DateTimeFormat("az", {
      month: "2-digit",
    }).format(today);

    if (!usuario.nombre) {
      toast.info("Error, tienes que colocar un nombre");
      return;
    }
    if (!usuario.correo) {
      toast.info("Error, tienes que colocar un correo");
      return;
    }
    if (!usuario.contraseña) {
      toast.info("Error, tienes que colocar  una contraseña");
      return;
    }

    setLoading(true);
    try {
      const datosUsuario = usuario;
      datosUsuario.uuid = `C-${year}${month}${nanoid(4)}`;

      const prod = await ClienteService.create(datosUsuario);
      console.log(prod);

      let dataProd = {
        label: prod.user.nombre,
        id: prod.user.id,
        uuid: prod.user.uuid,
        correo: prod.user.correo,
        telefono: prod.user.telefono,
        direccion: prod.user.direccion,
        fechaNacimiento: prod.user.fechaNacimiento,
      };

      console.log({ value: dataProd });
      onSumit({ value: dataProd });
      console.log("afther prod");

      toast.success("Cliente guardado!");
      closeModal();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <main
          id="middle"
          className="flex-fill mx-auto"
          style={{ height: "37.6rem", width: "100%" }}
        >
          <PreloaderElement />
        </main>
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header style={{ marginBottom: "0" }}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="">
                    <div className="h5 card-header border-bottom-0 fw-bold"></div>
                    <div className="card-body">
                      <div style={{ width: "-webkit-fill-available" }}></div>
                      <div className="row g-3">
                        <div>
                          <h3>Información personal</h3>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Nombre"
                              value={usuario?.nombre}
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  nombre: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            <label>Nombre</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="date"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  fechaNacimiento: e.target.value,
                                })
                              }
                              defaultValue={fechaNacimiento}
                              placeholder="Fecha nacimiento"
                            />
                            <label htmlFor="shipping__last_name">
                              Fecha de nacimiento
                            </label>
                          </div>
                        </div>
                        <div>
                          <h3>Detalles de contacto</h3>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="tel"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  telefono: e.target.value.toUpperCase(),
                                })
                              }
                              value={usuario.telefono}
                              placeholder="Telefono"
                            />
                            <label>Telefono</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  correo: e.target.value,
                                })
                              }
                              value={usuario.correo}
                              placeholder="Correo"
                            />
                            <label htmlFor="shipping__last_name">Correo</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                setUsuario({
                                  ...usuario,
                                  direccion: e.target.value.toUpperCase(),
                                })
                              }
                              value={usuario.direccion}
                              placeholder="Direccion"
                            />
                            <label htmlFor="shipping__last_name">
                              Direccion
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => saveUsuario()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className="small">Añadir</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
