import { useEffect, useState } from "react";

/* Components & dependencies */
import { toast } from "react-toastify";

/* Servicios */
import ClienteService from "../../../services/Cliente.service";
import { TextareaAutosize } from "@mui/material";

const CrearCarrusel = ({ onSumit,closeModal,usuarioDefault}) => {
  const [requerimientos,setRequerimientos] = useState("");

  const actualizarUsuario = async () => {
    try {
      let data = {
          id: usuarioDefault.uuid,
          requerimientos: requerimientos
        };

      const userActualizado = await ClienteService.update(data);
      console.log(userActualizado);
      toast.success("Requerimientos actualizados con exito");
      onSumit({ value: {  label: userActualizado.nombre.toUpperCase(),
        id: userActualizado.id,
        uuid: userActualizado.uuid,
        correo: userActualizado.correo,
        telefono: userActualizado.telefono,
        direccion: userActualizado.direccion,
        requerimientos: userActualizado.requerimientos,
        fechaNacimiento: userActualizado.fechaNacimiento
      } });
      closeModal();
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(()=>{
    setRequerimientos(usuarioDefault?.requerimientos ? usuarioDefault.requerimientos : "");
  },[usuarioDefault]);

  return (
    <>
      {
          usuarioDefault ? (
            <main id="middle">
              <header style={{ marginBottom: "0" }}>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb small">
                  <li
                      className="breadcrumb-item active"
                    >
                      {usuarioDefault?.nombre}
                    </li>
                    <li
                      className="breadcrumb-item text-muted active"
                      aria-current="page"
                    >
                      Editar requerimientos
                    </li>
                  </ol>
                </nav>
              </header>
              <form className="form-validate">
                <div className="row g-4">
                  <div className="col">
                      <div className="card-body">
                        <div style={{ width: "-webkit-fill-available" }}></div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-floating">
                              <TextareaAutosize
                                type="text"
                                className="form-control shadow-none"
                                placeholder="Requerimientos"
                                value={requerimientos}
                                minRows={5}
                                onChange={(e) =>
                                  setRequerimientos(e.target.value.toUpperCase())
                                }
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-12">
                <div
                  className="card-footer border-light"
                  style={{ textAlign: "right" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => actualizarUsuario()}
                  >
                    <svg
                      width="18px"
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span className="small">Guardar</span>
                  </button>
                </div>
              </div>
            </main>
        ): (<main id="middle" className="flex-fill mx-auto">
        <header style={{ marginBottom: "0" }}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb small">
              <li
                className="breadcrumb-item text-muted active"
                aria-current="page"
              >
               Usuario no seleccionado
              </li>
            </ol>
          </nav>
          </header></main>)
      }
    </>
  );
};

export default CrearCarrusel;
