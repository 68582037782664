/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Switch, Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import DropComponent from "../imagesDrop";

/* Servicios */
import NoticiaService from "../../../services/Producto.service";
import S3Service from "../../../services/S3.service";
import CategoriaBlogService from "../../../services/CategoriaProducto.service";
const CrearProducto = () => {
  const { currentUser } = useContext(AuthContext);
  const [noticia, setNoticia] = useState({});
  const [categoria, setCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [etiquetas, setEtiquetas] = useState("");
  const [file, setFile] = useState(undefined);
  const [destacados, setDestacados] = useState(false);
  const [disponible, setDisponible] = useState(false);

  const myTheme = createTheme({});
  const navigate = useNavigate();
  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);

  useEffect(() => {
    getCategoria();
  }, []);

  const handleDisponible = () => {
    setDisponible(!disponible);
  };

  const saveNoticia = async () => {
    if (!noticia.titulo) {
      toast.info("Error, tienes que agregar un nombre");
    } else if (noticia.descripcion.length < 10) {
      toast.info("Error, tienes que colocar una descripcion");
    } else if (!noticia.precio) {
      toast.info("Error, tienes que colocar un precio");
    } else {
      setLoading(true);
      try {
        const noticiaSubir = { ...noticia, usuarioId: currentUser.id };

        let imagenesArray = []; //Array para los id del s3

        /* Subida de imagenes del carrusel */
        for (const file of imagenesCarrusel) {
          const resultFile = await S3Service.upload(file);
          console.log("imagen guardad:" + resultFile.result.data);
          imagenesArray.push(resultFile.result.data);
        }

        const { titulo, usuarioId, descripcion, descuento, precio, categoria } =
          noticiaSubir;

        const dataNoticia = {
          titulo,
          usuarioId,
          descripcion,
          descuento,
          precios: [{ precio: precio }],
          categoria,
          imagenes: imagenesArray,
          disponible,
        };
        const noticiaSave = await NoticiaService.create(dataNoticia);

        /*if (file) {
          const uploadPhoto = await S3Service.upload(file);
          noticiaSubir.imagen = uploadPhoto.result.data;
          await NoticiaService.create(noticiaSubir);
        } else {
          await NoticiaService.create(noticiaSubir);
        }*/

        toast.success("Guardado!");
        navigate("/producto");
      } catch (error) {
        toast.error("Ha ocurrido un error al guardar");
      }
    }
  };

  const getCategoria = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategoria(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Nuevo producto</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/producto")}
                    >
                      Producto
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>

            <div class="card border-0 h-100">
              <div class="card-body p-4 h-100 row g-4">
                <div class="col-sm-12 col-lg-6 mb-5">
                  <div class="fw-bold d-flex align-items-center mb-3">
                    <span class="w-100">Nuevo producto</span>
                  </div>

                  <div class="small" style={{ marginLeft: "1.5rem" }}>
                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted "
                        style={{ width: "120px" }}
                      >
                        Nombre
                      </span>
                      <span style={{ width: "297px" }}>
                        <input
                          type="text"
                          className="form-control shadow-none"
                          placeholder="NOMBRE"
                          onChange={(e) =>
                            setNoticia({
                              ...noticia,
                              titulo: e.target.value.toUpperCase(),
                            })
                          }
                          value={noticia.titulo}
                        />
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Disponible
                      </span>
                      <span>
                        <Switch
                          checked={disponible}
                          onChange={handleDisponible}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Precio
                      </span>
                      <span style={{ width: "297px" }}>
                        <input
                          type="number"
                          className="form-control shadow-none"
                          placeholder="PRECIO $"
                          onChange={(e) =>
                            setNoticia({
                              ...noticia,
                              precio: e.target.value,
                            })
                          }
                          value={noticia.precio}
                        />
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Descuento
                      </span>
                      <span style={{ width: "297px" }}>
                        <input
                          type="number"
                          className="form-control shadow-none"
                          placeholder="DESCUENTO %"
                          onChange={(e) =>
                            setNoticia({
                              ...noticia,
                              descuento: e.target.value,
                            })
                          }
                          value={noticia.descuento}
                        />
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Categoria
                      </span>
                      <span>
                        <select
                          class="form-select shadow-none"
                          id="shipping__country"
                          name="shipping[country]"
                          aria-label="Country"
                          onChange={(e) => {
                            console.log(e.target.value);
                            setNoticia({
                              ...noticia,
                              categoria: e.target.value,
                            });
                          }}
                          defaultValue={noticia.categoria?.toUpperCase()}
                        >
                          <option disabled="disabled" selected="selected">
                            SELECCIONA UNA
                          </option>
                          {categoria.map((record, index) => {
                            return (
                              <option value={record.tipo}>
                                {record.tipo.toUpperCase()}
                              </option>
                            );
                          })}
                        </select>
                      </span>
                    </div>

                    <div class="d-flex mt-2">
                      <span
                        class="flex-none text-muted"
                        style={{ width: "120px" }}
                      >
                        Descripción
                      </span>
                      <span>
                        {" "}
                        <ThemeProvider theme={myTheme}>
                          <MUIRichTextEditor
                            label="DESCRIPCIÓN... "
                            controls={[
                              "italic",
                              "underline",
                              "strikethrough",
                              "highlight",
                              "undo",
                              "redo",
                              "link",
                              "numberList",
                              "bulletList",
                              "quote",
                              "code",
                              "clear",
                              // "save"
                            ]}
                            // onSave={save}
                            inlineToolbar={true}
                            onChange={(value) =>
                              setNoticia({
                                ...noticia,
                                descripcion: stateToHTML(
                                  value.getCurrentContent()
                                ),
                              })
                            }
                          />
                        </ThemeProvider>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 mb-3">
                  <div class="fw-bold d-flex align-items-center mb-3">
                    <span class="w-100"></span>
                  </div>

                  <div class="small">
                    <div>
                      <div className="col-lg-12">
                        <div
                          style={{
                            textAlign: "center",
                            filter: "grayscale(80%)",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        >
                          <img
                            src={
                              "/img/vecteezy_laundry-service-posters_red-min-min.svg"
                            }
                            style={{
                              height: "100%",
                              objectFit: "contain",
                              width: "-webkit-fill-available",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right", marginTop: "1.8rem" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => saveNoticia()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className="small">Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearProducto;
