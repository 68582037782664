import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";
import NoticiaService from "../../../services/Noticia.service";
import { getAutoHeightDuration } from "@mui/material/styles/createTransitions";
import BlogCard from "./BlogCard";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import ReCAPTCHA from "react-google-recaptcha";
import MensajeService from "../../../services/Mensaje.service";
import BlogMensaje from "./BlogMensaje";
import EntradaSimilar from "./EntradaSimilar";
import Carousel from "react-bootstrap/Carousel";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
} from "react-share";

const BlogEntrada = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [fecha, setFecha] = useState("");
  const [noticias, setNoticias] = useState([]);
  const [autor, setAutor] = useState({});
  const [loading, setLoading] = useState(true);
  const [filtro, setFiltro] = useState(undefined);
  const [mensajeinput, setMensajeinput] = useState("");
  // Array con los nombres de los meses
  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const [mensaje, setMensaje] = useState("");
  const [valido, setValido] = useState(true);
  const { currentUser, logout } = useContext(AuthContext);
  const [mensajes, setMensajes] = useState([]);
  const [totalMensajes, setTotalMensajes] = useState([]);
  const [ultimasNoticias, setultimasNoticias] = useState([]);
  const [Entradas, setEntradas] = useState([]);
  const [imagenesItem, setImagenes] = useState([]);
  const [hashtag, setHashtag] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";

  useEffect(() => {
    getNoticia();
    getNoticias();
    getTiempo();
    getMensajes();
    window.scrollTo(0, 0);
  }, [id]);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      setultimasNoticias(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getNoticia = async () => {
    try {
      setImagenes([]);
      //Obtener info
      const data = await NoticiaService.getById(id);
      setNoticia(data[0]);

      //Imagenes
      for (const record of data[0].imagen) {
        const imagen = await S3Service.get(record);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
      }

      //Hashtag
      setHashtag([
        data[0].categoria.replace(/\s+/g, ""),
        "TintoreríaLaEsperanza",
        "Tintorería",
      ]);

      //Autor
      const aut = await UserService.getById(data[0].usuarioId);
      setAutor(aut);

      setFiltro(data[0].categoria);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      getEntradasFiltroCategoria();
    }
  };

  const getTiempo = async () => {
    try {
      //Tiempo
      const data = await NoticiaService.getById(id);
      console.log("!!!!!!!!!!!!!!!" + String(data[0].updateAt)); // Salida: "17 de mayo de 2023 12:50:43"
      //Tiempo
      const date = new Date(data[0].updateAt);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFecha(`${day} de ${monthNames[monthIndex]} de ${year}`);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const getMensajes = async () => {
    try {
      const data = await MensajeService.getByIdBlog(id);
      setTotalMensajes(data.total);
      setMensajes(data.data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enviarMensaje = async () => {
    if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar un texto");
    } else {
      setLoading(true);
      try {
        let idUser = currentUser.id;
        let nombreUser = currentUser.nombre;
        const datosMensaje = {
          idBlog: id,
          IdUsuario: currentUser.id,
          nombre: nombreUser,
          MensajeCuerpo: mensaje.MensajeCuerpo,
          imagenes: currentUser.imagen,
        };
        await MensajeService.create(datosMensaje);

        const data = await MensajeService.getByIdBlog(id);
        setTotalMensajes(data.total);
        setMensajes(data.data);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        const limpiar = {
          Nombre: "",
          MensajeCuerpo: "",
          idBlog: "",
        };
        setMensajeinput("");
        setMensaje(limpiar);
        setValido(true);
        toast.info("Gracias, mensaje enviado");
        const data = await MensajeService.getByIdBlog(id);
        setTotalMensajes(data.total);
        setMensajes(data.data);
        getMensajes();
      }
    }
  };

  const activarMensaje = () => {
    if (currentUser == undefined) {
      return (
        <>
          <div className="clearfix mt-5">
            <h3 className="mb-4">Comentarios</h3>
            <div
              style={{
                backgroundColor: "#1c0950",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              {/* Not logged in */}
              <span className="d-block text-muted small mb-1">
                Para dejar un comentario debes{" "}
                <a
                  rel="nofollow"
                  href="javascript:void(0)"
                  onClick={() => navigate(`/iniciarsesion`)}
                  style={{ color: "whitesmoke" }}
                >
                  INICIAR SESIÓN.
                </a>
              </span>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="clearfix mt-5">
            <h3 className="mb-4">Comentarios</h3>

            {/* already logged in */}
            <span className="d-block text-muted small mb-1">
              Logged in as: <b>{currentUser.nombre}</b>. Not you? Please{" "}
              <a
                rel="nofollow"
                href="javascript:void(0)"
                onClick={() => logout()}
              >
                sign out!
              </a>
            </span>
            {/* Write Comment Form */}
            <div
              noValidate=""
              method="post"
              action="#"
              className="bs-validate d-block bg-white shadow-primary-xs rounded p-3 mb-5"
            >
              <div className="clearfix mb-3">
                <div className="form-floating">
                  <textarea
                    required=""
                    rows={3}
                    style={{ minHeight: 120 }}
                    id="comment_description"
                    data-output-target=".js-form-advanced-char-left"
                    className="form-control js-form-advanced-char-count-down"
                    maxLength={3000}
                    onChange={(evebt) => {
                      setMensaje({ MensajeCuerpo: evebt.target.value });
                      setMensajeinput(evebt.target.value);
                    }}
                    placeholder="Your comment"
                    value={mensajeinput}
                  />
                  <label htmlFor="comment_description">Comentario</label>
                </div>
                <span className="smaller text-muted text-align-end float-end mt-1">
                  characters left:{" "}
                  <span className="js-form-advanced-char-left">3000</span>
                </span>
              </div>
              {/* 
										GDPR CONSENT 
										!only if user not logged in!
									*/}
              <div className="mb-3 border p-3 rounded">
                <p className="small mb-3 pb-3 border-bottom">
                  I consent that my personal data is stored according to{" "}
                  <span className="fw-medium">2016/679/UE (UE GDPR)</span>.
                </p>
                <label className="form-checkbox form-checkbox-primary">
                  <input required="" type="checkbox" name="gdpr" />
                  <i />
                  <span>
                    I do accept Smarty{" "}
                    <a
                      className="text-decoration-none"
                      href="javascript:void(0)"
                      target="_blank"
                    >
                      terms &amp; conditions
                    </a>
                    .
                  </span>
                </label>
              </div>
              {/* /GDPR CONSENT */}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => enviarMensaje()}
              >
                Submit Comment
              </button>
            </div>
          </div>
        </>
      );
    }
  };

  const borrarComentario = async (idBlog) => {
    try {
      const dataBorrar = await MensajeService.remove(idBlog);
      const data = await MensajeService.getByIdBlog(id);
      setTotalMensajes(data.total);
      setMensajes(data.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getEntradasFiltroCategoria = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      const data = await NoticiaService.filtroCategoria(filtro, 3, 0);
      setEntradas(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="wrapper">
      <>
        {/* start :: blog content */}
        <div className="section">
          <div className="container">
            <div className="row g-xl-5">
              <div className="col-lg-9 order-2 order-lg-1">
                <div className="mb-4">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small mb-3">
                      <li className="breadcrumb-item">
                        <a
                          href="javascript:void(0)"
                          onClick={() => navigate(`/`)}
                        >
                          Inicio
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a
                          href="javascript:void(0)"
                          onClick={() => navigate(`/blog`)}
                        >
                          Blog
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="javascript:void(0)">{noticia.titulo}</a>
                      </li>
                    </ol>
                  </nav>
                  <h1 className="h3 mb-0">{noticia.titulo}</h1>
                  <p className="d-block text-muted small">
                    Fecha de publicación: <time>{fecha} </time>
                  </p>
                </div>
                {/* post image */}
                <figure className="d-block text-center rounded overflow-hidden mb-5">
                  <div className="swiper-wrapper h-100">
                    <Carousel
                      slide={false}
                      nextLabel={""}
                      prevLabel={""}
                      style={{ height: "500px" }}
                    >
                      {imagenesItem.map((record, index) => {
                        return (
                          <Carousel.Item>
                            <img
                              className="d-block w-100"
                              src={imagenesItem[index]}
                              alt="imagen"
                              style={{
                                filter: "brightness(70%)",
                                height: "500px",
                                borderRadius: "3%",
                              }}
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                </figure>
                <div className="article-format">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: noticia.cuerpoNoticia,
                    }}
                    style={{ textAlign: "justify" }}
                  ></p>
                </div>
                <p className="text-muted border-bottom pb-2 small mt-5">
                  Última actualización: {fecha}
                </p>
                {/* Tags and share */}
                <div className="row">
                  <div className="col-6">
                    <h4 className="smaller text-muted">Categoría</h4>
                    <a
                      className="text-dark bg-light small rounded py-2 px-3 m-1 text-decoration-none"
                      rel="nofollow"
                    >
                      {noticia.categoria}
                    </a>
                  </div>
                  <div className="col-6 col-6 text-align-end">
                    <h4 className="smaller text-muted">Comparte en redes</h4>
                    <a
                      className="btn btn-sm btn-facebook rounded-circle transition-hover-top mb-2"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FacebookShareButton
                        quote={
                          "Leí una entrada increíble en este blog, Lee aquí: "
                        }
                        title={
                          "Leí una entrada increíble en este blog, Lee aquí: "
                        }
                        hashtag={"#" + noticia.categoria}
                        id="btn-fb"
                        url="https://www.npmjs.com/package/react-share"
                      >
                        <i className="fi fi-social-facebook m-0" />
                      </FacebookShareButton>
                    </a>
                    <a
                      className="btn btn-sm btn-twitter rounded-circle transition-hover-top mb-2"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TwitterShareButton
                        title={
                          "Leí una entrada increíble en este blog, Lee aquí: "
                        }
                        hashtags={hashtag}
                        id="btn-fb"
                        url={window.location.href}
                      >
                        <i className="fi fi-social-twitter m-0" />
                      </TwitterShareButton>
                    </a>
                  </div>
                </div>
                {/* /Tags and share */}
                {/* Related Posts */}
                <h3 className="h5 mt-5 mb-4">Entradas relacionadas</h3>
                <div className="row">
                  {Entradas.map((record, index) => {
                    return (
                      <>
                        <EntradaSimilar
                          id={record.id}
                          titulo={record.titulo}
                          idAutor={record.usuarioId}
                          fecha={record.createdAt}
                          categoria={record.categoria}
                          imagenes={record.imagen}
                          cuerpo={record.cuerpoNoticia}
                        />
                      </>
                    );
                  })}
                </div>
                {/* /Related Posts */}
                {/* COMMENTS */}
                {activarMensaje()}
                <div className="clearfix mt-5">
                  {/* /Write Comment Form */}
                  {/* LIST COMMENTS */}
                  <h3 className="mb-4">{mensajes.length} Comentarios</h3>
                  {mensajes.map((record) => {
                    return (
                      <BlogMensaje
                        id={record.id}
                        nombre={record.nombre}
                        createdAt={record.createdAt}
                        imagenes={record.imagenes}
                        borrarComentario={(data) => borrarComentario(data)}
                        borrar={
                          currentUser?.id == record.IdUsuario ? true : false
                        }
                        MensajeCuerpo={record.MensajeCuerpo}
                      />
                    );
                  })}
                </div>
                {/* /COMMENTS */}
              </div>
              <div className="col-lg-3 order-1 order-lg-2 mb-5">
                {/* SEARCH 
                <form
                  method="get"
                  action="#"
                  className="input-group-over d-block mb-2"
                >
                  <div className="form-floating mb-3">
                    <input
                      required=""
                      placeholder="Blog search..."
                      id="blog_search"
                      name="keywords"
                      type="text"
                      className="form-control"
                    />
                    <label htmlFor="blog_search">Blog search...</label>
                  </div>
                  <button type="submit" className="btn smaller">
                    <i className="fi fi-search m-0" />
                  </button>
                </form>*/}
                {/* /SEARCH */}
                {/* CATEGORIES 
                <nav className="nav-deep nav-deep-light mb-2">
                  {/* mobile only
                  <button
                    className="clearfix btn btn-toggle btn-sm w-100 text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
                    data-bs-target="#nav_responsive"
                    data-toggle-container-class="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
                  >
                    <span className="group-icon px-2 py-2 float-start">
                      <i className="fi fi-bars-2" />
                      <i className="fi fi-close" />
                    </span>
                    <span className="h5 py-2 m-0 float-start">Categories</span>
                  </button>
                  {/* desktop only 
                  <h3 className="h5 py-3 m-0 d-none d-lg-block">Categories</h3>
                  {/* navbar : navigation
                  <ul
                    id="nav_responsive"
                    className="nav flex-column d-none d-lg-block"
                  >
                    <li className="nav-item active">
                      <a className="nav-link px-0" href="account-orders.html">
                        <i className="fi fi-arrow-end m-0 smaller" />
                        <span className="px-2 d-inline-block">Development</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link px-0"
                        href="account-favourites.html"
                      >
                        <i className="fi fi-arrow-end m-0 smaller" />
                        <span className="px-2 d-inline-block">Trends</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-0" href="account-settings.html">
                        <i className="fi fi-arrow-end m-0 smaller" />
                        <span className="px-2 d-inline-block">
                          Case Studies
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-0" href="javascript:void(0)">
                        <span className="group-icon">
                          <i className="fi fi-arrow-end" />
                          <i className="fi fi-arrow-down" />
                        </span>
                        <span className="px-2 d-inline-block">Multi level</span>
                      </a>
                      <ul className="nav flex-column px-3">
                        <li className="nav-item">
                          <a className="nav-link" href="javascript:void(0)">
                            Option 1
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="javascript:void(0)">
                            Option 2
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="javascript:void(0)">
                            Option 3
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>*/}
                {/* /CATEGORIES */}
                {/* mobile trigger : expand 
                <button
                  className="clearfix btn btn-toggle btn-sm w-100 text-align-left shadow-md border rounded mb-1 d-block d-lg-none"
                  data-bs-target="#sidebar_expand_mobile"
                  data-toggle-container-class="d-none d-sm-block bg-white shadow-md border animate-fadein rounded p-3"
                >
                  <span className="group-icon px-2 py-2 float-start">
                    <i className="fi fi-bars-2" />
                    <i className="fi fi-close" />
                  </span>
                  <span className="h5 py-2 m-0 float-start">Explore</span>
                </button>*/}
                <div id="sidebar_expand_mobile" className="d-none d-lg-block">
                  {/* RECOMMENDED */}
                  <h3 className="h5 mt-5 mt-0-xs">Recientes</h3>
                  <ul className="list-unstyled">
                    {ultimasNoticias.map((record, index) => {
                      return (
                        <li className="list-item border-bottom py-1">
                          <a
                            className="text-dark fw-light fs-6"
                            href="javascript:void(0)"
                            onClick={() => navigate(`/blog/${record.id}`)}
                          >
                            {record.titulo}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  {/* /RECOMMENDED */}
                  {/* BANNER */}
                  <a
                    href="javascript:void(0)"
                    className="mt-5 d-block text-center overlay-dark-hover overlay-opacity-2 rounded overflow-hidden"
                  >
                    <img
                      className="w-100 img-fluid rounded"
                      src="demo.files/images/unsplash/portfolio/thumb_330/boxed-water-is-better-6RTh34xCS1M-unsplash-min.jpg"
                      alt="..."
                    />
                  </a>
                  <h4 className="small text-gray-500 fw-normal mt-1 mb-0">
                    Sponsored Ad
                  </h4>
                  {/* /BANNER */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end :: blog content */}
      </>
    </div>
  );
};

export default BlogEntrada;
