/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import DropComponent from "../imagesDrop";

/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";
const CrearCarrusel = () => {
  const { currentUser } = useContext(AuthContext);
  const [noticia, setNoticia] = useState({});
  const [categoria, setCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [etiquetas, setEtiquetas] = useState("");
  const [file, setFile] = useState(undefined);
  const [destacados, setDestacados] = useState(false);
  const [principal, setPrincipal] = useState(false);

  const myTheme = createTheme({});
  const navigate = useNavigate();
  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);

  useEffect(() => {
    getCategoria();
  }, []);

  const saveNoticia = async () => {
    if (!noticia.titulo) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (noticia.cuerpoNoticia.length == 11) {
      toast.info("Error, tienes que colocar texto a la noticia");
    } /*else if (!file) {
      toast.info("Error, tienes que colocar una imagen");
    } */ else {
      setLoading(true);
      try {
        const noticiaSubir = { ...noticia, usuarioId: currentUser.id };

        let imagenesArray = []; //Array para los id del s3

        /* Subida de imagenes del carrusel */
        for (const file of imagenesCarrusel) {
          const resultFile = await S3Service.upload(file);
          console.log("imagen carrusel guardad:" + resultFile.result.data);
          imagenesArray.push(resultFile.result.data);
        }

        const {
          titulo,
          usuarioId,
          cuerpoNoticia,
          fraseCelebre,
          autorFraseCelebre,
          categoria,
        } = noticiaSubir;

        const dataNoticia = {
          titulo,
          usuarioId,
          cuerpoNoticia,
          fraseCelebre,
          autorFraseCelebre,
          categoria,
          imagen: imagenesArray,
        };
        const noticiaSave = await NoticiaService.create(dataNoticia);

        /*if (file) {
          const uploadPhoto = await S3Service.upload(file);
          noticiaSubir.imagen = uploadPhoto.result.data;
          await NoticiaService.create(noticiaSubir);
        } else {
          await NoticiaService.create(noticiaSubir);
        }*/

        toast.success("Noticia creada con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        navigate("/blog");
      }
    }
  };

  const getCategoria = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategoria(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Nueva entrada</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/blog")}
                    >
                      Blog
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="h5 card-header border-bottom-0 fw-bold text-bg-dark text-center">
                    Entrada
                  </div>
                  <div className="section mb-4">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  titulo: e.target.value.toUpperCase(),
                                })
                              }
                              value={noticia.titulo}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <div>
                              <ThemeProvider theme={myTheme}>
                                <MUIRichTextEditor
                                  label="Cuerpo de la entrada... "
                                  controls={[
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "highlight",
                                    "undo",
                                    "redo",
                                    "link",
                                    "numberList",
                                    "bulletList",
                                    "quote",
                                    "code",
                                    "clear",
                                    // "save"
                                  ]}
                                  // onSave={save}
                                  inlineToolbar={true}
                                  onChange={(value) =>
                                    setNoticia({
                                      ...noticia,
                                      cuerpoNoticia: stateToHTML(
                                        value.getCurrentContent()
                                      ),
                                    })
                                  }
                                />
                              </ThemeProvider>
                              <br></br>
                              <br></br>
                            </div>
                          </div>
                          {file ? (
                            <div>
                              <div className="col-lg-12">
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    style={{
                                      height: 300,
                                      objectFit: "contain",
                                      width: "-webkit-fill-available",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    style={{
                                      width: "-webkit-fill-available",
                                    }}
                                  >
                                    {file.name}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropComponent
                              subirImagen={(data) => setImagenesCarrusel(data)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  fraseCelebre: e.target.value,
                                })
                              }
                              value={noticia.fraseCelebre}
                            />
                            <label>Frace celebre</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="AUTHOR FRASE CELEBRE"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  autorFraseCelebre: e.target.value,
                                })
                              }
                              defaultValue={noticia?.autorFraseCelebre?.toUpperCase()}
                            />
                            <label className="text-uppercase">Autor</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <select
                              class="form-select shadow-none"
                              id="shipping__country"
                              name="shipping[country]"
                              aria-label="Country"
                              onChange={(e) => {
                                setNoticia({
                                  ...noticia,
                                  categoria: e.target.value,
                                });
                              }}
                              defaultValue={noticia?.categoria?.toUpperCase()}
                            >
                              <option disabled="disabled" selected="selected">
                                SELECCIONA UNA
                              </option>
                              {categoria.map((record, index) => {
                                return (
                                  <option value={record.tipo}>
                                    {record.tipo.toUpperCase()}
                                  </option>
                                );
                              })}
                            </select>
                            <label>Autor</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => saveNoticia()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className="small">Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
