/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import TextField from "@mui/material/TextField";
import MapGoogle from "./MapGoogle/MapGoogle";

/* Servicios */
import FooterService from "../../../services/Footer.service";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { esES } from "@mui/x-date-pickers/locales";
import "dayjs/locale/es-mx";
import { FormatTime, FormatTimeAmPm } from "../../utils/Format";

const TablaFooter = () => {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY;

  const today = new Date();
  const [footer, setFooter] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [contenido, setContenido] = useState({});
  const myTheme = createTheme({});

  const [updateCoordinates, setUpdateCoordinates] = useState(false);
  const [direction, setDirection] = useState({
    Direccion: "",
    lat: "",
    long: "",
  });

  const [fechaHoraInicioSemana, setFechaHoraInicioSemana] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(today)
  );
  const [fechaHoraCierreSemana, setFechaHoraCierreSemana] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(today)
  );

  const [fechaHoraInicioSabado, setFechaHoraInicioSabado] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(today)
  );
  const [fechaHoraCierreSabado, setFechaHoraCierreSabado] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(today)
  );

  const [fechaHoraInicioDomingo, setFechaHoraInicioDomingo] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(today)
  );
  const [fechaHoraCierreDomingo, setFechaHoraCierreDomingo] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(today)
  );

  useEffect(() => {
    getFooter();
  }, []);

  const getFooter = async () => {
    setLoading(true);
    try {
      const cont = await FooterService.getAll();
      setFooter(cont[0]);
      const contentHTML = convertFromHTML(cont[0].Texto);

      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);
      setDirection(cont[0].Direccion);

      let horarioSemana = cont[0].semana.split("a");
      let horarioSabado = cont[0].sabado.split("a");
      let horarioDomingo = cont[0].domingo.split("a");

      setFechaHoraInicioSemana(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "numeric",
          hour12: true,
        }).format(new Date("2024-12-12 " + horarioSemana[0]))
      );
      setFechaHoraCierreSemana(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "numeric",
          hour12: true,
        }).format(new Date("2024-12-12 " + horarioSemana[1]))
      );

      setFechaHoraInicioSabado(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "numeric",
          hour12: true,
        }).format(new Date("2024-12-12 " + horarioSabado[0]))
      );
      setFechaHoraCierreSabado(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "numeric",
          hour12: true,
        }).format(new Date("2024-12-12 " + horarioSabado[1]))
      );

      setFechaHoraInicioDomingo(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "numeric",
          hour12: true,
        }).format(new Date("2024-12-12 " + horarioDomingo[0]))
      );
      setFechaHoraCierreDomingo(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "numeric",
          hour12: true,
        }).format(new Date("2024-12-12 " + horarioDomingo[1]))
      );
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateFooter = async () => {
    if (!footer.Texto) {
      toast.info("Error, tienes que agregar texto");
    } else if (!footer.Contactanos) {
      toast.info("Error, tienes que colocar un texto en contactanos");
    } else if (!footer.Telefono) {
      toast.info("Error, tienes que colocar un número de telefono");
    } else if (!direction.Direccion) {
      toast.info("Error, tienes que colocar una dirección");
    } else if (!footer.Correo) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!footer.RedesSocialesTexto) {
      toast.info("Error, tienes que colocar un texto en Redes Sociales");
    } else {
      setLoading(true);
      try {
        const dataFooter = footer;
        dataFooter.Direccion = direction;

        dataFooter.semana = `${FormatTimeAmPm(
          fechaHoraInicioSemana,
          fechaHoraCierreSemana
        )}`;
        dataFooter.sabado = `${FormatTimeAmPm(
          fechaHoraInicioSabado,
          fechaHoraCierreSabado
        )}`;
        dataFooter.domingo = `${FormatTimeAmPm(
          fechaHoraInicioDomingo,
          fechaHoraCierreDomingo
        )}`;
        const updateFooter = await FooterService.update(dataFooter);
        toast.success("Contáctanos actualizado con éxito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getFooter();
      }
    }
  };

  const handleDirection = (newDirection) => {
    setDirection(newDirection);
  };

  const FormatTimeAmPm = (dateApertura, dateCierre) => {
    return `${dateApertura.split(" ")[1]} ${dateApertura.split(" ")[2]}  a ${
      dateCierre.split(" ")[1]
    } ${dateCierre.split(" ")[2]}`;
  };

  const handleAddress = async () => {
    //geocode google
    let requestOptions = {
      method: "GET",
    };

    var direccionCodificada = encodeURIComponent(direction?.Direccion);

    const results = await fetch(
      // `https://api.geoapify.com/v1/geocode/search?text=${direccionCodificada}&format=json&apiKey=${geoapify_apiKey}`,
      `https://maps.googleapis.com/maps/api/geocode/json?address=${direccionCodificada}&key=${google_apiKey}`,
      requestOptions
    ).then((response) => response.json());

    // const elementoMayor = results.results.find(tieneMayorPopularidad);
    console.log(results);
    // console.log(elementoMayor);
    setDirection({
      ...direction,
      lat: `${results?.results[0]?.geometry?.location?.lat}`,
      long: `${results?.results[0]?.geometry?.location?.lng}`,
    });
    //end geocoade geoapify
    setUpdateCoordinates(!updateCoordinates);
    // setDirection({
    //   Direccion: "",
    //   lat: "",
    //   long: "",
    // })
  };

  const handleHoraInicioSemana = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(newFecha.toDate());
    setFechaHoraInicioSemana(nuevaFechaEntrega);
  };
  const handleHoraCierreSemana = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(newFecha.toDate());
    setFechaHoraCierreSemana(nuevaFechaEntrega);
  };

  const handleHoraInicioSabado = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(newFecha.toDate());
    setFechaHoraInicioSabado(nuevaFechaEntrega);
  };
  const handleHoraCierreSabado = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(newFecha.toDate());
    setFechaHoraCierreSabado(nuevaFechaEntrega);
  };

  const handleHoraInicioDomingo = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(newFecha.toDate());
    setFechaHoraInicioDomingo(nuevaFechaEntrega);
  };
  const handleHoraCierreDomingo = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    }).format(newFecha.toDate());
    setFechaHoraCierreDomingo(nuevaFechaEntrega);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto text-uppercase">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">FOOTER</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Editar pie de página
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div className="section p-0">
                <div className="card-header p-4">
                  <div className="row g-3">
                    <h4>Footer</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className="row g-4">
                    <div className="col">
                      {/* Shipping address */}

                      <div className="section mb-4">
                        <div className="card-body">
                          <div className="row g-3">
                            <div className="col-sm-12">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control shadow-none text-uppercase"
                                  placeholder="Texto"
                                  defaultValue={footer.Texto}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      Texto: e.target.value.toUpperCase(),
                                    })
                                  }
                                />
                                <label>Texto principal</label>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-floating d-flex">
                                <input
                                  type="text"
                                  className="form-control shadow-none text-uppercase"
                                  placeholder="Dirección"
                                  value={direction?.Direccion}
                                  onChange={(e) =>
                                    setDirection({
                                      ...direction,
                                      Direccion: e.target.value,
                                    })
                                  }
                                />
                                <label>
                                  Dirección (la dirección generada en
                                  aproximada)
                                </label>

                                <Button
                                  style={{
                                    minWidth: "5rem",
                                    backgroundColor: "#9b242c",
                                    color: "#fff",
                                  }}
                                  onClick={() => handleAddress()}
                                >
                                  Buscar
                                </Button>
                              </div>
                              <p
                                style={{
                                  marginInline: "1rem",
                                  fontSize: "0.8rem",
                                  color: "#ec1a22",
                                }}
                              >
                                Puedes modificar la dirección generada antes de
                                guardar...
                              </p>
                              <div
                                style={{
                                  width: "70%",
                                  height: "20rem",
                                  margin: "1rem",
                                  position: "relative",
                                }}
                              >
                                {/* <MapLibreUe
                                  handleDirection={handleDirection}
                                  positionAprox={
                                    direction
                                      ? [
                                          Number(direction.long),
                                          Number(direction.lat),
                                        ]
                                      : null
                                  }
                                /> */}
                                <MapGoogle
                                  update={updateCoordinates}
                                  handleDirection={handleDirection}
                                  positionApproximate={
                                    direction
                                      ? [
                                          Number(direction.lat),
                                          Number(direction.long),
                                        ]
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Teléfono"
                                  value={footer.Telefono}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      Telefono: e.target.value.toUpperCase(),
                                    })
                                  }
                                />
                                <label>Teléfono</label>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Correo electrónico"
                                  value={footer.Correo}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      Correo: e.target.value.toUpperCase(),
                                    })
                                  }
                                />
                                <label>Correo electrónico</label>
                              </div>
                            </div>
                            <h4>Redes sociales</h4>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Facebook"
                                  value={footer.fb}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      fb: e.target.value.toUpperCase(),
                                    })
                                  }
                                />
                                <label>Facebook</label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Twitter"
                                  value={footer.tw}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      tw: e.target.value.toUpperCase(),
                                    })
                                  }
                                />
                                <label>Twitter</label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Instagram"
                                  value={footer.insta}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      insta: e.target.value.toUpperCase(),
                                    })
                                  }
                                />
                                <label>Instagram</label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Youtube"
                                  value={footer.yt}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      yt: e.target.value.toUpperCase(),
                                    })
                                  }
                                />
                                <label>Youtube</label>
                              </div>
                            </div>
                            <h4>Horario</h4>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                HORARIO ENTRE SEMANA
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  localeText={
                                    esES.components.MuiLocalizationProvider
                                      .defaultProps.localeText
                                  }
                                  adapterLocale="es-mx"
                                >
                                  <MobileTimePicker
                                    ampm
                                    label="Horario de apertura:"
                                    onChange={handleHoraInicioSemana}
                                    value={dayjs(fechaHoraInicioSemana)}
                                    sx={{ minWidth: "100%", marginBottom: 2 }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  localeText={
                                    esES.components.MuiLocalizationProvider
                                      .defaultProps.localeText
                                  }
                                  adapterLocale="es-mx"
                                >
                                  <MobileTimePicker
                                    ampm
                                    label="Horario de cierre:"
                                    onChange={handleHoraCierreSemana}
                                    value={dayjs(fechaHoraCierreSemana)}
                                    sx={{ minWidth: "100%", marginBottom: 2 }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                HORARIO SÁBADO
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  localeText={
                                    esES.components.MuiLocalizationProvider
                                      .defaultProps.localeText
                                  }
                                  adapterLocale="es-mx"
                                >
                                  <MobileTimePicker
                                    ampm
                                    label="Horario de apertura:"
                                    onChange={handleHoraInicioSabado}
                                    value={dayjs(fechaHoraInicioSabado)}
                                    sx={{ minWidth: "100%", marginBottom: 2 }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  localeText={
                                    esES.components.MuiLocalizationProvider
                                      .defaultProps.localeText
                                  }
                                  adapterLocale="es-mx"
                                >
                                  <MobileTimePicker
                                    ampm
                                    label="Horario de cierre:"
                                    onChange={handleHoraCierreSabado}
                                    value={dayjs(fechaHoraCierreSabado)}
                                    sx={{ minWidth: "100%", marginBottom: 2 }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                HORARIO DOMINGO
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  localeText={
                                    esES.components.MuiLocalizationProvider
                                      .defaultProps.localeText
                                  }
                                  adapterLocale="es-mx"
                                >
                                  <MobileTimePicker
                                    ampm
                                    label="Horario de apertura:"
                                    onChange={handleHoraInicioDomingo}
                                    value={dayjs(fechaHoraInicioDomingo)}
                                    sx={{ minWidth: "100%", marginBottom: 2 }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-floating">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  localeText={
                                    esES.components.MuiLocalizationProvider
                                      .defaultProps.localeText
                                  }
                                  adapterLocale="es-mx"
                                >
                                  <MobileTimePicker
                                    ampm
                                    label="Horario de cierre:"
                                    onChange={handleHoraCierreDomingo}
                                    value={dayjs(fechaHoraCierreDomingo)}
                                    sx={{ minWidth: "100%", marginBottom: 2 }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => updateFooter()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className="small">GUARDAR</span>
                </button>
              </div>
            </>
          </main>
        </>
      )}
    </>
  );
};

export default TablaFooter;
