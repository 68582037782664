import httpClient from "./HttpClient";
const prefix = "/reportes";

export default class ReportesService {
  static async printCorte(data) {
    return (await httpClient.post(`${prefix}/corte/`, data)).data;
  }

  static async printCorteById(data) {
    return (await httpClient.post(`${prefix}/corte-registrado/`, data)).data;
  }

  static async printGeneradas(data) {
    return (await httpClient.post(`${prefix}/generadas/`, data)).data;
  }

  static async printPendientes(data) {
    return (await httpClient.post(`${prefix}/pendientes/`, data)).data;
  }

  static async printDashboard(data) {
    return (await httpClient.post(`${prefix}/dashboard/`, data)).data;
  }
}
