import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Typed from "react-typed";
import { useNavigate } from "react-router-dom";

//Services
import ServicioService from "../../../services/Servicio.service";

//Components
//componentes
import ServicioItem from "../../utils/ServicioItem";
import ItemDestacado from "./ItemDestacado";

//imagenes
import imgServicio from "../layout/images/servicios2.jpg";
import iconServicio from "../layout/images/lavanderiaicon1.png";
import imgServicio2 from "../layout/images/servicios3.jpg";
import team1 from "../layout/images/team1.jpg";
import team2 from "../layout/images/team2.jpg";
import IndexService from "../../../services/Index.service";

const ServiciosGrid = () => {
  const { filtroI } = useParams();
  const [loading, setLoading] = useState(true);
  const [paquetes, setPaquetes] = useState([]);
  const [serviciosArray, setServiciosArray] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(0);
  const [tipo, setTipo] = useState(0);
  const { currentUser } = useContext(AuthContext);
  const [filtro, setFiltro] = useState(false);
  const [filtroCiudades, setFiltroCiudades] = useState([]);
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(undefined);
  const [filtroFechaFin, setFiltroFechaFin] = useState(undefined);
  const [value, setValue] = React.useState([0, 1000]);

  const [serviciosDestacados, setServiciosDestacados] = useState([]);
  const [servicioPrincipal, setServicioPrincipal] = useState([]);
  const [mejoresDescuentos, setMejoresDescuentos] = useState([]);
  const [info, setInfo] = useState({
    body: "",
    author: "",
  });
  const navigate = useNavigate();
  //Imagenes

  useEffect(() => {
    AOS.init();
    getPaquetes();
    getInformacion();
    window.scrollTo(0, 0);
  }, [page, limit]);

  const getInformacion = async () => {
    let data = await IndexService.get();
    setInfo(data[0]);
  };

  const getPaquetes = async () => {
    setLoading(true);
    try {
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
      if (filtroI != undefined) {
        setFiltro(true);
        setFiltroCiudades([filtroI]);
        const data = await ServicioService.filtroCiudad(100, filtroI);
        {
          paquetes.map((record, index) => {
            serviciosArray.concat(record.titulo);
          });
        }
        setPaquetes(data);
      } else {
        const data = await ServicioService.list(limit, page * limit);
        setPaquetes(data.data);
        setTotal(data.total);
      }

      const mejoresOfertas = await ServicioService.mejoresOfertas(4);
      setMejoresDescuentos(mejoresOfertas.data);

      const dataDestacados = await ServicioService.destacados(2);
      setServiciosDestacados(dataDestacados.data);

      const dataPrincipal = await ServicioService.principal(1);
      setServicioPrincipal(dataPrincipal.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buscar = async () => {
    setLoading(true);

    try {
      if (filtro) {
        console.log(
          "--Hay filtros" +
            filtroCiudades +
            "," +
            filtroFechaInicio +
            "," +
            filtroFechaFin +
            ","
        );
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio == undefined &&
          filtroFechaFin == undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
            };
            console.log("filtro de ciudades");
            console.log("la ciudad:" + filtros.ciudades);
            const data = await ServicioService.filtroCiudad(100, filtros);
            console.log("tipo:" + data.data);
            if (tipo == "Usuario") {
              data.data.forEach((element) => {
                for (const pre of element.paquetesUsuario) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            } else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            }
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades:" + cds.length);
        }
        if (
          (filtroCiudades.length == 0 &&
            filtroFechaInicio != undefined &&
            filtroFechaFin != undefined) ||
          (filtroCiudades.length == 0 &&
            filtroFechaInicio != "" &&
            filtroFechaFin != "")
        ) {
          const filtros = {
            fechaInicio: filtroFechaInicio.fecha,
            fechaFin: filtroFechaFin.fecha,
          };
          console.log(
            "paquetes del filtro de fechas:" +
              filtros.fechaInicio +
              "--" +
              filtros.fechaFin
          );
          let paq = [];
          const data = await ServicioService.filtroFecha(100, filtros);
          if (tipo == "Usuario") {
            data.data.forEach((element) => {
              for (const pre of element.paquetesUsuario) {
                if (pre.props.tipo == "Adulto") {
                  if (
                    pre.props.precio >= value[0] &&
                    pre.props.precio <= value[1]
                  ) {
                    paq.push(element);
                  }
                }
              }
            });
          } else {
            data.data.forEach((element) => {
              for (const pre of element.paquetesEmpresa) {
                if (pre.props.tipo == "Adulto") {
                  if (
                    pre.props.precio >= value[0] &&
                    pre.props.precio <= value[1]
                  ) {
                    paq.push(element);
                  }
                }
              }
            });
          }
          setPaquetes(paq);
          console.log("paquetes del filtro de fechas:" + paq.length);
        }
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio != undefined &&
          filtroFechaFin != undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
              fechaInicio: filtroFechaInicio.fecha,
              fechaFin: filtroFechaFin.fecha,
            };
            console.log("filtro de ciudades y fechas");
            console.log("la ciudad:" + cd);
            const data = await ServicioService.filtroCiudadFecha(100, filtros);
            console.log("tipo:" + data.data);
            if (tipo == "Usuario") {
              data.data.forEach((element) => {
                for (const pre of element.paquetesUsuario) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            } else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            }
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades y fecha:" + cds.length);
        }
        if (currentUser != undefined && currentUser.tipo == "Empresa") {
          setTipo("Empresa");
        } else {
          setTipo("Usuario");
        }
      } else {
        console.log("sin filtros");
        let paqs = [];
        const data = await ServicioService.list(limit, page * limit);
        data.data.forEach((element) => {
          for (const pre of element.paquetesEmpresa) {
            if (pre.props.tipo == "Adulto") {
              if (
                pre.props.precio >= value[0] &&
                pre.props.precio <= value[1]
              ) {
                paqs.push(element);
              }
            }
          }
        });
        setPaquetes(paqs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = React.useState([]);

  const setFiltrosCiudades = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroCiudades(value);
    setFiltro(true);
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <>
        {/* Cover */}
        <div className="section p-0 jarallax overlay-dark overlay-opacity-7 text-white">
          {/* jarallax image */}
          <img className="jarallax-img" src={imgServicio} alt="..." />
          <div className="container">
            <div className="d-middle min-vh-75 text-center">
              <div
                className="overflow-hidden mb-4"
                data-aos="fade-in"
                data-aos-delay={150}
              >
                <p className="h1 fw-medium mb-5">Conoce</p>
                <h1 className="display-3 fw-bold mb-0">
                  Nuestros{" "}
                  <span className="text-warning fw-medium">Servicios</span>
                </h1>

                {/* play button */}
                {/* <a
                  href="https://www.youtube.com/watch?v=DylzGXE_ibU"
                  className="fancybox bg-white text-dark d-inline-flex rounded-circle align-items-center justify-content-center"
                  style={{ width: 70, height: 70 }}
                >
                  <svg
                    width={34}
                    height={34}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi bi-play-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                  </svg>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        {/* /Cover */}

        {/* INFO BOX */}
        <div className="container my-6">
          <div className="p-3 border border-3 border-dotted">
            <div className="row g-4">
              <div
                className="col-12 col-sm-6 col-lg-3 d-flex text-center-xs py-2 py-sm-3 py-lg-4"
                data-aos="fade-in"
                data-aos-delay={0}
                data-aos-offset={0}
              >
                <img
                  width={60}
                  height={60}
                  className="lazy"
                  src={iconServicio}
                  alt="..."
                />
                <div className="ps-3">
                  <h3 className="fs-5 mb-1">Limpieza Profesional</h3>
                  <p className="m-0">Calidad impecable para tus prendas</p>
                </div>
              </div>
              <div
                className="col-12 col-sm-6 col-lg-3 d-flex text-center-xs py-2 py-sm-3 py-lg-4"
                data-aos="fade-in"
                data-aos-delay={150}
                data-aos-offset={0}
              >
                <img
                  width={60}
                  height={60}
                  className="lazy"
                  src={iconServicio}
                  alt="..."
                />
                <div className="ps-3">
                  <h3 className="fs-5 mb-1">Entrega Rápida</h3>
                  <p className="m-0">Servicio exprés para tu conveniencia</p>
                </div>
              </div>
              <div
                className="col-12 col-sm-6 col-lg-3 d-flex text-center-xs py-2 py-sm-3 py-lg-4"
                data-aos="fade-in"
                data-aos-delay={250}
                data-aos-offset={0}
              >
                <img
                  width={60}
                  height={60}
                  className="lazy"
                  src={iconServicio}
                  alt="..."
                />
                <div className="ps-3">
                  <h3 className="fs-5 mb-1">Descuentos Especiales</h3>
                  <p className="m-0">Ahorra en tus servicios de limpieza</p>
                </div>
              </div>
              <div
                className="col-12 col-sm-6 col-lg-3 d-flex text-center-xs py-2 py-sm-3 py-lg-4"
                data-aos="fade-in"
                data-aos-delay={350}
                data-aos-offset={0}
              >
                {/* link example */}
                <a
                  href="javascript:void(0)"
                  className="text-dark text-decoration-none d-flex"
                >
                  <img
                    width={60}
                    height={60}
                    className="lazy"
                    src={iconServicio}
                    alt="..."
                  />
                  <div className="ps-3">
                    <h3 className="fs-5 mb-1">Cuidado Delicado</h3>
                    <p className="m-0">
                      Atención especial para tus prendas delicadas
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /INFO BOX */}

        {/* special offer boxes */}
        <div className="container pb-6">
          <div className="row g-4">
            {serviciosDestacados.map((record, index) => {
              return <ItemDestacado data={record} tipo={tipo} />;
            })}
          </div>
        </div>
        {/* /special offer boxes */}

        {/* OFFER BLOCK */}
        <div className="section bg-light">
          <div className="container" style={{ marginTop: "-40px" }}>
            <h2 className="h1 mb-5 text-center fw-bold">
              Los mejores <span className="text-danger">Descuentos</span> de hoy
            </h2>
            {/* product list */}
            <div className="row g-2 g-xl-4">
              {mejoresDescuentos.map((record, index) => {
                return <ServicioItem data={record} tipo={tipo} />;
              })}
            </div>
            {/* /product list */}
          </div>
        </div>
        {/* /OFFER BLOCK */}

        {/* NEW ARRIVARLS */}
        <div className="section">
          <div className="container" style={{ marginTop: "-5%" }}>
            <h2 className="h1 mb-5 text-center fw-bold">Nuestros servicios</h2>
            <div className="row g-4">
              {/* banner */}
              <div className="col-lg-3 col-sm-4">
                <div
                  style={{
                    minHeight: 300,
                    backgroundImage: `url(${imgServicio})`,
                  }}
                  className="position-relative lazy overlay-dark overlay-opacity-5 bg-dark bg-cover p-4 h-100 rounded d-flex align-items-center d-lg-block"
                  data-background-image="demo.files/images/various/product/speakers/thumb_500/emmanuel-ikwuegbu-81fRHbVliQI-unsplash.jpg"
                >
                  <div className="position-relative text-white z-index-2">
                    <p className="lead fw-bold border-bottom border-light">
                      Si tienes alguna duda
                    </p>
                    <h3 className="h4 fw-bold mb-5">Contáctanos</h3>
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/Contactanos")}
                      className="btn btn-sm btn-outline-light shadow-none btn-danger"
                    >
                      Contáctanos
                      <svg
                        width="18px"
                        height="18px"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-arrow-right-short"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        />
                      </svg>
                    </a>
                  </div>
                  {/* lines, looks like through a glass */}
                  <div className="absolute-full w-100 overflow-hidden opacity-5">
                    <img
                      className="img-fluid"
                      width={2000}
                      height={2000}
                      src="assets/images/masks/shape-line-lense.svg"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              {/* /banner */}
              {/* product list */}
              <div className="col-lg-9 col-sm-8">
                <div className="row g-2 g-xl-4">
                  {paquetes.map((record, index) => {
                    return <ServicioItem data={record} tipo={tipo} />;
                  })}
                </div>
              </div>
              {/* /product list */}
            </div>
          </div>
        </div>
        {/* /NEW ARRIVARLS */}

        {/* banner (add margins like my-7 if needed) */}
        <div className="container">
          <div
            className="position-relative lazy overlay-dark overlay-opacity-7 bg-dark bg-cover p-4 rounded d-flex align-items-center"
            data-background-image="demo.files/images/unsplash/covers/adeolu-eletu-nKKQ-Hb_LGw-unsplash.jpg"
            style={{
              backgroundImage: `url(${imgServicio2})`,
            }}
          >
            <div className="row g-4 w-100 align-items-center position-relative text-white z-index-2">
              <div className="col-lg-3">
                <h2 className="display-2 fw-bold mb-0 d-flex align-items-center text-warning">
                  Blog
                </h2>
              </div>
              <div className="col">
                <h3 className="mb-0 fw-bold">Descubre nuestro blog</h3>
                <p className="mb-0 fw-bold lead">
                  Información y curiosidades para ti.
                </p>
              </div>
              <div className="col-lg-3 text-lg-end">
                <a
                  href="javascript:void(0)"
                  onClick={() => navigate("/Blog")}
                  className="btn btn-outline-light shadow-none"
                >
                  Muéstrame
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </a>
              </div>
            </div>
            {/* lines, looks like through a glass */}
            <div className="absolute-full w-100 overflow-hidden opacity-5">
              <img
                className="img-fluid"
                width={2000}
                height={2000}
                src="assets/images/masks/shape-line-lense.svg"
                alt="..."
              />
            </div>
          </div>
        </div>
        {/* /banner */}

        {/* TEAM BLOCK */}
        <div className="section">
          <div className="container">
            <div className="row g-xl-5">
              <div className="col-12 col-lg-6 mb-5">
                <div className="row">
                  <div className="col-6">
                    <img
                      className="img-fluid rounded mt-5 shadow-primary-lg"
                      data-aos="fade-up"
                      data-aos-delay={0}
                      data-aos-offset={0}
                      src={team1}
                      alt="..."
                    />
                  </div>
                  <div className="col-6">
                    <img
                      className="img-fluid rounded mb-5 shadow-primary-lg"
                      data-aos="fade-up"
                      data-aos-delay={150}
                      data-aos-offset={0}
                      src={team2}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 mb-5"
                data-aos="fade-up"
                data-aos-delay={0}
                data-aos-offset={0}
              >
                <h2 className="h1 mb-5 text-center fw-bold">
                  El mejor {"   "} <span className="text-danger">Equipo</span>
                </h2>
                <div className="d-flex mb-4">
                  {/* icon */}
                  <div className="text-primary">
                    <img width={40} height={40} src={iconServicio} alt="..." />
                  </div>
                  <div className="mx-4">
                    {/* heading */}
                    <h3 className="h5 mb-1">Profesionales</h3>
                    {/* text */}
                    <p>
                      Nuestro equipo está conformado por profesionales
                      comprometidos con la calidad y la excelencia en cada tarea
                      que realizan. Desde nuestros expertos en atención al
                      cliente hasta nuestros técnicos de lavado y planchado,
                      cada uno de ellos trabaja arduamente para que tu
                      experiencia con nosotros sea lo más satisfactoria posible.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  {/* icon */}
                  <div className="text-primary">
                    <img width={40} height={40} src={iconServicio} alt="..." />
                  </div>
                  <div className="mx-4">
                    {/* heading */}
                    <h3 className="h5 mb-1">Pasión</h3>
                    {/* text */}
                    <p>
                      Nos enorgullece contar con un equipo que está en constante
                      capacitación y actualización para estar al día con las
                      últimas tendencias en la industria. Además, valoramos la
                      honestidad, el trabajo en equipo y la pasión por lo que
                      hacemos.{" "}
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  {/* icon */}
                  <div className="text-primary">
                    <img width={40} height={40} src={iconServicio} alt="..." />
                  </div>
                  <div className="mx-4">
                    {/* heading */}
                    <h3 className="h5 mb-1">Conocenos</h3>
                    {/* text */}
                    <p>
                      ¡Conoce a nuestro equipo! Estamos seguros de que te
                      encantará trabajar con ellos y recibir el mejor servicio
                      de lavandería en la ciudad.
                    </p>
                  </div>
                </div>
                <div className="text-align-end">
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      navigate("/contactanos");
                    }}
                    className="btn btn-primary btn-soft"
                  >
                    Estamos a tu servicio!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /TEAM BLOCK */}

        {/* parallax quote */}
        <div
          className="section position-relative jarallax overlay-dark overlay-opacity-7 text-white bg-cover"
          style={{
            backgroundImage: `url(${imgServicio2})`,
          }}
        >
          <div className="container py-7 text-center position-relative z-index-2">
            <blockquote
              className="blockquote text-center"
              data-aos="fade-in"
              data-aos-delay={250}
              data-aos-offset={0}
            >
              <h2
                className="h1 max-w-800 m-auto fw-light fst-italic text-warning"
                style={{ color: "#fad776 !important" }}
                dangerouslySetInnerHTML={{ __html: info?.servicios?.body }}
              ></h2>
              <footer className="blockquote-footer mt-4 fs-5 fw-light text-gray-500">
                <cite title="Source Title">{info?.servicios?.author}</cite>
              </footer>
            </blockquote>
          </div>
          {/* lines, looks like through a glass */}
          <div className="absolute-full w-100 overflow-hidden opacity-5">
            <img
              className="img-fluid"
              width={2000}
              height={2000}
              src="assets/images/masks/shape-line-lense.svg"
              alt="..."
            />
          </div>
        </div>
        {/* /parallax quote */}
      </>
    </>
  );
};

export default ServiciosGrid;
