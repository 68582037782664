import httpClient from "./HttpClient";
const prefix = "/configuracion";

export default class ConfiguracionService {
  static async getConfiguracionTickets() {
    return (await httpClient.get(`${prefix}/tickets/`)).data;
  }

  static async updateConfiguracionTicketse(noticia) {
    return (await httpClient.put(`${prefix}/tickets/`, noticia)).data;
  }

  static async getConfiguracionDias() {
    return (await httpClient.get(`${prefix}/dias/`)).data;
  }

  static async updateConfiguracionDias(noticia) {
    return (await httpClient.put(`${prefix}/dias/`, noticia)).data;
  }

  static async getConfiguracionImpresion() {
    return (await httpClient.get(`${prefix}/impresion/`)).data;
  }

  static async updateConfiguracionImpresion(noticia) {
    return (await httpClient.put(`${prefix}/impresion/`, noticia)).data;
  }
}
