import { useState, useEffect } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextareaAutosize,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import CalendarioService from "../../../services/Calendario.service";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#9b242c",
  backgroundColor: "white",
  borderColor: "#9b242c",
}));

const FormAgenda = ({ handleStatus, fechaData, onSumit }) => {
  const [loading, setLoading] = useState(false);
  const [nota, setNota] = useState("");
  const [deshabilitado, setDeshabilitado] = useState(false);
  const [notas, setNotas] = useState([]);

  let today = new Date();
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );

  const handleChangeDeshabilitado = (e) => {
    setDeshabilitado(e.target.checked);
  };

  useEffect(() => {
    if (fechaData?.date) {
      console.log(fechaData.date);
      setFiltroFechaInicio(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(fechaData.date)
      );
    }
    getNota();
  }, [fechaData]);

  const getNota = async () => {
    try {
      const resutl = await CalendarioService.getNota(
        fechaData.date.toISOString()
      );
      console.log(resutl);
      setNota(resutl.data[0].nota);
      setDeshabilitado(resutl.data[0].status);
      setLoading(false);
      setNotas(resutl.data);
    } catch (error) {}
  };

  const generarNota = async () => {
    try {
      const dia = {
        nota: nota,
        startDate: fechaData.date,
        endDate: fechaData.date,
        status: deshabilitado,
      };
      console.log(notas);
      if (notas[0]) {
        dia.id = notas[0].id;
        await CalendarioService.updateNota(dia);
      } else {
        await CalendarioService.createNota(dia);
      }
      handleStatus(true);
    } catch (error) {
      toast.info(error.error);
    }
  };

  const deleteNota = async () => {
    try {
      const dia = {
        nota: nota,
        startDate: fechaData.date,
        endDate: fechaData.date,
        status: deshabilitado,
      };
      if (notas[0]) {
        dia.id = notas[0].id;
        await CalendarioService.deleteNota(dia);
      }
      handleStatus(true);
    } catch (error) {
      toast.info(error.error);
    }
  };

  return (
    <Box sx={{ width: "450px" }} role="presentation" className="text-uppercase">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div
          className="card"
          style={{
            border: "none",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <a
            class="bg-white shadow rounded p-3 m-1 text-center d-inline-block"
            href="javascript:void(0)"
          >
            <span class="y d-block">{fechaData.date.toLocaleDateString()}</span>
          </a>

          <div
            className="card-body"
            style={{
              marginTop: "2rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              boxShadow: "none",
            }}
          >
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deshabilitado}
                    onChange={handleChangeDeshabilitado}
                    sx={{
                      color: "#506690",
                      "&.Mui-checked": {
                        color: "#9b242c",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                    }}
                  />
                }
                sx={
                  deshabilitado
                    ? {
                        color: "#9b242c",
                      }
                    : {
                        color: "#506690",
                      }
                }
                label="Marcar dia no disponible"
              />
            </div>
            <span class="flex-none text-muted" style={{ width: "120px" }}>
              Nota:
            </span>
            <div
              style={{
                display: "flex",
                gap: "2rem",
              }}
            >
              <TextareaAutosize
                value={nota}
                style={{
                  border: "none",
                  width: "100%",
                  height: "76px",
                  backgroundColor: "#f8f9fa",
                  textTransform: "uppercase",
                }}
                minRows={2}
                maxRows={2}
                onChange={(event) => {
                  setNota(event.target.value.toUpperCase());
                }}
              />
            </div>
            <div className="d-flex justify-content-between">
              <ColorButton
                variant="outlined"
                onClick={() => {
                  handleStatus(false);
                }}
              >
                Cancelar
              </ColorButton>{" "}
              <ColorButton
                variant="outlined"
                onClick={() => {
                  generarNota();
                }}
              >
                Guardar
              </ColorButton>
              <ColorButton
                variant="outlined"
                onClick={() => {
                  deleteNota();
                }}
              >
                Limpiar
              </ColorButton>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default FormAgenda;
