/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import Button from "@mui/material/Button";
import DropZone from "../../DropZone";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";

/* services */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.service";
import useMediaQuery from "@mui/material/useMediaQuery";

const EditarCarrusel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [carrusel, setCarrusel] = useState();
  const matchesmd = useMediaQuery("(min-width:1200px)");
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    loadCarruselItem();
  }, []);

  const loadCarruselItem = async () => {
    setLoading(true);
    try {
      const result = await CarruselService.getById(parseInt(id));
      if (result.imagen) {
        const imagen = await S3Service.get(result.imagen);
        setImagen(imagen.result);
      }
      setCarrusel(result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(true);
      return;
    }
  };

  const updateCarrusel = async () => {
    setLoading(true);
    try {
      const dataCarrusel = carrusel;
      if (file) {
        await S3Service.delete(dataCarrusel.imagen);
        const uploadPhoto = await S3Service.upload(file);
        dataCarrusel.imagen = uploadPhoto.result.data;
        await CarruselService.update(dataCarrusel);
      } else {
        await CarruselService.update(dataCarrusel);
      }
      setLoading(false);
      navigate("/carousel");
      toast.success("Item de carrusel actualizado");
    } catch (error) {
      toast.error(error);
      setLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Editar Item</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/carousel")}
                    >
                      Carrusel
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Editar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold">
                      Editar imagen
                    </div>
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              value={carrusel?.titulo}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  titulo: e.target.value,
                                })
                              }
                            />
                            <label>Subtítulo</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              value={carrusel?.subtitulo}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  subtitulo: e.target.value,
                                })
                              }
                              placeholder="Mensaje"
                            />
                            <label htmlFor="shipping__last_name">Mensaje</label>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="form-floating my-3">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              value={carrusel?.url}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  url: e.target.value,
                                })
                              }
                              placeholder="Url"
                            />
                            <label
                              className="w-100 text-truncate"
                              htmlFor="shipping__address_1"
                            >
                              Url
                            </label>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-floating my-3">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              value={carrusel?.boton}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  boton: e.target.value,
                                })
                              }
                              placeholder="Texto del botón. Ej:ver más"
                            />
                            <label
                              className="w-100 text-truncate"
                              htmlFor="shipping__address_1"
                            >
                              Texto
                            </label>
                          </div>
                        </div>
                        {file ? (
                          <>
                            <div
                              style={
                                matches
                                  ? { textAlign: "center" }
                                  : { textAlign: "center", maxWidth: "23rem" }
                              }
                            >
                              <img
                                src={URL.createObjectURL(file)}
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                  width: "-webkit-fill-available",
                                }}
                              />
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  width: "-webkit-fill-available",
                                }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          </>
                        ) : null}
                        {!file && carrusel.imagen ? (
                          <>
                            <div
                              style={
                                matches
                                  ? { textAlign: "center" }
                                  : { textAlign: "center", maxWidth: "100%" }
                              }
                            >
                              <img
                                src={String(imagen)}
                                style={
                                  matchesmd
                                    ? {
                                        height: 300,
                                        objectFit: "contain",
                                        width: "100%",
                                      }
                                    : matches
                                    ? {
                                        height: 300,
                                        objectFit: "contain",
                                        width: "89%",
                                      }
                                    : {
                                        height: 300,
                                        objectFit: "contain",
                                        width: "100%",
                                      }
                                }
                              />
                            </div>
                          </>
                        ) : null}
                        <DropZone
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => updateCarrusel()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>{" "}
                  {"  "}
                  <span className="small">Editar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EditarCarrusel;
