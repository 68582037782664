/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import Button from "@mui/material/Button";
import DropZone from "../../DropZone";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import Preloader from "../../utils/Preloader";

/* Servicios */
import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.service";

const EliminarUsuario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UserService.getById(id);
      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        setImagen(imagen.result);
      }
      setUsuario(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUsuario = async () => {
    setLoading(true);
    try {
      const noticiaBorrada = await UserService.remove(id);
      toast.info("Usuario eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/usuario");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Eliminar usuario</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/usuario")}
                    >
                      Usuarios
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="h5 card-header border-bottom-0 fw-bold">
                      Usuario
                    </div>
                    {file ? (
                      <div>
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={URL.createObjectURL(file)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              style={{
                                width: "-webkit-fill-available",
                              }}
                            >
                              {file.name}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {!file && usuario.imagen ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={String(imagen)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Nombre"
                              value={usuario?.nombre}
                              disabled={true}
                            />
                            <label>Nombre</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <select
                              class="form-select shadow-none"
                              id="shipping__country"
                              name="shipping[country]"
                              aria-label="Country"
                              disabled={true}
                              value={usuario.tipo}
                            >
                              <option disabled="disabled" selected="selected">
                                Selecciona una
                              </option>
                              <option>Administrador</option>
                              <option>Usuario</option>
                              <option>Empresa</option>
                            </select>

                            <label htmlFor="shipping__last_name">Tipo</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              disabled={true}
                              value={usuario.correo}
                              placeholder="Correo"
                            />
                            <label htmlFor="shipping__last_name">Correo</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              className="form-control shadow-none"
                              disabled={true}
                              placeholder="Contraseña"
                              value={usuario.contraseña}
                              type="password"
                            />
                            <label htmlFor="shipping__last_name">
                              Contraseña
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => deleteUsuario()}
                >
                  <span className="small">Eliminar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EliminarUsuario;
