/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import DropComponent from "../imagesDrop";

/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

const CrearCarrusel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [categoria, setCategoria] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [etiquetas, setEtiquetas] = useState("");
  const [contenido, setContenido] = useState("");
  const [imagenesProducto, setImagenesProducto] = useState([]);
  const [imagenesOriginal, setImagenesOriginal] = useState([]);

  useEffect(() => {
    getNoticia();
    getCategorias();
  }, []);

  const getNoticia = async () => {
    setLoading(true);
    try {
      const data = await NoticiaService.getById(id);
      setNoticia(data[0]);

      const contentHTML = convertFromHTML(data[0].cuerpoNoticia);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );

      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);
      console.log("cateegoria: " + data[0].categoria);

      // if (data[0].imagenes) {
      //   for (const imagen of data.imagenes) {
      //     const result = await S3Service.get(imagen);
      //     setImagenesProducto((imagenesProducto) => [
      //       ...imagenesProducto,
      //       result.result,
      //     ]);
      //   }
      // }

      let imagenes = [];
      data[0].links = [];
      if (data[0].imagen) {
        for (const imagen of data[0].imagen) {
          console.log(imagen);
          const result = await S3Service.get(imagen);
          imagenes.push(result.result);
          data[0].links.push(result.result);
          // setImagenesProducto((imagenesProducto) => [
          //   ...imagenesProducto,
          //   result.result,
          // ]);
          console.log(result.result);
        }
        console.log(imagenes);
        setImagenesProducto(imagenes);
        setImagenesOriginal(data.imagenes);
      }
      console.log(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategorias(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  const updateNoticia = async () => {
    if (!noticia.titulo) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (noticia.cuerpoNoticia.length == 11) {
      toast.info("Error, tienes que colocar texto a la noticia");
    } else {
      setLoading(true);
      try {
        const dataNoticia = noticia;
        let imagenesArray = []; //Array para los id del s3
        /* Subida de imagenes del carrusel */
        for (const file of imagenesProducto) {
          if (!file.urlImaen) {
            const resultFile = await S3Service.upload(file);
            imagenesArray.push(resultFile.result.data);
          } else {
            const idImagen = file.urlImaen.split("/")[3].split("?")[0];
            imagenesArray.push(idImagen);
          }
        }

        const {
          id,
          titulo,
          usuarioId,
          cuerpoNoticia,
          fraseCelebre,
          autorFraseCelebre,
          categoria,
        } = dataNoticia;

        const dataNoticiasu = {
          id,
          titulo,
          usuarioId,
          cuerpoNoticia,
          fraseCelebre,
          autorFraseCelebre,
          categoria,
          imagen: imagenesArray,
        };

        const updateNoticia = await NoticiaService.update(dataNoticiasu);

        toast.success("Noticia actualizada con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/blog");
      }
    }
  };

  const myTheme = createTheme({});

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* PAGE TITLE */}
            <header>
              <h1 className="h4">Editar entrada</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                  <li className="breadcrumb-item">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/blog")}
                    >
                      Blog
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Editar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className="row g-4">
                <div className="col">
                  {/* Shipping address */}
                  <div className="section mb-4">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Título"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  titulo: e.target.value.toUpperCase(),
                                })
                              }
                              value={noticia.titulo}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-floating">
                            <div>
                              <ThemeProvider theme={myTheme}>
                                <MUIRichTextEditor
                                  label="Cuerpo de la entrada... "
                                  controls={[
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "highlight",
                                    "undo",
                                    "redo",
                                    "link",
                                    "numberList",
                                    "bulletList",
                                    "quote",
                                    "code",
                                    "clear",
                                    // "save"
                                  ]}
                                  // onSave={save}
                                  inlineToolbar={true}
                                  onChange={(value) =>
                                    setNoticia({
                                      ...noticia,
                                      cuerpoNoticia: stateToHTML(
                                        value.getCurrentContent()
                                      ),
                                    })
                                  }
                                  defaultValue={contenido}
                                />
                              </ThemeProvider>
                              <br></br>
                              <br></br>
                            </div>
                          </div>
                          {file ? (
                            <div>
                              <div className="col-lg-12">
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    style={{
                                      height: 300,
                                      objectFit: "contain",
                                      width: "-webkit-fill-available",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    style={{
                                      width: "-webkit-fill-available",
                                    }}
                                  >
                                    {file.name}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropComponent
                              label="Tamaño recomendado 900px * 600px"
                              footer="Archivos aceptados png,jpeg,jpg"
                              subirImagen={(data) => setImagenesProducto(data)}
                              imagenesDefault={noticia.links}
                            />
                          </div>

                          {/*{!file && noticia.imagen ? (
              <div>
                <div className="col-lg-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={String(imagen)}
                      style={{
                        height: 300,
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropZone
                              saveFile={async (file) => {
                                setFile(file);
                                var reader = new FileReader();
                                var url = reader.readAsDataURL(file);
                              }}
                            />
                          </div>*/}
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Frase celebre"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  fraseCelebre: e.target.value.toUpperCase(),
                                })
                              }
                              value={noticia.fraseCelebre}
                            />
                            <label>Frace celebre</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control shadow-none text-uppercase"
                              placeholder="Frase celebre"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  autorFraseCelebre: e.target.value,
                                })
                              }
                              value={noticia.autorFraseCelebre}
                            />
                            <label>Autor</label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating">
                            <select
                              class="form-select shadow-none"
                              id="shipping__country"
                              name="shipping[country]"
                              aria-label="Country"
                              onChange={(e) => {
                                setNoticia({
                                  ...noticia,
                                  categoria: e.target.value,
                                });
                              }}
                              value={noticia.categoria}
                              defaultValue={noticia.categoria}
                            >
                              <option disabled="disabled" selected="selected">
                                SELECCIONA UNA
                              </option>
                              {categorias.map((record, index) => {
                                if (record.tipo == noticia.categoria) {
                                  return (
                                    <option
                                      value={record.tipo}
                                      selected="selected"
                                    >
                                      {record.tipo?.toUpperCase()}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={record.tipo}>
                                      {record.tipo?.toUpperCase()}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                            <label>Autor</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-12">
              <div
                className="card-footer border-light"
                style={{ textAlign: "right" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => updateNoticia()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span>Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
