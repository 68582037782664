import { Route, Routes, Navigate } from "react-router-dom";
import FullPageLoading from "../utils/FullPageLoading";

//-----Private components------
import PrivateLayout from "../components/private/layout";
//Carrusel
import CarruselTable from "../components/private/carrusel/CarruselTable";
import CrearCarrusel from "../components/private/carrusel/CrearCarrusel";
import EditarCarrusel from "../components/private/carrusel/EditarCarrusel";
import EliminarCarrusel from "../components/private/carrusel/EliminarCarrusel";
//Blog
import TablaBlog from "../components/private/blog/TablaBlog";
import CrearBlog from "../components/private/blog/CrearBlog";
import EditarBlog from "../components/private/blog/EditarBlog";
import EliminarBlog from "../components/private/blog/EliminarBlog";
//Categoria Blog
import TablaCategoriaBlog from "../components/private/categoria/TablaCategoriaBlog";
//Producto
import TablaProducto from "../components/private/producto/TablaProducto";
import CrearProducto from "../components/private/producto/CrearProducto";
import EditarProducto from "../components/private/producto/EditarProducto";
import EliminarProducto from "../components/private/producto/EliminarProducto";
//Categoria Producto
import TablaCategoriaProducto from "../components/private/producto/categoria/TablaCategoriaProducto";

//Servicios
import TablaServicios from "../components/private/servicios/TablaServicios";
import CrearServicio from "../components/private/servicios/CrearServicio";
import EditarServicio from "../components/private/servicios/EditarServicio";
import EliminarServicio from "../components/private/servicios/EliminarServicio";

//Usuarios
import TablaUsuarios from "../components/private/usuarios/TablaUsuario";
import NuevoUsuario from "../components/private/usuarios/NuevoUsuario";
import EditarUsuario from "../components/private/usuarios/EditarUsuario";
import EliminarUsuario from "../components/private/usuarios/EliminarUsuario";

//index
import TablaIndex from "../components/private/index/TablaIndex";
//terminos
import TablaTerminos from "../components/private/terminos/TablaTerminos";
//aviso
import TablaAviso from "../components/private/aviso/TablaAviso";
//footer
import TablaFooter from "../components/private/footer/TablaFooter";
//dashboard
import Dashboard from "../components/private/dashboard/dashboard";
//Mensajes
import TablaMensaje from "../components/private/mensaje/TablaMensaje";

import TablaPermisos from "../components/private/usuarios/TablaPermisos";

//Usuarios
import TablaClientes from "../components/private/cliente/TablaUsuario";
import NuevoCliente from "../components/private/cliente/NuevoUsuario";
import EditarCliente from "../components/private/cliente/EditarUsuario";
import EliminarCliente from "../components/private/cliente/EliminarUsuario";

import TablaTickets from "../components/private/tickets/TablaTickets";
import TicketsDetails from "../components/private/tickets/TicketDetails";
import CrearTicket from "../components/private/tickets/CrearTicket";
import ListaTickets from "../components/private/tickets/ListaTickets";

import TablaSalidas from "../components/private/salidas/TablaTickets";

// import Invoice from "../components/private/dashboard/Invoice";
import PrivateRoute from "./PrivateRoute";
import TablaCorte from "../components/private/corte/TablaCorte";
import TablaCorteShort from "../components/private/corte/TablaCorteShort";

import TablaConfiguracion from "../components/private/configuracion/TablaConfiguracion";

//Producto
// import TablaInventario from "../components/private/inventario/TablaInventario";
// import CrearInventario from "../components/private/inventario/CrearInventario";
// import EditarInventario from "../components/private/inventario/EditarInventario";
// import EliminarInventario from "../components/private/inventario/EliminarInventario";
//Categoria Producto
// import TablaCategoriaInventario from "../components/private/inventario/categoria/TablaCategoriaInventario";
/*
import Places from "./components/private/paquetes/Places";
//Pagos
import TablaPagos from "./components/private/pagos/TablaPagos";
import Corte from "./components/private/pagos/Corte";
import EliminarPago from "./components/private/pagos/EliminarPago";
//Contactanos
import TablaContactanos from "./components/private/contactanos/TablaContactanos";

//Nosotros
import TablaNosotros from "./components/private/nosotros/TablaContactanos";


//Galeria
import GaleriaTable from "./components/private/galeria/GaleriaTable";
import CrearGaleria from "./components/private/galeria/CrearGaleria";
import EditarGaleria from "./components/private/galeria/EditarGaleria";
import EliminarGaleria from "./components/private/galeria/EliminarGaleria";
*/
/*

//Paquetes
import TablaPaquetes from "./components/private/paquetes/TablaPaquetes";
import CrearPaquete from "./components/private/paquetes/CrearPaquete";
import EliminarPaquete from "./components/private/paquetes/EliminarPaquete";
import EditarPaquet from "./components/private/paquetes/EditarPaquet";
//Servicios
import TablaServicios from "./components/private/servicios/TablaServicios";
import CrearServicio from "./components/private/servicios/CrearServicio";
import EliminarServicio from "./components/private/servicios/EliminarServicio";
import EditarServicio from "./components/private/servicios/EditarServicio";

//Ubicacion
import TablaUbicacion from "./components/private/ubicacion/TablaUbicacion";
import NuevaUbicacion from "./components/private/ubicacion/NuevaUbicacion";
import EditarUbicacion from "./components/private/ubicacion/EditarUbicacion";
import EliminarUbicacion from "./components/private/ubicacion/EliminarUbicacion";
/**/

import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import TicketsConfiguracion from "../components/private/configuracion/Tickets";
import SalidasConfiguracion from "../components/private/configuracion/Salidas";
import CalendarioConfiguracion from "../components/private/configuracion/Calendario";
import ImpresionConfiguracion from "../components/private/configuracion/Impresion";
import CrearTicketSalida from "../components/private/tickets/CrearTicketSalida";
import TablaReportes from "../components/private/reportes/TablaReportes";
import TablaOrdenesGeneradas from "../components/private/reportes/ordenesGeneradas/TablaOrdenesGeneradas";
import TablaOrdenesPendientes from "../components/private/reportes/ordenesPendientes/TablaOrdenesPendientes";
import CrearTicketSalidaDetails from "../components/private/tickets/CrearTicketSalidaDetails";
import ListCorte from "../components/private/corte/ListCorte";
import TablaCorteDetails from "../components/private/corte/TablaCorteDetaild";

const AdminRoutes = () => {
  const { checkPermissions } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/" element={<PrivateLayout />}>
        <Route index element={<Dashboard />} />
        <Route
          path="carousel"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("carousel", ["Ver", "Ver,Editar"])}
            >
              <CarruselTable />
            </PrivateRoute>
          }
        />
        <Route
          path="carousel/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("carousel", ["Ver,Editar"])}
            >
              <CrearCarrusel />
            </PrivateRoute>
          }
        />
        <Route
          path="carousel/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("carousel", ["Ver,Editar"])}
            >
              <EditarCarrusel />
            </PrivateRoute>
          }
        />
        <Route
          path="carousel/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("carousel", ["Ver,Editar"])}
            >
              <EliminarCarrusel />
            </PrivateRoute>
          }
        />

        <Route
          path="blog"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("blog", ["Ver", "Ver,Editar"])}
            >
              <TablaBlog />
            </PrivateRoute>
          }
        />
        <Route
          path="blog/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("blog", ["Ver,Editar"])}
            >
              <CrearBlog />
            </PrivateRoute>
          }
        />
        <Route
          path="blog/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("blog", ["Ver,Editar"])}
            >
              <EditarBlog />
            </PrivateRoute>
          }
        />
        <Route
          path="blog/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("blog", ["Ver,Editar"])}
            >
              <EliminarBlog />
            </PrivateRoute>
          }
        />
        <Route
          path="categoriaBlog"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("categorias de blog", ["Ver,Editar"])}
            >
              <TablaCategoriaBlog />
            </PrivateRoute>
          }
        />

        <Route
          path="producto"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("producto", ["Ver", "Ver,Editar"])}
            >
              <TablaProducto />
            </PrivateRoute>
          }
        />
        <Route
          path="producto/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("producto", ["Ver,Editar"])}
            >
              <CrearProducto />
            </PrivateRoute>
          }
        />
        <Route
          path="producto/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("producto", ["Ver,Editar"])}
            >
              <EditarProducto />
            </PrivateRoute>
          }
        />
        <Route
          path="producto/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("producto", ["Ver,Editar"])}
            >
              <EliminarProducto />
            </PrivateRoute>
          }
        />
        <Route
          path="categoriaProducto"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("categorias de producto", [
                "Ver,Editar",
              ])}
            >
              <TablaCategoriaProducto />
            </PrivateRoute>
          }
        />

        <Route
          path="servicio"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("servicios", ["Ver", "Ver,Editar"])}
            >
              <TablaServicios />
            </PrivateRoute>
          }
        />
        <Route
          path="servicio/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("servicios", ["Ver,Editar"])}
            >
              <CrearServicio />
            </PrivateRoute>
          }
        />
        <Route
          path="servicio/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("servicios", ["Ver,Editar"])}
            >
              <EditarServicio />
            </PrivateRoute>
          }
        />
        <Route
          path="servicio/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("servicios", ["Ver,Editar"])}
            >
              <EliminarServicio />
            </PrivateRoute>
          }
        />

        <Route
          path="usuario"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("usuarios", ["Ver", "Ver,Editar"])}
            >
              <TablaUsuarios />
            </PrivateRoute>
          }
        />
        <Route
          path="usuario/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("usuarios", ["Ver,Editar"])}
            >
              <NuevoUsuario />
            </PrivateRoute>
          }
        />
        <Route
          path="usuario/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("usuarios", ["Ver,Editar"])}
            >
              <EditarUsuario />
            </PrivateRoute>
          }
        />
        <Route
          path="usuario/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("usuarios", ["Ver,Editar"])}
            >
              <EliminarUsuario />
            </PrivateRoute>
          }
        />
        <Route
          path="usuario/permisos/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("usuarios", ["Ver,Editar"])}
            >
              <TablaPermisos />
            </PrivateRoute>
          }
        />

        <Route
          path="index"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("pagina inicio", [
                "Ver",
                "Ver,Editar",
              ])}
            >
              <TablaIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="terminos"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("terminos y condiciones", [
                "Ver",
                "Ver,Editar",
              ])}
            >
              <TablaTerminos />
            </PrivateRoute>
          }
        />
        <Route
          path="aviso"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("aviso privacidad", [
                "Ver",
                "Ver,Editar",
              ])}
            >
              <TablaAviso />
            </PrivateRoute>
          }
        />
        <Route
          path="footer"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("footer", ["Ver", "Ver,Editar"])}
            >
              <TablaFooter />
            </PrivateRoute>
          }
        />

        <Route
          path="mensajes"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("mensajes", ["Ver", "Ver,Editar"])}
            >
              <TablaMensaje />
            </PrivateRoute>
          }
        />

        <Route
          path="clientes"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("clientes", ["Ver", "Ver,Editar"])}
            >
              <TablaClientes />
            </PrivateRoute>
          }
        />
        <Route
          path="cliente/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("clientes", ["Ver,Editar"])}
            >
              <NuevoCliente />
            </PrivateRoute>
          }
        />
        <Route
          path="cliente/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("clientes", ["Ver,Editar"])}
            >
              <EditarCliente />
            </PrivateRoute>
          }
        />
        <Route
          path="cliente/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("clientes", ["Ver,Editar"])}
            >
              <EliminarCliente />
            </PrivateRoute>
          }
        />

        <Route
          path="tickets"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver", "Ver,Editar"])}
            >
              <TablaTickets />
            </PrivateRoute>
          }
        />
        <Route
          path="salidas"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver", "Ver,Editar"])}
            >
              <TablaSalidas />
            </PrivateRoute>
          }
        />
        <Route
          path="tickets/lista/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver", "Ver,Editar"])}
            >
              <ListaTickets />
            </PrivateRoute>
          }
        />
        <Route
          path="ticket/detalles/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver", "Ver,Editar"])}
            >
              <TicketsDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="ticket/salida/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver", "Ver,Editar"])}
            >
              <CrearTicketSalidaDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="ticket/nuevo/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver,Editar"])}
            >
              <CrearTicket />
            </PrivateRoute>
          }
        />
        <Route
          path="ticket/crear/"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver,Editar"])}
            >
              <CrearTicket />
            </PrivateRoute>
          }
        />
        <Route
          path="ticket-salida/crear/"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver,Editar"])}
            >
              <CrearTicketSalida />
            </PrivateRoute>
          }
        />
        <Route
          path="corte"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver,Editar"])}
            >
              <TablaCorte />
            </PrivateRoute>
          }
        />
        <Route
          path="cortes/historial"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver,Editar"])}
            >
              <ListCorte />
            </PrivateRoute>
          }
        />
        <Route
          path="cortes/detalles/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver,Editar"])}
            >
              <TablaCorteDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="corteshort"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("tickets", ["Ver,Editar"])}
            >
              <TablaCorteShort />
            </PrivateRoute>
          }
        />
        <Route
          path="reportes"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("reportes", ["Ver,Editar"])}
            >
              <TablaReportes />
            </PrivateRoute>
          }
        />
        <Route
          path="reportes/ordenes/generadas"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("configuracion", ["Ver,Editar"])}
            >
              <TablaOrdenesGeneradas />
            </PrivateRoute>
          }
        />
        <Route
          path="reportes/ordenes/pendientes"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("configuracion", ["Ver,Editar"])}
            >
              <TablaOrdenesPendientes />
            </PrivateRoute>
          }
        />
        <Route
          path="configuracion"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("configuracion", ["Ver,Editar"])}
            >
              <TablaConfiguracion />
            </PrivateRoute>
          }
        />
        <Route
          path="configuracion/tickets"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("configuracion", ["Ver,Editar"])}
            >
              <TicketsConfiguracion />
            </PrivateRoute>
          }
        />
        <Route
          path="configuracion/salidas"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("configuracion", ["Ver,Editar"])}
            >
              <SalidasConfiguracion />
            </PrivateRoute>
          }
        />
        <Route
          path="configuracion/calendario"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("configuracion", ["Ver,Editar"])}
            >
              <CalendarioConfiguracion />
            </PrivateRoute>
          }
        />

        <Route
          path="configuracion/impresion"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("configuracion", ["Ver,Editar"])}
            >
              <ImpresionConfiguracion />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="inventario"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("inventario", ["Ver", "Ver,Editar"])}
            >
              <TablaInventario />
            </PrivateRoute>
          }
        />
        <Route
          path="inventario/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("inventario", ["Ver,Editar"])}
            >
              <CrearInventario />
            </PrivateRoute>
          }
        />
        <Route
          path="inventario/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("inventario", ["Ver,Editar"])}
            >
              <EditarInventario />
            </PrivateRoute>
          }
        />
        <Route
          path="inventario/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("inventario", ["Ver,Editar"])}
            >
              <EliminarInventario />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="categoriaInventario"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkPermissions("categorias de inventario", [
                "Ver,Editar",
              ])}
            >
              <TablaCategoriaInventario />
            </PrivateRoute>
          }
        /> */}

        {/* 

        <Route path="contactanos" element={<TablaContactanos />} />
        <Route path="nosotros" element={<TablaNosotros />} />


       <Route index element={<GaleriaTable />} />
        <Route path="galeria" element={<GaleriaTable />} />
        <Route path="galeria/nuevo" element={<CrearGaleria />} />
        <Route path="galeria/editar/:id" element={<EditarGaleria />} />
        <Route path="galeria/eliminar/:id" element={<EliminarGaleria />} />

        

        <Route path="paquete" element={<TablaPaquetes />} />
        <Route path="paquete/nuevo" element={<CrearPaquete />} />
        <Route path="paquete/eliminar/:id" element={<EliminarPaquete />} />
        <Route path="paquete/:id" element={<PaqueteIndividual />} />
        <Route path="paquete/editar/:id" element={<EditarPaquet />} />

        
        
        <Route path="ubicacion" element={<TablaUbicacion />} />
        <Route path="ubicacion/nuevo" element={<NuevaUbicacion />} />
        <Route path="ubicacion/editar/:id" element={<EditarUbicacion />} />
        <Route path="ubicacion/eliminar/:id" element={<EliminarUbicacion />} />

        
        <Route path="places" element={<Places />} />

        <Route path="pagos" element={<TablaPagos />} />
        <Route path="pagos/corte/:id" element={<Corte />} />
        <Route path="pagos/eliminar/:id" element={<EliminarPago />} />*/}

        <Route path="*" element={<FullPageLoading />} />
      </Route>
      {/**/}
    </Routes>
  );
};

export default AdminRoutes;
