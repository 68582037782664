import httpClient from "./HttpClient";
const prefix = "/calendario";

export default class ConfiguracionService {
  static async createNota(noticia) {
    return (await httpClient.post(`${prefix}/`, noticia)).data;
  }

  static async updateNota(noticia) {
    return (await httpClient.put(`${prefix}/update/${noticia.id}`, noticia))
      .data;
  }

  static async getNotas(year) {
    return (await httpClient.post(`${prefix}/notas/`, { year: year })).data;
  }

  static async getNota(date) {
    return (await httpClient.post(`${prefix}/nota/`, { date: date })).data;
  }

  static async getDias(date, dateentrega) {
    return (
      await httpClient.post(`${prefix}/dias/`, {
        startDate: date,
        endDate: dateentrega,
      })
    ).data;
  }

  static async deleteNota(noticia) {
    return (await httpClient.delete(`${prefix}/delete/${noticia.id}`)).data;
  }
}
