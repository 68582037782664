import printJS from "print-js";

const prefix = `http://localhost:8080/`;

export default class ImpresionService {
  static async printUrl(urlPdf, printer) {
    return fetch(`${prefix}/url?impresora=${printer}&urlPdf=${urlPdf}`, {
      method: "GET",
      mode: "no-cors",
    })
      .then((respuesta) => {
        // Si la respuesta es OK, entonces todo fue bien
        if (respuesta.status === 200) {
          console.log(
            "Impreso correctamente (salvo que se haya indicado un error por parte de PDFtoPrinter"
          );
        } else {
          // Si no, decodificamos el mensaje para ver el error
          respuesta.json().then((mensaje) => {
            console.log("Error: " + mensaje);
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          throw new Error("Servicio no encontrado");
        } else if (error.message) {
          throw new Error(error.message);
        }

        throw new Error(error);
      });
  }

  static async printFile(namePdf, printer) {
    fetch(`${prefix}/?nombrePdf=${namePdf}&impresora=${printer}`, {
      method: "GET",
      mode: "no-cors",
    })
      .then((respuesta) => {
        // Si la respuesta es OK, entonces todo fue bien
        if (respuesta.status === 200) {
          console.log(
            "Impreso correctamente (salvo que se haya indicado un error por parte de PDFtoPrinter"
          );
        } else {
          // Si no, decodificamos el mensaje para ver el error
          respuesta.json().then((mensaje) => {
            console.log("Error: " + mensaje);
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          throw new Error("Servicio no encontrado");
        } else if (error.message) {
          throw new Error(error.message);
        }

        throw new Error(error);
      });
  }

  static async printUrlDialog(url) {
    printJS(url);
  }
}
