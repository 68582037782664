/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FullPageLoading from "../../FullPageLoading";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { toast } from "react-toastify";

/* Servicios */
import NoticiaService from "../../../services/Producto.service";
import Busqueda from "./Busqueda";

const TablaProducto = () => {
  const [noticias, setNoticias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [openEliminar, setOpenEliminar] = useState(false);
  const [noticia, setNoticia] = useState(null);
  const [update, setUpdate] = useState(false);
  const navigate = useNavigate();

  const [search, setSearch] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");

  useEffect(() => {
    searchFunctionPage(filterSearch);
  }, [page, limit, update]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEliminar = () => {
    setOpenEliminar(true);
  };
  const handleCloseEliminar = () => {
    setOpenEliminar(false);
    setNoticia(null);
  };
  const onUpdate = () => {
    setUpdate(!update);
  };

  const deleteCategoria = async () => {
    setLoading(true);
    try {
      const carruselBorrado = await NoticiaService.remove(noticia.id);
      toast.info("Eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      handleCloseEliminar();
      onUpdate();
    }
  };

  const badgeStatus = (status) => {
    if (status) {
      return <span class="badge bg-success">Disponible</span>;
    }

    return <span class="badge bg-secondary">No disponible</span>;
  };

  const getNoticias = async () => {
    setLoading(true);
    try {
      const data = await NoticiaService.list(limit, page * limit);
      setNoticias(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchFunctionPage = async (search) => {
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
      };
      const data = await NoticiaService.listByProductoSearch(searchData);

      if (data?.data) {
        setNoticias(data.data);
        setTotal(data.total);
      }
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchFunction = async (search) => {
    setFilterSearch(search);
    setPage(0);
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
      };
      const data = await NoticiaService.listByProductoSearch(searchData);

      if (data?.data) {
        setNoticias(data.data);
        setTotal(data.total);
      }
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <main id="middle" className="flex-fill mx-auto">
        {/* PAGE TITLE */}
        <header>
          <div class="row g-1 align-items-center">
            <div class="col">
              <h1 class="h4">PRODUCTO</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    HAY {noticias.length}{" "}
                    {noticias.length > 1 ? "PRODUCTOS" : "PRODUCTO"}
                  </li>
                </ol>
              </nav>
            </div>

            <div class="col-auto">
              <a
                href="javascript:void(0);"
                role="button"
                class="btn btn-sm btn-primary d-inline-flex align-items-center"
                onClick={() => navigate(`nuevo`)}
              >
                <svg
                  width="18px"
                  height="18px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                <span class="d-none d-sm-inline-block ps-2">
                  NUEVO PRODUCTO
                </span>
              </a>
            </div>
          </div>
        </header>
        {/* user list */}
        <div className="section p-0">
          <Busqueda
            searchFunction={searchFunction}
            placeholder="Nombre / CATEGORÍA"
          />
          <div className="card-header p-4">
            {loading ? (
              <FullPageLoading />
            ) : (
              <div style={{ padding: "1.8rem" }}>
                {/* item list */}
                <div className="table-responsive-md">
                  <table
                    className="table"
                    style={{ overflowX: "auto", width: "300px !important" }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="small text-muted"
                          style={{ width: "600px" }}
                        >
                          NOMBRE
                        </th>
                        <th className="small text-muted" style={{ width: 800 }}>
                          <a
                            href="javascript:void(0)"
                            className="d-flex link-muted"
                            title="order by inventory"
                            aria-label="order by inventory"
                          >
                            <span className="ms-2">CATEGORIA</span>
                          </a>
                        </th>
                        <th
                          className="small text-muted"
                          style={{ width: 30 }}
                        ></th>
                        <th
                          className="small text-muted"
                          style={{ width: 200, marginLeft: "20px" }}
                        >
                          <a
                            href="javascript:void(0)"
                            className="d-flex link-muted"
                            title="order by inventory"
                            aria-label="order by inventory"
                          >
                            <span className="ms-2">PRECIO</span>
                          </a>
                        </th>
                        <th className="small text-muted" style={{ width: 250 }}>
                          <a
                            href="javascript:void(0)"
                            className="d-flex link-muted"
                            title="order by inventory"
                            aria-label="order by inventory"
                          >
                            <span className="ms-2">DESCUENTO</span>
                          </a>
                        </th>
                        <th className="small text-muted" style={{ width: 20 }}>
                          <a
                            href="javascript:void(0)"
                            className="d-flex link-muted"
                            title="order by price"
                            aria-label="order by inventory"
                          >
                            <span className="ms-2"></span>
                          </a>
                        </th>
                        <th className="small text-muted" style={{ width: 20 }}>
                          <a
                            href="javascript:void(0)"
                            className="d-flex link-muted"
                            title="order by price"
                            aria-label="order by inventory"
                          >
                            <span className="ms-2"></span>
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {noticias?.map((record, index) => {
                        const regex = /<[^>]'>/gim;
                        return (
                          <tr key={`${record.id}_${record.nombre}`}>
                            <td
                              className="fw-medium"
                              style={{ cursor: "default" }}
                            >
                              <p style={{ margin: "0" }}>{record.nombre}</p>
                              {badgeStatus(record.disponible)}
                              <span class="badge fw-normal">
                                {/* <span class="text-muted">SKU-0019</span>, */}
                                {/* <span class="text-gray-400">
                                    36 orders, 3688 views
                                  </span> */}
                              </span>
                            </td>
                            <td>{record.categoria?.tipo}</td>
                            <td></td>
                            <td>
                              <span class="d-block">
                                ${record.precios[0].precio}
                              </span>
                            </td>
                            <td>
                              <span class="d-block">{record.descuento}%</span>
                            </td>
                            <td>
                              <a
                                href="javascript:void(0);"
                                className="btn btn-sm link-normal"
                              >
                                <span
                                  onClick={() =>
                                    navigate(`editar/${record.id}`)
                                  }
                                  className="material-icons-sharp"
                                >
                                  {" "}
                                  edit{" "}
                                </span>
                              </a>
                            </td>
                            <td>
                              <a
                                href="javascript:void(0);"
                                className="btn btn-sm link-normal"
                              >
                                <span
                                  className="material-icons-sharp"
                                  onClick={() => {
                                    setNoticia(record);
                                    handleOpenEliminar(record.id);
                                  }}
                                >
                                  {" "}
                                  delete{" "}
                                </span>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* pagination, selected items */}
                <div className="row">
                  <div className="col py-3 text-center text-md-start"></div>
                  <div className="col-md-auto py-3">
                    {/* pagination */}
                    <nav className="w-100 text-center" aria-label="Pagination">
                      {/* pagination : desktop */}
                      <nav
                        className="text-center float-md-end mt-3 mt-md-0 d-none d-md-flex"
                        aria-label="Pagination"
                      >
                        <TablePagination
                          style={{ color: "var(--color-dark-variant)" }}
                          component="div"
                          classes="recent-orders"
                          labelRowsPerPage="Items por pagina"
                          count={total}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={limit}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </nav>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      {/* /main */}
      {/** ELIMINAR */}
      <Modal
        open={openEliminar}
        onClose={handleCloseEliminar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Eliminar
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="row g-3 mb-3">
              <div className="col-lg-6">
                <p style={{ color: "#ff7782" }}>{noticia?.nombre}</p>
              </div>
              <div className="col-lg-2">
                <Button
                  variant="outlined"
                  color="error"
                  form="form1"
                  onClick={() => deleteCategoria()}
                  style={{ marginLeft: "150%" }}
                >
                  Eliminar
                </Button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default TablaProducto;
