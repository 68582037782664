import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import ReactHtmlParser from "react-html-parser";

const CardPaquetePrecio = ({
  cantidad,
  tiempo,
  precio,
  user,
  id,
  eliminarActual,
  eliminar,
}) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Precio para {user}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Cantidad: {cantidad}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Tiempo: {tiempo}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Precio: ${precio}
        </Typography>
      </CardContent>
      <CardActions>
        {eliminar ? null : (
          <Button
            size="small"
            onClick={() => {
              eliminarActual(id);
            }}
          >
            Eliminar
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default CardPaquetePrecio;
