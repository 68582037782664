/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import Calendar from "rc-year-calendar";
import tippy, { animateFill } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/backdrop.css";
import "tippy.js/animations/shift-away.css";

/* Servicios */
import ConfiguracionService from "../../../services/Configuracion.service";
import CalendarioService from "../../../services/Calendario.service";
import DiaModal from "./DiaModal";

const Calendario = () => {
  let tooltip = null;
  const [statusDiaModal, setStatusDiaModal] = useState(false);
  const [fechaData, setFechaData] = useState(null);
  const handleStatusDiaModal = (newStatus) => {
    setStatusDiaModal(newStatus);
  };

  const [semana, setSemana] = useState([
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ]);

  const [dias, setDias] = useState({
    domingo: false,
    lunes: false,
    martes: false,
    miércoles: false,
    jueves: false,
    viernes: false,
    sábado: false,
  });
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const navigate = useNavigate();
  const [configCalendar, setConfigCalendar] = useState({
    currentYear: new Date().getFullYear(),
    minDate: null,
    maxDate: null,
    language: "es",
    style: "background",
    allowOverlap: true,
    enableRangeSelection: false,
    displayWeekNumber: false,
    roundRangeLimits: false,
    alwaysHalfDay: false,
  });
  const [datasource, setDatasource] = useState([]);
  const onUpdate = () => {
    setUpdate(!update);
  };

  useEffect(() => {
    getDias();
  }, []);

  useEffect(() => {
    getNotas();
  }, [year, update]);

  const getNotas = async () => {
    console.log("GETNOTAS");
    try {
      const data = await CalendarioService.getNotas(year);

      let newDataSource = [];
      data.data.map((newnota) => {
        newDataSource.push({
          ...newnota,
          startDate: new Date(newnota.startDate),
          endDate: new Date(newnota.endDate),
        });
      });
      setDatasource(newDataSource);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getDias = async () => {
    setLoading(true);

    try {
      const data = await ConfiguracionService.getConfiguracionDias();
      console.log(data);
      setDias(data.dias);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const savedias = async (newdias) => {
    try {
      const data = await ConfiguracionService.updateConfiguracionDias({
        dias: newdias,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDiaLaborable = (newdia) => {
    setDias({ ...dias, [newdia]: !dias[newdia] });
    savedias({ ...dias, [newdia]: !dias[newdia] });
  };

  const laborable = (dialaboralbe) => {
    return dias[dialaboralbe];
  };

  const handleDayEnter = (e) => {
    if (e.events.length > 0) {
      var content = "";

      for (var i in e.events) {
        content +=
          '<div class="event-tooltip-content">' +
          '<div class="event-name" style="color:' +
          e.events[i].color +
          '">' +
          (e.events[i].status ? "DIA DESHABILITADO" : "Nota:") +
          "</div>" +
          '<div class="event-details">' +
          e.events[i].nota +
          "</div>" +
          "</div>";
      }

      if (tooltip != null) {
        tooltip.destroy();
        tooltip = null;
      }

      tooltip = tippy(e.element, {
        placement: "auto",
        content: content,
        animateFill: true,
        plugins: [animateFill],
        animation: "shift-away",
        arrow: true,
        allowHTML: true,
      });
      tooltip.show();
    }
  };

  const handleDayLeave = () => {
    if (tooltip !== null) {
      tooltip.destroy();
      tooltip = null;
    }
  };

  const handleYearChange = (e) => {
    setYear(parseInt(e.currentYear));
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <header>
              <h1 class="h4">Calendario</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/configuracion")}
                    >
                      Configuracion
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Calendario
                  </li>
                </ol>
              </nav>
            </header>

            {/* <!-- profile overview --> */}
            <div class="section p-xl-4">
              <p class="lead">Dias laborables</p>
              <div class="timer-countdown timer-countdown-inline small">
                {semana.map((permiso, index) => (
                  <a
                    className={
                      laborable(permiso)
                        ? "bg-white shadow rounded p-3 m-1 text-center d-inline-block"
                        : "bg-white shadow rounded p-3 m-1 text-center d-inline-block text-muted"
                    }
                    href="javascript:void(0)"
                    onClick={() => handleDiaLaborable(permiso)}
                  >
                    <span class="y d-block">{permiso.substring(0, 2)}</span>
                    <span class="smaller">{permiso.toUpperCase()}</span>
                  </a>
                ))}
              </div>
            </div>

            {/* <!-- notifications --> */}
            <div class="section p-xl-4">
              <h3 class="h5 mb-5">Calendario</h3>

              <Calendar
                {...configCalendar}
                dataSource={datasource}
                onDayEnter={(e) => handleDayEnter(e)}
                onDayLeave={() => handleDayLeave()}
                onYearChanged={(e) => handleYearChange(e)}
                onDayClick={(e) => {
                  console.log(
                    `Click on day: ${e.date.toLocaleDateString()} (${
                      e.events.length
                    } events)`
                  );
                  console.log(e);
                  setFechaData({
                    date: e.date,
                  });
                  handleStatusDiaModal(true);
                }}
              />
              <DiaModal
                status={statusDiaModal}
                handleStatus={handleStatusDiaModal}
                fechaData={fechaData}
                onSumit={onUpdate}
              />

              <div class="text-end">
                {/* <button type="submit" class="btn btn-primary" onClick={save}>
                  Guardar cambios
                </button> */}
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default Calendario;
